import {
  faClock,
  faEye,
  faPen,
  faSmile,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import image from "assets/img/icons/spot-illustrations/image.svg";
import { findPlatformByIdentifier } from "components/app/platforms";
import ConfirmModal from "components/common/ConfirmModal";
import Divider from "components/common/Divider";
import Flex from "components/common/Flex";
import PopDrop from "components/common/PopDrop";
import RepeatOptionPop, {
  getCurrentNumberOfWeek,
} from "components/common/RepeatOptionPop";
import AppContext from "context/Context";
import EmojiPicker from "emoji-picker-react";
import APIService from "http/api_service";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Form,
  Modal,
  OverlayTrigger,
  Popover,
  ProgressBar,
  Row,
  Spinner,
} from "react-bootstrap";
import { AiOutlineClose, AiOutlineTag } from "react-icons/ai";
import { FaHashtag } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "react-slidedown/lib/slidedown.css";
import { toast } from "react-toastify";
import { setSelectedPlatformForPreview } from "redux/slices/platformSlice";
import {
  getPublishablePostMedia,
  setPublishablePostMedia,
} from "redux/slices/post_media_slice";
import {
  getPublishablePostTextBody,
  setPublishablePostTextBody,
} from "redux/slices/post_text_body_slice";
import {
  cachePosts,
  canDisablePostButton,
  canExpandPublisherActions,
  canOpenPixelFilePickerDialogForWriteTab,
  canPostNow,
  canPublishIGImageAsStories,
  canPublishIGVideoAsReel,
  canPublishIGVideoAsStories,
  getAboutRepublishingPost,
  getAdvancedContentCreationOptions,
  getAutoSaveDraftContent,
  getBloggerTitle,
  getChangesFoundForDraft,
  getContentUploadingProgress,
  getCurrentDraft,
  getDraftContentIsBeingUsed,
  getEditablePost,
  getEditedPostFlag,
  getExternalMediaFiles,
  getFacebookCarouselLinks,
  getFirstComment,
  getGMBCTAActionType,
  getGMBCTAActionURL,
  getGMBCouponCode,
  getGMBEventSchedule,
  getGMBEventTitle,
  getGMBPostType,
  getGMBRedeemURL,
  getGMBTermsAndConditions,
  getGoogleDriveManager,
  getIGVideoCoverThumbOffset,
  getIsFacebookPostCarousel,
  getIsFacebookPostReel,
  getIsFacebookPostStory,
  getIsYouTubeVideoEmbeddable,
  getIsYouTubeVideoMadeForKids,
  getLoadingPostData,
  getMakingPostFromContentPlanner,
  getManuallyEditedPlatforms,
  getMediaBank,
  getMediaStore,
  getMunchedPostTargetIds,
  getPinterestAltText,
  getPinterestDestinationLink,
  getPinterestTitle,
  getPinterestVideoThumbnail,
  getPostGroupIdentifier,
  getPromptedMissingTextAlert,
  getRedditTitle,
  getRepeatOption,
  getRepeatOptionType,
  getSaveToDraftsButtonClicked,
  getSelectedMediaOption,
  getSelectedPlatforms,
  getSelectedPostCategory,
  getShowMediaOptions,
  getShowPreview,
  getStartedCollectingDraftData,
  getStitchAllowTiktok,
  getTiktokAllowComments,
  getTiktokAllowDuet,
  getTiktokCommercialForBrandedContent,
  getTiktokCommercialForYourBrand,
  getTiktokDiscloseVideoContent,
  getTiktokSelectedViewSetting,
  getTiktokVideoTitle,
  getTimeToPostContent,
  getTimeZoneToPostContent,
  getTwitterMediaAltText,
  getUserChooseToIgnoreMissingTextAlert,
  getUserTags,
  getWordPressTitle,
  getWordpressCategories,
  getWordpressFeaturedImageThumbnail,
  getWordpressFormat,
  getWordpressPostPassword,
  getWordpressPostTags,
  getWordpressSlug,
  getYouTubeVideoCategoryId,
  getYouTubeVideoComment,
  getYouTubeVideoLicense,
  getYouTubeVideoPrivacy,
  getYouTubeVideoTags,
  getYouTubeVideoThumbnail,
  getYouTubeVideoTitle,
  isContentPublishingOngoing,
  makeComposerFullScreen,
  setAdvancedContentCreationOptions,
  setAutoSaveDraftContent,
  setCalendarPostTime,
  setCanExpandPublisherActions,
  setCanSuggestPopularHashTags,
  setChoiceData,
  setContentPublishingInProgress,
  setContentUploadingProgress,
  setCurrentDraft,
  setEditablePost,
  setEditedPostFlag,
  setExternalMediaFiles,
  setFacebookCarouselLinks,
  setFirstComment,
  setForceFetchPosts,
  setGMBCTAActionType,
  setGMBCTAActionURL,
  setGMBCouponCode,
  setGMBEventSchedule,
  setGMBEventSummary,
  setGMBEventTitle,
  setGMBPostType,
  setGMBRedeemURL,
  setGMBTermsAndConditions,
  setGoogleDriveManager,
  setHashTagsSuggested,
  setIgnoreDraftUpdate,
  setIsFacebookPostCarousel,
  setIsFacebookPostReel,
  setIsFacebookPostStory,
  setJustAddedSpecialCharacterToPostTextBody,
  setMakingPostFromContentPlanner,
  setManuallyEditedPlatforms,
  setMediaBank,
  setMunchedPostTargetIds,
  setOpenPixelFilePickerDialogForWriteTab,
  setPinterestAltText,
  setPinterestDestinationLink,
  setPinterestTitle,
  setPinterestVideoThumbnail,
  setPollChoiceValue,
  setPostGroupIdentifier,
  setPostNow,
  setPromptedMissingTextAlert,
  setPublishIGImageAsStories,
  setPublishIGVideoAsReel,
  setPublishIGVideoAsStories,
  setRedditTitle,
  setRefreshCalendar,
  setRemovedMedia,
  setRepeatOption,
  setRepeatOptionType,
  setSaveToDraftsButtonClicked,
  setSelectedPlatforms,
  setSelectedPostCategory,
  setShouldNotifyYouTubeSubscribers,
  setShouldStartSyncingMediasOnPlatformSelection,
  setShowMediaOptions,
  setShowPreview,
  setStartedCollectingDraftData,
  setStitchAllowTiktok,
  setTiktokAllowComments,
  setTiktokAllowDuet,
  setTiktokSelectedViewSetting,
  setTiktokVideoTitle,
  setTimeToPostContent,
  setTwitterMediaAltText,
  setUserChooseToIgnoreMissingTextAlert,
  setYouTubeVideoComment,
  setYouTubeVideoPrivacy,
  setYouTubeVideoThumbnail,
  setYouTubeVideoTitle,
  shouldNotifyYouTubeSubscribers,
  getFacebookFirstComment,
  setFacebookFirstComment
} from "redux/slices/postslice";
import {
  getMediaArrangement,
  setMediaArrangement,
} from "redux/slices/publishable_media_arrangement_slice";
import { getLoggedInUser, setShowFreeUserBillingUpgradeModal } from "redux/slices/user_slice";
import { getActiveWorkSpace, setPlatformVideoUploadLimit } from "redux/slices/workspaceslice";
import { isVideo } from "utils/file_utils";
import { chunkTweetIntoThreads, getTagForPages } from "utils/gen";
import { grabHashTags } from "utils/hash_tags";
import MediaValidator from "utils/mediaValidator";
import StringUtils from "utils/string";
import TextValidator from "utils/textValidator";
import { createDateWithTimeZone } from "utils/time";
import { isFreeWorkspace } from "utils/workspace_utils";
import {
  MetricsProps,
  PlatformData,
  PlatformIdentifier,
  RoutePaths,
} from "../../../../constants";
import AddMediaPop from "./AddMediaPop";
import ComposeTextArea from "./ComposeTextArea";
import { freeUpLocallyCachedPlatforms } from "./CreatePost";
import EmailValidatorPop from "./EmailValidatorPop";
import FirstCommentView from "./FirstCommentView";
import HashTagPop from "./HashTagPop";
import PostAttachmentsPreviewContainer from "./PostAttachmentPreviewContainer";
import PostCategoriesPopup from "./PostCategoriesPopup";
import PrePublishErrorResolver from "./PrePublishErrorResolver";
import SelectedTargetAudienceContainer from "./SelectedTargetAudienceContainer";
import { munchTarget } from "./audience";
import GmbOptionsField from "./gmb_options_field";
import InstagramOptionsField from "./instagram_options_field";
import PinterestOptionsField from "./pinterest_field_options";
import Previewer from "./previewer";
import ExtrernalImageSearchDialogue from "./previewer/ExtrernalImageSearchDialogue";
import GDriveConnectionRequestListener from "./previewer/GDriveConnectionRequestListener";
import RecurringPostOptions from "./recurring-options";
import RedditOptionsField from "./reddit_options_field";
import TiktokOptionsField from "./tiktok_field_options";
import TimeToPostConfigurator, {
  generateTimeClause,
} from "./time_to_post_configurer";
import WordPressOptionsField from "./wordpress_field_options";
import { VscSmiley } from "react-icons/vsc";
import { FcSafe } from "react-icons/fc";
import YouTubeOptionsField from "./youtube_options_field";
import FacebookOptionsField from "./facebook_options_field";
import { LiaImageSolid } from "react-icons/lia";
import { RiEmojiStickerLine } from "react-icons/ri";
import { HiOutlineHashtag } from "react-icons/hi";
import { IoRepeat } from "react-icons/io5";
import { MdCampaign } from "react-icons/md";
import { faClockFour } from "@fortawesome/free-regular-svg-icons";
import {
  getAutoShortenerStatus,
  getIsShortening,
  setAutoShortenerStatus,
} from "redux/slices/links_shortener_slice";
import { saveDraftInTheBackground } from "utils/post_utils";
import BloggerOptionsField from "./blogger_field_options";
import { setPopupUpgradeAlert } from "redux/slices/authSlice";
import LinkedInOptionsField from "./linkedin_option_fields";
import TwitterOptionsField from "./twitter_options_fields";
import { setCollectMetric } from "redux/slices/analytics_slice";



const CreatePostForm = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { config } = useContext(AppContext);
  const selectTargetPlatforms = props.selectTargetPlatforms;
  const openPixelFilePickerDialogForWriteTab = useSelector(
    canOpenPixelFilePickerDialogForWriteTab
  );
  const eliminateTimePicker = props.eliminateTimePicker ?? false;
  const advancedContentCreationOptions = useSelector(
    getAdvancedContentCreationOptions
  );
  const selectedPlatforms = useSelector(getSelectedPlatforms);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openAdvancedPostOptions, setOpenAdvancedPostOptions] = useState(false);
  const [confirmModalTitle, setConfirmModalTitle] = useState("");
  const [confirmModalMessage, setConfirmModalMessage] = useState("");
  const updatablePost = useSelector(getEditablePost);
  const aboutRePublishingPost = useSelector(getAboutRepublishingPost);
  const postTextBody = useSelector(getPublishablePostTextBody);
  const [youtubeDetected, setYoutubeDetected] = useState(false);
  const [instagramDetected, setInstagramDetected] = useState(false);
  const [facebookDetected, setFacebookDetected] = useState(false);
  const [linkedInDetected, setLinkedInDetected] = useState(false);
  const [twitterDetected, setTwitterDetected] = useState(false);
  const [bloggerDetected, setBloggerDetected] = useState(false);
  // const [showMediaOptions, setShowMediaOptions] = useState(false);
  const showMediaOptions = useSelector(getShowMediaOptions);
  const currentDraft = useSelector(getCurrentDraft)
  const isShortening = useSelector(getIsShortening)
  const [
    instagramDetectedForImageStories,
    setInstagramDetectedForImageStories,
  ] = useState(false);
  const notifyYouTubeSubscribers = useSelector(shouldNotifyYouTubeSubscribers);
  const youTubeVideoTitle = useSelector(getYouTubeVideoTitle);
  const youTubeVideoComment = useSelector(getYouTubeVideoComment);
  const youTubeVideoPrivacy = useSelector(getYouTubeVideoPrivacy);
  const youTubeVideoThumbnail = useSelector(getYouTubeVideoThumbnail);
  const wordpressFeaturedImageThumbnail = useSelector(
    getWordpressFeaturedImageThumbnail
  );
  const publishIGVideoAsReel = useSelector(canPublishIGVideoAsReel);
  const publishIGVideoAsStories = useSelector(canPublishIGVideoAsStories);
  const publishIGImageAsStories = useSelector(canPublishIGImageAsStories);
  const igVideoCoverOffset = useSelector(getIGVideoCoverThumbOffset);
  const [redditDetected, setRedditDetected] = useState(false);
  const repeatOption = useSelector(getRepeatOption);
  const redditTitle = useSelector(getRedditTitle);
  const [pinterestDetected, setPinterestDetected] = useState(false);
  const [wordpressDetected, setWordpressDetected] = useState(false);
  const [tikTokDetected, setTikTokDetected] = useState(false);
  const [gmbDetected, setGMBDetected] = useState(false);
  const pinterestTitle = useSelector(getPinterestTitle);
  const pinterestDestinationLink = useSelector(getPinterestDestinationLink);
  const pinterestAltText = useSelector(getPinterestAltText);
  const wordpressTitle = useSelector(getWordPressTitle);
  const bloggerTitle = useSelector(getBloggerTitle);
  const wordpressPostFormat = useSelector(getWordpressFormat);
  const twitterMediaAltText = useSelector(getTwitterMediaAltText);
  const pinterestVideoThumbnail = useSelector(getPinterestVideoThumbnail);
  const gmbPostType = useSelector(getGMBPostType);
  const postNow = useSelector(canPostNow);
  const mediaObjects = useSelector(getPublishablePostMedia);
  const mediaObjectsTotalLength = 10;
  const textAreaRef = useRef();
  const defaultHint = "Write once, publish everywhere";
  const [hint, setHint] = useState(defaultHint);
  const posting = useSelector(isContentPublishingOngoing);
  const canExpandContentPublisher = useSelector(canExpandPublisherActions);
  const [openPollCreationDialog, setOpenPollCreationDialog] = useState(false);
  const externalMediaFiles = useSelector(getExternalMediaFiles);
  const redditRef = useRef();
  const gmbRef = useRef();
  const [openTimeConfigDialog, setOpenTimeConfigDialog] = useState(false);
  const [emailIsVerified, setEmailIsVerified] = useState(true);
  const postGroupIdentifier =
    useSelector(getPostGroupIdentifier) ?? StringUtils.generateRandomString(64);
  const contentUploadProgress = useSelector(getContentUploadingProgress);
  const loggedInUser = useSelector(getLoggedInUser);
  const workspace = useSelector(getActiveWorkSpace);
  const loadingPostData = useSelector(getLoadingPostData);
  const mediaItemsArrangement = useSelector(getMediaArrangement);
  const munchedTargetIds = [...useSelector(getMunchedPostTargetIds)];
  const canPreview = useSelector(getShowPreview);
  const mediaStore = useSelector(getMediaStore);
  const firstComment = useSelector(getFirstComment);
  const wordpressSlug = useSelector(getWordpressSlug);
  const wordpressPostPassword = useSelector(getWordpressPostPassword);
  const wordpressCategory = useSelector(getWordpressCategories);
  const wordpressPostTags = useSelector(getWordpressPostTags);
  const youTubeVideoCategory = useSelector(getYouTubeVideoCategoryId);
  const youTubeVideoTags = useSelector(getYouTubeVideoTags);
  const youTubeVideoLicense = useSelector(getYouTubeVideoLicense);
  const youTubeVideoMadeForKids = useSelector(getIsYouTubeVideoMadeForKids);
  const youTubeVideoEmbeddable = useSelector(getIsYouTubeVideoEmbeddable);
  const timeZone = useSelector(getTimeZoneToPostContent);
  const timeToPost = useSelector(getTimeToPostContent);
  const [centerTimeToPostDialog, setCentreTimeToPostDialog] = useState(true);
  const [recurringOptionsFeedBackData, setRecurringOptionsFeedBackData] =
    useState();
  const [stickyErrorMessage, setStickyErrorMessage] = useState(false);
  const [stickyErrorTitle, setStickyErrorTitle] = useState();
  const [stickyErrorActionMessge, setStickyErrorActionMessage] = useState();
  const [
    stickyErrorSecondaryActionMessage,
    setStickyErrorSecondaryActionMessage,
  ] = useState();
  const [stickyErrorAction, setStickyErrorAction] = useState();
  const [stickyErrorSecondaryAction, setStickyErrorSecondaryAction] =
    useState();
  const [youTubeVideoErrorMessage, setYouTubeVideoErrorMessage] = useState();
  const [redditTitleErrorMessage, setRedditTitleErrorMessage] = useState();
  const [pinterestErrorMessage, setPinterestErrorMessage] = useState();
  const [wordpressErrorMessage, setWordPressErrorMessage] = useState();
  const [bloggerErrorMessage, setBloggerErrorMessage] = useState();
  const [workSpaceOwner, setWorkspaceOwner] = useState();
  const [gmbErrorMessage, setGMBErrorMessage] = useState();
  const [newValueForShortening, setNewValueForShortening] = useState(postTextBody);
  const gmbEventTitle = useSelector(getGMBEventTitle);
  const gmbEventSchedule = useSelector(getGMBEventSchedule);
  const gmbCTAActionType = useSelector(getGMBCTAActionType);
  const gmbCTAActionUrl = useSelector(getGMBCTAActionURL);
  const gmbCouponCode = useSelector(getGMBCouponCode);
  const gmbCouponRedeemURL = useSelector(getGMBRedeemURL);
  const gmbTermsAndConditions = useSelector(getGMBTermsAndConditions);
  const [openCategoriesPopup, setOpenPostCategoriesPopup] = useState(false);
  const selectedPostCategory = useSelector(getSelectedPostCategory);
  const [currentSearchTag, setCurrentSearchTag] = useState("");
  const disablePostButton = useSelector(canDisablePostButton);
  const [displayConfirm, setDisplayConfirm] = useState(false);
  const workSpaceSocketIOEvent = props.workSpaceSocketIOEvent;
  const mediaUploadProgressSocketIOEvent =
    props.mediaUploadProgressSocketIOEvent;
  const googleDriveManager = useSelector(getGoogleDriveManager);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const googleDriveRef = useRef(null);
  const userTags = useSelector(getUserTags);
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
  const [openUpgradeDialogV2, setOpenUpgradeDialogV2] = useState(false);
  const allowComment = useSelector(getTiktokAllowComments);
  const tiktokAllowDuet = useSelector(getTiktokAllowDuet);
  const tiktokAllowStitch = useSelector(getStitchAllowTiktok);
  const tiktokVideoTitle = useSelector(getTiktokVideoTitle);
  const tiktokSelectedViewSetting = useSelector(getTiktokSelectedViewSetting);
  const userChooseToIgnoreMissingTextAlert = useSelector(
    getUserChooseToIgnoreMissingTextAlert
  );
  const promptedMissingTextAlert = useSelector(getPromptedMissingTextAlert);
  const repeatOptionType = useSelector(getRepeatOptionType);
  const [openTwitterThreadReviewConsent, setOpenTwitterThreadReviewConsent] =
    useState(false);
  const manuallyEditedPlatforms = useSelector(getManuallyEditedPlatforms);
  const selectedMediaOption = useSelector(getSelectedMediaOption);
  const isFacebookPostCarousel = useSelector(getIsFacebookPostCarousel);
  const isFacebookPostReel = useSelector(getIsFacebookPostReel);
  const isFacebookPostStory = useSelector(getIsFacebookPostStory);
  let facebookCarouselLinks = useSelector(getFacebookCarouselLinks);
  const autoShortenedStatus = useSelector(getAutoShortenerStatus);
  const mediaBank = useSelector(getMediaBank);
  const saveToDraftsButtonClicked = useSelector(getSaveToDraftsButtonClicked);
  const startedCollectingDraftData = useSelector(getStartedCollectingDraftData);
  const changesFoundForDraft = useSelector(getChangesFoundForDraft)
  const tiktokDiscloseVideoContent = useSelector(getTiktokDiscloseVideoContent);
  const tiktokCommercialForYourBrand = useSelector(getTiktokCommercialForYourBrand);
  const tiktokCommercialForBrandedContent = useSelector(getTiktokCommercialForBrandedContent);
  const makingPostFromContentPlanner = useSelector(getMakingPostFromContentPlanner)
  const [postTextBodyLocalValue, setPostTextBodyLocalValue] = useState('');
  const [postTextCursorPosition, setPostTextCursorPosition] = useState(0);
  const editedPostFlag = useSelector(getEditedPostFlag)
  const fbFirstComment = useSelector(getFacebookFirstComment); 

  const getPlatformVideoUploadLimit = async () => {
    if (workspace._id) {
      APIService.platformVideoUploadLimit(workspace._id, async (response, error) => {
        if (error) {
          return;
        }
        const data = response?.data;
        // console.log("🚀 ~ APIService.platformVideoUploadLimit ~ data:", data);
        dispatch(setPlatformVideoUploadLimit(data))
      });
    }
  }

  useEffect(() => {
    getPlatformVideoUploadLimit();
  }, [workspace?._id])


  const previewElementRef = useRef(null);

  const scrollToElement = () => {
    if (previewElementRef.current) {
      setTimeout(() => {
        previewElementRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 1000);
    }
  };

  let trueTargetPlatforms;

  useEffect(() => {
    if (workSpaceSocketIOEvent) {
      workSpaceEventListener(workSpaceSocketIOEvent);
    }
  }, [workSpaceSocketIOEvent]);

  useEffect(() => {
    if (mediaUploadProgressSocketIOEvent) {
      contentUploadProgressEventListener(mediaUploadProgressSocketIOEvent);
    }
  }, [mediaUploadProgressSocketIOEvent]);

  useEffect(() => {
    if (!openAdvancedPostOptions) {
      if (recurringOptionsFeedBackData) {
        setOpenAdvancedPostOptions(true);
        setRecurringOptionsFeedBackData(undefined);
      }
    }
  }, [openTimeConfigDialog]);

  useEffect(() => {
    if (selectedPlatforms) {
      let youtube = selectedPlatforms.find(
        (x) => x.identifier === PlatformIdentifier.YOUTUBE && x.selected
      );
      if (youtube) {
        setYoutubeDetected(true);
      }
      let reddit = selectedPlatforms.find(
        (x) => x.identifier === PlatformIdentifier.REDDIT && x.selected
      );
      if (reddit) {
        setRedditDetected(true);
      }
      let pinterest = selectedPlatforms.find(
        (x) => x.identifier === PlatformIdentifier.PINTEREST && x.selected
      );
      if (pinterest) {
        setPinterestDetected(true);
      }
      let gmb = selectedPlatforms.find(
        (x) =>
          x.identifier === PlatformIdentifier.GOOGLE_MY_BUSINESS && x.selected
      );
      if (gmb) {
        setGMBDetected(true);
      }
      let instagram = selectedPlatforms.find(
        (x) => x.identifier === PlatformIdentifier.INSTAGRAM && x.selected
      );
      if (instagram && videoDetectedInMedia()) {
        setInstagramDetected(true);
      }
      let facebook = selectedPlatforms.find(
        (x) => x.identifier === PlatformIdentifier.FACEBOOK && x.selected
      );
      if (facebook) {
        setFacebookDetected(true);
      }
      let linkedIn = selectedPlatforms.find(
        (x) => x.identifier === PlatformIdentifier.LINKED_IN && x.selected
      );
      if (linkedIn) {
        setLinkedInDetected(true);
      }
      let twitter = selectedPlatforms.find(
        (x) => x.identifier === PlatformIdentifier.TWITTER && x.selected
      );
      if (twitter) {
        setTwitterDetected(true);
      }
      if (instagram && imageDetectedInInstagramForStories()) {
        setInstagramDetectedForImageStories(true);
      }
      let tikTok = selectedPlatforms.find(
        (x) => x.identifier === PlatformIdentifier.TIKTOK && x.selected
      );
      if (tikTok) {
        setTikTokDetected(true);
      }
      let wordpress = selectedPlatforms.find(
        (x) => x.identifier === PlatformIdentifier.WORDPRESS && x.selected
      );
      let blogger = selectedPlatforms.find((x) => x.identifier === PlatformIdentifier.BLOGGER && x.selected);
      if (blogger) {
        setBloggerDetected(true);
      }
      if (wordpress) {
        setWordpressDetected(true);
      }
    }
  }, [selectedPlatforms]);

  const generateRecurringOptionsPresets = () => {
    let presetObject = {};
    if (advancedContentCreationOptions["daily_repeat_option_type_type"]) {
      presetObject["daily_repeat_option_type_type"] =
        advancedContentCreationOptions["daily_repeat_option_type_type"];
    }
    if (advancedContentCreationOptions["daily_repeat_exceptions"]) {
      presetObject["daily_repeat_exceptions"] =
        advancedContentCreationOptions["daily_repeat_exceptions"];
    }
    if (advancedContentCreationOptions["weekdays_to_repeat"]) {
      presetObject["weekdays_to_repeat"] =
        advancedContentCreationOptions["weekdays_to_repeat"];
    }
    if (advancedContentCreationOptions["specific_date_to_repeat"]) {
      presetObject["specific_date_to_repeat"] =
        advancedContentCreationOptions["specific_date_to_repeat"];
    }
    if (advancedContentCreationOptions["day_of_month_to_repeat"]) {
      presetObject["day_of_month_to_repeat"] =
        advancedContentCreationOptions["day_of_month_to_repeat"];
    }
    if (advancedContentCreationOptions["specific_time_to_repeat"]) {
      presetObject["specific_time_to_repeat"] =
        advancedContentCreationOptions["specific_time_to_repeat"];
    }
    if (advancedContentCreationOptions["stop_time"]) {
      presetObject["stop_time"] = advancedContentCreationOptions["stop_time"];
    }
    return presetObject;
  };

  const draftContent = () => {
    if (postTextBody.length > 0 || mediaObjects.length > 0) {
      let draftPayload = {
        body: postTextBody,
        youTubeVideoTitle,
        reddit_title: redditTitle,
        media: mediaObjects,
        targets: trueTargetPlatforms ?? selectedPlatforms,
        previewsArrangement: mediaItemsArrangement,
        mediaStore: mediaStore,
        draftId: postGroupIdentifier,
        platformEdited: manuallyEditedPlatforms,
      };
      //TODO: Too many [Draft payload too large] errors...Something is bloating the size of the payload above, a second look is needed
      // saveDraftInTheBackground(workspace, draftPayload);
    }
  };

  const togglePublishWindowFooter = () => {
    if (postTextBody.length > 0 || mediaObjects.length > 0) {
      if (!canExpandContentPublisher) {
        dispatch(setCanExpandPublisherActions(true));
      }
    } else {
      if (canExpandContentPublisher && !updatablePost) {
        dispatch(setCanExpandPublisherActions(false));
      }
    }
  };

  useEffect(() => {
    draftContent();
    togglePublishWindowFooter();
  }, [postTextBody, mediaObjects]);

  const handleCursorChange = (event) => {
    const position = event.target.selectionStart;
    // setCursorPosition(position);
    return position
  };

  const onEmojiClick = (emojiObject) => {
    let cursorPosition = postTextCursorPosition;
    // let cursorPosition = textAreaRef.current.selectionStart;
    let textBeforeCursorPosition = postTextBodyLocalValue.substring(
      0,
      cursorPosition
    );
    let textAfterCursorPosition = postTextBodyLocalValue.substring(
      cursorPosition,
      postTextBodyLocalValue.length
    );
    dispatch(setJustAddedSpecialCharacterToPostTextBody(true));
    let newValue =
      textBeforeCursorPosition +
      String.fromCodePoint(parseInt(emojiObject.unified, 16)) +
      textAfterCursorPosition;
    setPostTextBodyLocalValue(newValue)
    // dispatch(setPublishablePostTextBody(newValue));
  };

  const emojiPopOver = (
    <Popover id="popover-emoji">
      <EmojiPicker
        theme={config.isDark ? "dark" : "light"}
        onEmojiClick={onEmojiClick}
      />
    </Popover>
  );

  const platformsTrulySelected = () => {
    const filteredPlatforms = selectedPlatforms.filter((x) => x.identifier && x.selected).length > 0;
    return filteredPlatforms;
  };

  const getRepeatOptionTypeName = (option) => {
    // Get the current date and day of the week
    const currentDate = new Date(timeToPost) ?? new Date();
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentDayOfWeek = daysOfWeek[currentDate.getDay()];

    // Get the current month and day of the month
    const currentMonth = currentDate.toLocaleString("default", {
      month: "long",
    });
    const currentDay = currentDate.getDate();

    // Determine the repeat option type name based on the selected option
    switch (option) {
      case "no_repeat":
        return "Set recurring post";
      case "daily":
        return "Daily";
      case "weekly_on_":
        return `Weekly on ${currentDayOfWeek}`;
      case "monthly_on_the_":
        const currentNumOfWeek = getCurrentNumberOfWeek(timeToPost);
        return `Monthly on the ${currentNumOfWeek} ${currentDayOfWeek}`;
      case "annually_on_":
        return `Annually on ${currentMonth} ${currentDay}`;
      case "every_weekday_":
        return "Every Weekday";
      case "custom":
        return "Custom";
      default:
        return `Doesn't Repeat`;
    }
  };

  const TimeConfigurationRow = () => {
    return (
      <div
        className="border-bottom border-300 pb-3"
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          flexWrap: "wrap",
          alignItems: "center",
          gap: 10,
          justifyContent: "space-around",
          minHeight: 100,
          backgroundColor: config.isDark ? "#121e2d" : "#fff",
        }}
      >
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          className={"gap-3 mt-2 pt-2"}
          wrap={"wrap"}
        >

          <Form.Check
            checked={autoShortenedStatus}
            type="switch"
            id="auto-shorten-link-settings"
            label={"Auto-shorten links"}
            onChange={handleToggle}
          />
        </Flex>
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          className={"gap-3 mt-2 pt-2"}
          wrap={"wrap"}
        >
          <Form.Check
            checked={postNow}
            type="switch"
            id="time-to-post-check"
            label={"Publish now"}
            onChange={(e) => {
              let value = e.target.checked;
              dispatch(setPostNow(value));
              if (value) {
                let newTimeToPostContent = new Date();
                dispatch(setTimeToPostContent(newTimeToPostContent));
              }
            }}
          />
        </Flex>
        <Button
          transform="shrink-3"
          size="sm"
          variant={"outline-primary"}
          className="rounded-pill shadow-none d-inline-flex align-items-center fs--1 mb-0 me-1 p-1 px-3 borderless_bg"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
          }}
          onClick={() => {
            // if (isFreeWorkspace(workspace)) {
            //  dispatch(setPopupUpgradeAlert(true))
            //   return;
            // }
            setOpenTimeConfigDialog(!openTimeConfigDialog);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 5,
            }}
          >
            <FontAwesomeIcon icon={faClockFour} />
            <span>
              {postNow
                ? "Schedule post"
                : `${generateTimeClause(
                  createDateWithTimeZone(timeToPost, timeZone, true)
                )} (${timeZone})`}
            </span>
          </div>
        </Button>

        <PopDrop
          width={270}
          dropDownAnchor={
            <>
              <Button
                onClick={() => {
                  if (isFreeWorkspace(workspace)) {
                    let metricInitializer = {
                      action: MetricsProps.RECURRING_POSTS,
                      detailedAction: `Upgrade notice: Clicked on recurring post button`,
                      timestamp: new Date().toISOString(),
                      route: window.location.pathname.replace(workspace._id, '').replace('//', '/'),
                    }
                    dispatch(setCollectMetric(metricInitializer))
                    dispatch(setPopupUpgradeAlert(true))
                    return;
                  }
                }}
                transform="shrink-3"
                size="sm"
                variant={"outline-primary"}
                className="rounded-pill shadow-none d-inline-flex align-items-center fs--1 mb-0 me-1 p-1 px-3 borderless_bg"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 5,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <IoRepeat size={20} />
                  <span style={{ fontSize: "13px" }}>
                    {getRepeatOptionTypeName(repeatOptionType)}
                  </span>
                </div>
              </Button>
            </>
          }
          PopContainer={<RepeatOptionPop />}
        />
        <Button
          size="md"
          variant={"outline-primary"}
          className="rounded-pill shadow-none d-inline-flex align-items-center fs--1 mb-0 me-1 p-1 px-3 borderless_bg"
          onClick={() => {
            setOpenPostCategoriesPopup(true);
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
          }}
        >
          <MdCampaign size={15} />
          <span style={{ fontSize: "12px" }}>
            {selectedPostCategory?.name}
          </span>
        </Button>

      </div>
    );
  };

  const generateNewMediaEntryFromResult = (arr, identifier, text, result) => {
    let newMediaArr = arr[identifier]?.media ?? [];
    if (result && !newMediaArr.includes(result)) {
      newMediaArr.push(result);
    }
    let newEntry = {
      media: newMediaArr,
      text,
    };
    return newEntry;
  };

  const urlToFile = async (url, filename, mimeType) => {
    let fileExtension = StringUtils.substringAfterLast(filename, ".");
    if (fileExtension.toLowerCase() === "webp") {
      filename = filename.replace(fileExtension, ".png");
    }
    const res = await fetch(url);
    const buf = await res.arrayBuffer();
    return new File([buf], filename, { type: mimeType });
  };

  const singleFileUploadRequest = async (optimized, file, url, name) => {
    let uploadRequestBody = new FormData();
    let fileSize = 0;
    if (optimized) {
      uploadRequestBody.append("file", await urlToFile(url, name, "image/*"));
    } else {
      uploadRequestBody.append("file", file);
      fileSize = file.size;
    }
    return [uploadRequestBody, fileSize];
  };

  const checkAndSkipOptimizedFileUpload = (
    uploadMap,
    optimized,
    name,
    url,
    uploaded
  ) => {
    if (uploadMap[name]) {
      if (!optimized) {
        uploaded = true;
        url = uploadMap[name];
      }
    }
    return [url, uploaded];
  };

  const cacheUploadForUnOptimizedFiles = (
    optimized,
    uploadMap,
    name,
    result
  ) => {
    if (!optimized) {
      uploadMap[name] = result;
    }
  };

  const validateMediaAgainstSelectedPlatforms = async () => {
    let specificTargets = trueTargetPlatforms.map((x) => x.identifier);
    let mediaItemsArrangementCopy = { ...mediaItemsArrangement };
    let mediaItemsArrangementArray = [
      ...Object.entries(mediaItemsArrangementCopy),
    ].filter((x) => specificTargets.includes(x[0]));
    let validatableMedia = [];
    for (let i = 0; i < mediaItemsArrangementArray.length; i++) {
      let arrangedMediaItemEntry = mediaItemsArrangementArray[i];
      let entryIdentifier = arrangedMediaItemEntry[0];
      let mediaAtPoint = arrangedMediaItemEntry[1].media ?? [];
      if (entryIdentifier === PlatformIdentifier.TWITTER) {
        let threads = [...Object.entries(arrangedMediaItemEntry[1])];
        let allThreadMedia = [];
        for (let k = 0; k < threads.length; k++) {
          let thread = threads[k];
          let mediaInThread = thread[1]?.media ?? [];
          if (mediaInThread.length) {
            allThreadMedia = [...allThreadMedia, ...mediaInThread];
          }
        }
        validatableMedia.push({
          identifier: entryIdentifier,
          media: allThreadMedia,
        });
      } else if (entryIdentifier === PlatformIdentifier.THREADS) {
        let threads = [...Object.entries(arrangedMediaItemEntry[1])];
        let allThreadMedia = [];
        for (let k = 0; k < threads.length; k++) {
          let thread = threads[k];
          let mediaInThread = thread[1]?.media ?? [];
          if (mediaInThread.length) {
            allThreadMedia = [...allThreadMedia, ...mediaInThread];
          }
        }
        validatableMedia.push({
          identifier: entryIdentifier,
          media: allThreadMedia,
        });
      }
      else {
        if (mediaAtPoint.length) {
          validatableMedia.push({
            identifier: entryIdentifier,
            media: mediaAtPoint,
          });
        }
      }
    }
    if (validatableMedia.length) {
      return MediaValidator(validatableMedia, {
        publishIGVideoAsStories: publishIGVideoAsStories,
        publishIGVideoAsReel: publishIGVideoAsReel,
      });
    }
    return { success: true };
  };

  const uploadFacebookCarousels = async () => {
    if (!isFacebookPostCarousel) {
      return [];
    }
    let results = [];
    for (let i = 0; i < facebookCarouselLinks.length; i++) {
      let carouselLink = facebookCarouselLinks[i];
      if (carouselLink?.uploaded) {
        results.push(carouselLink);
        continue;
      }
      let file;
      let isEmptyObj =
        Object.keys(carouselLink?.file).length === 0 &&
        carouselLink.file.constructor === Object;
      if (!carouselLink.file && isEmptyObj) {
        file = new File([carouselLink.picture], carouselLink?.name, {
          type: "image/*",
        });
      } else {
        file = carouselLink.file;
      }
      let uploadRequestBody = new FormData();
      let fileSize = file.size;
      uploadRequestBody.append("file", file);
      try {
        let carouselUploadResponse = await APIService.uploadNewFile(
          workspace._id,
          uploadRequestBody,
          fileSize,
          "Facebook carousel",
          i + 1
        );
        results.push({
          picture: carouselUploadResponse.data,
          link: carouselLink.link,
          name: carouselLink.name,
          description: carouselLink.description,
        });
      } catch (e) {
        dispatch(setContentPublishingInProgress(false));
        dispatch(setContentUploadingProgress(null));
        toast.error(
          "Sorry, we could not upload your Facebook carousel. Please try again later.",
          { theme: "colored" }
        );
        return;
      }
    }
    return results;
  };

  const uploadNonPlatformSpecificMedia = async (data, mediaItems) => {
    if ((mediaItems ?? []).length === 0) {
      return {};
    }
    for (let i = 0; i < mediaItems.length; i++) {
      let mediaItem = mediaItems[i];
      if (!mediaItem.uploaded) {
        let file = mediaItem.file;
        let uploadRequestBody = new FormData();
        let fileSize = file.size;
        uploadRequestBody.append("file", file);
        try {
          const fileAlreadyInDraft = mediaBank.find(m => m?.old?.file?.lastModified === file.lastModified && m?.old?.file?.name === file.name && m?.old?.file?.size === file.size);
          let result;
          if (fileAlreadyInDraft) {
            result = fileAlreadyInDraft.new?.url
          } else {
            let response = await APIService.uploadNewFile(
              workspace._id,
              uploadRequestBody,
              fileSize,
              "all targets",
              i + 1
            );
            result = response.data;
          }
          let dataKey = mediaItem.data_key;
          if (dataKey) {
            data[dataKey] = result;
          } else {
            let existingMediaUrls = data.mediaUrls ?? [];
            existingMediaUrls.push(result);
            data.mediaUrls = existingMediaUrls;
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
    return data;
  };

  const uploadArrangedMediaItems = async () => {
    let specificTargets = trueTargetPlatforms.map((x) => x.identifier);
    let transformedMediaArrangementCopy = {};
    let uploadMap = {};
    let mediaItemsArrangementArray = [
      ...Object.entries(mediaItemsArrangement),
    ].filter((x) => specificTargets.includes(x[0]));
    for (let i = 0; i < mediaItemsArrangementArray.length; i++) {
      let arrangedMediaItemEntry = mediaItemsArrangementArray[i];
      let entryIdentifier = arrangedMediaItemEntry[0];
      let platformNameFromIdentifier =
        findPlatformByIdentifier(entryIdentifier)?.name;
      let media = arrangedMediaItemEntry[1].media ?? [];
      let text = arrangedMediaItemEntry[1].text;
      if (entryIdentifier === PlatformIdentifier.TWITTER) {
        let threads = [...Object.entries(arrangedMediaItemEntry[1])];
        let twitterThreads = [];
        for (let k = 0; k < threads.length; k++) {
          let thread = threads[k];
          let mediaInThread = thread[1]?.media ?? [];
          let textInThread = thread[1]?.text;
          if (mediaInThread.length) {
            let newThreadMedia = [];
            for (let tMI = 0; tMI < mediaInThread.length; tMI++) {
              let threadMedia = mediaInThread[tMI];
              let uploaded = threadMedia.uploaded ?? false;
              let name = threadMedia.name;
              let alt_text = threadMedia.alt_text;
              let optimized = threadMedia.optimized ?? false;
              let file = threadMedia.file;
              let url = threadMedia.url;
              [url, uploaded] = checkAndSkipOptimizedFileUpload(
                uploadMap,
                optimized,
                name,
                url,
                uploaded
              );
              if (!uploaded) {
                let uploadRequestBody = await singleFileUploadRequest(
                  optimized,
                  file,
                  url,
                  name
                );
                try {
                  const fileAlreadyInDraft = mediaBank.find(m => m?.old?.file?.lastModified === file.lastModified && m?.old?.file?.name === file.name && m?.old?.file?.size === file.size);
                  let result;
                  if (fileAlreadyInDraft) {
                    result = fileAlreadyInDraft.new?.url
                  } else {
                    let response = await APIService.uploadNewFile(
                      workspace._id,
                      uploadRequestBody[0],
                      uploadRequestBody[1],
                      platformNameFromIdentifier,
                      tMI + 1
                    );
                    result = response.data;
                  }
                  cacheUploadForUnOptimizedFiles(
                    optimized,
                    uploadMap,
                    name,
                    result
                  );
                  newThreadMedia.push({
                    url: result,
                    alt_text,
                  });
                } catch (e) {
                  console.log(e);
                }
              } else {
                newThreadMedia.push({
                  url,
                  alt_text,
                });
              }
            }
            twitterThreads.push({
              media: newThreadMedia,
              text: textInThread,
            });
          } else {
            twitterThreads.push({
              text: textInThread,
            });
          }
        }
        transformedMediaArrangementCopy[entryIdentifier] = twitterThreads;
      } else if (entryIdentifier === PlatformIdentifier.THREADS) {
        let threads = [...Object.entries(arrangedMediaItemEntry[1])];
        let twitterThreads = [];
        for (let k = 0; k < threads.length; k++) {
          let thread = threads[k];
          let mediaInThread = thread[1]?.media ?? [];
          let textInThread = thread[1]?.text;
          if (mediaInThread.length) {
            let newThreadMedia = [];
            for (let tMI = 0; tMI < mediaInThread.length; tMI++) {
              let threadMedia = mediaInThread[tMI];
              let uploaded = threadMedia.uploaded ?? false;
              let name = threadMedia.name;
              let alt_text = threadMedia.alt_text;
              let optimized = threadMedia.optimized ?? false;
              let file = threadMedia.file;
              let url = threadMedia.url;
              [url, uploaded] = checkAndSkipOptimizedFileUpload(
                uploadMap,
                optimized,
                name,
                url,
                uploaded
              );
              if (!uploaded) {
                let uploadRequestBody = await singleFileUploadRequest(
                  optimized,
                  file,
                  url,
                  name
                );
                try {
                  const fileAlreadyInDraft = mediaBank.find(m => m?.old?.file?.lastModified === file.lastModified && m?.old?.file?.name === file.name && m?.old?.file?.size === file.size);
                  let result;
                  if (fileAlreadyInDraft) {
                    result = fileAlreadyInDraft.new?.url
                  } else {
                    let response = await APIService.uploadNewFile(
                      workspace._id,
                      uploadRequestBody[0],
                      uploadRequestBody[1],
                      platformNameFromIdentifier,
                      tMI + 1
                    );
                    result = response.data;
                  }
                  cacheUploadForUnOptimizedFiles(
                    optimized,
                    uploadMap,
                    name,
                    result
                  );
                  newThreadMedia.push({
                    url: result,
                    alt_text,
                  });
                } catch (e) {
                  console.log(e);
                }
              } else {
                newThreadMedia.push({
                  url,
                  alt_text,
                });
              }
            }
            twitterThreads.push({
              media: newThreadMedia,
              text: textInThread,
            });
          } else {
            twitterThreads.push({
              text: textInThread,
            });
          }
        }
        transformedMediaArrangementCopy[entryIdentifier] = twitterThreads;
      }
      else {
        if (media.length) {
          for (let m = 0; m < media.length; m++) {
            let mediaEntry = media[m];
            mediaEntry = { ...mediaEntry };
            let uploaded = mediaEntry.uploaded ?? false;
            let name = mediaEntry.name;
            let optimized = mediaEntry.optimized ?? false;
            let file = mediaEntry.file;
            let url = mediaEntry.url;
            [url, uploaded] = checkAndSkipOptimizedFileUpload(
              uploadMap,
              optimized,
              name,
              url,
              uploaded
            );
            if (!uploaded) {
              let uploadRequestBody = await singleFileUploadRequest(
                optimized,
                file,
                url,
                name
              );
              try {
                const fileAlreadyInDraft = mediaBank.find(m => m?.old?.file?.lastModified === file.lastModified && m?.old?.file?.name === file.name && m?.old?.file?.size === file.size);
                let result;
                if (fileAlreadyInDraft) {
                  result = fileAlreadyInDraft.new?.url
                } else {
                  let response = await APIService.uploadNewFile(
                    workspace._id,
                    uploadRequestBody[0],
                    uploadRequestBody[1],
                    platformNameFromIdentifier,
                    m + 1
                  );
                  result = response.data;
                }
                cacheUploadForUnOptimizedFiles(
                  optimized,
                  uploadMap,
                  name,
                  result
                );
                transformedMediaArrangementCopy[entryIdentifier] =
                  generateNewMediaEntryFromResult(
                    transformedMediaArrangementCopy,
                    entryIdentifier,
                    text,
                    result
                  );
              } catch (e) {
                console.log(e);
              }
            } else {
              transformedMediaArrangementCopy[entryIdentifier] =
                generateNewMediaEntryFromResult(
                  transformedMediaArrangementCopy,
                  entryIdentifier,
                  text,
                  url
                );
            }
          }
        } else {
          transformedMediaArrangementCopy[entryIdentifier] =
            generateNewMediaEntryFromResult(
              transformedMediaArrangementCopy,
              entryIdentifier,
              text,
              undefined
            );
        }
      }
    }
    return transformedMediaArrangementCopy;
  };

  const throwInvalidAudienceRequirementError = (title) => {
    setStickyErrorTitle(null);
    setStickyErrorAction(() => () => selectTargetPlatforms());
    setStickyErrorActionMessage(title);
  };

  const resetStickyErrors = () => {
    setStickyErrorTitle(null);
    setStickyErrorMessage(undefined);
    setStickyErrorAction(undefined);
    setStickyErrorActionMessage(undefined);
    setStickyErrorSecondaryActionMessage(undefined);
  };

  const isInstagramSelected = () => {
    if (selectedPlatforms) {
      return selectedPlatforms.find(
        (x) => x.identifier === PlatformIdentifier.INSTAGRAM && x.selected
      );
    }
    return false;
  };

  const setUpTiktokAddons = (data) => {
    data["tiktokTitle"] = tiktokVideoTitle;
    data["tiktokAllowComment"] = allowComment ?? false;
    data["tiktokAllowDuet"] = tiktokAllowDuet ?? false;
    data["tiktokAllowStitch"] = tiktokAllowStitch ?? false;
    data["tiktokDiscloseVideoContent"] = tiktokDiscloseVideoContent ?? false;
    data["tiktokCommercialForYourBrand"] = tiktokCommercialForYourBrand ?? false;
    data["tiktokCommercialForBrandedContent"] = tiktokCommercialForBrandedContent ?? false;
    switch (tiktokSelectedViewSetting) {
      case "public to everyone":
        data["tiktokViewSetting"] = "PUBLIC_TO_EVERYONE";
        break;
      case "mutual fellow friends":
        data["tiktokViewSetting"] = "MUTUAL_FELLOW_FRIENDS";
        break;
      case "private to me":
        data["tiktokViewSetting"] = "SELF_ONLY";
        break;
      default:
        data["tiktokViewSetting"] = "PUBLIC_TO_EVERYONE";
        break;
    }
    return data;
  };

  //function to execute link shortener action
  // Wrapper function to convert callback-based API to Promise-based
  const shortenLinkPromise = (url) => {
    return new Promise((resolve, reject) => {
      APIService.shortenLink({ full_link: url }, (response, error) => {
        if (error) {
          reject(error);
        } else {
          resolve(response.data); // Assuming the response contains the shortened URL
        }
      });
    });
  };

  const getStatusHandler = async () => {
    APIService.getAutoShortenedLinkStatus((response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      let { data } = response;
      dispatch(setAutoShortenerStatus(data));
      return data;
    });
  };


  const shortnerReseter = async () => {
    await APIService.toggleAutoShortenLinks({ status: false }, (response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      let { message, data } = response;
      console.log("🚀 ~ awaitAPIService.toggleAutoShortenLinks ~ message:", message)
      dispatch(setAutoShortenerStatus(data));
    });

  }

  useEffect(async () => {
    if (autoShortenedStatus && isFreeWorkspace(workspace) === true) {
      shortnerReseter()
    }
  }, [autoShortenedStatus]);


  useEffect(() => {
    if (isFreeWorkspace(workspace)) return;
    getStatusHandler();
  }, []);

  const handleToggle = async (e) => {
    e.preventDefault();
    if (isFreeWorkspace(workspace) === true) {
      let metricInitializer = {
        action: MetricsProps.LINK_SHORTENER,
        detailedAction: `Upgrade notice: Clicked on auto-shorten link toggle button`,
        timestamp: new Date().toISOString(),
        route: window.location.pathname.replace(workspace._id, '').replace('//', '/'),
      }
      dispatch(setCollectMetric(metricInitializer))
      dispatch(setPopupUpgradeAlert(true))
      return;
    }
    let requestBody = {
      status: e.target.checked,
    };
    await APIService.toggleAutoShortenLinks(requestBody, (response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      let { message, data } = response;
      dispatch(setAutoShortenerStatus(data));
      toast.success(data);
      toast.success(`Auto-shorten link turned ${data !== true ? 'on' : 'off'}`);
      getStatusHandler();
    });
  };

  const attemptToPost = async () => {
    trueTargetPlatforms = selectedPlatforms.filter(
      (x) => x.identifier && x.selected
    );
    if (trueTargetPlatforms.length === 0) {
      throwInvalidAudienceRequirementError("Select targets");
      setStickyErrorAction(undefined);
      setStickyErrorMessage(`Kindly select target platforms to publish to on the left.`);
      return;
    }
    for (let platform of trueTargetPlatforms) {
      if (
        platform.identifier === PlatformIdentifier.FACEBOOK ||
        platform.identifier === PlatformIdentifier.INSTAGRAM ||
        platform.identifier === PlatformIdentifier.LINKED_IN ||
        platform.identifier === PlatformIdentifier.YOUTUBE ||
        platform.identifier === PlatformIdentifier.REDDIT ||
        platform.identifier === PlatformIdentifier.PINTEREST ||
        platform.identifier === PlatformIdentifier.GOOGLE_MY_BUSINESS ||
        platform.identifier === PlatformIdentifier.WORDPRESS
      ) {
        let accounts = platform.accounts;
        for (let account of accounts) {
          if (
            (account.connected_pages_and_groups ?? []).length === 0 &&
            account?.selected
          ) {
            // throwInvalidAudienceRequirementError("Select");
            setStickyErrorMessage(
              `Kindly select target ${getTagForPages(platform)} for ${account.user_name
              } under ${platform.name}. \n \n Go to the Platforms page, select the account, and click on the Manage channels button to display and connect the channels.`
            );
            return;
          }
        }
        if (platform.identifier === PlatformIdentifier.FACEBOOK) {
          const urlPattern =
            /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+)(\/[^\s]*)?$/i;
          if (isFacebookPostCarousel) {
            if (facebookCarouselLinks.length === 0) {
              throwInvalidAudienceRequirementError("Add Carousel");
              setStickyErrorMessage(
                `Kindly add carousel links for ${platform.name}`
              );
              return;
            }
            if (facebookCarouselLinks.length < 2) {
              throwInvalidAudienceRequirementError("Add Carousel");
              setStickyErrorMessage(
                `Kindly add at least 2 carousel links for ${platform.name}`
              );
              return;
            }
            if (facebookCarouselLinks.length > 10) {
              throwInvalidAudienceRequirementError("Remove Carousel item");
              setStickyErrorMessage(
                `Maximum number of images allowed on ${platform.name} carousel is 10. Kindly reduce the number of images`
              );
            }
            for (let i = 0; i < facebookCarouselLinks.length; i++) {
              let carouselLink = facebookCarouselLinks[i];
              if (
                !urlPattern.test(carouselLink.link) ||
                !carouselLink.link ||
                carouselLink.link.includes(",")
              ) {
                setStickyErrorMessage(
                  `Kindly provide a valid carousel link for ${platform.name}`
                );
                return;
              }
            }
          }
        }
        if (platform.identifier === PlatformIdentifier.YOUTUBE) {
          if (!videoDetectedInMedia()) {
            setStickyErrorAction(() => () => {
              document.getElementById("global-video-button").scrollIntoView();
              document.getElementById("global-video-button").click();
            });
            setStickyErrorTitle("YouTube Needs a Video");
            setStickyErrorActionMessage("Add Video");
            setStickyErrorMessage("Kindly attach a video for YouTube");
            return;
          }
          setYoutubeDetected(true);
          if (!youTubeVideoTitle) {
            setYouTubeVideoErrorMessage("Kindly provide YouTube Video Title");
            return;
          }
          if (!youTubeVideoPrivacy) {
            setYouTubeVideoErrorMessage(
              "Kindly Select YouTube Video Privacy Option"
            );
            return;
          }
          setYouTubeVideoErrorMessage(null);
        }
        if (platform.identifier === PlatformIdentifier.REDDIT) {
          setRedditDetected(true);
          if (!redditTitle) {
            setRedditTitleErrorMessage("Kindly provide Reddit Title");
            return;
          }
          if (!redditRef.current.canPostToReddit()) {
            return;
          }
          setRedditTitleErrorMessage(null);
        }
        if (platform.identifier === PlatformIdentifier.PINTEREST) {
          setPinterestDetected(true);
          if (!pinterestTitle) {
            setPinterestErrorMessage("Kindly provide Pinterest Title");
            return;
          }
          setPinterestErrorMessage(null);
        }
        if (platform.identifier === PlatformIdentifier.WORDPRESS) {
          setWordpressDetected(true);
          if (!wordpressTitle) {
            setWordPressErrorMessage("Kindly provide WordPress Title");
            return;
          }
          setWordPressErrorMessage(null);
        }
        if (platform.identifier === PlatformIdentifier.BLOGGER) {
          setBloggerDetected(true);
          if (!bloggerTitle) {
            setBloggerErrorMessage("Kindly provide Blogger Title");
            return;
          }
          setBloggerErrorMessage(null);
        }

        if (platform.identifier === PlatformIdentifier.GOOGLE_MY_BUSINESS) {
          setGMBDetected(true);
          if (!gmbPostType) {
            setGMBErrorMessage(
              "Kindly Select a Post Type for Google My Business"
            );
            return;
          }
          if (!gmbRef.current.canPostToGMB()) {
            return;
          }
          setGMBErrorMessage(null);
        }
      }
      if (platform.identifier === PlatformIdentifier.PINTEREST) {
        if (!mediaItemsArrangement?.pinterest?.media?.length) {
          setStickyErrorMessage(
            `Kindly attach an image or video for ${platform.name}`
          );
          return;
        }
        setStickyErrorMessage(null);
      }
      if (platform.identifier === PlatformIdentifier.INSTAGRAM) {
        if (!mediaItemsArrangement?.instagram?.media?.length) {
          setStickyErrorMessage(
            `Kindly attach an image or video for ${platform.name}`
          );
          return;
        }
        setStickyErrorMessage(null);
      }
      if (platform.identifier === PlatformIdentifier.YOUTUBE) {
        if (!videoDetectedInMedia() || !mediaItemsArrangement[PlatformIdentifier.YOUTUBE]?.media?.length) {
          setStickyErrorMessage(`Oops! No video attached for ${platform.name}`);
          return;
        }
      }
      if (platform.identifier === PlatformIdentifier.PINTEREST) {
        if (videoDetectedInMedia()) {
          if (!pinterestVideoThumbnail) {
            setPinterestErrorMessage(
              "You must select a cover image for your pinterest video"
            );
            return;
          }
          setPinterestErrorMessage(null);
        }
      }

      if (tikTokDetected && !tiktokVideoTitle) {
        setStickyErrorMessage("Kindly provide TikTok Title");
        return;
      } else {
        setStickyErrorMessage(null);
      }

      if (platform.identifier === PlatformIdentifier.TIKTOK) {
        if (!videoDetectedInMedia()) {
          setStickyErrorMessage("Kindly attach a video for TikTok");
          return;
        }
        setTikTokDetected(true);
      } else {
        setTikTokDetected(false);
      }
      setStickyErrorMessage(null);
    }
    let refinedTargetPlatforms = [];
    for (let platform of trueTargetPlatforms) {
      platform = JSON.parse(JSON.stringify(platform));
      let accountsUnderPlatform = platform.accounts;
      let newAccounts = [];
      for (let account of accountsUnderPlatform) {
        let newPages = [];
        if (account.connected_pages_and_groups) {
          for (let page of account.connected_pages_and_groups) {
            let preloadedPage = newPages.find((x) => x.id === page.id);
            if (!preloadedPage) {
              newPages.push(page);
            }
          }
          account.connected_pages_and_groups = newPages;
        }
        let preloadedAccount = newAccounts.find(
          (x) => x.id === account.user_id
        );
        if (!preloadedAccount) {
          newAccounts.push(account);
        }
      }
      platform.accounts = newAccounts;
      let preloadedPlatform = refinedTargetPlatforms.find(
        (x) => x.identifier === platform.identifier
      );
      if (!preloadedPlatform) {
        refinedTargetPlatforms.push(platform);
      }
    }
    let keywords = trueTargetPlatforms.map((x) => x.identifier);
    if (keywords.includes(PlatformIdentifier.TWITTER)) {
      if (postTextBody) {
        let statusParts = chunkTweetIntoThreads(postTextBody);
        if (statusParts.length > 1) {
          let satisfiedWithTwitterThread = localStorage.getItem(
            "satisfied_with_twitter_thread"
          );
          if (!satisfiedWithTwitterThread) {
            setOpenTwitterThreadReviewConsent(true);
            return;
          }
        }
      }
    }
    if (keywords.includes(PlatformIdentifier.THREADS)) {
      if (postTextBody) {
        let statusParts = chunkTweetIntoThreads(postTextBody);
        if (statusParts.length > 1) {
          let satisfiedWithTwitterThread = localStorage.getItem(
            "satisfied_with_threads_thread"
          );
          // !BRB: COME BACK HERE FOR DOING THE SAME FOR THREADS AS THIS IS FOR TWITTER
          // if (!satisfiedWithTwitterThread) {
          //   setOpenTwitterThreadReviewConsent(true);
          //   return;
          // }
        }
      }
    }
    let hashTags = grabHashTags(postTextBody);
    if (keywords.includes(PlatformIdentifier.INSTAGRAM)) {
      if (hashTags.length > 30) {
        setStickyErrorMessage("Please limit your hashtags to 30");
        return;
      }
    }

    const { error } = await TextValidator(
      trueTargetPlatforms,
      mediaItemsArrangement
    );
    if (error && !userChooseToIgnoreMissingTextAlert) {
      setStickyErrorMessage(error);
      setStickyErrorTitle("Missing Text");
      // setStickyErrorActionMessage("Open Preview");
      setStickyErrorSecondaryActionMessage("Ignore");
      dispatch(setPromptedMissingTextAlert(true));
      setStickyErrorSecondaryAction(() => () => {
        dispatch(setUserChooseToIgnoreMissingTextAlert(true));
        resetStickyErrors();
      });
      setStickyErrorAction(undefined);
      dispatch(setContentPublishingInProgress(false));
      dispatch(setContentUploadingProgress(null));
      return;
    }


    dispatch(setContentPublishingInProgress(!posting));
    let mediaValidation = await validateMediaAgainstSelectedPlatforms();
    if (!mediaValidation.success) {
      dispatch(setContentPublishingInProgress(false));
      setStickyErrorMessage(mediaValidation.errorMessage);
      // setStickyErrorActionMessage("Open Preview");
      // setStickyErrorAction(() => () => {
      //   dispatch(setShowPreview(true));
      //   scrollToElement();
      //   resetStickyErrors();
      // });
      setStickyErrorAction(undefined);
      return;
    }


    try {
      const facebookUploadedCarouselResults = await uploadFacebookCarousels();
      facebookCarouselLinks = facebookUploadedCarouselResults;
    } catch (e) {
      dispatch(setContentPublishingInProgress(false));
      dispatch(setContentUploadingProgress(null));
      toast.error(
        "Sorry, something went wrong while uploading some platform specific media. Please try again",
        { theme: "colored" }
      );
      return;
    }

    setStickyErrorMessage(null);
    if ((mediaObjects ?? []).length > 0) {
      dispatch(
        setContentUploadingProgress({
          completed: 20,
          message: `Please wait...`,
        })
      );
    }
    let arrangedMediaItems;
    try {
      arrangedMediaItems = await uploadArrangedMediaItems();
    } catch (e) {
      dispatch(setContentPublishingInProgress(false));
      dispatch(setContentUploadingProgress(null));
      toast.error(
        "Sorry, something went wrong while uploading some platform specific media. Please try again",
        { theme: "colored" }
      );
      return;
    }
    let gmbData = {};
    if (gmbPostType) {
      let gmbDataOptions = {
        gmbPostType,
        gmbEventTitle,
        gmbEventSchedule,
        gmbCTAActionUrl,
        gmbCouponCode,
        gmbCouponRedeemURL,
        gmbTermsAndConditions,
        gmbSummary: postTextBody ?? "",
      };
      if (gmbPostType !== "OFFER") {
        gmbDataOptions["gmbCTAActionType"] = gmbCTAActionType;
      }
      gmbData.gmb_data = gmbDataOptions;
    }
    let bodyText = postTextBody ?? "";
    const threadsIndex = refinedTargetPlatforms?.findIndex(
      (item) => item?.identifier === PlatformIdentifier.THREADS
    );
    if (threadsIndex !== -1) {
      const threadsObject = refinedTargetPlatforms.splice(threadsIndex, 1)[0];
      refinedTargetPlatforms.push(threadsObject); // move twitter to the end of the array
    }
    const twitterIndex = refinedTargetPlatforms?.findIndex(
      (item) => item?.identifier === PlatformIdentifier.TWITTER
    );
    if (twitterIndex !== -1) {
      const twitterObject = refinedTargetPlatforms.splice(twitterIndex, 1)[0];
      refinedTargetPlatforms.push(twitterObject); // move twitter to the end of the array
    }
    const tiktokIndex = refinedTargetPlatforms?.findIndex(
      (item) => item?.identifier === PlatformIdentifier.TIKTOK
    );
    if (tiktokIndex !== -1) {
      const tiktokObject = refinedTargetPlatforms.splice(tiktokIndex, 1)[0];
      refinedTargetPlatforms.unshift(tiktokObject); // move tiktok to the beginning of the array
    }
    let data = {
      targets: refinedTargetPlatforms,
      body: bodyText,
      youTubeVideoTitle,
      publishIGVideoAsReel,
      publishIGVideoAsStories,
      publishIGImageAsStories,
      igVideoCoverOffset,
      pinterestTitle,
      pinterestDestinationLink,
      pinterestAltText,
      twitterMediaAltText,
      youTubeVideoPrivacy,
      youTubeVideoCategory,
      youTubeVideoLicense,
      youTubeVideoTags,
      youTubeVideoMadeForKids,
      youTubeVideoEmbeddable,
      youTubeVideoComment,
      notifyYouTubeSubscribers,
      isFacebookPostCarousel,
      facebookCarouselLinks,
      isFacebookPostReel,
      isFacebookPostStory,
      reddit_title: redditTitle,
      description: bodyText,
      time_to_post: createDateWithTimeZone(
        timeToPost,
        timeZone,
        true
      ).toString(),
      time_zone: timeZone,
      post_group_identifier: postGroupIdentifier,
      refined: true,
      keywords,
      advanced_options: advancedContentCreationOptions,
      platformSpecificData: arrangedMediaItems,
      firstComment,
      createdBy: {
        first_name: loggedInUser.first_name,
        last_name: loggedInUser.last_name,
        avatar: loggedInUser.avatar,
        _id: loggedInUser._id,
        email: loggedInUser.email,
      },
      instantPost: postNow,
      selectedPostCategory,
      ...gmbData,
      facebookComment: fbFirstComment
    };
    if (tikTokDetected) {
      data = setUpTiktokAddons(data);
    }

    if (currentDraft) {
      data["draft_id"] = currentDraft?.uniqueContentDraftId;
    }

    if (promptedMissingTextAlert) {
      data["prompted_missing_text_alert"] = true;
    }

    if (userChooseToIgnoreMissingTextAlert) {
      data["user_ignored_missing_text_alert"] =
        userChooseToIgnoreMissingTextAlert;
    }

    if (advancedContentCreationOptions) {
      data["recurrence_start_time"] = timeToPost.toString();
    }
    if (eliminateTimePicker) {
      data["posted_from_content_planner"] = true;
    }
    if (youTubeVideoThumbnail) {
      if (youTubeVideoThumbnail.uploaded) {
        data["youTubeVideoThumbnail"] = youTubeVideoThumbnail.url;
      }
    }
    if (wordpressFeaturedImageThumbnail) {
      if (wordpressFeaturedImageThumbnail.uploaded) {
        data["wordpressFeaturedImageThumbnail"] =
          wordpressFeaturedImageThumbnail.url;
      }
    }
    if (userTags?.length > 0) {
      data["user_tags"] = userTags;
    }
    if (wordpressTitle) {
      data["wordpressTitle"] = wordpressTitle;
    }
    if (wordpressSlug) {
      data["wordpressSlug"] = wordpressSlug;
    }

    if (wordpressPostFormat) {
      data["wordpressFormat"] = wordpressPostFormat;
    }

    if (wordpressPostPassword) {
      data["wordpressPassword"] = wordpressPostPassword;
    }

    if (wordpressPostTags) {
      data["wordpressTags"] = wordpressPostTags;
    }
    if (wordpressCategory) {
      data["wordpressCategories"] = wordpressCategory;
    }
    if (bloggerTitle) {
      data["bloggerTitle"] = bloggerTitle;
    }
    if (updatablePost && !aboutRePublishingPost) {
      data["updatable_post_id"] = updatablePost._id;
    }

    if (repeatOption?.type !== "no-repeat" && repeatOption?.type) {
      data["repeat_option"] = repeatOption;
    }
    let mediaObjectsCopy = [...mediaObjects];
    if (youTubeVideoThumbnail) {
      let youTubeVideoThumbnailCopy = { ...youTubeVideoThumbnail };
      if (
        !youTubeVideoThumbnailCopy.uploaded &&
        !StringUtils.isStringAFirebaseLink(youTubeVideoThumbnail)
      ) {
        let newName = `thumbnail_for_${PlatformIdentifier.YOUTUBE}${youTubeVideoThumbnail.extension}`;
        youTubeVideoThumbnailCopy.name = newName;
        youTubeVideoThumbnailCopy.data_key = "youTubeVideoThumbnail";
        mediaObjectsCopy.push(youTubeVideoThumbnailCopy);
      }
    }
    if (wordpressFeaturedImageThumbnail) {
      let wordpressFeaturedImageThumbnailCopy = {
        ...wordpressFeaturedImageThumbnail,
      };
      if (
        !wordpressFeaturedImageThumbnailCopy.uploaded &&
        !StringUtils.isStringAFirebaseLink(wordpressFeaturedImageThumbnail)
      ) {
        let newName = `thumbnail_for_${PlatformIdentifier.WORDPRESS}${wordpressFeaturedImageThumbnail.extension}`;
        wordpressFeaturedImageThumbnailCopy.name = newName;
        wordpressFeaturedImageThumbnailCopy.data_key =
          "wordpressFeaturedImageThumbnail";
        mediaObjectsCopy.push(wordpressFeaturedImageThumbnailCopy);
      }
    }
    if (pinterestVideoThumbnail) {
      let pinterestVideoThumbnailCopy = { ...pinterestVideoThumbnail };
      if (
        !pinterestVideoThumbnailCopy.uploaded &&
        !StringUtils.isStringAFirebaseLink(pinterestVideoThumbnail)
      ) {
        let newName = `thumbnail_for_${PlatformIdentifier.PINTEREST}${pinterestVideoThumbnail.extension}`;
        pinterestVideoThumbnailCopy.name = newName;
        pinterestVideoThumbnailCopy.data_key = "pinterestVideoThumbnail";
        mediaObjectsCopy.push(pinterestVideoThumbnailCopy);
      }
    }
    let publishedMediaUrls = [];

    if (mediaObjectsCopy.length > 0) {
      let lengthOfMediaObjects = mediaObjectsCopy.length;
      let existingMediaUrls = data.mediaUrls ?? [];
      for (let i = 0; i < lengthOfMediaObjects; i++) {
        let mediaObjectAtI = { ...mediaObjectsCopy[i] };
        if (
          mediaObjectAtI.uploaded &&
          !publishedMediaUrls.includes(mediaObjectAtI.url)
        ) {
          publishedMediaUrls.push(mediaObjectAtI.url);
        }
        if (
          mediaObjectAtI.uploaded &&
          !existingMediaUrls.includes(mediaObjectAtI.url)
        ) {
          existingMediaUrls.push(mediaObjectAtI.url);
        }
      }
      data.mediaUrls = existingMediaUrls;
    }
    if (publishedMediaUrls.length > 0) {
      data["published_media"] = publishedMediaUrls;
    }
    // let newShortenedDesc = await shortenUrls()
    // data["body"]= newShortenedDesc;
    // data["description"]= newShortenedDesc;
    try {
      await uploadNonPlatformSpecificMedia(data, mediaObjectsCopy);
    } catch (e) {
      console.log(`Something went haywire`);
      console.log(e);
      dispatch(setContentPublishingInProgress(false));
      dispatch(setContentUploadingProgress(null));
      toast.error(
        "Sorry, something went wrong during uploads. Please try again",
        { theme: "colored" }
      );
      return;
    }
    if (editedPostFlag?.edited) {
      data['edited'] = editedPostFlag?.count + 1;
    }
    // dispatch(setContentPublishingInProgress(false));
    // dispatch(setContentUploadingProgress(null));
    // console.log(data, 'data')
    APIService.postToWorkSpace(workspace._id, { data }, (_response, err) => {
      if (err) {
        dispatch(setContentPublishingInProgress(false));
        dispatch(setContentUploadingProgress(null));
        toast.error(err, { theme: "colored" });
      }
    });
  };




  const setMediaObjects = (data) => {
    dispatch(setPublishablePostMedia([...data]));
  };

  const updateMediaObjects = (
    files,
    canCreateObjectURL = true,
    moreProps = {}
  ) => {
    resetStickyErrors();
    let expectedFilesLength = files.length + mediaObjects.length;
    if (
      files.length > mediaObjectsTotalLength ||
      mediaObjects.length > mediaObjectsTotalLength ||
      expectedFilesLength > mediaObjectsTotalLength
    ) {
      toast.error(
        `Sorry, you can only upload a maximum of ${mediaObjectsTotalLength} files at a time`,
        { theme: "colored" }
      );
      return;
    }
    let mo = [...mediaObjects] ?? [];
    for (let file of files) {
      if (canCreateObjectURL) {
        let fileUrl = URL.createObjectURL(file);
        let fileName = file.name;
        let existingFile = mo.find((x) => x.url === fileUrl);
        if (!existingFile) {
          let fileExtension = `.${fileName.split(".").pop()}`;
          let newMediaObject = {
            name: fileName,
            url: fileUrl,
            extension: fileExtension,
            file: file,
            ...moreProps,
          };
          // if (file.size > PlatformData.MAXIMUM_ALLOWED_FILE_UPLOAD_SIZE) {
          //   toast.error(
          //     `Maximum allowed file size is ${PlatformData.MAXIMUM_ALLOWED_FILE_UPLOAD_SIZE / 1e9
          //     }GB`,
          //     { theme: "colored" }
          //   );
          // } 
          let videoExists = mo.find((x) => x.is_video);
          if (videoExists && moreProps.is_video) {
            toast.error(
              "Sorry, you may not upload more than 1 video at a time",
              { theme: "colored" }
            );
          } else {
            mo.push(newMediaObject);
          }
        }
      } else {
        let fileWithName = mo.find((item) => item.name === file.name);
        if (!fileWithName) {
          mo.push(file);
        }
      }
    }
    setMediaObjects([...mo]);
    if ([...mo].length > 0) {
      setHint("Write a nice caption or hashtag");
    } else {
      setHint("Write once, publish everywhere");
    }
  };

  useEffect(() => {
    if (externalMediaFiles.length > 0) {
      updateMediaObjects(externalMediaFiles, false);
    }
  }, [externalMediaFiles]);

  useEffect(() => {
    if (mediaObjects) {
      if (mediaObjects.length > 0) {
        if (selectedPlatforms) {
          let instagram = selectedPlatforms.find(
            (x) => x.identifier === PlatformIdentifier.INSTAGRAM && x.selected
          );
          if (instagram) {
            setInstagramDetected(videoDetectedInMedia());
          }
          let youTube = selectedPlatforms.find(
            (x) => x.identifier === PlatformIdentifier.YOUTUBE && x.selected
          );
          if (youTube) {
            setYoutubeDetected(videoDetectedInMedia());
          }
        }
      } else {
        setInstagramDetected(false);
        setYoutubeDetected(false);
      }
    }
  }, [mediaObjects]);

  const constructRepeatOptionType = (option) => {
    if (option?.startsWith('monthly_on_the_')) {
      return "monthly_on_the_";
    }
    if (option?.startsWith('weekly_on_')) {
      return "weekly_on_";
    }
    if (option?.startsWith('annually_on_')) {
      return "annually_on_";
    }
    if (option?.startsWith('every_weekday_')) {
      return "every_weekday_";
    }
    return option;

    // switch (option) {
    //   case "no_repeat":
    //     return "Set recurring post";
    //   case "daily":
    //     return "Daily";
    //   case "weekly_on_":
    //     return `Weekly on ${currentDayOfWeek}`;
    //   case "monthly_on_the_":
    //     const currentNumOfWeek = getCurrentNumberOfWeek(timeToPost);
    //     return `Monthly on the ${currentNumOfWeek} ${currentDayOfWeek}`;
    //   case "annually_on_":
    //     return `Annually on ${currentMonth} ${currentDay}`;
    //   case "every_weekday_":
    //     return "Every Weekday";
    //   case "custom":
    //     return "Custom";
    //   default:
    //     return `Doesn't Repeat`;
    // }
  }

  const videoDetectedInMedia = () => {
    if (mediaObjects) {
      let videoMedia = mediaObjects.find((mediaObject) => mediaObject.is_video);
      return videoMedia;
    }
    return false;
  };

  const imageDetectedInInstagramForStories = () => {
    const imagesMedia = mediaItemsArrangement?.instagram?.media;
    if (imagesMedia?.length === 1 && !imagesMedia[0].is_video) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    let instagram = selectedPlatforms.find(
      (x) => x.identifier === PlatformIdentifier.INSTAGRAM && x.selected
    );
    if (!instagram) return;
    const value = imageDetectedInInstagramForStories();
    setInstagramDetectedForImageStories(value);
  }, [mediaItemsArrangement]);

  const addMediaFromUrl = (obj) => {
    const newMedia = [...mediaObjects, obj];
    dispatch(setPublishablePostMedia(newMedia));
    if (googleDriveManager?.docs) {
      dispatch(
        setGoogleDriveManager({
          ...googleDriveManager,
          docs: null,
          fromPreviews: true,
          promptConnect: false,
        })
      );
    }
  };

  useEffect(() => {
    if (
      !googleDriveManager?.docs ||
      localStorage.getItem("gdrivePlatform") !== "global"
    )
      return;
    addMediaFromUrl(googleDriveManager?.docs);
    localStorage.removeItem("gdrivePlatform");
  }, [googleDriveManager]);

  const handleConnect = async () => {
    try {
      googleDriveRef.current.click();
    } catch (error) {
      console.log(error, "as error");
    }
  };

  useEffect(() => {
    if (
      !googleDriveManager["fromPreviews"] &&
      googleDriveManager["promptConnect"]
    ) {
      setOpenConfirmation(true);
    }
  }, [googleDriveManager]);



  useEffect(() => {
    if (updatablePost) {
      let updatablePostDefaultsSet = localStorage.getItem(
        "updatable_post_defaults_set"
      );
      //! The if condition was removed because it makes the republish fails sometimes. Critically look at this later
      // if (!updatablePostDefaultsSet || updatablePost.drafted) {
      let data = updatablePost.data;
      let updatablePostBody = data.body;
      let updatablePostMedia = data.mediaUrls;
      let targets = data.targets ?? [];
      let editableAdvancedOptions = data.advanced_options;
      dispatch(
        setIsFacebookPostCarousel(data?.isFacebookPostCarousel ?? false)
      );
      if (targets.length > 0) {
        let tikTokTarget = targets.find(
          (x) => x.identifier === PlatformIdentifier.TIKTOK
        );
        if (tikTokTarget) {
          setTikTokDetected(true);
        }
        let cachedPlatforms = localStorage.getItem("cached_platforms");
        if (cachedPlatforms) {
          cachedPlatforms = JSON.parse(cachedPlatforms);
        }
        if (!trueTargetPlatforms) {
          for (let target of targets) {
            let existingPlatform;
            if (target.selected) {
              if (cachedPlatforms) {
                existingPlatform = cachedPlatforms.find(
                  (x) => x.identifier === target.identifier
                );
                if (existingPlatform) {
                  let indexOfExistingPlatform =
                    cachedPlatforms.indexOf(existingPlatform);
                  existingPlatform.selected = true;
                  cachedPlatforms[indexOfExistingPlatform] = existingPlatform;
                }
              }
            }
            for (let account of target.accounts) {
              let accountMunch = munchTarget(account._id, account.identifier);
              if (account.connected_pages_and_groups) {
                for (let page of account.connected_pages_and_groups) {
                  let pageMunch = munchTarget(page.id, page.target);
                  if (page.selected) {
                    if (!munchedTargetIds.includes(pageMunch)) {
                      munchedTargetIds.push(pageMunch);
                    }
                  }
                }
                let areAllPagesSelected =
                  account.connected_pages_and_groups.every((x) => x.selected);
                if (areAllPagesSelected) {
                  if (!munchedTargetIds.includes(accountMunch)) {
                    munchedTargetIds.push(accountMunch);
                  }
                }
              } else {
                if (account.selected) {
                  if (!munchedTargetIds.includes(accountMunch)) {
                    munchedTargetIds.push(accountMunch);
                  }
                }
              }
              if (existingPlatform) {
                let domiciledAccount = existingPlatform.accounts.find(
                  (y) => y._id === account._id
                );
                if (domiciledAccount) {
                  let indexOfDomiciledAccount =
                    existingPlatform.accounts.indexOf(domiciledAccount);
                  existingPlatform.accounts[indexOfDomiciledAccount] =
                    account;
                }
              }
            }
          }
          dispatch(setMunchedPostTargetIds([...munchedTargetIds]));
          dispatch(setSelectedPlatforms(targets));
          if (cachedPlatforms) {
            localStorage.setItem(
              "cached_platforms",
              JSON.stringify(cachedPlatforms)
            );
          }
        }
      }
      let poll = data.poll_data;
      dispatch(setPostNow(false));
      const now = new Date();
      let updatableTimeToPostContent = aboutRePublishingPost
        ? new Date(now.getTime() + 60 * 60 * 1000)
        : new Date(
          updatablePost.scheduled_for ??
          updatablePost?.data?.time_to_post ??
          new Date()
        );
      if (editableAdvancedOptions) {
        dispatch(setAdvancedContentCreationOptions(editableAdvancedOptions));
      }
      if (data?.repeat_option) {
        dispatch(setRepeatOption(data?.repeat_option))
        dispatch(setRepeatOptionType(constructRepeatOptionType(data?.repeat_option?.type)))
      }
      dispatch(
        setPublishIGImageAsStories(
          data?.publishIGImageAsStories === "Yes" ? true : false
        )
      );
      dispatch(
        setPublishIGVideoAsStories(data?.publishIGVideoAsStories || false)
      );
      dispatch(
        setPublishIGVideoAsReel(
          data?.publishIGVideoAsReel === "Yes" ? true : false
        )
      );

      dispatch(setTimeToPostContent(updatableTimeToPostContent));
      if (data.post_group_identifier) {
        !aboutRePublishingPost &&
          dispatch(setPostGroupIdentifier(data.post_group_identifier));
      }
      if (poll) {
        setOpenPollCreationDialog(true);
        return;
      }
      if (data?.tiktokTitle) {
        dispatch(setTiktokVideoTitle(data?.tiktokTitle ?? ""));
        dispatch(setTiktokSelectedViewSetting(data?.tiktokViewSetting));
        dispatch(
          setTiktokAllowComments(
            data?.tiktokAllowComment === "Yes" ? true : false
          )
        );
        dispatch(
          setTiktokAllowDuet(data?.tiktokAllowDuet === "Yes" ? true : false)
        );
        dispatch(
          setStitchAllowTiktok(data?.tiktokAllowStitch === "Yes" ? true : false)
        );
      }
      if (data.youTubeVideoTitle) {
        setYoutubeDetected(true);
        dispatch(setYouTubeVideoTitle(data.youTubeVideoTitle));
        dispatch(setYouTubeVideoPrivacy(data.youTubeVideoPrivacy));
        dispatch(setYouTubeVideoThumbnail(data.youTubeVideoThumbnail));
        dispatch(
          setShouldNotifyYouTubeSubscribers(data.notifyYouTubeSubscribers)
        );
        dispatch(setYouTubeVideoComment(data.youTubeVideoComment));
      }
      if (data.reddit_title) {
        setRedditDetected(true);
        dispatch(setRedditTitle(data.reddit_title));
      }
      if (data.pinterestTitle) {
        setPinterestDetected(true);
        dispatch(setPinterestTitle(data.pinterestTitle));
        dispatch(setPinterestAltText(data.pinterestAltText));
        dispatch(setPinterestDestinationLink(data.pinterestDestinationLink));
        dispatch(setPinterestVideoThumbnail(data.pinterestVideoThumbnail));
      }
      if (data.twitterMediaAltText) {
        dispatch(setTwitterMediaAltText(data.twitterMediaAltText));
      }
      let gmb = targets.find(
        (x) => x.identifier === PlatformIdentifier.GOOGLE_MY_BUSINESS
      );
      if (gmb && data.gmb_data) {
        let gmbDataOpts = data.gmb_data;
        setGMBDetected(true);
        dispatch(setGMBPostType(gmbDataOpts.gmbPostType));
        dispatch(setGMBEventTitle(gmbDataOpts.gmbEventTitle));
        dispatch(setGMBEventSchedule(gmbDataOpts.gmbEventSchedule));
        dispatch(setGMBEventSummary(gmbDataOpts.gmbSummary));
        dispatch(setGMBCTAActionType(gmbDataOpts.gmbCTAActionType));
        dispatch(setGMBCTAActionURL(gmbDataOpts.gmbCTAActionUrl));
        dispatch(setGMBCouponCode(gmbDataOpts.gmbCouponCode));
        dispatch(setGMBRedeemURL(gmbDataOpts.gmbCouponRedeemURL));
        dispatch(setGMBTermsAndConditions(gmbDataOpts.gmbTermsAndConditions));
      }
      dispatch(setPublishablePostTextBody(updatablePostBody));
      if (updatablePostMedia) {
        setTimeout(() => {
          updatablePostMedia = updatablePostMedia.map((mediaEntry) => {
            let url;
            if (typeof mediaEntry === "string") {
              url = mediaEntry;
              return {
                name: url,
                url,
                uploaded: true,
                extension: `.${StringUtils.substringAfterLast(
                  StringUtils.substringBeforeLast(url, "?"),
                  "."
                )}`,
                is_video: isVideo(url),
              };
            } else {
              return mediaEntry;
            }
          });
          setMediaObjects([...updatablePostMedia]);
        }, 1000);
      }
      if (textAreaRef.current) {
        textAreaRef.current.focus();
      }
      dispatch(setCanExpandPublisherActions(true));
      let editCount = data?.edited ?? 0;
      dispatch(setEditedPostFlag({
        edited: true,
        count: editCount
      }))
      localStorage.setItem("updatable_post_defaults_set", true);
      // }
    }
  }, [updatablePost]);

  const contentUploadProgressEventListener = (response) => {
    let { message, progress } = response;
    dispatch(
      setContentUploadingProgress({
        completed: Math.min(progress, 101),
        message: `${message} ${Math.min(progress, 101)}%`,
      })
    );
    if (progress >= 100) {
      let timeout = setTimeout(() => {
        dispatch(setContentUploadingProgress(null));
        clearTimeout(timeout);
      }, 3000);
    }
  };

  const contentBelongsToLoggedInUser = (error, creatorId, data, ignoreDataAndError = false) => {
    if (ignoreDataAndError) {
      return creatorId === loggedInUser._id
    }
    if (error && creatorId && creatorId === loggedInUser._id) {
      return true;
    }
    if (data && data?.data?.createdBy?._id === loggedInUser._id) {
      return true;
    }
    return false;
  };

  const workSpaceEventListener = (response) => {
    try {
      let { error, creatorId, data, notifyOnly } = response;
      if (error && creatorId && creatorId !== loggedInUser._id) {
        return;
      }
      if (contentBelongsToLoggedInUser(error, creatorId, data)) {
        dispatch(setContentPublishingInProgress(false));
        dispatch(setContentUploadingProgress(null));
      }
      if (notifyOnly) {
        toast.success(response.message, { theme: "colored" });
        dispatch(setPublishablePostTextBody(""));
        setMediaObjects([...[]]);
        dispatch(setExternalMediaFiles([]));
        dispatch(setCanExpandPublisherActions(false));
        dispatch(setEditablePost(null));
        if (contentBelongsToLoggedInUser(null, creatorId, null, true)) {
          dispatch(setContentPublishingInProgress(false));
          dispatch(setContentUploadingProgress(null));
        }
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        return;
      }
      if (error) {
        if (error.toLowerCase().includes("upgrade")) {
          setOpenPollCreationDialog(false);
          setConfirmModalTitle("Action requires upgrade");
          setConfirmModalMessage(error);
          setOpenConfirmModal(true);
        } else {
          toast.error(error, { theme: "colored" });
        }
      } else {
        if (contentBelongsToLoggedInUser(error, creatorId, data)) {
          dispatch(setPublishablePostTextBody(""));
          setMediaObjects([...[]]);
          dispatch(setExternalMediaFiles([]));
          dispatch(setCanExpandPublisherActions(false));
          dispatch(setEditablePost(null));
        }
        if (data) {
          if (contentBelongsToLoggedInUser(error, creatorId, data)) {
            setOpenPollCreationDialog(false);
            dispatch(setMunchedPostTargetIds([]));
            dispatch(setSelectedPlatforms([]));
            dispatch(setAdvancedContentCreationOptions({}));
            let currentBatchId =
              window.localStorage.getItem("current_batch_id");
            if (currentBatchId) {
              if (data.data) {
                if (data.data.batch_id === currentBatchId) {
                  window.localStorage.removeItem("current_batch_id");
                }
              }
            }
          }
          let tempPosts = localStorage.getItem("cached_posts")
            ? JSON.parse(localStorage.getItem("cached_posts"))
            : [];
          let existingPostsCopy = [...tempPosts];
          let existingPost = existingPostsCopy.find(
            (x) =>
              x.data.post_group_identifier === data.data.post_group_identifier
          );
          if (!existingPost) {
            if (existingPostsCopy.length > 0) {
              existingPostsCopy.unshift(data);
            } else {
              existingPostsCopy.push(data);
            }
          } else {
            let indexOfPost = existingPostsCopy.indexOf(existingPost);
            if (indexOfPost !== -1) {
              existingPostsCopy[indexOfPost] = { ...data };
            }
          }
          dispatch(cachePosts([]));
          dispatch(cachePosts([...existingPostsCopy]));
        } else {
          dispatch(setForceFetchPosts(true));
        }
        if (!contentBelongsToLoggedInUser(error, creatorId, data)) {
          return;
        }
        dispatch(setPinterestAltText(null));
        dispatch(setPinterestDestinationLink(null));
        dispatch(setPinterestTitle(null));
        freeUpLocallyCachedPlatforms();
        dispatch(setPostGroupIdentifier(null));
        dispatch(makeComposerFullScreen(false));
        dispatch(setTimeToPostContent(new Date()));
        setPinterestDetected(false);
        setYoutubeDetected(false);
        setRedditDetected(false);
        setInstagramDetected(false);
        setInstagramDetectedForImageStories(false);
        setGMBDetected(false);
        setTikTokDetected(false);
        setFacebookDetected(false)
        setTwitterDetected(false)
        setLinkedInDetected(false)
        setWordpressDetected(false);
        dispatch(setCalendarPostTime(null));
        dispatch(setRefreshCalendar(data));
        dispatch(setChoiceData({}));
        dispatch(setPollChoiceValue({}));
        dispatch(setMediaArrangement([]));
        dispatch(setRemovedMedia({}));
        dispatch(setShouldStartSyncingMediasOnPlatformSelection(false));
        localStorage.removeItem("updatable_post_defaults_set");
        localStorage.removeItem("satisfied_with_twitter_thread");
        localStorage.removeItem("content_upload_progress");
        localStorage.removeItem("cached_platforms");
        dispatch(setShowPreview(false));
        dispatch(setPromptedMissingTextAlert(false));
        dispatch(setUserChooseToIgnoreMissingTextAlert(false));
        dispatch(setHashTagsSuggested(false));
        dispatch(setCanSuggestPopularHashTags(false));
        dispatch(setManuallyEditedPlatforms({}));
        dispatch(setSelectedPostCategory({ name: "Campaign" }));
        dispatch(setPostNow(true));
        dispatch(setIsFacebookPostCarousel(false));
        dispatch(setIsFacebookPostReel(false));
        dispatch(setIsFacebookPostStory(false));
        dispatch(setFacebookCarouselLinks([]));
        dispatch(setFirstComment({}));
        dispatch(setFacebookFirstComment(null)); 
        dispatch(setCurrentDraft(null));
        dispatch(setStartedCollectingDraftData(false))
        dispatch(setIgnoreDraftUpdate(true));
        dispatch(setEditedPostFlag({}));
        dispatch(setMediaBank([]));
        // textAreaRef.current.value = "";
        setPostTextBodyLocalValue("")
        if (makingPostFromContentPlanner) {
          dispatch(setMakingPostFromContentPlanner(false))
          navigate(RoutePaths.PLANNER);
        } else {
          if (workspace?.settings?.approval_option > 1) {
            toast.success(`Sent for approval`, {
              theme: 'colored'
            })
            setTimeout(() => {
              window.location.reload();
            }, 2000);
            return;
          }
          if ((new Date(timeToPost) > Date.now()) && !postNow) {
            toast.success(`Scheduled successfully`, {
              theme: 'colored'
            })
          } else {
            toast.success(`Published successfully`, {
              theme: 'colored'
            })
          }
          if(isFreeWorkspace(workspace)) {
            dispatch(setShowFreeUserBillingUpgradeModal(true));
          } else{
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const contentPublishingCallback = (event) => {
      if (posting || selectedPlatforms.length) {
        freeUpLocallyCachedPlatforms();
        trueTargetPlatforms = null;
        localStorage.removeItem("updatable_post_defaults_set");
        event.preventDefault();
        event.returnValue = "";
        return "";
      }
    };
    window.addEventListener("beforeunload", contentPublishingCallback);
    return () =>
      window.removeEventListener("beforeunload", contentPublishingCallback);
  });

  // const handleDisplayDialogueForTagging = () => {
  //   let doNotShow = localStorage.getItem('showPreviewHintDialogueAgain')
  //   if (doNotShow == 'true') {
  //     localStorage.setItem('open_edit_text', true);
  //     dispatch(setSelectedPlatformForPreview(PlatformIdentifier.TWITTER));
  //     dispatch(setShowPreview(true));
  //     return
  //   }
  //   setDisplayConfirm(true);
  // }

  const handleOnClose = (canShowDialogPromptAgain) => {
    if (canShowDialogPromptAgain) {
      localStorage.setItem("showPreviewHintDialogueAgain", true);
    }
    setDisplayConfirm(false);
  };

  const handleConfirm = async (canShowDialogPromptAgain) => {
    try {
      if (canShowDialogPromptAgain) {
        localStorage.setItem("showPreviewHintDialogueAgain", true);
      }
      // localStorage.setItem('open_edit_text', true);
      dispatch(setSelectedPlatformForPreview(PlatformIdentifier.TWITTER));
      dispatch(setShowPreview(true));
      setDisplayConfirm(false);
    } catch (error) { }
  };

  // !BRB: Do the same for thread as is for twitter above
  // const handleConfirm = async (canShowDialogPromptAgain) => {
  //   try {
  //     if (canShowDialogPromptAgain) {
  //       localStorage.setItem("showPreviewHintDialogueAgain", true);
  //     }
  //     // localStorage.setItem('open_edit_text', true);
  //     dispatch(setSelectedPlatformForPreview(PlatformIdentifier.TWITTER));
  //     dispatch(setShowPreview(true));
  //     setDisplayConfirm(false);
  //   } catch (error) { }
  // };

  const cleanUpEditablePost = () => {
    if (updatablePost) {
      cancelEditOperation();
    }
  };

  const cancelEditOperation = () => {
    dispatch(setEditablePost(null));
    dispatch(setCanExpandPublisherActions(false));
    dispatch(setPublishablePostTextBody(""));
    dispatch(setPostGroupIdentifier(null));
    dispatch(setAdvancedContentCreationOptions({}));
    dispatch(setTimeToPostContent(new Date()));
    dispatch(setPostNow(true));
    setMediaObjects([]);
    localStorage.removeItem("updatable_post_defaults_set");
    dispatch(setRemovedMedia({}));
    localStorage.removeItem("cached_platforms");
    dispatch(setSelectedPlatforms([]));
    trueTargetPlatforms = null;
    setRedditDetected(false);
    setPinterestDetected(false);
    setInstagramDetected(false);
    setFacebookDetected(false)
    setTwitterDetected(false)
    setLinkedInDetected(false)
    setInstagramDetectedForImageStories(false);
    setGMBDetected(false);
    setYoutubeDetected(false);
    setTikTokDetected(false);
    freeUpLocallyCachedPlatforms();
    dispatch(setRepeatOption({}));
    dispatch(setShouldStartSyncingMediasOnPlatformSelection(false));
    dispatch(setIsFacebookPostCarousel(false));
    dispatch(setFacebookCarouselLinks([]));
    dispatch(setCurrentDraft(null));
    dispatch(setStartedCollectingDraftData(false))
    dispatch(setIgnoreDraftUpdate(true));
    dispatch(setEditedPostFlag({}));
    dispatch(setMediaBank([]));
  };

  useEffect(() => {
    APIService.checkIfEmailIsVerified((response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      setEmailIsVerified(response.data);
    });
  }, []);

  return (
    <div ref={previewElementRef}>
      <Modal
        centered
        show={!emailIsVerified}
        onHide={() => null}
        onExit={() => null}
      >
        <Modal.Body>
          <EmailValidatorPop setEmailIsVerified={setEmailIsVerified} />
        </Modal.Body>
      </Modal>
      <Flex direction={"column"}>
        {canPreview ? (
          <>
            {postTextBody.length < 1 && mediaObjects.length < 1 && (
              <Flex
                alignItems={"center"}
                justifyContent={"center"}
                className={"p-2"}
              >
                <span
                  style={{
                    textAlign: "center",
                    padding: 10,
                    maxWidth: 300,
                    textAlign: "center",
                  }}
                >
                  Write and see how your content will look like on each of your
                  target platforms
                </span>
              </Flex>
            )}
            {(postTextBody.length > 0 || mediaObjects.length > 0) && (
              <Previewer />
            )}
          </>
        ) : (
          <ComposeTextArea
            postTextBody={postTextBody}
            setPostTextBodyLocalValue={setPostTextBodyLocalValue}
            postTextBodyLocalValue={postTextBodyLocalValue}
            setPostTextCursorPosition={setPostTextCursorPosition}
            postTextCursorPosition={postTextCursorPosition}
            config={config}
            placeholder={hint}
            ref={textAreaRef}
            currentSearchTag={currentSearchTag}
            setCurrentSearchTag={setCurrentSearchTag}
          />
        )}
        {platformsTrulySelected() && (
          <div style={{ padding: 10 }}>
            <SelectedTargetAudienceContainer
              selectedPlatforms={selectedPlatforms.filter(
                (x) => x.identifier && x.selected
              )}
            // onClick={() => selectTargetPlatforms()}
            />
          </div>
        )}
        {mediaObjects.length ? <div style={{ height: 20 }}></div> : null}
        {!canPreview && (
          <PostAttachmentsPreviewContainer
            updateMediaObjects={updateMediaObjects}
          />
        )}
        <Form.Group as={Flex} alignItems="center" className="border-top">
          <Flex
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-around",
              minHeight: 70,
            }}
          >
            <Button
              id={
                selectedMediaOption === "image"
                  ? "global-photo-span"
                  : "global-video-span"
              }
              size="sm"
              variant={"outline-primary"}
              className="rounded-pill shadow-none d-inline-flex align-items-center fs--1 mb-0 me-1 p-1 px-3 borderless_bg"
              onClick={() => {
                localStorage.setItem("gdrivePlatform", "global");
                dispatch(setShowMediaOptions(true));
              }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 5,
              }}
            >
              <LiaImageSolid />
              <span style={{ fontSize: "13px" }}>Add Media</span>
            </Button>
            {/* {!platformsTrulySelected() && (
              <Button
                disabled={isShortening}
                size="sm"
                variant={"outline-primary"}
                className="rounded-pill shadow-none d-inline-flex align-items-center fs--1 mb-0 me-1 p-1 px-3 borderless_bg"
                onClick={() => selectTargetPlatforms()}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 5,
                }}
              >
                <AiOutlineTag />
                <span style={{ fontSize: "13px" }}>Select platforms</span>
              </Button>
            )} */}
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              rootClose
              overlay={emojiPopOver}
            >
              <Button
                size="md"
                variant={"outline-primary"}
                className="rounded-pill shadow-none d-inline-flex align-items-center fs--1 mb-0 me-1 p-1 px-3 borderless_bg"
                style={{}}
              >
                <VscSmiley />
                {/* <RiEmojiStickerLine /> */}
                {/* <span
                  className="ms-2 d-none d-md-inline-block"
                  style={{ fontSize: "13px" }}
                >
                  Emoji
                </span> */}
              </Button>
            </OverlayTrigger>
            {!canPreview && (
              <PopDrop
                dropDownAnchor={
                  <Button
                    size="md"
                    variant={"outline-primary"}
                    className="rounded-pill shadow-none d-inline-flex align-items-center fs--1 mb-0 me-1 p-1 px-3 borderless_bg"
                    style={{}}
                  >
                    <HiOutlineHashtag />
                    {/* <span
                      className="ms-2 d-none d-md-inline-block"
                      style={{ fontSize: "13px" }}
                    >
                      Hashtags
                    </span> */}
                  </Button>
                }
                PopContainer={
                  <HashTagPop
                    value={postTextBody}
                    updater={null}
                    identifier={null}
                    icon={null}
                    fromTyping={false}
                    dropShadow={true}
                    mainWidth={"420px"}
                  />
                }
              />
            )}
          </Flex>
        </Form.Group>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {updatablePost && !aboutRePublishingPost && !updatablePost?.drafted && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 10,
              }}
            >
              <Button
                id="cancel-post-edit-button"
                variant={"outline-danger"}
                className="rounded-pill me-1 mb-1"
                size={"sm"}
                onClick={() => {
                  dispatch(setEditablePost(null));
                  dispatch(setCanExpandPublisherActions(false));
                  dispatch(setPublishablePostTextBody(""));
                  dispatch(setPostGroupIdentifier(null));
                  dispatch(setAdvancedContentCreationOptions({}));
                  dispatch(setTimeToPostContent(new Date()));
                  dispatch(setPostNow(true));
                  setMediaObjects([]);
                  localStorage.removeItem("updatable_post_defaults_set");
                  localStorage.removeItem("cached_platforms");
                  dispatch(setSelectedPlatforms([]));
                  trueTargetPlatforms = null;
                  setRedditDetected(false);
                  setPinterestDetected(false);
                  setInstagramDetected(false);
                  setInstagramDetectedForImageStories(false);
                  setGMBDetected(false);
                  setYoutubeDetected(false);
                  setTikTokDetected(false);
                  dispatch(setRepeatOption({}));
                  freeUpLocallyCachedPlatforms();
                  dispatch(
                    setShouldStartSyncingMediasOnPlatformSelection(false)
                  );
                  dispatch(setIsFacebookPostCarousel(false));
                  dispatch(setEditedPostFlag({}));
                  dispatch(setFacebookCarouselLinks([]));
                  dispatch(setMediaBank([]));
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: 3,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                  <span>CANCEL EDIT</span>
                </div>
              </Button>
            </div>
          )}
          {/* <p>the</p> */}

          <TimeConfigurationRow />

          <Flex direction={"column"} className={"bg-100 gap-2 mt-2"}>
            {youtubeDetected && (
              <YouTubeOptionsField
                videoErrorMessage={youTubeVideoErrorMessage}
              />
            )}
            {redditDetected && (
              <RedditOptionsField
                ref={redditRef}
                redditErrorMessage={redditTitleErrorMessage}
                selectedPlatforms={selectedPlatforms}
                flairSelectedHandler={(copiedPlatforms) => {
                  dispatch(setSelectedPlatforms(copiedPlatforms));
                }}
              />
            )}
            {pinterestDetected && (
              <PinterestOptionsField
                pinterestErrorMessage={pinterestErrorMessage}
                videoDetected={videoDetectedInMedia()}
              />
            )}
            {gmbDetected && (
              <GmbOptionsField ref={gmbRef} gmbErrorMessage={gmbErrorMessage} />
            )}
            {tikTokDetected && <TiktokOptionsField />}
            {facebookDetected && <FacebookOptionsField />}
            {linkedInDetected && <LinkedInOptionsField />}
            {twitterDetected && <TwitterOptionsField />}
            {instagramDetected && <InstagramOptionsField />}
            {instagramDetectedForImageStories && (
              <InstagramOptionsField mediaType={"image"} />
            )}
            {wordpressDetected && (
              <WordPressOptionsField
                wordpressErrorMessage={wordpressErrorMessage}
              />
            )}
            {bloggerDetected && (
              <BloggerOptionsField bloggerErrorMessage={bloggerErrorMessage} />
            )}
            {openTwitterThreadReviewConsent && (
              <div className="mt-3">
                <PrePublishErrorResolver
                  title={"Review Autogenerated Twitter Thread"}
                  variant={"info"}
                  error={
                    "Your content will appear as a thread on twitter. It's important you review the thread before publishing"
                  }
                  actionMessage={"Click Here to Review"}
                  action={() => {
                    dispatch(
                      setSelectedPlatformForPreview(PlatformIdentifier.TWITTER)
                    );
                    dispatch(setShowPreview(true));
                    setOpenTwitterThreadReviewConsent(false);
                  }}
                />
              </div>
            )}
          </Flex>

          {isInstagramSelected() && (
            <Flex direction={"column"}>
              <Divider />
              <span className="fs--2 fw-medium px-3">MORE OPTIONS</span>
              <FirstCommentView
                postTextBody={postTextBody}
                mediaObjects={mediaObjects}
                firstComment={firstComment}
              />
            </Flex>
          )}
        </div>
        {stickyErrorMessage && (
          <PrePublishErrorResolver
            error={stickyErrorMessage}
            action={stickyErrorAction}
            actionMessage={stickyErrorActionMessge}
            secondaryActionMessage={stickyErrorSecondaryActionMessage}
            secondaryAction={stickyErrorSecondaryAction}
            title={stickyErrorTitle}
          />
        )}
        <Row className="g-0 mt-3  pb-3 justify-content-between">
          <Flex wrap={"wrap"} justifyContent={"center"} gap={"3"}>
            {/* <PostButton
              disabled={isShortening}
              borderless_bg
              onClick={() => {
                dispatch(setShowPreview(!canPreview));
                scrollToElement();
              }}
              variant={canPreview ? "outline-secondary" : "outline-primary"}
              style={{
                color: config.isDark && "whitesmoke",
              }}
              icon={canPreview ? faPen : faEye}
              title={!canPreview ? "Preview post" : "Continue Editing"}
              iconStyles={{
                color: "#509bfc",
              }}
            /> */}
            <Button
              onClick={() => {
                if (saveToDraftsButtonClicked) {
                  toast.error("Draft save still in progress...", { theme: "colored" })
                  return
                }
                dispatch(setSaveToDraftsButtonClicked(true));
              }}
              style={{
                backgroundColor: "#1e63fe",
                minHeight: 35,
              }}
              disabled={saveToDraftsButtonClicked || !changesFoundForDraft || (!postTextBody && !mediaObjects?.length)}
              size="sm"

            >
              <span style={{ fontSize: "12px" }}>
                {
                  saveToDraftsButtonClicked ? "Draft save in progress..." : "Save to drafts"
                }
              </span>
            </Button>
            <Button
              onClick={() => {
                // if (!postNow) {
                //   if (isFreeWorkspace(workspace)) {
                //     setOpenUpgradeDialogV2(true);
                //     return;
                //   }
                // }
                attemptToPost();
              }}
              style={{
                alignSelf: "flex-end",
                backgroundColor: "#1e63fe",
                minHeight: 35,
              }}
              disabled={
                (!postTextBody && !mediaObjects.length) ||
                posting ||
                loadingPostData ||
                disablePostButton || isShortening
              }
              size="sm"
              className={`px-${posting ? 3 : postNow ? 5 : 0} px-sm-${posting ? (postNow ? 3 : 2) : postNow ? 5 : 4
                }`}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 10,
                }}
              >
                {posting && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                <span className="fs--1">
                  {`${posting
                    ? new Date(timeToPost) > Date.now()
                      ? "Scheduling..."
                      : "Publishing..."
                    : updatablePost && !aboutRePublishingPost
                      ? updatablePost.drafted
                        ? `${postNow ? "Publish" : "Schedule"}`
                        : "Update Post"
                      : `${postNow ? "Publish" : "Schedule"}`
                    }`}
                </span>
              </div>
            </Button>
            {/* <Button onClick={shortenUrls}>shortener</Button> */}
          </Flex>
        </Row>
        {contentUploadProgress && (
          <ProgressBar
            style={{
              borderRadius: 0,
              height: 30,
            }}
            animated={true}
            now={contentUploadProgress.completed}
            label={contentUploadProgress.message}
          />
        )}
        <Modal
          centered
          show={openTimeConfigDialog}
          onHide={() => setOpenTimeConfigDialog(false)}
          onExit={() => setOpenTimeConfigDialog(false)}
        >
          <Modal.Body>
            <TimeToPostConfigurator
              initialValues={timeToPost}
              modalClose={() => {
                setOpenTimeConfigDialog(false);
              }}
            />
          </Modal.Body>
        </Modal>
        <Modal
          size="lg"
          centered
          show={openCategoriesPopup}
          onHide={() => setOpenPostCategoriesPopup(false)}
          onExit={() => setOpenPostCategoriesPopup(false)}
        >
          <Modal.Body>
            <PostCategoriesPopup
              initialValue={selectedPostCategory}
              modalClose={() => {
                setOpenPostCategoriesPopup(false);
              }}
            />
          </Modal.Body>
        </Modal>
        {/* MEDIA MODAL OPTIONS */}
        <Modal
          size="lg"
          centered
          show={showMediaOptions}
          onHide={() => dispatch(setShowMediaOptions(false))}
          onExit={() => dispatch(setShowMediaOptions(false))}
        >
          <div style={{ padding: "40px" }}>
            <div style={{ border: "1px solid #bfc9d7" }}>
              <Modal.Header>
                <span
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <Modal.Title>Select media type</Modal.Title>
                  </div>
                  <AiOutlineClose
                    cursor={"pointer"}
                    onClick={() => dispatch(setShowMediaOptions(false))}
                  />
                </span>
              </Modal.Header>
              <Modal.Body>
                <AddMediaPop
                  specificPlatformTied="global"
                  alignment={"start"}
                  initiatorId={
                    selectedMediaOption === "image"
                      ? "global-photo-chooser"
                      : "global-video-chooser"
                  }
                  externalFilePickerTitle={
                    selectedMediaOption === "image"
                      ? "Enter Photo Location"
                      : "Enter Vidoe Location"
                  }
                  prompt={
                    selectedMediaOption === "image"
                      ? "Add Photo From"
                      : "Add Video From"
                  }
                  fileType={selectedMediaOption === "image" ? "image" : "video"}
                  externalPickerPromptLabel={
                    selectedMediaOption === "image" ? "Photo" : "Video"
                  }
                  multiple={true}
                  fromWriteTab
                  fileSelectionHandler={(response) => {
                    if (response) {
                      let { files, from_local, ...others } = response;
                      updateMediaObjects(files, from_local, others);
                    }
                  }}
                />
                {/* <PostCategoriesPopup
              initialValue={selectedPostCategory}
              modalClose={() => {
                setOpenPostCategoriesPopup(false);
              }}
            /> */}
              </Modal.Body>
            </div>
          </div>
        </Modal>

        <Modal
          size="lg"
          centered={centerTimeToPostDialog}
          show={openAdvancedPostOptions}
          onHide={() => {
            setOpenAdvancedPostOptions(false);
            setCentreTimeToPostDialog(true);
          }}
          onExit={() => {
            setOpenAdvancedPostOptions(false);
            setCentreTimeToPostDialog(true);
          }}
        >
          <Modal.Header>
            <Modal.Title>How do you want your post to repeat?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <RecurringPostOptions
              startFrom={timeToPost}
              presetOptions={
                recurringOptionsFeedBackData ??
                generateRecurringOptionsPresets()
              }
              modalClose={() => {
                setOpenAdvancedPostOptions(false);
                setCentreTimeToPostDialog(true);
              }}
              onOptionsApplied={(appliedOptions) => {
                dispatch(setAdvancedContentCreationOptions(appliedOptions));
              }}
              onOpenTimeToPostDialog={(feedBackData) => {
                setRecurringOptionsFeedBackData(feedBackData);
                setCentreTimeToPostDialog(false);
                setOpenAdvancedPostOptions(false);
                setOpenTimeConfigDialog(true);
              }}
            />
          </Modal.Body>
        </Modal>
        <ConfirmModal
          open={openConfirmModal}
          title={confirmModalTitle}
          message={confirmModalMessage}
          confirmText={"UPGRADE"}
          cancelText={"CANCEL"}
          onCancel={() => {
            setOpenConfirmModal(false);
          }}
          onConfirm={() => {
            setOpenConfirmModal(false);
            navigate(`${RoutePaths.BILLING_STRIPE}`)
          }}
        />
        <Modal
          show={openPixelFilePickerDialogForWriteTab}
          onHide={() => {
            dispatch(setOpenPixelFilePickerDialogForWriteTab(false));
          }}
          onExit={() => {
            dispatch(setOpenPixelFilePickerDialogForWriteTab(false));
          }}
        >
          <Modal.Header>
            <Modal.Title>Pexels.com</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ExtrernalImageSearchDialogue fromWriteTab modalClose={() => { }} />
          </Modal.Body>
        </Modal>
      </Flex>
      <ConfirmModal
        open={displayConfirm}
        onCancel={(canShowDialogPromptAgain) => {
          handleOnClose(canShowDialogPromptAgain);
        }}
        title={"Message"}
        message={"To tag a user, use the Previews"}
        cancelText="CANCEL"
        showDialoguePrompt
        confirmText="CONTINUE"
        onConfirm={(canShowDialogPromptAgain) => {
          handleConfirm(canShowDialogPromptAgain);
        }}
      />
      <GDriveConnectionRequestListener googleDriveRef={googleDriveRef} />
      <ConfirmModal
        open={openConfirmation}
        title={"Connect Your Google Drive Account"}
        message={
          "Your google drive account is not connected or has been disconnected. Do you wish to connect?"
        }
        confirmText={"PROCEED"}
        cancelText={"CANCEL"}
        showDialoguePrompt
        onCancel={() => {
          setOpenConfirmation(false);
          dispatch(
            setGoogleDriveManager({
              ...googleDriveManager,
              promptConnect: false,
            })
          );
        }}
        onConfirm={() => {
          handleConnect();
          dispatch(
            setGoogleDriveManager({
              ...googleDriveManager,
              promptConnect: false,
              fromPreviews: false,
            })
          );
        }}
      />
      <ConfirmModal
        open={openUpgradeDialog}
        title={"Action requires Upgrade"}
        message={"This feature requires that you are on a premium account"}
        confirmText={"UPGRADE"}
        cancelText={"CANCEL"}
        onCancel={() => {
          setOpenUpgradeDialog(false);
        }}
        onConfirm={() => {
          setOpenUpgradeDialog(false);
          navigate(`${RoutePaths.BILLING_STRIPE}`)
        }}
      />
      <ConfirmModal
        open={openUpgradeDialogV2}
        title={"Action requires Upgrade"}
        message={"Scheduling posts for the future requires that you are on a premium account"}
        confirmText={"UPGRADE"}
        cancelText={"CANCEL"}
        onCancel={() => {
          setOpenUpgradeDialogV2(false);
        }}
        onConfirm={() => {
          setOpenUpgradeDialogV2(false);
          navigate(`${RoutePaths.BILLING_STRIPE}`)
        }}
      />
    </div>
  );
};

const PostButton = ({
  icon,
  image,
  iconStyles = {},
  title,
  variant = "light",
  customBgColor,
  reactIconImage,
  borderless_bg,
  ...others
}) => (
  <Button
    variant={variant}
    size="sm"
    className={`rounded-pill shadow-none d-inline-flex align-items-center fs--1 mb-0 me-1 p-2 px-3 ${borderless_bg && "borderless_bg"
      }`}
    style={{
      backgroundColor: customBgColor,
    }}
    {...others}
  >
    {image ? (
      <img src={image} alt="" width={17} />
    ) : reactIconImage ? (
      reactIconImage
    ) : (
      <FontAwesomeIcon icon={icon} alt="" width={17} {...iconStyles} />
    )}
    <span className="ms-2 d-none d-md-inline-block">{title}</span>
  </Button>
);

PostButton.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default CreatePostForm;
