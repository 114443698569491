import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import {
    getActiveWorkSpace,
    getPanelVisibilityChoices,
    setActiveWorkspace,
    setPanelVisibilityChoices
} from 'redux/slices/workspaceslice';
import Divider from 'components/common/Divider';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getLoggedInUser } from 'redux/slices/user_slice';

const HomePanelSettings = () => {

    const dispatch = useDispatch();
    const workSpace = useSelector(getActiveWorkSpace);
    const panelVisibilityChoices = useSelector(getPanelVisibilityChoices);
    const loggedInUser = useSelector(getLoggedInUser);

    const currentEventChoice = () => {
        let vCopy = { ...panelVisibilityChoices };
        let loggedInUserChoices = vCopy[loggedInUser?._id] ?? { "EVENTS": true };
        if (loggedInUserChoices.hasOwnProperty("EVENTS")) {
            return loggedInUserChoices["EVENTS"];
        }
        return true;
    }

    const currentTrendsChoice = () => {
        let vCopy = { ...panelVisibilityChoices };
        let loggedInUserChoices = vCopy[loggedInUser?._id] ?? { "TRENDS": true };
        if (loggedInUserChoices.hasOwnProperty("TRENDS")) {
            return loggedInUserChoices["TRENDS"];
        }
        return true;
    }

    const [showEventsPanel, setShowEventsPanel] = useState(currentEventChoice());
    const [showTrendsPanel, setShowTrendsPanel] = useState(currentTrendsChoice());

    return (
        <Card className="mb-3">
            <FalconCardHeader title="Post Panels" />
            <Card.Body className="bg-light">
                <Form.Group>
                    {/* <Form.Check
                        type='switch'
                        id='eventsPanel'
                        checked={showTrendsPanel}
                        label='Show Trends Panel on Post Page'
                        onChange={(e) => {
                            let newValue = e.target.checked;
                            setShowTrendsPanel(newValue);
                            APIService.togglePanelData(workSpace._id, { "TRENDS": newValue }, (response, error) => {
                                if (error) {
                                    setShowTrendsPanel(!newValue);
                                    toast.error(error, { theme: 'colored' });
                                    return;
                                }
                                let { message, data } = response;
                                toast.success(message, { theme: 'colored' });
                                dispatch(setPanelVisibilityChoices(data.panel_visibility_choices));
                                dispatch(setActiveWorkspace({
                                    ...workSpace,
                                    panel_visibility_choices: data.panel_visibility_choices
                                }));
                            });
                        }}
                    /> */}
                    <Form.Check
                        type='switch'
                        id='eventsPanel'
                        checked={showEventsPanel}
                        label='Show Events Panel on Post Page'
                        onChange={(e) => {
                            let newValue = e.target.checked;
                            setShowEventsPanel(newValue);
                            APIService.togglePanelData(workSpace._id, { "EVENTS": newValue }, (response, error) => {
                                if (error) {
                                    setShowEventsPanel(!newValue);
                                    toast.error(error, { theme: 'colored' });
                                    return;
                                }
                                let { message, data } = response;
                                toast.success(message, { theme: 'colored' });
                                dispatch(setPanelVisibilityChoices(data.panel_visibility_choices));
                                dispatch(setActiveWorkspace({
                                    ...workSpace,
                                    panel_visibility_choices: data.panel_visibility_choices
                                }));
                            });
                        }}
                    />
                </Form.Group>
                <Divider />
                <span className='fs--1'>Changes to panels affects only you and not all workspace members.</span>
            </Card.Body>
        </Card>
    );
};

export default HomePanelSettings;