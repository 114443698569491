import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Spinner } from 'react-bootstrap';
import Background from 'components/common/Background';
import classNames from 'classnames';
import MultimediaSelectionDropdown from 'components/common/MultimediaSelectionDropdown';
import { getExternalMediaFiles, setExternalMediaFiles } from 'redux/slices/postslice';
import { useSelector, useDispatch } from 'react-redux';
import Flex from 'components/common/Flex';
import { toast } from 'react-toastify';
import APIService from 'http/api_service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PERMISSION_EDIT_WORKSPACE_LOGO } from '../../../constants';
import FalconLightBox from 'components/common/FalconLightBox';
import { getActiveWorkSpace, setActiveWorkspace } from 'redux/slices/workspaceslice';
import { userCanPerformActionInWorkspace } from 'utils/workspace_utils';
import Avatar from 'components/common/Avatar';

const WorkspaceBannerHeader = ({ coverSrc, className }) => {

  const pickedFiles = useSelector(getExternalMediaFiles);
  const activeWorkspace = useSelector(getActiveWorkSpace);
  const [selectedLogo, setSelectedLogo] = useState();
  const [displaySubmitButton, setDisplaySubmitButton] = useState(false);
  const [uploadingLogo, setUploadingLogo] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (pickedFiles) {
      setSelectedLogo(pickedFiles[0]);
    }
  }, [pickedFiles]);

  useEffect(() => {
    if (selectedLogo) {
      setDisplaySubmitButton(true);
    } else {
      setDisplaySubmitButton(false);
    }
  }, [selectedLogo]);

  const uploadWorkSpaceLogo = async () => {
    if (uploadingLogo) {
      return;
    }
    setUploadingLogo(true);
    let data = {};
    if (selectedLogo.uploaded) {
      data.uploaded_logo = selectedLogo.url;
    } else {
      let requestBody = new FormData();
      requestBody.append("file", selectedLogo.file);
      let response = await APIService.uploadNewFile(activeWorkspace?._id, requestBody, 0, null, 1);
      let result = response.data;
      data.uploaded_logo = result;
    }
    setTimeout(() => {
      APIService.updateWorkSpaceLogo(activeWorkspace._id, data, (response, error) => {
        setUploadingLogo(false);
        if (error) {
          toast.error(error, { theme: 'colored' });
          return;
        }
        let { message, data } = response;
        if (data) {
          toast.success(message, { theme: 'colored' });
          dispatch(setActiveWorkspace({
            ...activeWorkspace,
            avatar: data.avatar
          }));
          setUploaded(true);
          setTimeout(() => {
            dispatch(setExternalMediaFiles([]));
          }, 100);
          setTimeout(() => {
            setDisplaySubmitButton(false);
            setSelectedLogo(undefined);
          }, 2000);
        }
      });
    }, 3000);
  }

  return (
    <Card 
      className={classNames(className, 'position-relative min-vh-25 shadow-none')}
    >
      {/* <FalconLightBox image={selectedLogo?.url ?? activeWorkspace?.avatar ?? coverSrc}> */}
        {/* <Background image={selectedLogo?.url ?? activeWorkspace?.avatar ?? coverSrc} className="rounded-3 rounded-bottom-0" /> */}
      {/* </FalconLightBox> */}

      <Avatar
          src={selectedLogo?.url ?? activeWorkspace?.avatar ?? coverSrc}
          size={'4xl'}
          className=" m-3" // Reduce bottom margin
      />

      <div
        style={{
          position: 'absolute',
          bottom: 10,
          left: 30,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        {
          displaySubmitButton &&
          <Button
            onClick={() => {
              uploadWorkSpaceLogo();
            }} variant={uploaded ? 'success' : 'primary'} disabled={uploadingLogo} className={`rounded-pill`}>
            {
              uploadingLogo &&
              <Flex alignItems={'center'} className={'gap-2'}>
                <Spinner animation='border' />
                <span>Uploading Logo</span>
              </Flex>
            }
            {
              uploaded &&
              <span>Logo set successfully</span>
            }
            {
              !uploadingLogo && !uploaded &&
              <span>Upload Selection</span>
            }
          </Button>
        }
      </div>
      {
        userCanPerformActionInWorkspace(activeWorkspace, PERMISSION_EDIT_WORKSPACE_LOGO) &&
        // <div style={{  position: 'absolute', marginLeft: '12px', marginBottom: '10px', marginRight: '18px' }}>
        <div style={{ position: 'absolute', right: 10 }}>         
         <MultimediaSelectionDropdown
            dropDownAnchor={
              <Button variant='outline-primary' className='rounded-pill'>
                <Flex alignItems={'center'} className={'gap-2'}>
                  <FontAwesomeIcon icon={'pencil-alt'} />
                  <span>Update Workspace Logo</span>
                </Flex>
              </Button>
            }
            initiatorId={'workspace-logo-chooser'}
            externalFilePickerTitle={"Enter Logo Location"}
            prompt={"Add Workspace Logo From"}
            fileType={"image"}
            externalPickerPromptLabel="Logo"
            multiple={false}
            fileSelectionHandler={(response) => {
              if (response) {
                let { files, from_local, ...others } = response;
                let file = files[0];
                let canCreateObjectURL = from_local ?? true;
                if (canCreateObjectURL) {
                  let fileUrl = URL.createObjectURL(file);
                  let fileName = file.name;
                  let fileExtension = `.${fileName.split('.').pop()}`;
                  let newMediaObject = {
                    name: fileName,
                    url: fileUrl,
                    extension: fileExtension,
                    file: file,
                    ...others
                  };
                  setSelectedLogo(newMediaObject);
                } else {
                  setSelectedLogo(file);
                }
              }
            }}
          />
        </div>
      }
    </Card>
  );
};

const WorkspaceBannerBody = ({ children }) => {
  return <Card.Body>{children}</Card.Body>;
};

const WorkspaceBanner = ({ children }) => {
  return <Card className="mb-3">{children}</Card>;
};

WorkspaceBanner.Header = WorkspaceBannerHeader;
WorkspaceBanner.Body = WorkspaceBannerBody;

WorkspaceBannerHeader.propTypes = {
  coverSrc: PropTypes.string.isRequired,
  className: PropTypes.string
};

WorkspaceBannerBody.propTypes = {
  children: PropTypes.node.isRequired
};

WorkspaceBanner.propTypes = {
  children: PropTypes.node.isRequired
};

export default WorkspaceBanner;
