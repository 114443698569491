import Flex from "components/common/Flex";
import CopyLinkButton from "components/common/copylinkbutton";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { FaRegCopy } from "react-icons/fa";
import { copyToClipboard } from "utils/browser";
import CopyIcon from "../../../assets/svgs/CopyIcon.svg";
import { toast } from "react-toastify";
import APIService from "http/api_service";
import Divider from "components/common/Divider";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import { useSelector } from "react-redux";
import ConfirmModal from "components/common/ConfirmModal";
import { RoutePaths } from "constants";
import { useNavigate } from "react-router-dom";

const Builder = () => {
  const navigate = useNavigate()
  const workSpace = useSelector(getActiveWorkSpace);
  const [workSpaceOwner, setWorkspaceOwner] = useState();
  const [campaignData, setCampaignData] = useState({
    utm_campaign: "",
    utm_medium: "",
    utm_source: "",
    utm_content: "",
    utm_term: "",
    utm_id: "",
  });
  const [websiteurl, setWebsiteurl] = useState("");
  const [generatedLink, setGeneratedLink] = useState("");
  const [shortenLink, setShortenLink] = useState("");
  const [generatingShortLink, setGeneratingShortLink] = useState();
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);

  const fetchWorkspaceOwner = () => {
    APIService.fetchWorkSpaceOwner(workSpace._id, (response, error) => {
      if (response) {
        let { data } = response;
        setWorkspaceOwner(data);
      }
    });
  };

  useEffect(() => {
    fetchWorkspaceOwner();
  }, []);

  const userOnFreePlan = () => {
    let activePlan = workSpaceOwner?.active_plan;
    if (
      activePlan?.Name?.toLowerCase() === "free" ||
      activePlan?.Key?.toLowerCase() === "free"
    ) {
      return true;
    }
    return false;
  };

  //onchange handler
  const handleInputChanges = async (e) => {
    const { name, value } = e.target;
    setCampaignData({ ...campaignData, [name]: value });
  };

  //generate linnk function
  const generateLinkHandler = async () => {
    try {
      const url = new URL(websiteurl);
      await Object.entries(campaignData)?.forEach(([key, value]) => {
        if (value) {
          url.searchParams.set(`${key}`, value);
        }
      });
      let stringUrl = url.toString();
      setGeneratedLink(stringUrl);
      toast.success("Link genrated successfully...");
    } catch (error) {
      toast.error(error.message || "Error Occured");
    }
  };

  //clear all field function
  const clearFieldsHandler = async () => {
    const clearedCampaignData = {};
    Object.keys(campaignData).forEach((key) => {
      clearedCampaignData[key] = "";
    });

    setCampaignData(clearedCampaignData);
    console.log(
      "🚀 ~ file: Builder.jsx:52 ~ clearFieldsHandler ~ clearedCampaignData:",
      clearedCampaignData
    );
    setWebsiteurl("");
    setGeneratedLink("");
    setShortenLink("");
  };

  //link shortening function
  const handleSubmit = (e) => {
    e.preventDefault();
    // if (userOnFreePlan() === true) {
    //   dispatch(setPopupUpgradeAlert(true))
    //   return;
    // }
    if (!generatedLink) {
      toast.error("No link to shorten");
      return;
    }

    setGeneratingShortLink(true);
    let formData = {
      full_link: generatedLink,
    };
    APIService.shortenLink(formData, (response, error) => {
      setGeneratingShortLink(false);
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      let { data } = response;
      setShortenLink(data);
      toast.success("Link shortened");
      // setSaveLinkData({ ...saveLinkData, link_url: data });
      // document.getElementById("link-shortener-footer")?.scrollIntoView();
    });
  };



  return (
    <>
      <Card.Body className="text-left py-50">
        <div className="d-flex justify-content-end align-items-center">
          <Button
            onClick={clearFieldsHandler}
            style={{
              postion: "relative",
              right: "0px",
              fontSize: "12px",
              padding: "auto 5px",
            }}
          >
            Clear fields
          </Button>
        </div>
        <Divider />
        <Row className="justify-content-center">
          <Col md={12}>
            <Form className="g-2">
              <Row>
                <Col sm style={{ marginBottom: "12px" }}>
                  <Form.Label>URL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="https://postly.ai/"
                    aria-label="Long Link Text box"
                    value={websiteurl}
                    name={"websiteurl"}
                    onChange={(e) => setWebsiteurl(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className="g-3">
                <Col sm md={12}>
                  <Form.Label>
                    Campaign Name{" "}
                    <span style={{ fontSize: "12px" }}>
                      (Used to identify the promotional or strategic campaign)
                    </span>
                  </Form.Label>
                  <div style={{ width: "100%", position: "relative" }}>
                    <Form.Control
                      type="text"
                      placeholder="SeasonalHolidayCampaign"
                      aria-label="Long Link Text box"
                      value={campaignData.utm_campaign}
                      name={"utm_campaign"}
                      onChange={handleInputChanges}
                    />
                  </div>
                </Col>
                <Col sm md={12}>
                  <Form.Label>
                    Medium{" "}
                    <span style={{ fontSize: "12px" }}>
                      (Used to identify a medium of delivery)
                    </span>
                  </Form.Label>
                  <div style={{ width: "100%", position: "relative" }}>
                    <Form.Control
                      type="text"
                      placeholder="Social"
                      aria-label="Long Link Text box"
                      value={campaignData.utm_medium}
                      name={"utm_medium"}
                      onChange={handleInputChanges}
                    />
                  </div>
                </Col>
                <Col sm md={12}>
                  <Form.Label>
                    Source{" "}
                    <span style={{ fontSize: "12px" }}>
                      (Used to identify a source of delivery)
                    </span>
                  </Form.Label>
                  <div style={{ width: "100%", position: "relative" }}>
                    <Form.Control
                      type="text"
                      placeholder="LinkedIn"
                      aria-label="Long Link Text box"
                      value={campaignData.utm_source}
                      name={"utm_source"}
                      onChange={handleInputChanges}
                    />
                  </div>
                </Col>
                <Col sm md={12}>
                  <Form.Label>
                    Content{" "}
                    <span style={{ fontSize: "12px" }}>
                      (Used to differentiate ads or links that point to the same
                      URL)
                    </span>
                  </Form.Label>
                  <div style={{ width: "100%", position: "relative" }}>
                    <Form.Control
                      type="text"
                      placeholder="AdVersion1"
                      aria-label="Long Link Text box"
                      value={campaignData.utm_content}
                      name={"utm_content"}
                      onChange={handleInputChanges}
                    />
                  </div>
                </Col>
                <Col sm md={12}>
                  <Form.Label>
                    Term{" "}
                    <span style={{ fontSize: "12px" }}>
                      (Used for paid search to note the keywords for an ad)
                    </span>
                  </Form.Label>
                  <div style={{ width: "100%", position: "relative" }}>
                    <Form.Control
                      type="text"
                      placeholder="holidayShopping"
                      aria-label="Long Link Text box"
                      value={campaignData.utm_term}
                      name={"utm_term"}
                      onChange={handleInputChanges}
                    />
                  </div>
                </Col>
                <Col sm md={12}>
                  <Form.Label>
                    Campaign ID{" "}
                    <span style={{ fontSize: "12px" }}>
                      (Used to identify specific ad campaigns)
                    </span>
                  </Form.Label>
                  <div style={{ width: "100%", position: "relative" }}>
                    <Form.Control
                      type="text"
                      placeholder="1234"
                      aria-label="Long Link Text box"
                      value={campaignData.utm_id}
                      name={"utm_id"}
                      onChange={handleInputChanges}
                    />
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <Col sm md={12}>
                  <Form.Label>
                    Generate Link{" "}
                    <span style={{ fontSize: "12px" }}>
                      (Click the generate link button to generate link with utms params)
                    </span>
                  </Form.Label>
                  <Flex
                    style={{
                      width: "100%",
                      position: "relative",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <div
                      style={{ width: "100%", position: "relative", flex: "5" }}
                    >
                      <Form.Control
                        type="text"
                        placeholder="https://postly.ai/
                      ?utm_campaign=SeasonalHolidayCampaign
                      &utm_medium=Social
                      &utm_source=LinkedIn
                      &utm_content=AdVersion1"
                        aria-label="Long Link Text box"
                        value={generatedLink}
                        name={"generatedLink"}
                        disabled={true}
                        style={{ width: "100%" }}
                      />
                      {/* <FaRegCopy style={{position: "absolute", right: "0px"}} /> */}
                      <CopyLinkButton
                        defaultText={
                          <img
                            src={CopyIcon}
                            alt="copy"
                            height="30px"
                            width="30px"
                          />
                        }
                        variant={"outline-info"}
                        handleCopy={() => {
                          copyToClipboard(generatedLink);
                        }}
                        style={{
                          fontSize: "12px",
                          padding: "5px",
                          backgroundColor: "inherit",
                          border: "none",
                          position: "absolute",
                          top: "0px",
                          right: "0px",
                        }}
                      />
                    </div>
                    <Button
                      onClick={generateLinkHandler}
                      variant="primary"
                      className="d-block w-100"
                      style={{ flex: "1", fontSize: "14px" }}
                    >
                      Generate Link
                    </Button>
                  </Flex>
                </Col>
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <Col sm md={12}>
                  <Form.Label>
                    Shorten Link{" "}
                    <span style={{ fontSize: "12px" }}>
                      (Click shorten link to shorten your link)
                    </span>
                  </Form.Label>
                  <Flex
                    style={{
                      width: "100%",
                      position: "relative",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <div
                      style={{ width: "100%", position: "relative", flex: "5" }}
                    >
                      <Form.Control
                        type="text"
                        placeholder="https://postly.ai/"
                        aria-label="Long Link Text box"
                        value={shortenLink}
                        name={"shortenLink"}
                        disabled={true}
                        style={{ width: "100%" }}
                      />
                      {/* <FaRegCopy style={{position: "absolute", right: "0px"}} /> */}
                      <CopyLinkButton
                        defaultText={
                          <img
                            src={CopyIcon}
                            alt="copy"
                            height="30px"
                            width="30px"
                          />
                        }
                        variant={"outline-info"}
                        handleCopy={() => {
                          copyToClipboard(shortenLink);
                        }}
                        style={{
                          fontSize: "12px",
                          padding: "5px",
                          backgroundColor: "inherit",
                          border: "none",
                          position: "absolute",
                          top: "0px",
                          right: "0px",
                        }}
                      />
                    </div>
                    <Button
                      onClick={handleSubmit}
                      variant="primary"
                      className="d-block w-100"
                      style={{ flex: "1", fontSize: "14px" }}
                    >
                      {/* Shorten link */}
                      {generatingShortLink
                        ? "Please wait..."
                        : "Shorten your link"}
                    </Button>
                  </Flex>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card.Body>
      <ConfirmModal
        open={openUpgradeDialog}
        title={"Action requires Upgrade"}
        message={"This feature requires that you are on a premium account"}
        confirmText={"UPGRADE"}
        cancelText={"CANCEL"}
        onCancel={() => {
          setOpenUpgradeDialog(false);
        }}
        onConfirm={() => {
          setOpenUpgradeDialog(false);
          navigate(`${RoutePaths.BILLING_STRIPE}`);
        }}
      />
    </>
  );
};

export default Builder;
