import StringUtils from 'utils/string';
import { findPlatformByIdentifier } from 'components/app/platforms';
import { Alert, Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function PostTargetTag(props) {

    const { onClick } = props;
    const style = props.style ?? {};
    const { target = 'facebook', name } = props;
    const targetPlatform = findPlatformByIdentifier(target);

    return (
        !targetPlatform ? <div></div> :
            <OverlayTrigger
                placement='top'
                overlay={
                    <Tooltip id="target-overlay-trigger">
                        {`${name} on ${StringUtils.capitalizeString(target.replace("_", ""))}`}
                    </Tooltip>
                }>
                <>
                    {/* <Alert
                    onClick={onClick}
                    style={{
                        borderRadius: 15,
                        cursor: 'pointer',
                        display: 'flex',
                        padding: 0,
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 0,
                        paddingBottom: 0,
                        margin: 0,
                        alignItems: 'center',
                        justifyContent: 'center',
                        ...style
                    }}> */}
                    <Button variant='falcon-default' style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 5,
                        // border: "1px solid #edf2f9",
                        padding: "1px",
                        // paddingLeft: "8px",
                        // paddingRight: "8px",
                        // borderRadius: "5%",
                        color: targetPlatform.color,
                        width: 90,
                        margin: 3
                    }}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 2,
                                // border: "1px solid #edf2f9",
                                padding: "0px",
                                // paddingLeft: "8px",
                                // paddingRight: "8px",
                                // borderRadius: "10%",
                                color: targetPlatform.color
                            }}>
                            <span
                                style={{
                                    fontSize: 9,
                                    fontWeight: 500,
                                    textAlign: 'center',
                                    ...style
                                }}
                            >{name ?? targetPlatform.name}
                            </span>
                            <FontAwesomeIcon
                                icon={targetPlatform.icon}
                                style={{
                                    width: 15,
                                    height: 15,
                                    fontSize: 8,
                                    borderRadius: 100,
                                    padding: 2,
                                }}
                            />
                        </div>
                    </Button>
                    {/* </Alert> */}
                    {/* <p>checking...</p> */}
                </>
            </OverlayTrigger>
    );
}