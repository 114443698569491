import React from 'react'
import { EachMetaDataSlide, Header, MainArena } from './metadataPopStyles'
import { convertToTitleFormat } from 'utils/platform_utils'
import AppContext from 'context/Context';
import { useContext } from 'react';


const MetaDataPop = (props) => {
    const { config } = useContext(AppContext);
    const { isDark } = config
    const { platformMetaData, target, isGeneral } = props
    const imagesKeys = ['picture']

    const truncateWords = (str, numberOfWords = 100) => {
        try {
            if (str.length > numberOfWords) {
                return str.substring(0, numberOfWords) + '...';
            }
            return str;
        } catch (e) {
            return str
        }
    }

    const renderMetaData = () => {
        if (platformMetaData) {
            return Object.entries(platformMetaData).map((entry) => {
                let key = entry[0];
                let data = entry[1];

                if (Array.isArray(data)) {
                    return data.map((e, index) => {
                        return <>
                            {
                                Object.entries(e).map((entry) => {
                                    let key = entry[0];
                                    let data = entry[1];
                                    if (imagesKeys.includes(key)) {
                                        return (
                                            <EachMetaDataSlide key={key}>
                                                <div>{convertToTitleFormat(key)}:</div> <div><img src={data} alt="" height={30} width={50} /></div>
                                            </EachMetaDataSlide>
                                        )
                                    }
                                    return (
                                        <EachMetaDataSlide key={key}>
                                            {data === '' ? <div>{[key]}: N/A</div> : <><div>{convertToTitleFormat(key)}:</div> <div>{data === true ? 'YES' : truncateWords(data)}</div></>}
                                        </EachMetaDataSlide>
                                    )
                                })
                            }
                            <div
                                style={{
                                    borderBottom: '1px dashed #ccc',
                                    width: '100%',
                                    marginTop: 10,
                                    marginBottom: 10
                                }}
                            ></div>
                        </>

                    })
                }

                if (key === 'post_approver') {
                    return <EachMetaDataSlide key={key}>
                        <div>{convertToTitleFormat(key)}:</div> <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 5
                            }}
                        >
                            {data.email} {data?.name && `(${data?.name})`} <img src={data.avatar} alt="" height={30} width={30} style={{
                                borderRadius: '50%'
                            }} />
                        </div>
                    </EachMetaDataSlide>
                }
                try {
                    if (data?.includes('http')) {
                        data = <a href={data} target="_blank" rel="noreferrer">
                            {data}
                        </a>
                    }
                } catch (e) {
                    // do nothing
                }
                return (
                    <EachMetaDataSlide key={key}>
                        <div>{convertToTitleFormat(key)}:</div> <div>{data === true ? 'YES' : truncateWords(data)} {convertToTitleFormat(key, true, true)}</div>
                    </EachMetaDataSlide>
                )
            })
        }
    }

    return (
        <MainArena
            bg={isDark ? '#232e3c' : '#d5e5fa'}
        >
            <Header>
                {isGeneral ? 'General Metadata' : ` Metadata for ${convertToTitleFormat(target)}`}
            </Header>
            {renderMetaData()}
        </MainArena>
    )
}

export default MetaDataPop