import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import EmailSetup from './EmailSetup';

const CustomEmailManagement = (props) => {
    const { customDomains } = props;

    const [allVerifiedDomain, setAllVerifiedDomain] = useState([])
 

    const shuffleOutVerifiedDomain = () => {
        let verifiedDomain = [];

        for (const domain of customDomains) {
            if (domain.verified) {
                verifiedDomain.push(domain)
            }
        }
        setAllVerifiedDomain(verifiedDomain)
    }

    useEffect(() => {
        shuffleOutVerifiedDomain();
    }, [customDomains])

    if (!allVerifiedDomain.length) {
        return <></>
    }

    const displayCustomDomainMappings = allVerifiedDomain?.map((domain, index) => {
        return <EmailSetup domain={domain} />
    })

    return (
        <Card className='mb-3' id='Custom-email-management'>
            <FalconCardHeader title="Custom Email Management" />
            <Card.Body className="bg-light">
                <Flex
                    direction={'column'}
                    justifyContent={'center'}
                    gap={5}
                >
                    {displayCustomDomainMappings}
                </Flex>
            </Card.Body>
        </Card>
    )
}

export default CustomEmailManagement