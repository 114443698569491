import React, {
  useContext, useEffect,
  useRef, useState
} from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import ScheduleNewPostModal from './ScheduleNewPostModal';
import DropdownFilter from 'components/common/DropdownFilter';
import AppContext from 'context/Context';
import { getActiveWorkSpace, getSearchTerm, setSearchHint } from 'redux/slices/workspaceslice';
import RecurrenceMapper from 'utils/recurrence_mapper';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import {
  canDisplayWeekNumbers, canOpenPostDetailsDialog, getCachedPosts,
  getCalendarPostTime,
  getDeletedPost, getEditablePost, getPostFilters,
  getRefreshedCalendarData,
  getSelectedPostType, setCalendarPostTime, setDisplayWeekNumbers,
  setDrafts,
  setMakingPostFromContentPlanner,
  setOpenPostDetailsDialog,
  setPostNow,
  setPostsFilters,
  setRefreshCalendar,
  setTimeToPostContent
} from 'redux/slices/postslice';
import Flex from 'components/common/Flex';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import StringUtils from 'utils/string';
import CalendarVideoOrPhotoView from './CalendarVideoOrPhotoView';
import { isVideo } from 'utils/file_utils';
import { createDateWithTimeZone } from 'utils/time';
import { faRepeat } from '@fortawesome/free-solid-svg-icons';
import { FeedStatus } from '../social/feed/FeedStatus';
import PollItem from '../social/feed/polls/poll_item';
import { findPlatformByIdentifier } from '../platforms';
import ReactLoading from 'react-loading';
import {
  getFetchRounds,
  getSinglePostType,
} from '../../../redux/slices/postslice';
import { PlatformIdentifier } from 'constants';
import { AiFillYoutube } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'constants';
import TooltipBadge from 'components/common/TooltipBadge';

const Calendar = () => {

  const getRemainingDaysOfTheMonth = () => {
    let currentCalendarDate = calendarRef?.current?.getApi()?.getDate() ?? new Date();
    let refDate = new Date(currentCalendarDate.getFullYear(), currentCalendarDate.getMonth(), 0);
    let daysInMonth = 2 * refDate.getDate();
    return daysInMonth;
  }

  const checkAndRepeatEventAcrossCalendar = (event, eventPool) => {
    if (event.data.repeat_option) {
      let remainingDaysOfTheMonth = getRemainingDaysOfTheMonth();
      if (remainingDaysOfTheMonth > 0) {
        let lastPost = event;
        for (let i = 0; i < remainingDaysOfTheMonth; i++) {
          try {
            if (lastPost) {
              lastPost = RecurrenceMapper.checkAndRepeatPostOnlyOnCalendar(lastPost, i);
              if (lastPost) {
                let existingPost = eventPool.find(x => x?.data?.time_to_post === lastPost?.data?.time_to_post && x._id === lastPost._id);
                if (!existingPost) {
                  eventPool.push(lastPost);
                } else {
                  let indexOfPost = eventPool.indexOf(existingPost);
                  eventPool[indexOfPost] = lastPost;
                }
              }
            }
          } catch (e) {
            console.error(`checkAndRepeatEventAcrossCalendar: ${e.message}`);
          }
        }
      }
    }
  }

  const checkAndFastForwardScheduledDate = (datum) => {
    let scheduledDate = new Date(datum.data?.time_to_post);
    let currentCalendarDate = calendarRef?.current?.getApi()?.getDate() ?? new Date();
    if (currentCalendarDate >= scheduledDate) {
      if (currentCalendarDate.getMonth() !== scheduledDate.getMonth()) {
        scheduledDate.setDate(1);
      }
      scheduledDate.setMonth(currentCalendarDate.getMonth());
    }
    scheduledDate.setFullYear(currentCalendarDate.getFullYear());
    return {
      ...datum,
      scheduled_for: scheduledDate
    };
  }

  const eventDataTransformer = (sources, fresh = false) => {
    if (!sources.length) {
      return posts;
    }
    let dataSources = [...sources];
    let repetitions = [];
    for (let dataSource of dataSources) {
      dataSource = { ...dataSource };
      if (dataSource.data.repeat_option) {
        dataSource = checkAndFastForwardScheduledDate(dataSource);
        checkAndRepeatEventAcrossCalendar(dataSource, repetitions);
      }
    }
    dataSources = [...dataSources, ...repetitions];
    if (fresh) {
      return dataSources;
    }
    return [...posts, ...dataSources];
  }

  const checkAndRepeatEventsOnDateChange = () => {
    let repetitions = [];
    let nonRecurringPosts = posts.filter(x => !x.recurring);
    let recurringPosts = posts.filter(x => x.recurring && !x.repeated);
    if (recurringPosts.length > 0) {
      for (let dataSource of recurringPosts) {
        dataSource = { ...dataSource };
        dataSource = checkAndFastForwardScheduledDate(dataSource);
        checkAndRepeatEventAcrossCalendar(dataSource, repetitions);
      }
      setPosts(constructEventsFrom([...nonRecurringPosts, ...recurringPosts, ...repetitions]));
    }
  }

  const getPostBackground = (post) => {
    if (post.posted) {
      return 'bg-soft-success';
    }
    if (post.failed) {
      return 'bg-soft-disapproval';
    }
    if (post.pending_approval && post?.data?.disapprovals) {
      return 'bg-soft-disapproval';
    }
    if (post.pending_approval) {
      return 'bg-soft-approval';
    }
    if (post.drafted) {
      return 'bg-soft-warning'
    }
    if (post.recurring || post?.data?.repeat_option) {
      return 'bg-soft-info'
    }
    return 'bg-soft-primary'
  }

  const constructEventsFrom = (eventSources) => {
    let events = eventSources.map(x => {
      let scheduledDate = new Date(x.data?.time_to_post);
      return {
        ...x,
        id: x._id,
        title: x.data?.body,
        start: scheduledDate,
        end: moment(scheduledDate).add(59, 'minutes').toDate(),
        title: x.data?.body,
        className: getPostBackground(x),
      }
    });
    return events;
  }

  const getInitialEvents = (initialPosts) => {
    return constructEventsFrom(eventDataTransformer(initialPosts));
  }

  const displayWeekNumbers = useSelector(canDisplayWeekNumbers);
  const deletedPost = useSelector(getDeletedPost);
  const editablePost = useSelector(getEditablePost);
  const cachedPosts = useSelector(getCachedPosts);
  const [posts, setPosts] = useState([]);
  const [fetchingPosts, setFetchingPosts] = useState(false);
  const postFilters = useSelector(getPostFilters);
  const selectedPostType = useSelector(getSelectedPostType);
  const postSearchTerm = useSelector(getSearchTerm);
  const [previousPostSearchTerm, setPreviousPostSearchTerm] = useState();
  const { config: { isRTL } } = useContext(AppContext);
  const calendarRef = useRef();
  const [title, setTitle] = useState('');
  const [calendarApi, setCalendarApi] = useState({});
  const [currentFilter, setCurrentFilter] = useState('Month View');
  const [isOpenCreatePostModal, setIsOpenCreatePostModal] = useState(false);
  const [scheduleStartDate, setScheduleStartDate] = useState();
  const [scheduleEndDate, setScheduleEndDate] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const workSpace = useSelector(getActiveWorkSpace);
  const refreshedCalendarData = useSelector(getRefreshedCalendarData);
  const calendarTime = useSelector(getCalendarPostTime);
  const openPostDialog = useSelector(canOpenPostDetailsDialog);
  const [skip, setSkip] = useState(0);
  const [canLoadMore, setCanLoadMore] = useState(false);
  const singlePostType = useSelector(getSinglePostType);
  const fetchedRounds = useSelector(getFetchRounds);

  useEffect(() => {
    dispatch(setMakingPostFromContentPlanner(false))
  }, [])

  useEffect(() => {
    if (postSearchTerm) {
      setPreviousPostSearchTerm(postSearchTerm);
      fetchWorkSpacePosts(0);
    } else {
      if (previousPostSearchTerm) {
        fetchWorkSpacePosts(0);
        setPosts([]);
        setSkip(0);
        setPreviousPostSearchTerm(undefined);
        fetchWorkSpacePosts(0);
      }
    }
  }, [postSearchTerm]);

  useEffect(() => {
    if (cachedPosts.length > 0) {
      setPosts(getInitialEvents(cachedPosts));
    }
  }, []);

  useEffect(() => {
    if (calendarTime) {
      dispatch(setPostNow(false));
      // setIsOpenCreatePostModal(true);
      dispatch(setMakingPostFromContentPlanner(true))
      navigate(RoutePaths.POSTS)
    }
  }, [calendarTime]);

  useEffect(() => {
    if (deletedPost) {
      let existingPost = posts.find(x => x._id === deletedPost._id);
      if (existingPost) {
        setPosts((prevPosts) => {
          let indexOfPost = prevPosts.indexOf(existingPost);
          if (indexOfPost !== -1) {
            prevPosts.splice(indexOfPost, 1);
          }
          return [...prevPosts];
        })
      }
    }
  }, [deletedPost]);

  useEffect(() => {
    if (editablePost) {
      dispatch(setCalendarPostTime(new Date(editablePost.data?.time_to_post)));
    }
  }, [editablePost]);

  const eventTimeFormat = {
    hour: 'numeric',
    minute: '2-digit',
    omitZeroMinute: true,
    meridiem: true
  };

  const viewName = [
    'Month View',
    'Week View',
    'Day View',
    'List View',
    'Year View'
  ];

  const handleFilter = filter => {
    setCurrentFilter(filter);
    switch (filter) {
      case 'Month View':
        calendarApi.changeView('dayGridMonth');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Week View':
        calendarApi.changeView('timeGridWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Day View':
        calendarApi.changeView('timeGridDay');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'List View':
        calendarApi.changeView('listWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      default:
        calendarApi.changeView('listYear');
        setTitle(calendarApi.getCurrentData().viewTitle);
    }
  }

  useEffect(() => {
    dispatch(setSearchHint("Search content planner..."));
    setCalendarApi(calendarRef.current.getApi());
    return () => {
      dispatch(setPostsFilters({}));
    }
  }, []);


  useEffect(() => {
    if (refreshedCalendarData) {
      let scheduledDate = new Date(refreshedCalendarData.data?.time_to_post);
      let newEvent = {
        id: refreshedCalendarData._id,
        title: refreshedCalendarData.data?.body,
        start: scheduledDate,
        end: moment(scheduledDate).add(59, 'minutes').toDate(),
        ...(refreshedCalendarData ?? {})
      }
      setPosts((prevPosts) => {
        let existingPost = prevPosts.find(x => x.data.post_group_identifier === newEvent.data.post_group_identifier);
        if (!existingPost) {
          prevPosts.push(newEvent);
        } else {
          let indexOfExistingPost = prevPosts.indexOf(existingPost);
          prevPosts[indexOfExistingPost] = newEvent;
        }
        return [...prevPosts];
      });
      dispatch(setRefreshCalendar(null));
    }
  }, [refreshedCalendarData]);

  const prepareEventsFromPosts = (data, fresh) => {
    setPosts(constructEventsFrom(eventDataTransformer(data, fresh)));
  }

  const fetchWorkSpacePosts = async (receivedSkip) => {
    if (openPostDialog.open) {
      return;
    }
    if (fetchingPosts) {
      return;
    }
    setFetchingPosts(true);
    try {
      let response = await APIService.fetchAllWorkSpacePosts(workSpace['_id'], postFilters, selectedPostType, postSearchTerm, (postSearchTerm && Object.entries(postFilters).length > 0) ? 0 : receivedSkip ?? skip);
      let { data } = response;
      console.log("🚀 ~ fetchWorkSpacePosts ~ data:", data)
      if(selectedPostType === "pending_approval"){
        data = data?.data
      }
      setFetchingPosts(false);
      setCanLoadMore(false);
      let receivedData = data;
      setSkip((prev) => {
        return prev + data.length;
      });
      if (selectedPostType === 'draft') {
        dispatch(setDrafts(data));
      }
      if (selectedPostType === 'all') {
        data = data.filter(x => !x.drafted);
      }
      if (singlePostType && fetchedRounds === 0 && (!(data ?? []).length)) {
        if (posts.length) {
          let everyExistingPostIsWithinSelectedType = posts.every((x) => {
            let satisfiesCondition = () => {
              switch (selectedPostType) {
                case 'draft':
                  return x.drafted
                case 'recurring':
                  return x.can_display_under_recurring;
                case 'failed':
                  return x.failed;
                default:
                  return true;
              }
            }
            return satisfiesCondition();
          });
          if (!everyExistingPostIsWithinSelectedType) {
            console.log("no posts array...")
            setPosts([]);
            return;
          }
        }
      }
      let postsCopy = posts;
      if (singlePostType && fetchedRounds === 0) {
        postsCopy = [];
      }
      if (!postSearchTerm && !Object.entries(postFilters).length) {
        let alreadyExistingPostIds = [];
        for (let postInResponse of data) {
          let existingPost = postsCopy.find(x => x._id === postInResponse._id);
          if (existingPost) {
            alreadyExistingPostIds.push(postInResponse._id);
          }
        }
        data = data.filter(x => !alreadyExistingPostIds.includes(x._id));
        prepareEventsFromPosts(data, singlePostType && fetchedRounds === 0);
        setCanLoadMore(receivedData.length > 0);
      } else {
        prepareEventsFromPosts(data);
      }
    } catch (error) {
      console.log('error', error)
      toast.error(error);
      setFetchingPosts(false);
      setCanLoadMore(false);
    }
  }

  const loadMore = () => {
    if (canLoadMore && posts.length > 0) {
      fetchWorkSpacePosts();
    }
  }

  useEffect(() => {
    loadMore();
  }, [canLoadMore]);

  useEffect(() => {
    fetchWorkSpacePosts(0);
  }, [selectedPostType, postFilters]);

  const isToday = (someDate) => {
    const today = new Date()
    return someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
  }

  const handleDateSlotClickEvent = (selectInfo) => {
    let calendarApi = selectInfo.view.calendar
    calendarApi.unselect();
    let selectedDateStr = selectInfo.date.toString();
    const selectedDateIsToday = isToday(new Date(selectedDateStr));
    if (selectedDateIsToday && currentFilter == "Month View") {
      selectedDateStr = new Date(selectedDateStr);
      selectedDateStr.setHours(new Date().getHours());
      selectedDateStr.setMinutes(new Date().getMinutes());
      selectedDateStr.setSeconds(new Date().getSeconds() + 1);
      dispatch(setTimeToPostContent(new Date(selectedDateStr)));
      dispatch(setCalendarPostTime(new Date(selectedDateStr)));
      return;
    }
    if (new Date(selectedDateStr) >= new Date()) {
      dispatch(setTimeToPostContent(new Date(selectedDateStr)));
      dispatch(setCalendarPostTime(new Date(selectedDateStr)));
    } else {
      toast.info(`Sorry, you can't schedule a post into the past. Kindly pick a date and time into the future`, { theme: 'colored' });
    }
  }

  const handleEventDrop = (info) => {
    let postId = info.event.extendedProps._id;
    let workSpaceId = info.event.extendedProps.workSpace;
    let newTime = info.event.start;
    let oldEvent = info.oldEvent;
    let newEvent = JSON.parse(JSON.stringify(info.event));
    if (oldEvent.extendedProps.posted) {
      newEvent.extendedProps.data.post_group_identifier = StringUtils.generateRandomString(64);
      newEvent.extendedProps.posted = false;
      newEvent.extendedProps.scheduled_for = createDateWithTimeZone(newTime, newEvent.extendedProps.data.timeZone);
      setPosts((prevPosts) => {
        prevPosts.push({
          id: info.event.id,
          title: info.event.title,
          start: newTime,
          end: moment(new Date(newTime)).add(59, 'minutes').toDate(),
          ...newEvent.extendedProps
        });
        return [...prevPosts];
      });
    } else {
      newEvent.extendedProps.scheduled_for = createDateWithTimeZone(newTime, oldEvent.extendedProps.data.timeZone);
      newEvent.extendedProps.data.time_to_post = createDateWithTimeZone(newTime, oldEvent.extendedProps.data.timeZone);
      setPosts((prevPosts) => {
        let existingOldPost = prevPosts.find(x => x.data?.post_group_identifier === oldEvent?.extendedProps.data?.post_group_identifier);
        let indexOfOldPost = prevPosts.indexOf(existingOldPost);
        prevPosts[indexOfOldPost] = {
          id: info.event.id,
          title: info.event.title,
          start: newTime,
          end: moment(new Date(newTime)).add(59, 'minutes').toDate(),
          ...newEvent.extendedProps
        };
        return [...prevPosts];
      });
    }
    if (!info.event.extendedProps.repeated) {
      let workSpaceCopy = workSpaceId;
      if (typeof workSpaceCopy === 'object') {
        workSpaceCopy = workSpaceId._id;
      }
      APIService.updatePostTime(workSpaceCopy, postId, newTime, (response, error) => {
        if (error) {
          toast.error(error);
        }
      });
    }
  }

  const prefetchVideos = (allPosts) => {
    let includedMedia = allPosts?.map(post => post.data?.mediaUrls ?? post.data?.media);
    let videoItems = includedMedia?.filter(mediaEntry => isVideo(mediaEntry));
    if (videoItems.length > 10) {
      return "none"
    }
    return "auto";
  }

  const renderEventContent = (eventInfo) => {
    let post = eventInfo?.event?.extendedProps;
    const postBody = () => {
      let originalBody = post.data?.poll_data ? (post.data?.poll_data?.question) : post.data?.body ?? "";
      let body = originalBody.substring(0, 12);
      if (originalBody.length > 16) {
        body = `${body}...`;
      }
      return body;
    }

    const LazyEmbedMedia = () => {
      let mediaItems = post.data?.mediaUrls ?? post.data?.media;
      let originalMediaItems = mediaItems;
      if ((mediaItems ?? []).length > 0) {
        mediaItems = mediaItems.slice(0, 1);
        let remainingMedia = originalMediaItems.length - 1;
        return (
          <div
            style={{
              position: 'relative'
            }}>
            <CalendarVideoOrPhotoView
              prefetchVideos={prefetchVideos(posts)}
              closeable={false}
              width={'100%'}
              height={60}
              mediaObjects={
                mediaItems.map((mediaEntry) => {
                  let url;
                  if (typeof mediaEntry === 'string') {
                    url = mediaEntry;
                    return {
                      name: url,
                      url,
                      extension: `.${StringUtils.substringAfterLast(StringUtils.substringBeforeLast(url, "?"), ".")}`
                    }
                  } else {
                    return mediaEntry;
                  }
                })
              }
            />
            {
              remainingMedia > 0 &&
              <span
                style={{
                  position: 'absolute',
                  bottom: 5,
                  right: 10,
                  zIndex: 1,
                  background: '#F9FBE7',
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 100,
                  color: 'black'
                }}>{`+${remainingMedia}`}
              </span>
            }
          </div>
        )
      }
      return '';
    }

    const getHoverContent = () => {
      return post.data?.poll_data ? post.data?.poll_data?.question : post.data?.body ?? "";
    }

    const Targets = () => {
      let targets = post.data?.targets ?? post.data?.approval_targets ?? [];
      let usableTargets = targets.slice(0, 2);
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            gap: 2
          }}>
          {
            usableTargets.map((target) => {
              let realTarget = findPlatformByIdentifier(target.identifier);

              return (
                <div
                  className='bg-200'
                  style={{
                    width: 40,
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '100%'
                  }}>

                  <FontAwesomeIcon
                    icon={realTarget.icon}
                    color={realTarget.color}
                    width={33}
                    height={33}
                    size='2x'
                  />
                </div>
              );
            })
          }
          {
            targets.length > 2 &&
            <span className='fs--1'>+{targets.length - 2}</span>
          }
        </div>
      )
    }

    return (
      <div
        className={`${post?.className}`}
        onClick={() => {
          let currentWindowLocation = window.location.href.replace("content-planner/", "content-planner");
          let newLocation = `${currentWindowLocation}/${post?._id}`;
          window.history.pushState("", "", newLocation);
          dispatch(setOpenPostDetailsDialog({ open: true, data: post?._id, time_to_post: post?.data?.time_to_post }));
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          width: '100%',
        }}
        title={post.data && getHoverContent()}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            padding: 0,
            width: '100%',
          }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}>
            {
              (post?.recurring || post?.data?.repeat_option) &&
              <FontAwesomeIcon
                icon={faRepeat}
                size={'sm'}
                title={"This is a repeating post"}
              />
            }
            <span>{
              post?.scheduled_for ?
                moment(createDateWithTimeZone(new Date(post?.scheduled_for), post.data?.timeZone)).format("hh:mm a") : (post?.data?.time_to_post
                  ?? post?.updated_at) ? moment(createDateWithTimeZone(new Date(post?.data?.time_to_post
                    ?? post?.updated_at), post.data?.timeZone)).format("hh:mm a") : 'loading...'
            }</span>
          </div>
          <div
            style={{
              flex: 1
            }}
          />
          <FeedStatus
            post={post}
            percentage={0}
            skipLabel={true}
          />
        </div>
        <p
          style={{
            fontSize: 12,
            width: 'auto'
          }}>{postBody()}
        </p>
        <LazyEmbedMedia />
        {
          post.data?.poll_data &&
          <PollItem polls={post.data?.poll_data?.choices} />
        }
        <Flex
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 3,
            paddingTop: 5,
          }}>
          <Targets />
        </Flex>
      </div>
    );
  }


  return (
    <>
      <Card>
        <Card.Header>
          <Row className="align-items-center gx-0">
            <Col xs="auto" className="d-flex justify-content-end order-md-1">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="nextTooltip">Previous</Tooltip>}
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-1 ms-md-2"
                  onClick={() => {
                    calendarApi.prev();
                    setTitle(calendarApi.getCurrentData().viewTitle);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-left" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="previousTooltip">Next</Tooltip>}
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-lg-2"
                  onClick={() => {
                    calendarApi.next();
                    setTitle(calendarApi.getCurrentData().viewTitle);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-right" />
                </Button>
              </OverlayTrigger>
            </Col>
            <Col xs="auto" className="d-flex justify-content-end order-md-2">
              <h4 className="mb-0 fs-0 fs-sm-1 fs-lg-2">
                {title || `${calendarApi.currentDataManager?.data?.viewTitle}`}
              </h4>
            </Col>
            <Col xs md="auto" className="d-flex justify-content-end order-md-3">
              <Button
                size="sm"
                variant="falcon-primary"
                onClick={() => {
                  calendarApi.today();
                  setTitle(calendarApi.getCurrentData().viewTitle);
                }}
              >
                Today
              </Button>
            </Col>
            <Col md="auto" className="d-md-none">
              <hr />
            </Col>
            <Col xs="auto" className="d-flex order-md-0">
              <TooltipBadge
                tooltip="Click on the date to schedule a post"
                iconSize="26px"
                icon="info-circle"
              />
            </Col>
            <Col xs="auto">
              {
                fetchingPosts &&
                <Flex className={'px-2'}>
                  <ReactLoading type={'bubbles'} width={50} height={50} color={'#0052cc'} />
                </Flex>
              }
            </Col>
            
            <Col className="d-flex justify-content-end order-md-2">
              <DropdownFilter
                className="me-2"
                filters={viewName}
                currentFilter={currentFilter}
                handleFilter={handleFilter}
                icon="sort"
                right
              />
            </Col>
            
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <FullCalendar
            ref={calendarRef}
            headerToolbar={false}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin
            ]}
            initialView="dayGridMonth"
            themeSystem="bootstrap"
            dayMaxEvents={2}
            direction={isRTL ? 'rtl' : 'ltr'}
            height={800}
            stickyHeaderDates={true}
            editable
            selectable
            selectMirror
            expandRows
            slotEventOverlap={false}
            eventDrop={handleEventDrop}
            dateClick={handleDateSlotClickEvent}
            eventTimeFormat={eventTimeFormat}
            events={posts}
            eventContent={renderEventContent}
            weekNumbers={displayWeekNumbers}
            datesSet={checkAndRepeatEventsOnDateChange}
          />
        </Card.Body>
        <Flex className={'p-5'} alignItems={'center'} justifyContent={'center'}>
          <Form.Check
            id={'display-week-numbers-of-planner'}
            label={'Display Week Numbers?'}
            checked={displayWeekNumbers}
            onChange={(e) => {
              dispatch(setDisplayWeekNumbers(e.target.checked));
            }}
          />
        </Flex>
      </Card>
      <ScheduleNewPostModal
        eliminateTimePicker={true}
        isOpenCreatePostModal={isOpenCreatePostModal}
        setIsOpenCreatePostModal={setIsOpenCreatePostModal}
        scheduleStartDate={scheduleStartDate}
        scheduleEndDate={scheduleEndDate}
        setScheduleStartDate={setScheduleStartDate}
        setScheduleEndDate={setScheduleEndDate}
        publishFeedBack={(publishedData) => {
          if (publishedData) {
            setIsOpenCreatePostModal(false);
          }
        }}
      />
    </>
  );
};

export default Calendar;
