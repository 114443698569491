import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import {
    getTimeZoneToPostContent,
    setTimeZoneToPostContent
} from 'redux/slices/postslice';
import { useDispatch, useSelector } from 'react-redux';
import TimeZoneSelector from 'components/common/TimeZoneSelector';
import { getActiveWorkSpace, setActiveWorkspace } from 'redux/slices/workspaceslice';

const TimeZoneSettings = () => {

    const dispatch = useDispatch();
    const workSpace = useSelector(getActiveWorkSpace);
    const selectedTimezone = useSelector(getTimeZoneToPostContent);

    useEffect(() => {
        if (workSpace) {
            if (workSpace?.default_time_zone_for_publishing) {
                dispatch(setTimeZoneToPostContent(workSpace?.default_time_zone_for_publishing?.value));
            }
        }
    }, []);

    return (
        <Card className="mb-3">
            <FalconCardHeader
                title={
                    <h5>
                        Default Timezone Settings
                    </h5>
                }
            />
            <Card.Body className="bg-light">
                <Flex direction={'column'}>
                    <span>Select a default Timezone to be used for Publishing in this workspace</span>
                    <TimeZoneSelector
                        defaultTimeZone={selectedTimezone}
                        onChange={(result) => {
                            dispatch(setTimeZoneToPostContent(result));
                            APIService.setDefaultTimeZoneForPublishing(workSpace._id, result, (response, error) => {
                                if (error) {
                                    toast.error(error, { theme: 'colored' });
                                    return;
                                }
                                let { message, data } = response;
                                toast.success(message, { theme: 'colored' });
                                dispatch(setActiveWorkspace({
                                    ...workSpace,
                                    default_time_zone_for_publishing: data.workspace.default_time_zone_for_publishing
                                }));
                            })
                        }}
                    />
                </Flex>
            </Card.Body>
        </Card>
    );
};

export default TimeZoneSettings;