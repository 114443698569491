import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findPlatformByIdentifier } from 'components/app/platforms';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import React, { useEffect, useState } from 'react';
import { Alert, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Switch from "react-switch";
import { getPublishablePostMedia } from 'redux/slices/post_media_slice';
import { getFacebookCarouselLinks, getFacebookMediaRemovalObserver, getForceUpdateUseableMediaOnPlatformPreviews, getIsFacebookPostCarousel, getIsFacebookPostReel, getIsFacebookPostStory, setFacebookCarouselLinks, setForceUpdateUseableMediaOnPlatformPreviews, setIsFacebookPostCarousel, setIsFacebookPostReel, setIsFacebookPostStory } from 'redux/slices/postslice';
import { getMediaArrangement, setMediaArrangement } from 'redux/slices/publishable_media_arrangement_slice';
import { MetricsProps, PlatformIdentifier, RoutePaths } from '../../../../constants';
import FacebookLinkCarousels from './FacebookLinkCarousels';
import ConfirmModal from 'components/common/ConfirmModal';
import { isFreeWorkspace } from "utils/workspace_utils";
import { useNavigate } from 'react-router-dom';
import { getActiveWorkSpace, getPlatformVideoUploadLimit } from 'redux/slices/workspaceslice';
import Divider from 'components/common/Divider';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { setPopupUpgradeAlert } from 'redux/slices/authSlice';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { getLoggedInUser } from 'redux/slices/user_slice';
import { setCollectMetric } from 'redux/slices/analytics_slice';
import FBCommentView from './FacebookFirstComment';

const FacebookOptionsField = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const workSpace = useSelector(getActiveWorkSpace);
    const facebookPlatform = findPlatformByIdentifier(PlatformIdentifier.FACEBOOK);
    const mediaObjects = useSelector(getPublishablePostMedia);
    const isFacebookPostCarousel = useSelector(getIsFacebookPostCarousel)
    const isFacebookPostReel = useSelector(getIsFacebookPostReel);
    const isFacebookPostStory = useSelector(getIsFacebookPostStory);
    const [facebookPost, setFacebookPost] = useState(!isFacebookPostCarousel && !isFacebookPostReel && !isFacebookPostStory);
    const [facebookCarousel, setFacebookCarousel] = useState(isFacebookPostCarousel);
    const media_items_arrangement = useSelector(getMediaArrangement);
    const [facebookMedias, setFacebookMedia] = useState([]);
    const facebookCarouselLinks = useSelector(getFacebookCarouselLinks)
    const forceUpdateUseableMediaOnPlatformPreviews = useSelector(getForceUpdateUseableMediaOnPlatformPreviews);
    const facebookMediaRemovalObserver = useSelector(getFacebookMediaRemovalObserver);
    const [openVideoNotSupportedAlert, setOpenVideoNotSupportedAlert] = useState(false);
    const [openImageNotSupportedAlert, setOpenImageNotSuportedAlert] = useState(false);
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
    const [facebookReel, setFacebookReel] = useState(isFacebookPostReel);
    const [facebookStory, setFacebookStory] = useState(isFacebookPostStory);
    const platformVideoUploadLimit = useSelector(getPlatformVideoUploadLimit);
    const [show, setShow] = useState(true);
    

    useEffect(() => {
        let _media = media_items_arrangement?.facebook?.media || [];
        setFacebookMedia(_media)
    }, [media_items_arrangement?.facebook?.media])

    const checkIfVideoExistInFacebookMedias = () => {
        const video = media_items_arrangement?.facebook?.media?.find(e => e.is_video === true);
        return video ? true : false;
    }

    const checkIfImageExistsInFacebookMedias = () => {
        const imageExists = media_items_arrangement?.facebook?.media?.find((e) => !e?.is_video);
        return imageExists ? true : false;
    }

    const handleFreeWorkspace = (detailedAction, action) => {
        setFacebookPost(true);
        setFacebookCarousel(false);
        setFacebookReel(false);
        setFacebookStory(false);
        dispatch(setIsFacebookPostCarousel(false));
        dispatch(setIsFacebookPostReel(false));
        dispatch(setIsFacebookPostStory(false));
        let metricInitializer = {
            action,
            detailedAction,
            timestamp: new Date().toISOString(),
            route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
        }
        dispatch(setCollectMetric(metricInitializer))
        dispatch(setPopupUpgradeAlert(true))
    };

    const handleSwitch = (type, e, ignoreChecks = false) => {
        if (type === 'facebookPost') {
            if (!e && isFreeWorkspace(workSpace)) {
                handleFreeWorkspace('Upgrade notice: Facebook post toggle', MetricsProps.FACEBOOK_POSTS);
                return;
            }
            setFacebookPost(e)
            setFacebookCarousel(!e);
            setFacebookReel(!e);
            setFacebookStory(!e);
            dispatch(setIsFacebookPostCarousel(!e))
            dispatch(setIsFacebookPostReel(!e));
            dispatch(setIsFacebookPostStory(!e));

            if (!e) {
                setFacebookCarousel(false);
                setFacebookReel(false);
                setFacebookStory(false);
                dispatch(setIsFacebookPostReel(false));
                dispatch(setIsFacebookPostCarousel(false));
                dispatch(setIsFacebookPostStory(false));
                return;
            }
            return;
        }
        if (type === "facebookReel") {
            if (e && isFreeWorkspace(workSpace)) {
                handleFreeWorkspace('Upgrade notice: Facebook reel toggle', MetricsProps.FACEBOOK_REELS);
                return;
            }
            setFacebookReel(e);
            setFacebookCarousel(!e);
            setFacebookPost(!e);
            setFacebookStory(!e);
            dispatch(setIsFacebookPostCarousel(!e));
            dispatch(setIsFacebookPostStory(!e));
            dispatch(setIsFacebookPostReel(e));
            if (!ignoreChecks && e && checkIfImageExistsInFacebookMedias()) {
                setOpenImageNotSuportedAlert(true);
                return;
            }
            if (!e) {
                setFacebookCarousel(false);
                setFacebookStory(false);
                dispatch(setIsFacebookPostReel(false));
                dispatch(setIsFacebookPostCarousel(false));
                dispatch(setIsFacebookPostStory(false));
                return;
            }
            return;
        }
        if (type === "facebookStory") {
            if (e && isFreeWorkspace(workSpace)) {
                handleFreeWorkspace('Upgrade notice: Facebook story toggle', MetricsProps.FACEBOOK_STORIES);
                return;
            }
            setFacebookStory(e);
            setFacebookCarousel(!e);
            setFacebookPost(!e);
            setFacebookReel(!e);
            dispatch(setIsFacebookPostStory(e));
            dispatch(setIsFacebookPostCarousel(!e));
            dispatch(setIsFacebookPostReel(!e));
            if (!e) {
                setFacebookCarousel(false);
                setFacebookReel(false);
                dispatch(setIsFacebookPostCarousel(false));
                dispatch(setIsFacebookPostReel(false));
                dispatch(setIsFacebookPostStory(false));
                return;
            }
            return;
        }
        if (type === "facebookCarousel") {
            if (e && isFreeWorkspace(workSpace)) {
                handleFreeWorkspace('Upgrade notice: Facebook carousel toggle', MetricsProps.FACEBOOK_CAROUSEL);
                return;
            }
            setFacebookCarousel(e);
            setFacebookReel(!e)
            setFacebookPost(!e);
            setFacebookStory(!e);
            dispatch(setIsFacebookPostCarousel(e));
            dispatch(setIsFacebookPostReel(!e));
            dispatch(setIsFacebookPostStory(!e));
            if (!ignoreChecks && e && checkIfVideoExistInFacebookMedias()) {
                setOpenVideoNotSupportedAlert(true)
                return;
            }
            if (!e) {
                setFacebookReel(false);
                setFacebookStory(false);
                dispatch(setIsFacebookPostCarousel(false));
                dispatch(setIsFacebookPostReel(false));
                dispatch(setIsFacebookPostStory(false));
                return;
            }
            return;
        }

        let media = media_items_arrangement?.facebook?.media || [];
        let images = media.filter(e => e.is_video !== true);
        let newMediaItemsArrangement = {
            ...media_items_arrangement,
            facebook: {
                ...media_items_arrangement.facebook,
                media: images
            }
        }
        ignoreChecks && setOpenVideoNotSupportedAlert(false);
        setFacebookPost(!e)
        setFacebookCarousel(e);
        dispatch(setIsFacebookPostCarousel(e))
        dispatch(setMediaArrangement(newMediaItemsArrangement))
        setTimeout(() => {
            if (!forceUpdateUseableMediaOnPlatformPreviews.includes(PlatformIdentifier.FACEBOOK)) {
                dispatch(setForceUpdateUseableMediaOnPlatformPreviews([PlatformIdentifier.FACEBOOK]));
            }
        }, 1000);
    }


    useEffect(() => {
        if (!facebookMediaRemovalObserver || !isFacebookPostCarousel) return;
        setFacebookCarousel(false);
        setFacebookPost(true);
        setTimeout(() => {
            setFacebookCarousel(true)
            setFacebookPost(false);
        }, 1000);
    }, [facebookMediaRemovalObserver])

    const configureCarouselLinks = () => {
        let newCarouselLinks = [];
        for (let index = 0; index < facebookMedias.length; index++) {
            const element = facebookMedias[index];
            const { url, carousel, name, ...others } = element;
            newCarouselLinks.push({
                link: carousel?.link || '',
                picture: carousel?.picture || url,
                name: carousel?.name || '',
                description: carousel?.description || '',
                ...others
            })
        }
        dispatch(setFacebookCarouselLinks(newCarouselLinks))
    }

    useEffect(() => {
        configureCarouselLinks()

    }, [facebookMedias])

    return (
        <Card className='mb-3'>
            <FalconCardHeader
                title={"For Facebook"}
                // title={bulkContentMode ? "Facebook" : "For Facebook"}
                endEl={
                    <Flex
                        alignItems="center"
                        justifyContent="center"
                    >
                        <div
                            style={{
                                width: 20,
                                height: 20,
                                borderRadius: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: facebookPlatform.color
                            }}>
                            <FontAwesomeIcon
                                icon={facebookPlatform.icon}
                                style={{
                                    color: 'white',
                                    height: 12,
                                    width: 12
                                }}
                            />
                        </div>
                        <Flex
                            className="ms-2"
                            // alignItems="center"
                            // justifyContent="center"
                            style={{ cursor: 'pointer' }}
                        >
                            {
                                show ?
                                    <IoIosArrowUp
                                        onClick={() => setShow(false)}
                                        style={{
                                            // color: 'black',
                                            height: 20,
                                            width: 20
                                        }}
                                    /> :
                                    <IoIosArrowDown
                                        onClick={() => setShow(true)}
                                        style={{
                                            // color: 'black',
                                            height: 20,
                                            width: 20
                                        }}
                                    />
                            }
                        </Flex>
                    </Flex>
                } />
            {
                show &&
                <Card.Body className='bg-light'>
                    <Flex direction={'column'}>
                        <div
                            style={{
                                marginTop: 10,
                                marginBottom: 20,
                                paddingTop: 5,
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                fontSize: 18,
                                gap: 10,
                                fontWeight: 500
                            }}>
                            Facebook Post
                            <Switch
                                checked={facebookPost}
                                onChange={(e) => {
                                    handleSwitch('facebookPost', e)
                                }}
                            />
                        </div>

                        <div
                            style={{
                                marginTop: 10,
                                marginBottom: 20,
                                paddingTop: 5,
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                fontSize: 18,
                                gap: 10,
                                fontWeight: 500
                            }}>
                            Facebook Carousel Post
                            <Switch
                                checked={facebookCarousel}
                                onChange={(e) => {
                                    handleSwitch('facebookCarousel', e)
                                }}
                            />
                        </div>

                        <div
                            style={{
                                marginTop: 10,
                                marginBottom: 20,
                                paddingTop: 5,
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                fontSize: 18,
                                gap: 10,
                                fontWeight: 500
                            }}>
                            Facebook Story
                            <Switch
                                checked={facebookStory}
                                onChange={(e) => {
                                    handleSwitch('facebookStory', e)
                                }}
                            />
                        </div>

                        <div
                            style={{
                                marginTop: 10,
                                marginBottom: 20,
                                paddingTop: 5,
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                fontSize: 18,
                                gap: 10,
                                fontWeight: 500
                            }}>
                            Facebook Reel
                            <Switch
                                checked={facebookReel}
                                onChange={(e) => {
                                    handleSwitch('facebookReel', e)
                                }}
                            />
                        </div>

                        <div>
                            <Divider />
                            <FBCommentView />
                        </div>
                        {
                            facebookCarousel && <Alert variant="warning" className="p-2 mb-0">
                                <Flex>
                                    <Alert variant="warning" className="p-2 mb-0">
                                        <Flex>
                                            <FontAwesomeIcon icon="exclamation-triangle" className="fs-2" />
                                            <div className="ms-3 flex-1">
                                                <h4 className="alert-heading">Please note</h4>
                                                1. You need at least 2 images to create a carousel post.
                                                <br />
                                                2.  Image links are mandatory for Facebook Carousel posts.
                                            </div>
                                        </Flex>
                                    </Alert>
                                </Flex>
                            </Alert>
                        }
                    </Flex>
                    {
                        facebookCarousel && facebookCarouselLinks.length > 1 &&
                        <FacebookLinkCarousels
                            mediaObjects={mediaObjects}
                            facebookMedias={facebookMedias}
                            facebookCarouselLinks={facebookCarouselLinks}
                            setFacebookCarousel={setFacebookCarousel}
                        />
                    }
                    {
                        facebookReel &&
                        <Flex direction={'column'}>
                            <Divider />
                            <Alert variant="warning" className="p-2 mb-0">
                                <Flex>
                                    <div className="ms-3 flex-1">
                                        <h4 className="alert-heading">Please note</h4>
                                        1.  The recommended specifications are: MP4, 1080 x 1920 px and aspect ratio of 9:16.
                                        <br />
                                        2. Video should have a maximum length of 60 seconds.
                                    </div>
                                </Flex>
                            </Alert>
                        </Flex>
                    }

                    {
                        facebookStory &&
                        <Flex direction={'column'}>
                            <Divider />
                            <Alert variant="warning" className="p-2 mb-0">
                                <Flex>
                                    <FontAwesomeIcon icon="exclamation-triangle" className="fs-2" />
                                    <Alert variant="warning" className="p-2 mb-0">
                                        <Flex>
                                            <div className="ms-3 flex-1">
                                                <h4 className="alert-heading">Please note</h4>
                                                1.  The recommended specifications are: MP4, 1080 x 1920 px and aspect ratio of 9:16.
                                                <br />
                                                2. Video should have a maximum length of 60 seconds, and 10MB.
                                                <br />
                                                3. You can publish only one Image. Multiple-images post is not supported
                                            </div>
                                        </Flex>
                                    </Alert>
                                </Flex>
                            </Alert>
                        </Flex>
                    }
                </Card.Body>
            }
            <ConfirmModal
                open={openVideoNotSupportedAlert}
                onCancel={() => {
                    setOpenVideoNotSupportedAlert(false);
                }}
                title="Video alert for Facebook Carousel:"
                message={`We noticed you have a video in your Carousel. Unfortunately, Facebook doesn't allow videos in Carousels. If you click "CONTINUE", all images will be removed from your Facebook media.`}
                cancelText="CANCEL"
                confirmText="CONTINUE"
                onConfirm={() => {
                    handleSwitch('facebookCarousel', true, true)

                }}
            // title="Video Alert: Carousel Changes"
            // message={`We noticed you have a video in your carousel. Unfortunately, Facebook doesn't allow videos in carousels. If you click "CONTINUE", all videos will be removed from your Facebook medias.`}
            // cancelText="CANCEL"
            // confirmText="CONTINUE"
            // onConfirm={() => {
            //     handleSwitch('facebookCarousel', true, true)

            // }}
            />

            <ConfirmModal
                open={openImageNotSupportedAlert}
                onCancel={() => {
                    setOpenImageNotSuportedAlert(false);
                }}
                // title="Image Alert: Reel Changes"
                // message={`We noticed you have an image in your Reel. Unfortunately, Facebook doesn't allow images in Reels. If you click "CONTINUE", all images will be removed from your Facebook media`}
                // cancelText="CANCEL"
                // confirmText="CONTINUE"
                // onConfirm={() => {
                //     handleSwitch('facebookReel', true, true)

                // }}
                title="Image alert for Facebook Reel:"
                message={`We noticed you have an image in your Reel. Unfortunately, Facebook doesn't allow images in Reels. If you click "CONTINUE", all images will be removed from your Facebook media.`}
                cancelText="CANCEL"
                confirmText="CONTINUE"
                onConfirm={() => {
                    handleSwitch('facebookReel', true, true)

                }}
            />
            <ConfirmModal
                open={openUpgradeDialog}
                title={"Action requires Upgrade"}
                message={"This feature requires that you are on a premium workspace"}
                confirmText={"UPGRADE"}
                cancelText={"CANCEL"}
                onCancel={() => {
                    setOpenUpgradeDialog(false);
                }}
                onConfirm={() => {
                    setOpenUpgradeDialog(false);
                    navigate(`${RoutePaths.BILLING_STRIPE}`);
                }}
            />
        </Card>
    )
}

export default FacebookOptionsField