import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import {
    getActiveWorkSpace,
    getPostEmailNotificationChoices,
    setActiveWorkspace,
    setPostEmailNotificationChoices
} from 'redux/slices/workspaceslice';
import Divider from 'components/common/Divider';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getLoggedInUser } from 'redux/slices/user_slice';

const PostEmailNotificationsSettings = () => {

    const dispatch = useDispatch();
    const workSpace = useSelector(getActiveWorkSpace);
    const loggedInUser = useSelector(getLoggedInUser);
    const postEmailNotificationChoices = useSelector(getPostEmailNotificationChoices);

    const publishedPostChoice = () => {
        let vCopy = { ...postEmailNotificationChoices };
        let loggedInUserChoices = vCopy[loggedInUser?._id] ?? { "PUBLISHED": true };
        if (loggedInUserChoices.hasOwnProperty("PUBLISHED")) {
            return loggedInUserChoices["PUBLISHED"];
        }
        return true;
    }

    const failedPostChoice = () => {
        let vCopy = { ...postEmailNotificationChoices };
        let loggedInUserChoices = vCopy[loggedInUser?._id] ?? { "FAILED": true };
        if (loggedInUserChoices.hasOwnProperty("FAILED")) {
            return loggedInUserChoices["FAILED"];
        }
        return true;
    }

    const [receiveEmailNotificationsOfPublishedPosts, setReceiveEmailNotificationsOfPublishedPosts] = useState(publishedPostChoice());
    const [receiveEmailNotificationsOfFailedPosts, setReceiveEmailNotificationsOfFailedPosts] = useState(failedPostChoice());

    return (
        <Card id="manage_email_preference" className="mb-3">
            <FalconCardHeader title="Post Email Notifications" />
            <Card.Body className="bg-light">
                <Form.Group>
                    <Form.Check
                        type='switch'
                        id='posts_email_notifications'
                        checked={receiveEmailNotificationsOfPublishedPosts}
                        label='Receive Email Notifications of Published posts'
                        onChange={(e) => {
                            let newValue = e.target.checked;
                            setReceiveEmailNotificationsOfPublishedPosts(newValue);
                            APIService.updatePostEmailNotificationSettings(workSpace._id, { "PUBLISHED": newValue }, (response, error) => {
                                if (error) {
                                    setReceiveEmailNotificationsOfPublishedPosts(!newValue);
                                    toast.error(error, { theme: 'colored' });
                                    return;
                                }
                                let { message, data } = response;
                                toast.success(message, { theme: 'colored' });
                                dispatch(setPostEmailNotificationChoices(data.post_email_notification_choices));
                                dispatch(setActiveWorkspace({
                                    ...workSpace,
                                    post_email_notification_choices: data.post_email_notification_choices
                                }));
                            });
                        }}
                    />
                    <Form.Check
                        type='switch'
                        id='posts_email_notifications'
                        checked={receiveEmailNotificationsOfFailedPosts}
                        label='Receive Email Notifications of Failed Posts'
                        onChange={(e) => {
                            let newValue = e.target.checked;
                            setReceiveEmailNotificationsOfFailedPosts(newValue);
                            APIService.updatePostEmailNotificationSettings(workSpace._id, { "FAILED": newValue }, (response, error) => {
                                if (error) {
                                    setReceiveEmailNotificationsOfFailedPosts(!newValue);
                                    toast.error(error, { theme: 'colored' });
                                    return;
                                }
                                let { message, data } = response;
                                toast.success(message, { theme: 'colored' });
                                dispatch(setPostEmailNotificationChoices(data.post_email_notification_choices));
                                dispatch(setActiveWorkspace({
                                    ...workSpace,
                                    post_email_notification_choices: data.post_email_notification_choices
                                }));
                            });
                        }}
                    />
                </Form.Group>
                <Divider />
                <span className='fs--1'>Changes to Post(s) Email Notifications affects only you and not all workspace members</span>
            </Card.Body>
        </Card>
    );
};

export default PostEmailNotificationsSettings;