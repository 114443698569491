import { createSlice } from '@reduxjs/toolkit';
import { Strings } from '../../constants';

const initialState = {
    page: 'Welcome',
    pageSubtitle: '',
    openSearchWorkSpace: false,
    headerSubContent: null,
    headerBackContent: null,
    unreadNotificationsCount: 0,
    allNotificationsCount: 0,
    refreshNotificationsCount: false,
    notifications: [],
    openSideBar: false,
    openJobTitleDialog: false,
    titleInWorkSpace: null,
    openCustomDayCreator: false,
    workspaceLogo: null,
    telegramBotLogo: null,
    selectedAccountTab: 0,
    fetchTeam: true,
    team: null,
    searchHint: 'Search...',
    searchTerm: '',
    rightBarItemPositions: {
        TRENDS: 0,
        EVENTS: 1
    },
    openPopularDays: false,
    panelVisibilityChoices: {
        TRENDS: true,
        EVENTS: true
    },
    billedYearly: false,
    socialAccountQuantity: 0,
    openBulkWorkspaceCreator: false,
    reloadPage: false,
    bulkWorkSpacesToCreate: [],
    postEmailNotificationChoices: {
        PUBLISHED: true,
        FAILED: true
    },
    active_workspace: null,
    cached_workspaces: [],
    platformVideoUploadLimit: null,
};

export const workSpaceSlice = createSlice({
    name: 'workspace',
    initialState,
    reducers: {
        updateActivePageName: (state, action) => {
            state.page = action.payload;
        },
        updateActivePageSubTitle: (state, action) => {
            state.pageSubtitle = action.payload;
        },
        searchWorkSpace: (state, action) => {
            state.openSearchWorkSpace = action.payload;
        },
        setHeaderSubContent: (state, action) => {
            state.headerSubContent = action.payload;
        },
        updateHeaderBackContent: (state, action) => {
            state.headerBackContent = action.payload;
        },
        updateUnreadNotificationsCount: (state, action) => {
            state.unreadNotificationsCount = action.payload;
        },
        updateAllNotificationsCount: (state, action) => {
            state.allNotificationsCount = action.payload;
        },
        setRefreshNotificationsCount: (state, action) => {
            state.refreshNotificationsCount = action.payload;
        },
        updateNotifications: (state, action) => {
            state.notifications = action.payload;
        },
        setOpenSideBar: (state, action) => {
            state.openSideBar = action.payload;
        },
        setOpenJobTitleDialog: (state, action) => {
            state.openJobTitleDialog = action.payload;
        },
        setTitleInWorkSpace: (state, action) => {
            state.titleInWorkSpace = action.payload;
        },
        setOpenCustomDayCreator: (state, action) => {
            state.openCustomDayCreator = action.payload;
        },
        setWorkspaceLogo: (state, action) => {
            state.workspaceLogo = action.payload;
        },
        setSelectedAccountTab: (state, action) => {
            state.selectedAccountTab = action.payload;
        },
        setTelegramBotLogo: (state, action) => {
            state.telegramBotLogo = action.payload;
        },
        setCanFetchTeam: (state, action) => {
            state.fetchTeam = action.payload;
        },
        setTeam: (state, action) => {
            state.team = action.payload;
        },
        setOpenAICharAllocator: (state, action) => {
            state.openAICharAllocator = action.payload;
        },
        setSearchHint: (state, action) => {
            state.searchHint = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setRightBarItemPositions: (state, action) => {
            state.rightBarItemPositions = action.payload;
        },
        setOpenPopularDays: (state, action) => {
            state.openPopularDays = action.payload;
        },
        setPanelVisibilityChoices: (state, action) => {
            state.panelVisibilityChoices = action.payload;
        },
        setPostEmailNotificationChoices: (state, action) => {
            state.postEmailNotificationChoices = action.payload;
        },
        setBilledYearly: (state, action) => {
            state.billedYearly = action.payload;
        },
        setSocialAccountQuantity: (state, action) => {
            state.socialAccountQuantity = action.payload;
        },
        setOpenBulkWorkspaceCreator: (state, action) => {
            state.openBulkWorkspaceCreator = action.payload;
        },
        setReloadPage: (state, action) => {
            state.reloadPage = action.payload;
        },
        setBulkWorkSpacesToCreate: (state, action) => {
            state.bulkWorkSpacesToCreate = action.payload;
        },
        setPlatformVideoUploadLimit: (state, action) => {
            state.platformVideoUploadLimit = action.payload;
        },
        setActiveWorkspace: (state, action) => {
            let activeWorkspace = action.payload;
            if (activeWorkspace) {
                state.active_workspace = { ...activeWorkspace };
                localStorage.setItem(Strings.ACTIVE_WORKSPACE, JSON.stringify(activeWorkspace));
                let cachedWorkspaces = state.cached_workspaces ?? [];
                if (cachedWorkspaces.length === 0) {
                    let localStorageCachedWorkspacesString = localStorage.getItem(Strings.CACHED_WORKSPACES);
                    if (localStorageCachedWorkspacesString) {
                        cachedWorkspaces = JSON.parse(localStorageCachedWorkspacesString);
                    }
                }
                const indexOfWorkspace = cachedWorkspaces.findIndex(workspace => workspace._id === activeWorkspace._id);
                if (indexOfWorkspace !== -1) {
                    cachedWorkspaces[indexOfWorkspace] = { ...activeWorkspace };
                    state.cached_workspaces = [...cachedWorkspaces];
                    localStorage.setItem(Strings.CACHED_WORKSPACES, JSON.stringify(cachedWorkspaces));
                }
            } else {
                state.active_workspace = null;
                localStorage.removeItem(Strings.ACTIVE_WORKSPACE);
            }
        },
        setCachedWorkspaces: (state, action) => {
            let workspaces = [...action.payload];
            state.cached_workspaces = workspaces;
            localStorage.setItem(Strings.CACHED_WORKSPACES, JSON.stringify(workspaces));
        }
    }
});
export const getActiveWorkSpace = (state) => {
    let realTimeActiveWorkspace = state.active_workspace;
    let localStorageWorkspaceString = localStorage.getItem(Strings.ACTIVE_WORKSPACE);
    if (realTimeActiveWorkspace) {
        return realTimeActiveWorkspace;
    }
    if (localStorageWorkspaceString) {
        return JSON.parse(localStorageWorkspaceString);
    }
    return null;
};

export const getCachedWorkSpaces = (state) => {
    let realTimeCachedWorkspaces = state.cached_workspaces;
    let localStorageCachedWorkspacesString = localStorage.getItem(Strings.CACHED_WORKSPACES);
    if (realTimeCachedWorkspaces) {
        return realTimeCachedWorkspaces;
    }
    if (localStorageCachedWorkspacesString) {
        return JSON.parse(localStorageCachedWorkspacesString);
    }
    return state.cached_workspaces;
};

export const activePageName = (state) => state.workspace.page;
export const getHeaderBackContent = (state) => state.workspace.headerBackContent;
export const activePageSubtitle = (state) => state.workspace.pageSubtitle;
export const getHeaderSubContent = (state) => state.workspace.headerSubContent;
export const canOpenWorkspaceSearch = (state) => state.workspace.openSearchWorkSpace;
export const canRefreshNotificationsCount = (state) => state.workspace.refreshNotificationsCount;
export const getUnreadNotificationsCount = (state) => state.workspace.unreadNotificationsCount;
export const getAllNotificationsCount = (state) => state.workspace.allNotificationsCount;
export const getNotifications = (state) => state.workspace.notifications;
export const openSideBar = (state) => state.workspace.openSideBar;
export const canOpenJobTitleDialog = (state) => state.workspace.openJobTitleDialog;
export const canOpenAICharAllocator = (state) => state.workspace.openAICharAllocator;
export const getTitleInWorkSpace = (state) => state.workspace.titleInWorkSpace;
export const canOpenCustomDayCreator = (state) => state.workspace.openCustomDayCreator;
export const getWorkspaceLogo = (state) => state.workspace.workspaceLogo;
export const getSelectedAccountTab = (state) => state.workspace.selectedAccountTab;
export const getTelegramBotLogo = (state) => state.workspace.telegramBotLogo;
export const refreshTeam = (state) => state.workspace.fetchTeam;
export const getTeam = (state) => state.workspace.team;
export const getSearchHint = (state) => state.workspace.searchHint;
export const getSearchTerm = (state) => state.workspace.searchTerm;
export const getRightBarSectionsPositions = (state) => state.workspace.rightBarItemPositions;
export const canOpenPopularDays = (state) => state.workspace.openPopularDays;
export const getPanelVisibilityChoices = (state) => state.workspace.panelVisibilityChoices;
export const getPostEmailNotificationChoices = (state) => state.workspace.postEmailNotificationChoices;
export const getBilledYearly = (state) => state.workspace.billedYearly;
export const getSocialAccountQuantity = (state) => state.workspace.socialAccountQuantity;
export const getOpenBulkWorkspaceCreator = (state) => state.workspace.openBulkWorkspaceCreator;
export const getReloadPage = (state) => state.workspace.reloadPage;
export const getBulkWorkSpacesToCreate = (state) => state.workspace.bulkWorkSpacesToCreate;
export const getPlatformVideoUploadLimit = (state) => state.workspace.platformVideoUploadLimit;
export const {
    setActiveWorkspace,
    setCachedWorkspaces,
    updateActivePageName,
    updateActivePageSubTitle,
    updateHeaderBackContent,
    setHeaderSubContent,
    searchWorkSpace,
    updateUnreadNotificationsCount,
    updateAllNotificationsCount,
    setRefreshNotificationsCount,
    updateNotifications,
    setOpenSideBar,
    setOpenJobTitleDialog,
    setOpenAICharAllocator,
    setTitleInWorkSpace,
    setOpenCustomDayCreator,
    setWorkspaceLogo,
    setSelectedAccountTab,
    setTelegramBotLogo,
    setCanFetchTeam,
    setTeam,
    setSearchHint,
    setSearchTerm,
    setRightBarItemPositions,
    setOpenPopularDays,
    setPanelVisibilityChoices,
    setPostEmailNotificationChoices,
    setBilledYearly,
    setSocialAccountQuantity,
    setOpenBulkWorkspaceCreator,
    setReloadPage,
    setBulkWorkSpacesToCreate,
    setPlatformVideoUploadLimit
} = workSpaceSlice.actions;
export default workSpaceSlice.reducer;