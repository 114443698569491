import Flex from 'components/common/Flex';
import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RoutePaths } from '../../../constants';
import EmailVerificationForm from '../EmailVerifivationForm';
import AuthSimpleLayout from 'layouts/AuthSimpleLayout';


const VerifyEmailAddressOnSignup = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const formData = location?.state?.formData;

    useEffect(() => {
        if (!formData) {
            navigate(RoutePaths.REGISTER);
        }
    }, [])


    return (
        <AuthSimpleLayout>
            <Flex alignItems="center" justifyContent="between">
                <h4 className="mb-3">You’re almost there</h4>
                
            </Flex>
            <p className="mb-4 fs--1 fw-semi-bold">Welcome to Postly, {formData?.name}!</p>
            <p className="mb-4 fs--1 fw-semi-bold">Verify that we have the right email for you by entering the verification code sent to your email.</p>
            <p className="mb-4 fs--1 fw-semi-bold">Please check your Inbox and Spam folders for the verification code.</p>
            {/* <p className="mb-0 fs--1">
                    <span className="fw-semi-bold">Already a User? </span>
                    <Link to={RoutePaths.LOGIN}>Log In</Link>
                </p> */}
            {/* </Flex> */}
            <EmailVerificationForm layout="split" hasLabel formData={formData} />
            <p className="fs--1 mt-4">
                <span className="fw-semi-bold">Already verified? </span>
                <Link to={RoutePaths.LOGIN}>Log in</Link>
            </p>
        </AuthSimpleLayout>
    );
};

export default VerifyEmailAddressOnSignup;
