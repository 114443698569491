import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Form } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import av3 from 'assets/img/team/3.jpg';
import FeedReactions from './FeedReactions';
import FeedActionButtons from './FeedActionButtons';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import Comments from './Comments';
import { FeedContext } from 'context/Context';
import PostTargetTag from './PostTargetTag';
import StringUtils from 'utils/string';
import moment from 'moment';
import { getPlatformMetaData } from 'utils/platform_utils';
import PopDrop from 'components/common/PopDrop';
import MetaDataPop from './MetaDataPop';
import { getLoggedInUser, getLoggedInUserFullName } from 'redux/slices/user_slice';
import { useSelector } from 'react-redux';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import ReactSimplyCarousel from 'react-simply-carousel';

export const Targets = React.forwardRef((props, ref) => {

  const { post, fromPostDetailsDialog } = props;
  const style = props.style ?? {};
  const chipStyles = props.chipStyles ?? {};

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  let targets = post.data.targets ?? post.data.approval_targets ?? post.data.selectedPlatforms;
  let successfullyPublishedTo = {};
  // if (!post.drafted) {
  //   if (targets) {
  //     for (let target of targets) {
  //       for (let account of target.accounts) {
  //         if (account.selected) {
  //           if (account.connected_pages_and_groups) {
  //             for (let page of account.connected_pages_and_groups) {
  //               if (page.selected) {
  //                 successfullyPublishedTo[StringUtils.generateRandomString(64)] = page;
  //               }
  //             }
  //           } else {
  //             successfullyPublishedTo[StringUtils.generateRandomString(64)] = {
  //               name: account.user_name ?? account.name,
  //               target: account.identifier ?? account.target
  //             };
  //           }
  //         }
  //       }
  //     }
  //   } else {
  //     targets = post.data.successfully_published_to;
  //     if (targets) {
  //       targets = Object.entries(targets).map((entry) => entry[1]);
  //       for (let item of targets) {
  //         successfullyPublishedTo[StringUtils.generateRandomString(64)] = item;
  //       }
  //     }
  //   }
  // }

  if (targets) {
    for (let target of targets) {
      for (let account of target.accounts) {
        if (account.selected) {
          if (account.connected_pages_and_groups) {
            for (let page of account.connected_pages_and_groups) {
              if (page.selected) {
                successfullyPublishedTo[StringUtils.generateRandomString(64)] = page;
              }
            }
          } else {
            successfullyPublishedTo[StringUtils.generateRandomString(64)] = {
              name: account.user_name ?? account.name,
              target: account.identifier ?? account.target
            };
          }
        }
      }
    }
  } else {
    targets = post.data.successfully_published_to;
    if (targets) {
      targets = Object.entries(targets).map((entry) => entry[1]);
      for (let item of targets) {
        successfullyPublishedTo[StringUtils.generateRandomString(64)] = item;
      }
    }
  }

  let chips = [];
  if (successfullyPublishedTo) {
    successfullyPublishedTo = Object.entries(successfullyPublishedTo).map((entry) => entry);
    for (let successfulPage of successfullyPublishedTo) {
      let pageData = successfulPage;
      let pageKey = pageData[0];
      let pageValue = pageData[1];
      let { target, name } = pageValue;
      if (!target) {
        target = pageKey;
      }
      chips.push(
        <PostTargetTag
          target={target}
          name={name}
          style={chipStyles}
        // onClick={() => handleGetPlatformMetaData(target, post?.data)}
        />
      );
    }

    if (fromPostDetailsDialog) {
      return <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          marginTop: 10,
          marginBottom: 10,
        }}>
        <span
          style={{
            fontSize: 12,
            paddingRight: 5
          }}>To:
        </span>
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            gap: 5,

            flexWrap: 'wrap',
            ...style
          }}>{
            chips.map((x, index) => {
              return (
                <div key={index}>
                  {x}
                </div>
              )
            })
          }</div>
      </div>
    }

    return <ReactSimplyCarousel
      activeSlideIndex={activeSlideIndex}
      onRequestChange={setActiveSlideIndex}
      itemsToShow={1}
      itemsToScroll={1}
      autoplay={true}
      autoplayDelay={2000}
      forwardBtnProps={{
        //here you can also pass className, or any other button element attributes
        style: {
          alignSelf: 'center',
          background: '#b6c1d2',
          border: 'none',
          borderRadius: '50%',
          color: 'white',
          cursor: 'pointer',
          fontSize: '13px',
          height: 20,
          lineHeight: 1,
          textAlign: 'center',
          width: 20,
        },
        children: <span>{`>`}</span>,
      }}
      backwardBtnProps={{
        //here you can also pass className, or any other button element attributes
        style: {
          alignSelf: 'center',
          background: '#b6c1d2',
          border: 'none',
          borderRadius: '50%',
          color: 'white',
          cursor: 'pointer',
          fontSize: '13px',
          height: 20,
          lineHeight: 1,
          textAlign: 'center',
          width: 20,
        },
        children: <span>{`<`}</span>,
      }}
      responsiveProps={[
        {
          itemsToShow: 2,
          itemsToScroll: 2,
          minWidth: 768,
        },
      ]}
      speed={400}
      easing="linear"
    >
      {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}
      {chips.map((x, index) => {
        return (
          <div key={index}>
            {x}
          </div>
        )
      })}
    </ReactSimplyCarousel>
  }
  return '';
});

const FeedCardFooter = ({
  id,
  countLCS,
  reactions,
  otherComments,
  fromPostDetailsDialog,
  ...others
}) => {

  const loggedInUser = useSelector(getLoggedInUser);
  const { feeds, feedDispatch } = useContext(FeedContext);
  const [comment, setComment] = useState('');
  const loggedInUserAvatar = loggedInUser?.avatar;
  const loggedInUserFullName = useSelector(getLoggedInUserFullName);
  const activeWorkspace = useSelector(getActiveWorkSpace);

  let {
    _id,
    first_name,
    last_name,
    avatar,
    email,
  } = others.created_by ?? {}

  const getAuthorName = () => {
    if (_id === loggedInUser?._id) {
      return "You";
    }
    if (others?.data?.createdBy?._id === loggedInUser?._id) {
      return "You";
    }
    if (!first_name && !last_name) {
      return email ?? activeWorkspace?.name;
    }
    return `${first_name} ${last_name}`;
  }

  const getAuthorAvatar = () => {
    return avatar ?? activeWorkspace?.avatar;
  }

  const getFirstComment = () => {
    if (others?.data?.firstComment && others?.data?.firstComment?.firstCommentText?.length > 0) {
      let commentAuthorName = getAuthorName();
      let commentText = others.data.firstComment.firstCommentText;
      let commentTime = others.created_at;
      let commentId = others.data.firstComment.id ?? `${_id}${others._id}`;
      return [
        {
          name: commentAuthorName,
          content: commentText,
          postTime: moment(commentTime).calendar(),
          avatarSrc: getAuthorAvatar(),
          id: commentId
        }
      ]
    }
    return [];
  }

  let comments = [...getFirstComment(), ...(others.comments ?? [])];

  const submitComment = e => {
    e.preventDefault();
    const newComment = {
      id: uuid(),
      avatarSrc: av3,
      name: 'Rebecca Marry',
      content: comment,
      postTime: '1m'
    };

    const feed = feeds.find(feed => feed.id === id);
    feed.details.reactions.comment = true;
    feed.details.comments = [newComment, ...comments];
    feedDispatch({ type: 'UPDATE', payload: { id, feed } });
    setComment('');
  };

  if (fromPostDetailsDialog) {
    return (
      <Card.Footer className="bg-light pt-0">
        <Targets
          post={{ ...others }}
          fromPostDetailsDialog={fromPostDetailsDialog}
        />
        {
          comments.length > 0 && (
            <Comments comments={comments.slice(0, 2)} loadComment={comments.length > 2 ? comments.length : false} />
          )
        }
      </Card.Footer>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        width: '100%',
      }}
    >
      <Targets post={{ ...others }} />
      {/* {
        comments.length > 0 && (
          <Comments comments={comments.slice(0, 2)} loadComment={comments.length > 2 ? comments.length : false} />
        )
      } */}
      {/* //TODO: uncomment the above Comment code when done with dashboard_update */}
      {/* </Card.Footer> */}
    </div>
  );
};

FeedCardFooter.propTypes = {
  id: PropTypes.string.isRequired,
  countLCS: PropTypes.object.isRequired,
  reactions: PropTypes.object,
  comments: PropTypes.array,
  otherComments: PropTypes.string
};

FeedCardFooter.defaultProps = {
  countLCS: { like: 0 },
  comments: []
};

export default FeedCardFooter;
