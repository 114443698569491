import { css } from 'styled-components';
import Color from 'color';

export const lightTheme = {
  name: 'light',
  rootBackground: '#F4F5F7',
  primary: '#0052cc', // Blue
  surfacePrimary: '#0052cc',
  appBlue: '#0052cc',
  pollItemBackground: '#0052cc',
  pollFeedItem: '#0052cc',
  activeSideBarLinkItem: '#0052cc',
  empty: '#FFF',
  platformGridItem: 'white',
  success: '#0B875B', // green
  danger: '#E13C3C', // red
  warning: '#F89C1C', // orange
  secondary: '#F4F5F7', // light grey
  emptyButtonActive: '#D2E5FE',
  modalColor: '#FFFFFF',
  sideBarBackground: '#F4F5F7',
  surfaceColor: '#F5F5F5',
  inputElementBackground: '#F5F5F5',
  buttonText: '#42526E',
  textDarkest: '#172b4d',
  textDark: '#42526E',
  textColor: '#42526E',
  gridItemHoverColor: '#E3F2FD',
  platformGridTextColor: '#42526E',
  postCard: 'white',
  footerTextColor: '#5E6C84',
  workSpaceName: '#42526E',
  textMedium: '#5E6C84',
  textLight: '#8993a4',
  textLink: '#0052cc',
  inputTextColor: 'black',
  textPrimary: '#385898',
  appBarTitle: "#000000",
  appBarSubTitle: "#222222",
  appBarBackground: '#FFFFFF',
  loggedInUsername: "#222222",
  sideBarLinkItem: "#333333",
  commentCard: '#FAFAFA',
  dividerColor: "#CFD8DC",
  backgroundDarkPrimary: '#0747A6',
  backgroundMedium: '#dfe1e6',
  backgroundLight: '#ebecf0',
  hoveredInput: '#ebecf0',
  focusedInput: '#FFF',
  backgroundLightest: '#F4F5F7',
  selectOptionColor: '#D2E5FE',
  textAreaBackground: '#F4F5F7',
  postTimeSurfaces: '#F5F5F5',
  backgroundLightPrimary: '#D2E5FE',
  backgroundLightSuccess: '#E4FCEF',
  navBar: "#0747A6",
  borderLightest: '#dfe1e6',
  borderLight: '#C1C7D0',
  sideBarLinkItemActiveBackground: "#ebecf0",
  borderInputFocus: '#4c9aff',
  commentOnTrendIcon: '#F57F17',
  shareOnEventIcon: '#43A047',
  addVideoIcon: '#D32F2F',
  addPhotoIcon: '#43A047',
  addEmojiIcon: '#FFB74D',
  draftAvailableIndicator: '#81C784',
  socialsCountProgress: '#8BC34A',
  localComputerFileIcon: '#F57F17',
  remoteFileIcon: '#1565C0',
  pollIcon: '#FBC02D',
  workSpaceSubTitle: '#5E6C84',
  activeButtonColor: '#0052cc',
  searchInputBackground: 'white',
  platformGridItemCheckColor: '#689F38',
  inputBackground: '#ebecf0',
  cardColor: 'white',
  defaultTextColor: '#42526E',
  boldTextColor: 'black',
  daysForYouEvenBackground: '#F5F5F5',
  daysForYouOddBackground: 'white',
  commIcon: '#0052cc',
  postCardWorkSpaceName: '#172b4d',
  postCardTime: '#5E6C84',
  publishedPostStatus: '#33691E',
  otherPostStatus: '#5E6C84',
  typography: '#42526E',
  postOptionsTrigger: '#607D8B',
  postTargetChipBackground: '#EEEEEE',
  PostTargetChipPlatformIcon: 'white',
  textAreaFocusedBackground: '#FFFFFF',
  textAreaColor: '#172b4d',
  notificationsBackground: 'white',
  workSpaceSettingsHeaderBar: '#FAFAFA',
  select: '#F4F5F7',
  dropDown: '#FFFFFF',
  dateTimePicker: '#FFFFFF',
  postNowBackground: '#F5F5F5',
  dateTimePickerWrapper: '#F5F5F5',
  clickableBackground: '#F5F5F5',
  audiencePanelColor: '#FFFFFF',
  audienceSectionBackground: '#EEEEEE',
  audienceListItemColor: '#FFFFFF',
  extraFieldSubContainer: '#FAFAFA',
  defaultFileButton: '#FAFAFA',
  notificationReadItem: '#FFFFFF',
  notificationUnreadItem: '#E3F2FD',
  draftedContentFeedback: '#F9FBE7',
  baseColor: '#FFFFFF',
  tableHeader: '#FAFAFA',
  bulkContentPlatformBackground: '#E0F2F1',
  bulkContentPageEntryBackground: '#FFFFFF',
  pollItemLegend: '#EEEEEE',
  feedBackApproved: '#E8F5E9',
  feedBackDisapproved: '#FFEBEE',
  BoardSectionEntryColor: '#CFD8DC',
  characterCounter: '#37474F',
  contentDisabled: '#FAFAFA88',
  calendarTextColor: '#42526E',
  calendarStickyHeader: 'white',
  faMention: '#00ACC1'
};

export const darkTheme = {
  name: 'dark',
  workSpaceSettingsHeaderBar: '#2d3748',
  primary: '#0052cc', // Blue
  activeSideBarLinkItem: '#FAFAFA',
  pollFeedItem: '#FFFFFF',
  pollItemBackground: '#4FC3F7',
  empty: '#2d3748',
  success: '#0B875B', // green
  appBlue: '#0052cc',
  pollItemLegend: '#546E7A',
  danger: '#E13C3C', // red
  warning: '#F89C1C', // orange
  secondary: '#F4F5F7', // light grey
  rootBackground: '#2d3748',
  BoardSectionEntryColor: '#2d3748',
  feedBackApproved: '#2d3748',
  feedBackDisapproved: '#2d3748',
  surfacePrimary: '#2d3748',
  inputElementBackground: '#2d3748',
  bulkContentPlatformBackground: '#2d3748',
  bulkContentPageEntryBackground: '#2d3748',
  audienceSectionBackground: '#2d3748',
  audienceListItemColor: '#425466',
  audiencePanelColor: '#425466',
  dateTimePicker: '#2d3748',
  postNowBackground: '#2d3748',
  dateTimePickerWrapper: '#2d3748',
  clickableBackground: '#2d3748',
  dropDown: '#2d3748',
  select: '#2d3748',
  notificationsBackground: '#2d3748',
  textAreaFocusedBackground: '#2d3748',
  defaultTextColor: '#FAFAFA',
  textDarkest: '#172b4d',
  textDark: '#42526E',
  textColor: '#FAFAFA',
  textMedium: '#5E6C84',
  textLight: '#8993a4',
  textLink: '#0052cc',
  gridItemHoverColor: '#2d3748',
  inputTextColor: 'white',
  postCard: '#425466',
  commentCard: '#425466',
  workSpaceName: '#ECEFF1',
  appBarTitle: "#FFFFFF",
  appBarSubTitle: "#B0BEC5",
  appBarBackground: '#2d3748',
  sideBarBackground: '#425466',
  extraFieldSubContainer: '#425466',
  searchInputBackground: '#425466',
  platformGridItem: '#425466',
  platformGridTextColor: 'white',
  surfaceColor: '#425466',
  contentDisabled: '#42546688',
  characterCounter: '#B0BEC5',
  baseColor: '#425466',
  defaultFileButton: '#425466',
  selectOptionColor: '#425466',
  modalColor: '#425466',
  backgroundDarkPrimary: '#0747A6',
  backgroundMedium: '#dfe1e6',
  backgroundLight: '#425466',
  emptyButtonActive: '#425466',
  activeButtonColor: '#FAFAFA',
  hoveredInput: '#425466',
  focusedInput: '#425466',
  backgroundLightest: '#425466',
  backgroundLightPrimary: '#D2E5FE',
  backgroundLightSuccess: '#E4FCEF',
  loggedInUsername: "#FAFAFA",
  navBar: "#2d3748",
  buttonText: '#B0BEC5',
  borderLightest: '#2d3748',
  borderLight: '#C1C7D0',
  borderInputFocus: '#2d3748',
  sideBarLinkItem: "#FAFAFA",
  sideBarLinkItemActiveBackground: "#2d3748",
  dividerColor: "#546E7A",
  footerTextColor: '#B0BEC5',
  commentOnTrendIcon: '#F57F17',
  shareOnEventIcon: '#43A047',
  addVideoIcon: '#D32F2F',
  addPhotoIcon: '#43A047',
  addEmojiIcon: '#FFB74D',
  draftAvailableIndicator: '#81C784',
  socialsCountProgress: '#8BC34A',
  localComputerFileIcon: '#F57F17',
  remoteFileIcon: '#1565C0',
  pollIcon: '#FBC02D',
  workSpaceSubTitle: '#B0BEC5',
  platformGridItemCheckColor: '#64B5F6',
  inputBackground: '#425466',
  cardColor: '#425466',
  textAreaBackground: '#2d3748',
  boldTextColor: '#FAFAFA',
  daysForYouEvenBackground: '#2d3748',
  daysForYouOddBackground: '#425466',
  commIcon: '#FEFEFE',
  postCardWorkSpaceName: '#FAFAFA',
  postCardTime: '#B0BEC5',
  publishedPostStatus: '#C8E6C9',
  otherPostStatus: '#ECEFF1',
  typography: '#FEFEFE',
  postOptionsTrigger: '#E0E0E0',
  postTargetChipBackground: '#2d3748',
  PostTargetChipPlatformIcon: '#425466',
  textAreaColor: '#FFFFFF',
  notificationReadItem: '#2d3748',
  notificationUnreadItem: '#01579B',
  draftedContentFeedback: '#2d3748',
  tableHeader: '#2d3748',
  calendarTextColor: '#FFFFFF',
  calendarStickyHeader: '#425466'
};

export const color = lightTheme;

export const sizes = {
  appNavBarLeftWidth: 64,
  secondarySideBarWidth: 250,
  minViewportWidth: 1000,
};

export const zIndexValues = {
  modal: 1000,
  dropdown: 101,
  navLeft: 100,
};

export const font = {
  regular: 'font-family: "CircularStdBook"; font-weight: normal;',
  medium: 'font-family: "CircularStdMedium"; font-weight: normal;',
  bold: 'font-family: "CircularStdBold"; font-weight: normal;',
  black: 'font-family: "CircularStdBlack"; font-weight: normal;',
  size: size => `font-size: ${size}px;`,
  previews: {
    facebook: `font-family: "Segoe UI"; font-weight: normal;`
  }
};

export const mixin = {
  darken: (colorValue, amount) =>
    Color(colorValue)
      .darken(amount)
      .string(),
  lighten: (colorValue, amount) =>
    Color(colorValue)
      .lighten(amount)
      .string(),
  rgba: (colorValue, opacity) =>
    Color(colorValue)
      .alpha(opacity)
      .string(),
  boxShadowMedium: css`
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  `,
  boxShadowDropdown: css`
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  `,
  truncateText: css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
  clickable: css`
    cursor: pointer;
    user-select: none;
  `,
  hardwareAccelerate: css`
    transform: translateZ(0);
  `,
  cover: css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `,
  placeholderColor: colorValue => css`
    ::-webkit-input-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
    :-moz-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
    ::-moz-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
    :-ms-input-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
  `,
  scrollableY: css`
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  `,
  customScrollbar: ({ width = 8, background = color.backgroundMedium } = {}) => css`
    &::-webkit-scrollbar {
      width: ${width}px;
    }
    &::-webkit-scrollbar-track {
      background: none;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 99px;
      background: ${background};
    }
  `,
  backgroundImage: imageURL => css`
    background-image: url("${imageURL}");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: ${color.backgroundLight};
  `,
  link: (colorValue = color.textLink) => css`
    cursor: pointer;
    color: ${colorValue};
    ${font.medium}
    &:hover, &:visited, &:active {
      color: ${colorValue};
    }
    &:hover {
      text-decoration: underline;
    }
  `,
  tag: (background = color.backgroundMedium, colorValue = color.textDarkest) => css`
    display: inline-flex;
    align-items: center;
    height: 24px;
    padding: 0 8px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    color: ${colorValue};
    background: ${background};
    ${font.bold}
    ${font.size(12)}
    i {
      margin-left: 4px;
    }
  `,
};
