import AppContext from 'context/Context';
import { useContext, useState } from 'react';
import TimezoneSelect, { allTimezones } from 'react-timezone-select'

const TimeZoneSelector = (
    {
        defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone,
        onChange
    }
) => {

    const { config } = useContext(AppContext);
    const [selectedTimezone, setSelectedTimeZone] = useState(defaultTimeZone);

    return (
        <TimezoneSelect
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: '#2c7be5'
                }
            })}
            styles={{
                option: (provided, state) => {
                    return {
                        ...provided,
                        cursor: 'pointer',
                        ":hover": {
                            background: config.isDark ? "#121e2d" : ""
                        }
                    }
                },
                menuList: (provided) => {
                    return {
                        ...provided,
                        backgroundColor: config.isDark ? "#0b1727" : "white",
                        color: 'inherit'
                    }
                },
                valueContainer: (provided) => {
                    return {
                        ...provided,
                        backgroundColor: config.isDark ? "#0b1727" : "white",
                    }
                },
                dropdownIndicator: (provided) => {
                    return {
                        ...provided,
                        backgroundColor: config.isDark ? "#0b1727" : "white",
                        color: 'inherit'
                    }
                },
                control: (provided) => {
                    return {
                        ...provided,
                        backgroundColor: config.isDark ? "#0b1727" : "white",
                        color: 'inherit'
                    }
                },
                input: (provided) => {
                    return {
                        ...provided,
                        color: config.isDark ? '#fff' : 'black'
                    }
                },
                singleValue: (provided) => {
                    return {
                        ...provided,
                        color: config.isDark ? '#fff' : 'black'
                    }
                }
            }}
            value={selectedTimezone}
            timezones={{
                [defaultTimeZone]: defaultTimeZone,
                ...allTimezones
            }}
            onChange={(selection) => {
                onChange(selection.value);
                setSelectedTimeZone(selection.value);
            }}
        />
    );
}
export default TimeZoneSelector;