export default class HttpErrorHandler {
    static spitHttpErrorMsg(e) {
        if (e !== undefined && e !== null) {
            if (e.hasOwnProperty('response')) {
                let response = e.response;
                if (response !== undefined && response !== null) {
                    if (response.hasOwnProperty('data')) {
                        let responseData = response['data'];
                        if (responseData && responseData.hasOwnProperty('message')) {
                            return responseData['message'];
                        }
                    }
                }
            }
        }
        if(navigator.onLine){
            console.log('error on key: navigator.onLine. Reporting to console.')
        }
        return navigator.onLine ? '' : "Oops! An error occurred!.\nEnsure your data connection is still right.";
    }
}