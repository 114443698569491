import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../slices/authSlice';
import themeReducer from '../slices/themeSlice';
import workSpaceReducer from '../slices/workspaceslice';
import postsReducer from '../slices/postslice';
import platformSlice from '../slices/platformSlice';
import aiWriterSlice from '../slices/ai_writer_slice';
import aiArtSlice from '../slices/ai_art_slice';
import analyticsSlice from '../slices/analytics_slice';
import userSlice from '../slices/user_slice';
import postTextBodySlice from '../slices/post_text_body_slice';
import postMediaSlice from 'redux/slices/post_media_slice';
import postMediaArrangementSlice from 'redux/slices/publishable_media_arrangement_slice';
import messagingSlice from 'redux/slices/messagingSlice';
import linksSlice from '../slices/links_shortener_slice'
import adminSlice from '../slices/admin_slice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        theme: themeReducer,
        workspace: workSpaceReducer,
        posts: postsReducer,
        platform: platformSlice,
        aiwriter: aiWriterSlice,
        aiart: aiArtSlice,
        linkShortener: linksSlice,
        analytics: analyticsSlice,
        users: userSlice,
        admin: adminSlice,
        post_text_body: postTextBodySlice,
        post_media: postMediaSlice,
        media_arrangement: postMediaArrangementSlice,
        messenger: messagingSlice
    }
});
