import APIService from 'http/api_service';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import EditableSection from './debounceable_edit_box';
import { useSelector, useDispatch } from 'react-redux';
import { getLoggedInUser, setLoggedInUser } from 'redux/slices/user_slice';
import { setActiveWorkspace } from 'redux/slices/workspaceslice';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'constants';

const EmailValidatorPop = (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { setEmailIsVerified } = props;
    const loggedInUser = useSelector(getLoggedInUser);
    const [email, setEmail] = useState(loggedInUser.email);
    const [buttonText, setButtonText] = useState('Send Verification Email');
    const [requestInProcess, setRequestInProcess] = useState(false);
    const [codeIsSent, setCodeIsSent] = useState(false);
    const [code, setCode] = useState('');
    const [resendButtonText, setResendButtonText] = useState('Resend Verification Email');

    const validateEmail = (email) => {
        // eslint-disable-next-line no-useless-escape
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const handleConfirmCodeForEmailVerification = () => {
        if (!code) {
            toast.error('Please enter the code we sent to you', { theme: 'colored' });
            return
        }
        let requestBody = {
            email: email,
            code: code
        }
        setRequestInProcess(true);
        APIService.confirmCodeForEmailVerification(requestBody, 0, (response, error) => {
            if (error) {
                setRequestInProcess(false);
                toast.error(error, { theme: 'colored' });
                return;
            }
            setRequestInProcess(false);
            let { data } = response;
            dispatch(setLoggedInUser({ ...data }));
            toast.success('Thank you! Your email is now verified.', { theme: 'colored' });
            setEmailIsVerified(true);
        });
    }


    const handleSendVerificationEmail = () => {
        if (!email) {
            toast.error('Please enter your email address', { theme: 'colored' });
            return
        }
        if (!validateEmail(email)) {
            toast.error('Please enter a valid email address', { theme: 'colored' });
            return
        }
        let requestBody = {
            email: email
        }
        setRequestInProcess(true);
        APIService.requestCodeForEmailVerification(requestBody, 0, (response, error) => {
            if (error) {
                setRequestInProcess(false);
                toast.error(error, { theme: 'colored' });
                return;
            }
            setRequestInProcess(false);
            setButtonText('Verify Email');
            setCodeIsSent(true);
            toast.success(response.message, { theme: 'colored' });
        });
    }

    if (codeIsSent) {
        return <div>
            <p>Please paste the code we sent to you to continue</p>
            <EditableSection
                style={{
                    width: '100%'
                }}
                resetValue={true}
                placeholder={'Enter the code we sent to you'}
                value={code}
                onChange={(value) => {
                    setCode(value);
                }}
            />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '10px',
                    gap: '10px'
                }}
            >
                <Button
                    disabled={requestInProcess}
                    style={{
                        alignSelf: 'flex-end'
                    }}
                    onClick={handleSendVerificationEmail}
                    variant='primary'>
                    {resendButtonText}
                </Button>
                <Button
                    disabled={requestInProcess}
                    style={{
                        alignSelf: 'flex-end'
                    }}
                    onClick={handleConfirmCodeForEmailVerification}
                    variant='primary'>
                    {buttonText}
                </Button>
            </div>

        </div>
    }

    const logOut = () => {
        APIService.logOut((res, err) => { });
        dispatch(setLoggedInUser(null));
        dispatch(setActiveWorkspace(null));
        localStorage.clear();
        navigate(RoutePaths.LOGIN)
    }

    return (
        <div>
            <div
                style={{
                    marginBottom: '20px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <p>Please we need you to verify your email address</p>
                <Button
                    onClick={() => {
                        logOut();
                    }}
                    variant='primary'>
                    Logout
                </Button>
            </div>
            <EditableSection
                style={{
                    width: '100%'
                }}
                placeholder={email ?? 'Enter your email'}
                value={email}
                onChange={(value) => {
                    setEmail(value);
                }}
            />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '10px'
                }}
            >
                <Button
                    disabled={requestInProcess}
                    style={{
                        // minWidth: 200,
                        alignSelf: 'flex-end'
                    }}
                    onClick={handleSendVerificationEmail}
                    variant='primary'>
                    {buttonText}
                </Button>
            </div>

        </div>
    )
}

export default EmailValidatorPop