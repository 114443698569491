import Flex from 'components/common/Flex';
import React from 'react'
import { Button, Card, Form, Spinner } from "react-bootstrap";

const PreviewSkeleton = () => {
  return (
    <Card 
    style={{
      width:'100%'
    }}
    className='mt-2 p-2'>
      <Flex
        gap={3}
        alignItems={'center'}
        style={{
          width:'100%'
        }}
      >
        <div
          style={{
            width: '45px',
            height: '45px',
            borderRadius: '50%',
            backgroundColor: '#e5e6eb',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >

        </div>
        <Flex
          direction={'column'}
          justifyContent={'center'}
          gap={2}
        >
          <div style={{ height: 5, width: 130, backgroundColor: '#e5e6eb', borderRadius: '8px', }}></div>
          <div style={{ height: 5, width: 100, backgroundColor: '#e5e6eb', borderRadius: '8px', }}></div>
        </Flex>
      </Flex>
      <Flex
        gap={3}
        alignItems={'center'}
      >
        <Flex
          direction={'column'}
          justifyContent={'center'}
          gap={2}
          className={'mt-3'}
        >
          <div style={{ height: 10, width: 270, backgroundColor: '#e5e6eb', borderRadius: '10px', }}></div>
          <div style={{ height: 10, width: 250, backgroundColor: '#e5e6eb', borderRadius: '10px', }}></div>
          <div style={{ height: 10, width: 200, backgroundColor: '#e5e6eb', borderRadius: '10px', }}></div>
        </Flex>
      </Flex>
      <Flex
        gap={3}
        alignItems={'center'}
      >
        <Flex
          direction={'column'}
          justifyContent={'center'}
          gap={2}
          className={'mt-3'}
        >
          <div style={{ height: 220, minWidth: 265, backgroundColor: '#e5e6eb', borderRadius: '10px', }}></div>
        </Flex>
      </Flex>
    </Card>
  )
}

export default PreviewSkeleton