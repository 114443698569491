/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import CreatePost from "./CreatePost";
import FeedCard from "./FeedCard";
import classNames from "classnames";
import PlatformDisconnectedHeadsUp from "components/app/platforms/platform_disconnected_heads_up";
import APIService from "http/api_service";
import { toast } from "react-toastify";
import {
  cachePosts,
  canForceFetchPosts,
  canOpenPostDetailsDialog,
  getCachedPosts,
  getDeletedPost,
  getFeeds,
  getGoogleDriveManager,
  getPostBatchData,
  getPostFilters,
  getSelectedPostType,
  getWorkspaceManagement,
  setDeletedPost,
  setDrafts,
  setFeeds,
  setPostBatchData,
  setSelectedPostType,
  setWorkspaceManagement,
} from "redux/slices/postslice";
import EmptyPosts from "assets/animations/empty_posts.json";
import { useSelector, useDispatch } from "react-redux";
import {
  Alert,
  Button,
  Card,
  FloatingLabel,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import Flex from "components/common/Flex";
import Lottie from "lottie-react";
import ScheduleFirstPostAnimation from "assets/animations/schedule_first_post_animation.json";
import { PERMISSION_EDIT_POST, RoutePaths } from "../../../../constants";
import { getActiveWorkSpace, getSearchTerm } from "redux/slices/workspaceslice";
import InfiniteScroll from "react-infinite-scroll-component";
import LazyList from "lazylist-react";
import usePubSub from "pubsub";
import Divider from "components/common/Divider";
import ReactLoading from "react-loading";
import { SHA256 } from "crypto-js";
import GDriveDashboard from "components/common/g-drive/dashboard";
import StorageEngine from "../../../../utils/storage_engine";
import { userCanPerformActionInWorkspace } from "utils/workspace_utils";
import { getLoggedInUserFullName } from "redux/slices/user_slice";
import InfoBar from "components/common/InfoBar";
import { FeedListDisplay } from "components/app/rss/styles/feed";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconAlert from "components/common/IconAlert";
import SoftBadge from "components/common/SoftBadge";

const PendingApprovalPosts = (props) => {
  const { postType } = props;
  const [canDisplayPostForm, setCanDisplayPostForm] = useState(false);
  const workSpace = useSelector(getActiveWorkSpace);
  const loggedInUserFullName = useSelector(getLoggedInUserFullName);
  // const posts = useSelector(getCachedPosts);
  const posts = useSelector(getFeeds).pending_approval || [];
  const [fetchingPosts, setFetchingPosts] = useState(true);
  const forceFetchPosts = useSelector(canForceFetchPosts);
  const postFilters = useSelector(getPostFilters);
  const postSearchTerm = useSelector(getSearchTerm);
  const [previousPostSearchTerm, setPreviousPostSearchTerm] = useState();
  const deletedPost = useSelector(getDeletedPost);
  const dispatch = useDispatch();
  const openPostDialog = useSelector(canOpenPostDetailsDialog);
  const workspaceManagement = useSelector(getWorkspaceManagement);
  const googleDriveManager = useSelector(getGoogleDriveManager);
  const [skip, setSkip] = useState(0);
  const { addPubSubEventListener, removePubSubEventListener } = usePubSub();
  const [noContentToDisplay, setNoContentToDisplay] = useState(false);
  const postBatchData = useSelector(getPostBatchData);
  const [openBatchDeleteModal, setOpenBatchDeleteModal] = useState(false);
  const deleteBatchPostsOnlyOnPostly = useRef(null);
  const deleteBatchPostsOnlyOnPlatforms = useRef(null);
  const deleteBatchPostsEverywhere = useRef(null);
  const cachedPosts = [...useSelector(getCachedPosts)];
  const [deletingBatchPosts, setDeletingBatchPosts] = useState(false);
  const [
    openBatchDeleteModalForSelectAll,
    setOpenBatchDeleteModalForSelectAll,
  ] = useState(false);
  const [selectAllPendingAction, setSelectAllPendingAction] = useState(null);
  const selectedPostType = "pending_approval";
  const [showPostActionModal, setShowPostActionModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");
  const [comment, setComment] = useState("");
  const [approvingPost, setApprovingPost] = useState(false);
  const [approved, setApproved] = useState(false);
  const [disapproved, setDisapproved] = useState(false);
  const [disapprovingPost, setDisapprovingPost] = useState(false);

  // useEffect(() => {
  //     dispatch(cachePosts([]));
  //     localStorage.setItem('cached_posts', JSON.stringify([]));
  // }, [])

  const deletePostInBatch = async (isDraft, postData) => {
    if (isDraft) {
      const deleteDraftedPost = (workspaceId, uniqueContentDraftId) => {
        return new Promise((resolve, reject) => {
          APIService.deleteDraftedPost(
            workspaceId,
            uniqueContentDraftId,
            (response, error) => {
              if (error) {
                reject(error);
                return;
              }
              const publishedPosts = posts || [];
              let postMark = publishedPosts.find((x) => x._id === postData._id);
              if (postMark) {
                let indexOfPostMark = cachedPosts.indexOf(postMark);
                if (indexOfPostMark !== -1) {
                  let postsClone = [...cachedPosts];
                  postsClone.splice(indexOfPostMark, 1);
                  dispatch(
                    setFeeds({
                      ...posts,
                      pending_approval: [...postsClone],
                    })
                  );
                  // dispatch(cachePosts(postsClone));
                }
              }
              resolve(response);
            }
          );
        });
      };
      try {
        await deleteDraftedPost(workSpace._id, postData?.uniqueContentDraftId);
      } catch (error) {
        toast.error(error, { theme: "colored" });
      }
      return;
    }
    const deletePost = (workspaceId, postId) => {
      return new Promise((resolve, reject) => {
        APIService.deletePost(workspaceId, postId, (response, error) => {
          if (error) {
            reject(error);
            return;
          }
          const publishedPosts = posts || [];
          let postMark = publishedPosts.find((x) => x._id === postData._id);
          if (postMark) {
            let indexOfPostMark = cachedPosts.indexOf(postMark);
            if (indexOfPostMark !== -1) {
              let postsClone = [...cachedPosts];
              postsClone.splice(indexOfPostMark, 1);
              dispatch(
                setFeeds({
                  ...posts,
                  pending_approval: [...postsClone],
                })
              );
            }
          }
          resolve(response);
        });
      });
    };

    try {
      await deletePost(workSpace._id, postData?._id);
    } catch (error) {
      console.error(error);
      toast.error(error, { theme: "colored" });
    }
  };

  const deleteFromPublishedPlatforms = (postData) => {
    return new Promise((resolve, reject) => {
      APIService.deleteWorkSpacePostFromOnlyPlatformsItWasPublishedTo(
        workSpace._id,
        postData._id,
        (response, error) => {
          if (error) {
            toast.error(error, { theme: "colored" });
            reject(error);
            return;
          }
          let { message } = response;
          toast.success(message, { theme: "colored" });
          resolve(response);
        }
      );
    });
  };

  const deleteFromPostly = (postData) => {
    return new Promise((resolve, reject) => {
      APIService.deleteWorkSpacePostFromOnlyPostly(
        workSpace._id,
        postData._id,
        (response, error) => {
          if (error) {
            toast.error(error, { theme: "colored" });
            reject(error);
            return;
          }
          const publishedPosts = posts || [];
          let postMark = publishedPosts.find((x) => x._id === postData._id);
          if (postMark) {
            let indexOfPostMark = cachedPosts.indexOf(postMark);
            if (indexOfPostMark !== -1) {
              let postsClone = [...cachedPosts];
              postsClone.splice(indexOfPostMark, 1);
              dispatch(
                setFeeds({
                  ...posts,
                  pending_approval: [...postsClone],
                })
              );
            }
          }
          resolve(response);
        }
      );
    });
  };

  const handleDraftBatchDelete = async () => {
    setDeletingBatchPosts(true);
    for (const postData of postBatchData) {
      await deletePostInBatch(true, postData);
    }
    setDeletingBatchPosts(false);
    window.location.reload();
  };

  const handleBatchPostDeletion = async (howPublishedPostIsDeleted) => {
    setDeletingBatchPosts(true);
    for (const postData of postBatchData) {
      if (postData?.posted) {
        if (howPublishedPostIsDeleted === "everywhere") {
          await deletePostInBatch(false, postData);
        } else if (howPublishedPostIsDeleted === "platforms") {
          await deleteFromPublishedPlatforms(postData);
        } else if (howPublishedPostIsDeleted === "postly") {
          await deleteFromPostly(postData);
        }
      } else {
        await deletePostInBatch(false, postData);
      }
    }
    toast.success(`Operation completed...Please wait`);
    setDeletingBatchPosts(false);
    window.location.reload();
  };

  // const initialPostTypeFromProps = (value = "pending_approval") => {
  //     setSkip(0);
  //     setCanDisplayPostForm(true);
  //     dispatch(cachePosts([]));
  //     dispatch(setSelectedPostType("pending_approval"));
  // }

  // useEffect(() => {
  //     if (postType) {
  //         initialPostTypeFromProps(postType);
  //     } else {
  //         initialPostTypeFromProps("all");
  //     }
  // }, [postType])

  useEffect(() => {
    if (postSearchTerm) {
      setPreviousPostSearchTerm(postSearchTerm);
    } else {
      if (previousPostSearchTerm) {
        dispatch(cachePosts([]));
        dispatch(
          setFeeds({
            ...posts,
            pending_approval: [],
          })
        );
        setSkip(0);
        setPreviousPostSearchTerm(undefined);
        fetchPostsFromRemoteSource(0);
      }
    }
  }, [postSearchTerm]);

  useEffect(() => {
    let deletedPostEventListener = async ({ data }) => {
      let postId = data;
      const publishedPosts = posts || [];
      // let tempPosts = await StorageEngine.fetchPostsFromCache("cached_posts");
      let existingPostsCopy = [...publishedPosts];
      let existingPost = existingPostsCopy.find((x) => x._id === postId);
      if (existingPost) {
        let indexOfPost = existingPostsCopy.indexOf(existingPost);
        if (indexOfPost !== -1) {
          existingPostsCopy.splice(indexOfPost, 1);
        }
        // dispatch(cachePosts([]));
        // dispatch(cachePosts([...existingPostsCopy]));
        dispatch(
          setFeeds({
            ...posts,
            pending_approval: [...existingPostsCopy],
          })
        );
      }
    };
    addPubSubEventListener("deleted_post_status", deletedPostEventListener);
    return () => {
      removePubSubEventListener(
        "deleted_post_status",
        deletedPostEventListener
      );
    };
  }, []);

  const [canLoadMore, setCanLoadMore] = useState(false);

  const generateGetStartedMessageBasedOnPostType = () => {
    if (selectedPostType === "recurring") {
      return `Let's get you started with creating your first recurring post on Postly`;
    }
    if (selectedPostType === "posted") {
      return `Let's get you started with publishing your first post on Postly`;
    }
    return `Let's get you started with scheduling your first post on Postly`;
  };

  const fetchPostsFromRemoteSource = async (receivedSkip) => {
    if (openPostDialog && openPostDialog.open) {
      return;
    }
    if (!workSpace) {
      return;
    }
    try {
      let response;
      response = await APIService.fetchAllWorkSpacePosts(
        workSpace["_id"],
        postFilters,
        selectedPostType,
        postSearchTerm,
        postSearchTerm && Object.entries(postFilters).length > 0
          ? 0
          : receivedSkip ?? skip
      );
      let { workspaceManagement, data } = response.data;
      console.log(data, "data")
      //   let { data } = response;

      // Filter the workspaceManagement.members array
      if (workspaceManagement?.members) {
        workspaceManagement.members = workspaceManagement.members.filter((m) =>
          ["owner", "Admin", "Approver", "Owner"].includes(m?.role)
        );
      }
      dispatch(setWorkspaceManagement(workspaceManagement));

      if (data?.length < 1) {
        setFetchingPosts(false);
        setNoContentToDisplay(true);
      } else {
        setNoContentToDisplay(false);
      }
      setSkip((prev) => {
        return prev + data?.length;
      });
      if (!postSearchTerm && !Object.entries(postFilters).length) {
        if (posts.length > 0 && data?.length > 0) {
          let alreadyExistingPostIds = [];
          for (let postInResponse of data) {
            let existingPost = posts.find((x) => x._id === postInResponse._id);
            if (existingPost) {
              alreadyExistingPostIds.push(postInResponse._id);
            }
          }
          data = data?.filter((x) => !alreadyExistingPostIds.includes(x._id));
        }
        const publishedPosts = posts || [];
        let existingPosts = [...publishedPosts];
        // let existingPosts = [...posts];
        for (let post of data) {
          let existingPost = existingPosts.find((x) => x._id === post._id);
          if (!existingPost) {
            existingPosts.push(post);
          } else {
            let indexOfExistingPost = existingPosts.indexOf(existingPost);
            existingPosts[indexOfExistingPost] = post;
          }
        }
        let newList = [...existingPosts];
        // dispatch(cachePosts(newList));
        dispatch(
          setFeeds({
            ...posts,
            pending_approval: [...newList],
          })
        );
        setCanLoadMore(data?.length > 4);
      } else {
        // dispatch(cachePosts(data));
        dispatch(
          setFeeds({
            ...posts,
            pending_approval: [...data],
          })
        );
      }
    } catch (error) {
      setFetchingPosts(false);
      toast.error(error, { theme: "colored" });
    }
  };

  const loadMore = () => {
    if (canLoadMore && posts.length > 0) {
      fetchPostsFromRemoteSource();
    }
  };

  useEffect(() => {
    let unloadListener = async (event) => {
      await StorageEngine.removeItem("cached_posts");
      return "";
    };
    window.addEventListener("beforeunload", unloadListener);
    return () => window.removeEventListener("beforeunload", unloadListener);
  }, []);

  useEffect(() => {
    if (postSearchTerm || Object.entries(postFilters).length) {
      fetchPostsFromRemoteSource(0);
    }
  }, [postSearchTerm, postFilters]);

  useEffect(() => {
    if (forceFetchPosts) {
      fetchPostsFromRemoteSource(0);
    }
  }, [forceFetchPosts]);

  useEffect(() => {
    fetchPostsFromRemoteSource(0);
  }, [selectedPostType]);

  useEffect(() => {
    if (deletedPost) {
      const publishedPosts = posts || [];
      let updatablePosts = [...publishedPosts];
      // let updatablePosts = [...posts];
      let existingDeletedPost = updatablePosts.find(
        (x) => x._id === deletedPost._id
      );
      if (existingDeletedPost) {
        let indexOfPost = updatablePosts.indexOf(existingDeletedPost);
        if (indexOfPost !== -1) {
          updatablePosts.splice(indexOfPost, 1);
          // dispatch(cachePosts([...updatablePosts]));
          dispatch(
            setFeeds({
              ...posts,
              pending_approval: [...updatablePosts],
            })
          );
          dispatch(setDeletedPost(null));
        }
      }
    }
  }, [deletedPost]);

  const GetStartedWithSchedulingView = () => {
    return (
      <Flex
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Lottie animationData={ScheduleFirstPostAnimation} loop={false} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 10,
          }}
        >
          <h5
            style={{
              fontSize: 20,
              alignSelf: "center",
              fontWeight: 600,
            }}
          >
            {`Welcome ${loggedInUserFullName}`}
          </h5>
          <span
            style={{
              color: "#607D8B",
              textAlign: "center",
            }}
          >
            {generateGetStartedMessageBasedOnPostType()}
          </span>
          <span
            style={{
              color: "#607D8B",
            }}
          >
            It's easy in just a few steps
          </span>
        </div>
        <Button
          variant={"primary"}
          style={{
            paddingLeft: 50,
            paddingRight: 50,
            margin: 10,
          }}
          onClick={() => {
            if (
              userCanPerformActionInWorkspace(workSpace, PERMISSION_EDIT_POST)
            ) {
              setCanDisplayPostForm(true);
            }
          }}
        >
          Get Started
        </Button>
      </Flex>
    );
  };

  const EmergencyNotice = () => {
    return (
      <Alert variant="warning" className="text-center">
        <strong>
          <span role="img" aria-label="attention">
            🌐
          </span>{" "}
          Important Notice: Draft saving functionality is temporarily
          unavailable but will be restored shortly. Thank you for your patience.
          <span role="img" aria-label="turbo">
            🚀
          </span>
        </strong>
      </Alert>
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === "Delete") {
      if (postBatchData.length) {
        setOpenBatchDeleteModal(true);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [postBatchData]);

  const handleSelectAllClick = (e) => {
    let postBatchDataCopy = [];
    if (e.target.checked) {
      for (const post of posts) {
        postBatchDataCopy = [
          ...postBatchDataCopy,
          {
            _id: post._id,
            uniqueContentDraftId: post?.uniqueContentDraftId || "",
            posted: post?.posted || false,
          },
        ];
      }
      dispatch(setPostBatchData(postBatchDataCopy));
    } else {
      dispatch(setPostBatchData([]));
    }
  };

  const getDeleteAllMessage = () => {
    if (selectAllPendingAction === "everywhere") {
      return `Delete all posts?`;
    }
    if (selectAllPendingAction === "postly") {
      return `Delete all posts only on Postly servers?`;
    }
    if (selectAllPendingAction === "platforms") {
      return `Delete all posts only on the respective platforms?`;
    }
  };

  const approvePost = (post) => {
    APIService.approvePost(
      workSpace["_id"],
      post._id,
      {
        reason: comment,
      },
      (response, error) => {
        if (error) {
          toast.error(error, { theme: "colored" });
          return;
        }
        let { data, message } = response;
        // toast.success(message, { theme: "colored" });
      }
    );
  };

  const disapprovePost = (post) => {
    APIService.disApprovePost(
      workSpace["_id"],
      post._id,
      {
        reason: comment,
      },
      (response, error) => {
        if (error) {
          toast.error(error, { theme: "colored" });
          return;
        }
        let { data, message } = response;
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      }
    );
  };

  const approveAllPosts = async (posts) => {
    try {
      setApprovingPost(true);
      await Promise.all(postBatchData.map((post) => approvePost(post)));
      toast.success("All posts approved...Please wait.", { theme: "colored" });
      setApprovingPost(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      setApprovingPost(false);
      console.error("Error approving some posts:", error);
      toast.error("Error approving some posts", { theme: "colored" });
    }
  };

  const disApproveAllPosts = async (posts) => {
    try {
      setDisapprovingPost(true);
      await Promise.all(postBatchData.map((post) => disapprovePost(post)));
      toast.success("All posts rejected...Please wait.", { theme: "colored" });
      setDisapprovingPost(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      setDisapprovingPost(false);
      console.error("Error approving some posts:", error);
      toast.error("Error approving some posts", { theme: "colored" });
    }
  };
  

  const workspaceApprovalDetailsSideNav = () => {
    return (
      <div
        style={{
          flex: 1,
          position: "relative",
        }}
      >
        <Card
          style={{
            position: "fixed",
            minHeight: "50vh",
            width: "20rem",
          }}
        >
          <Card.Body
            className="bg-light"
            style={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <div>
              <h5 style={{ fontSize: "16px" }}>List of approvers</h5>
              <br />
              {workspaceManagement?.members?.map((m) => (
                <Card.Text
                  style={{
                    fontSize: "12px",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {/* <FontAwesomeIcon icon={faCheck} color="#339933" />{" "} */}
                  {m?.user_id?.avatar ? (
                    <img
                      src={m?.user_id?.avatar}
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "100%",
                      }}
                    />
                  ) : (
                    <span style={{ fontSize: "20px" }}>&#128512;</span>
                  )}
                  {m?.user_id?.email} <SoftBadge bg="info">{m?.role}</SoftBadge>
                </Card.Text>
              ))}
            </div>
            <InfoBar
              customStyles={{ fontSize: "12px" }}
              innerIconStyles={{ fontSize: "14px" }}
              info={`Number of approvers required {${workspaceManagement?.workspaceSetting?.third_approval_option_value || 1}}`}
              disableSpeakerIcon={true}
            />
          </Card.Body>
        </Card>
      </div>
    );
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Body
          className="bg-light"
          style={{
            width: "100%",
          }}
        >
          <Flex
            gap={2}
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: 200,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Form.Check
                type="checkbox"
                style={{
                  cursor: "pointer",
                  // marginTop: 2,
                }}
                label="Select all"
                onChange={handleSelectAllClick}
                // ref={checkboxRef}
              />
            </div>
            {/* <div
                            style={{
                                width:'100%'
                            }}
                        >
                            <InfoBar
                                variant={'info'}
                                info={`Posts cannot be edited after approval.`}
                            />
                        </div> */}
            {postBatchData?.length ? (
              <Flex
                justifyContent={"end"}
                gap={3}
                style={{
                  width: "100%",
                  padding: 10,
                }}
              >
                <Button
                  style={{
                    fontSize: "12px",
                    width: "fit-content",
                    height: 40,
                    // color: "#444f5e"
                  }}
                  onClick={() => {
                    // approvePost();
                    setShowPostActionModal(true);
                    setSelectedAction("approve");
                  }}
                  isWorking={approvingPost}
                  disabled={approvingPost}
                  variant={"primary"}
                >
                  <Card.Text>
                    {approvingPost ? "Submitting..." : "Approve"}
                  </Card.Text>
                </Button>
                <Button
                  variant={"danger"}
                  style={{
                    fontSize: "12px",
                    width: "fit-content",
                    height: 40,
                  }}
                  //   className="text-danger"
                  onClick={() => {
                    // disapprovePost();
                    setShowPostActionModal(true);
                    setSelectedAction("reject");
                  }}
                >
                  {disapprovingPost ? "Submitting..." : "Reject"}
                </Button>
                <Button
                  variant="warning"
                  onClick={() => {
                    if (!postBatchData?.length) {
                      toast.error(`Select all first before taking an action.`, {
                        theme: "coloured",
                      });
                      return;
                    }
                    setSelectAllPendingAction("everywhere");
                    setOpenBatchDeleteModalForSelectAll(true);
                  }}
                  style={{
                    fontSize: 14,
                    height: 40,
                    alignSelf: "center",
                  }}
                >
                  Delete
                </Button>
              </Flex>
            ) : null}
          </Flex>
        </Card.Body>
      </Card>

      {/*  */}
      
      <Flex justifyContent={"space-between"} gap={1}>
        {posts.length > 0 && workspaceApprovalDetailsSideNav()}
        <Row
          style={{
            width: `calc(100% - 20rem)`,
          }}
        >
          {fetchingPosts && posts.length < 1 && (
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              direction={"column"}
            >
              <Flex
                className={"vh-70"}
                alignItems={"center"}
                justifyContent={"center"}
                direction={"column"}
              >
                <ReactLoading type={"bubbles"} color={"#0052cc"} />
              </Flex>
            </Flex>
          )}
          {/* {
                postBatchData.length > 0 && !openBatchDeleteModal ?
                    <Card
                        style={{
                            position: 'sticky',
                            top: 70,
                            zIndex: 9999,
                            width: '100%',
                        }}
                    >
                        <Button
                            onClick={() => {
                                setOpenBatchDeleteModal(true)
                            }}
                            style={{
                                alignSelf: "flex-end",
                                backgroundColor: "#e63757",
                                borderColor: "transparent",
                                minHeight: 35,
                                width: '100%',
                            }}
                            size="sm"
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: 10,
                                }}
                            >
                                <span className="fs--1">
                                    Click here to delete {postBatchData.length} {postBatchData.length > 1 ? "posts" : "post"}
                                </span>
                            </div>
                        </Button>
                        <p
                            style={{
                                marginBottom: 10,
                                fontSize: 12,
                                textAlign: 'center',
                                marginTop: 5
                            }}
                        >
                            You can also press <kbd>Delete</kbd> key to delete selected posts
                        </p>
                    </Card>
                    : null
            }
            {
                postBatchData.length ? <div
                    style={{
                        marginBottom: 20
                    }}
                ></div> : null
            } */}
          {posts.length > 0 && (
            <InfiniteScroll
              dataLength={posts.length}
              next={loadMore}
              hasMore={canLoadMore}
            >
              {/* <LazyList> */}
              <Flex
                // alignItems={'center'}
                // justifyContent={'center'}
                // direction={'column'}
                gap={2}
                wrap={"wrap"}
                className={"mb-3"}
              >
                {posts.map((post, index) => {
                  // if(post?.data?.disapprovals ){
                  //     return;
                  // }
                  return (
                    <FeedCard
                      position={index}
                      key={SHA256(JSON.stringify(post))}
                      post={{
                        ...post,
                        search: postSearchTerm,
                      }}
                      className={classNames({
                        "mb-3": index + 1 !== post.length,
                      })}
                      postType={postType}
                      fetchPostsFromRemoteSource={fetchPostsFromRemoteSource}
                    />
                  );
                })}
              </Flex>
              {/* </LazyList> */}
            </InfiniteScroll>
          )}
          {noContentToDisplay && !fetchingPosts && posts.length === 0 && (
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              direction={"column"}
              className={"vh-95"}
            >
              <Lottie
                animationData={EmptyPosts}
                loop={false}
                style={{
                  width: 300,
                  maxWidth: 400,
                  height: 300,
                }}
              />
              <p>Nothing to display here.</p>
            </Flex>
          )}
          {!fetchingPosts &&
            posts.length === 0 &&
            !canDisplayPostForm &&
            !postSearchTerm && (
              <Flex
                alignItems={"center"}
                justifyContent={"center"}
                direction={"column"}
                className={"vh-95"}
              >
                <Flex
                  alignItems={"center"}
                  justifyContent={"center"}
                  direction={"column"}
                >
                  <GetStartedWithSchedulingView />
                </Flex>
              </Flex>
            )}
          {!fetchingPosts && posts.length === 0 && postSearchTerm && (
            <Flex alignItems={"center"} justifyContent={"center"}>
              <span style={{ textAlign: "center" }}>No result found</span>
            </Flex>
          )}
          {posts.length > 0 && canLoadMore && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spinner animation="border" size={40} />
            </div>
          )}
        </Row>
      </Flex>
      {/*  */}
      <Modal
        show={openBatchDeleteModalForSelectAll}
        centered
        onHide={() =>
          !deletingBatchPosts && setOpenBatchDeleteModalForSelectAll(false)
        }
        onExit={() =>
          !deletingBatchPosts && setOpenBatchDeleteModalForSelectAll(false)
        }
      >
        <Modal.Header>
          <Modal.Title>
            {postType === "draft"
              ? "Delete drafts"
              : postBatchData.length > 1
              ? getDeleteAllMessage()
              : getDeleteAllMessage()}
          </Modal.Title>
        </Modal.Header>
        {postType !== "draft" && (
          <Modal.Body>
            <InfoBar
              info={`Before proceeding, please be aware that the action you are about to take cannot be undone. Once confirmed, the changes will be permanent. \n \n If you are sure you wish to proceed, please confirm by clicking [YES, DELETE]. Otherwise, click [CANCEL] to go back.`}
              customStyles={{
                fontSize: 13,
              }}
            />
          </Modal.Body>
        )}
        <Modal.Footer>
          <Flex
            style={{ width: "100%" }}
            className={"gap-2"}
            alignItems={"center"}
          >
            <Button
              disabled={deletingBatchPosts}
              onClick={() => {
                setOpenBatchDeleteModalForSelectAll(false);
                dispatch(setPostBatchData([]));
              }}
            >
              CANCEL
            </Button>
            <Button
              disabled={deletingBatchPosts}
              onClick={() => {
                if (postType === "draft") {
                  handleDraftBatchDelete();
                  return;
                }
                if (selectAllPendingAction === "everywhere") {
                  handleBatchPostDeletion("everywhere");
                } else if (selectAllPendingAction === "postly") {
                  handleBatchPostDeletion("platforms");
                } else if (selectAllPendingAction === "platforms") {
                  handleBatchPostDeletion("postly");
                } else {
                  toast.error("Error occurred. Refresh and try again.", {
                    theme: "colored",
                  });
                }
              }}
            >
              {deletingBatchPosts ? "DELETING..." : "YES, DELETE"}
            </Button>
          </Flex>
        </Modal.Footer>
      </Modal>
      <Modal
        show={openBatchDeleteModal}
        centered
        onHide={() => !deletingBatchPosts && setOpenBatchDeleteModal(false)}
        onExit={() => !deletingBatchPosts && setOpenBatchDeleteModal(false)}
      >
        <Modal.Header>
          <Modal.Title>
            {postType === "draft"
              ? "Delete drafts"
              : postBatchData.length > 1
              ? "Delete options for Published Posts"
              : "Delete options for Published Post"}
          </Modal.Title>
        </Modal.Header>
        {postType !== "draft" && (
          <Modal.Body>
            <Form.Check
              className="p-0 m-0 ms-3 cursor-pointer"
              type="checkbox"
              id="deletePost1"
              name="deletePost1"
              onChange={(e) => {
                if (e.target.checked) {
                  deleteBatchPostsEverywhere.current.checked = false;
                  deleteBatchPostsOnlyOnPlatforms.current.checked = false;
                }
              }}
              label={"Delete only on Postly"}
              ref={deleteBatchPostsOnlyOnPostly}
            />
            <Form.Check
              className="p-0 m-0 ms-3 cursor-pointer"
              type="checkbox"
              id="deletePost2"
              name="deletePost2"
              onChange={(e) => {
                if (e.target.checked) {
                  deleteBatchPostsOnlyOnPostly.current.checked = false;
                  deleteBatchPostsEverywhere.current.checked = false;
                }
              }}
              label={"Delete only on platforms"}
              ref={deleteBatchPostsOnlyOnPlatforms}
            />
            <Form.Check
              className="p-0 m-0 ms-3 cursor-pointer"
              type="checkbox"
              id="deletePost3"
              name="deletePost3"
              onChange={(e) => {
                if (e.target.checked) {
                  deleteBatchPostsOnlyOnPostly.current.checked = false;
                  deleteBatchPostsOnlyOnPlatforms.current.checked = false;
                }
              }}
              label={"Delete Everywhere"}
              ref={deleteBatchPostsEverywhere}
            />
            <InfoBar
              info={
                "Please note that these options apply exclusively to Published posts. Scheduled posts will be permanently deleted. "
              }
            />
            <InfoBar
              info={
                "Please note that this process does not delete the post from Twitter, TikTok, and Instagram. You would have to delete the post from these platforms manually."
              }
            />
          </Modal.Body>
        )}
        <Modal.Footer>
          <Flex
            style={{ width: "100%" }}
            className={"gap-2"}
            alignItems={"center"}
          >
            <Button
              disabled={deletingBatchPosts}
              onClick={() => {
                setOpenBatchDeleteModal(false);
                dispatch(setPostBatchData([]));
              }}
            >
              CANCEL
            </Button>
            <Button
              disabled={deletingBatchPosts}
              onClick={() => {
                if (postType === "draft") {
                  handleDraftBatchDelete();
                  return;
                }
                if (deleteBatchPostsEverywhere.current.checked) {
                  handleBatchPostDeletion("everywhere");
                } else if (deleteBatchPostsOnlyOnPlatforms.current.checked) {
                  handleBatchPostDeletion("platforms");
                } else if (deleteBatchPostsOnlyOnPostly.current.checked) {
                  handleBatchPostDeletion("postly");
                } else {
                  toast.error("Please select an option to proceed", {
                    theme: "colored",
                  });
                }
              }}
            >
              {deletingBatchPosts ? "DELETING..." : "YES, DELETE"}
            </Button>
          </Flex>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showPostActionModal}
        centered
        onHide={() => {
          setShowPostActionModal(false);
          setComment("");
        }}
        onExit={() => {
          setShowPostActionModal(false);
          setComment("");
        }}
      >
        <Modal.Header>
          <Modal.Title>
            {selectedAction === "approve" ? "Approve Post" : "Reject Post"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel
            controlId="floatingTextarea2"
            label="Comments (optional)"
          >
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Flex
            style={{ width: "100%" }}
            className={"gap-2"}
            alignItems={"center"}
          >
            <Button
              onClick={() => {
                setShowPostActionModal(false);
                setComment("");
              }}
              style={{
                fontSize: "12px",
                width: "fit-content",
                height: "fit-content",
                // color: "#444f5e"
              }}
              variant="danger"
            >
              Cancel
            </Button>
            <Button
              style={{
                fontSize: "12px",
                width: "fit-content",
                height: "fit-content",
                // color: "#444f5e"
              }}
              onClick={() => {
                if (selectedAction === "approve") {
                  approveAllPosts();
                } else {
                  disApproveAllPosts();
                }
              }}
            >
              {selectedAction === "approve"
                ? approvingPost
                  ? "Submitting..."
                  : "Approve"
                : selectedAction === "reject" && disapprovingPost
                ? "Submitting..."
                : "Reject"}
            </Button>
          </Flex>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PendingApprovalPosts;
