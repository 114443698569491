import ConfirmModal from "components/common/ConfirmModal";
import Flex from "components/common/Flex";
import { RoutePaths } from "constants";
import { saveAs } from 'file-saver';
import APIService from "http/api_service";
import Papa from 'papaparse';
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Modal,
  ProgressBar,
  Table
} from "react-bootstrap";
import {
  AiFillFolderAdd,
  AiOutlineClose,
  AiOutlineDelete,
} from "react-icons/ai";
import { CiExport } from "react-icons/ci";
import { IoIosSend } from "react-icons/io";
import { MdChevronRight, MdMoveUp } from 'react-icons/md';
import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getPublishablePostMedia,
  setPublishablePostMedia,
} from "redux/slices/post_media_slice";
import {
  getDocuments,
  getGoogleDriveManager,
  getPostlyCloudGoogleDriveUploadIsActive,
  getPostlyCloudPickerMediaType,
  getPostlyCloudTotalStorageLimit,
  getPostlyCloudUsedBytes,
  setDocuments,
  setGoogleDriveManager,
  setPostlyCloudGoogleDriveUploadIsActive,
  setPostlyCloudPickerMediaType
} from "redux/slices/postslice";
import { getActiveWorkspaceFromLocalStorage } from "utils/workspace_utils";
import GDriveLogo from "../../../../../assets/images/driveImg.png";
import PostlyLogo from "../../../../../assets/images/postly symbol brand.png";
import DocumentItem from "./DocumentItem";
import LocalUpload from "./LocalUpload";
import {
  DivideLine
} from "./styles/media_cloud";
import { FaCheck } from "react-icons/fa";

const MediaCloud = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { parent_folder } = useParams();
  const [addFolderModal, setAddFolderModal] = useState(false);
  const [moveToFolderModal, setMoveToFolderModal] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [folderToMoveId, setFolderToMoveId] = useState(null);
  const [documentsSelected, setDocumentsSelected] = useState([]);
  const [fetchingFiles, setFetchingFiles] = useState(false);
  const [deletingFiles, setDeletingFiles] = useState(false);
  const [openConfirmDeleteAction, setOpenConfirmDeleteAction] = useState(false);
  const workSpace = getActiveWorkspaceFromLocalStorage();
  const workSpaceId = workSpace._id;
  const documents = useSelector(getDocuments);
  const [modifyDocuments, setModifyDocuments] = useState(documents);
  const [constantDocuments, setConstantDocuments] = useState(documents);
  const mediaObjects = useSelector(getPublishablePostMedia);
  const [folderGenerations, setFolderGenerations] = useState([])
  const [itemsToMove, setItemsToMove] = useState([])
  const [checkBoxMasterInstructor, setCheckBoxMasterInstructor] = useState('');
  const [promptUserConcentOnFilesMovement, setPromptUserConcentOnFilesMovement] = useState(false);
  const [aboutUploadingFromGoogleDrive, setAboutUploadingFromGoogleDrive] = useState(false)
  const googleDriveManager = useSelector(getGoogleDriveManager);
  const postlyCloudGoogleDriveUploadIsActive = useSelector(getPostlyCloudGoogleDriveUploadIsActive);
  const [initializingGoogleDriveUpload, setInitializingGoogleDriveUpload] = useState(false)
  const [googleDriveUploadFiles, setGoogleDriveUploadFiles] = useState([])
  const [openExtraConfirmDeleteAction, setOpenExtraConfirmDeleteAction] = useState(false)
  const [filesUploaded, setFilesUploaded] = useState([]);
  const postlyCloudUsedBytes = useSelector(getPostlyCloudUsedBytes)
  const postlyCloudTotalStorageLimit = useSelector(getPostlyCloudTotalStorageLimit)
  const [usedBytes, setUsedBytes] = useState(postlyCloudUsedBytes);
  const [totalStorageLimit, setTotalStorageLimit] = useState(postlyCloudTotalStorageLimit);
  const [usagePercentage, setUsagePercentage] = useState({
    value: 0,
    label: `fetching...`,
    valueWithoutDecimal: 0
  });
  const [createFolderButton, setCreateFolderButton] = useState('Create Folder')
  const [selectAllInitiated, setSelectAllInitiated] = useState(false)
  const [openExportModal, setOpenExportModal] = useState(false)
  const [exportFileName, setExportFileName] = useState('')
  const [fileSearchQuery, setFileSearchQuery] = useState('')
  const [selectedMediaType, setSelectedMediaType] = useState('All')
  const postlyCloudPickerMediaType = useSelector(getPostlyCloudPickerMediaType)
  const [selectedSortOption, setSelectedSortOption] = useState(undefined)
  const [gettingStorage, setGettingStorage] = useState(null)
  // const [gettingStorage, setGettingStorage] = useState('Getting storage usage...')

  const sortOptions = ['date', 'name', 'type', 'size', 'ascending', 'descending'];

  const sorting = (sortType) => {
    setSelectedSortOption(sortType)
    if (!sortType) {
      setModifyDocuments(constantDocuments);
      return;
    }
    if (sortType === 'date') {
      let cloned = [...modifyDocuments]
      setModifyDocuments(cloned.sort((a, b) => {
        const dateA = new Date(a.updatedAt);
        const dateB = new Date(b.updatedAt);
        return dateB - dateA; // Sorting in descending order (latest first)
      }))
    } else if (sortType === 'name') {
      let cloned = [...modifyDocuments]
      setModifyDocuments(cloned.sort((a, b) => {
        const numA = parseInt(a.name.match(/\d+/)?.[0]) || 0;
        const numB = parseInt(b.name.match(/\d+/)?.[0]) || 0;
        const nameA = a.name.replace(/\d+/g, '').toLowerCase();
        const nameB = b.name.replace(/\d+/g, '').toLowerCase();
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        } else {
          return numA - numB;
        }
      }))
    }
    else if (sortType === 'type') {
      let cloned = [...modifyDocuments]
      setModifyDocuments(cloned.sort((a, b) => {
        const typeA = a.type.toLowerCase();
        const typeB = b.type.toLowerCase();
        if (typeA < typeB) {
          return -1;
        } else if (typeA > typeB) {
          return 1;
        } else {
          return 0;
        }
      }))
    }
    else if (sortType === 'size') {
      let cloned = [...modifyDocuments]
      setModifyDocuments(cloned.sort((a, b) => {
        if ((a.size === undefined && b.size === undefined || !a.size && !b.size)) {
          return 0;
        }
        if (a.size === undefined) {
          return 1;
        }
        if (b.size === undefined) {
          return -1;
        }
        return a.size - b.size;
      }))
    }
    else if (sortType === 'ascending') {
      let cloned = [...modifyDocuments]
      setModifyDocuments(cloned.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)))
    }
    else if (sortType === 'descending') {
      let cloned = [...modifyDocuments]
      setModifyDocuments(cloned.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)))
    } else {
      setModifyDocuments(constantDocuments);
    }
  }

  useEffect(() => {
    const type = postlyCloudPickerMediaType
    dispatch(setPostlyCloudPickerMediaType(null))
    if (type === 'image') {
      setSelectedMediaType('Images')
      return
    }
    if (type === 'video') {
      setSelectedMediaType('Videos')
      return
    }
  }, [])


  const mediaTypes = ['All', 'Images', 'Videos', 'Folders', 'Images only', 'Videos only'];

  const convertToBytes = (value, unit) => {
    const units = {
      'bytes': 1,
      'kb': 1024,
      'mb': 1024 * 1024,
      'gb': 1024 * 1024 * 1024
    };
    const normalizedUnit = unit.toLowerCase();
    if (units[normalizedUnit] === undefined) {
      console.error('Invalid unit. Please use "bytes", "kb", "mb", or "gb".');
      return NaN;
    }
    return value * units[normalizedUnit];
  }

  const convertBytes = (bytes) => {
    let result;
    if (bytes >= 1024 * 1024 * 1024) {
      result = {
        value: `${(bytes / (1024 * 1024 * 1024)).toFixed(2)} GB`,
        unit: 'GB'
      };
    } else if (bytes >= 1024 * 1024) {
      result = {
        value: `${(bytes / (1024 * 1024)).toFixed(2)} mb`,
        unit: 'mb'
      }
    } else if (bytes >= 1024) {
      result = {
        value: `${(bytes / 1024).toFixed(2)} kb`,
        unit: 'kb'
      }
    } else {
      result = {
        value: `${bytes} bytes`,
        unit: 'bytes'
      }
    }
    return result;
  }

  useEffect(() => {
    if (postlyCloudGoogleDriveUploadIsActive && googleDriveManager?.aboutUploadingToPostlyCloudFromGoogleDrive) {
      const file = googleDriveManager?.docs;
      dispatch(setGoogleDriveManager({
        ...googleDriveManager,
        docs: [],
        aboutUploadingToPostlyCloudFromGoogleDrive: false
      }))
      setAboutUploadingFromGoogleDrive(false)
      dispatch(setPostlyCloudGoogleDriveUploadIsActive(false))
      setInitializingGoogleDriveUpload(true)
      setGoogleDriveUploadFiles([file])
    }
  }, [])

  const getNestedFolderLayout = () => {
    APIService.getPostlyCloudParentGenerations(parent_folder, (response, error) => {
      if (error) {
        return;
      }
      let { data } = response;
      let firstGrandParent = { name: "My Cloud", parentFolder: 'undefined' }
      data = [firstGrandParent, ...data]
      setFolderGenerations(data)
    });
  }

  const postlyMediaCloudFetch = async () => {
    if (!workSpaceId) {
      return;
    }
    setFetchingFiles(true);
    APIService.fetchMediaCloud(workSpaceId, parent_folder, (response, error) => {
      if (error) {
        setFetchingFiles(false);
        return;
      }
      let { data } = response;
      getNestedFolderLayout();
      dispatch(setDocuments(data?.data));
      setFetchingFiles(false);
      APIService.getPostlyCloudUsage(workSpaceId, (response, error) => {
        setFetchingFiles(false);
        // setGettingStorage('Getting storage usage...')
        if (error) {
          return;
        }
      });
    });
  };

  useEffect(() => {
    setTotalStorageLimit(postlyCloudTotalStorageLimit)
    setUsedBytes(postlyCloudUsedBytes)
    setGettingStorage(null);
  }, [postlyCloudUsedBytes, postlyCloudTotalStorageLimit])


  const MediaCloudRemove = async () => {
    if (documentsSelected.length < 1) {
      toast.error("Select File(s) For Deleting");
      return;
    }
    const filesData = documentsSelected?.map((file) => {
      return { _id: file?._id, url: file?.url, type: file?.type };
    });
    let requestBody = { files: filesData };
    setDeletingFiles(true)
    APIService.deleteMediaCloud(workSpaceId, requestBody, (response, error) => {
      if (error) {
        toast.error(error, { theme: 'colored' })
        setDeletingFiles(false)
        return;
      }
      let { data } = response;
      setDeletingFiles(false)
      toast.success(data?.message ?? 'File(s) deleted successfully', { theme: 'colored' });
      setDocumentsSelected([]);
      setDocumentsSelected([]);
      postlyMediaCloudFetch();
    }
    );
  };

  const createNewFolderHandler = async () => {
    if (!folderName) {
      toast.error("Input folder name before creating folder.");
      return;
    }
    setCreateFolderButton('Creating...')
    let folderObj = { name: folderName, type: "folder" };
    APIService.createFolderMediaCloud(workSpaceId, parent_folder, folderObj, (response, error) => {
      if (error) {
        setAddFolderModal(false);
        setFolderName("");
        setCreateFolderButton('Create Folder')
        toast.error(error, { theme: 'colored' })
        return;
      }
      let { data } = response;
      setTimeout(() => {
        setAddFolderModal(false);
        setFolderName("");
        setCreateFolderButton('Create Folder')
        toast.success(data?.message, { theme: 'colored' });
        postlyMediaCloudFetch();
      }, 1000);
    }
    );
  };

  const moveItemsToFolder = async (userActionConfirmed) => {
    if (!folderToMoveId && !userActionConfirmed) {
      setPromptUserConcentOnFilesMovement(true)
      return;
    }
    APIService.moveDocsToFolderMediaCloud(workSpaceId, folderToMoveId ? folderToMoveId : 'undefined', itemsToMove, (response, error) => {
      if (error) {
        setMoveToFolderModal(false);
        setDocumentsSelected([]);
        setItemsToMove([]);
        setFolderToMoveId(null);
        return;
      }
      let { data } = response;
      toast.success(data?.message, { theme: 'colored' });
      setMoveToFolderModal(false);
      setDocumentsSelected([]);
      setItemsToMove([]);
      setFolderToMoveId(null);
      postlyMediaCloudFetch();
    }
    );
  };

  const selectAllBox = (e) => {
    if (e.target.checked) {
      setSelectAllInitiated('all')
      if (selectedMediaType === 'Images only') {
        const filteredDocs = documents.filter(doc => doc?.type?.includes('image/'))
        setDocumentsSelected(filteredDocs)
        return
      }
      if (selectedMediaType === 'Videos only') {
        const filteredDocs = documents.filter(doc => doc?.type?.includes('video/'))
        setDocumentsSelected(filteredDocs)
        return
      }
      if (selectedMediaType === 'Images') {
        const filteredDocs = documents.filter(doc => doc?.type?.includes('image/') || doc?.type === 'folder')
        setDocumentsSelected(filteredDocs)
        return
      }
      if (selectedMediaType === 'Videos') {
        const filteredDocs = documents.filter(doc => doc?.type?.includes('video/') || doc?.type === 'folder')
        setDocumentsSelected(filteredDocs)
        return
      }
      if (selectedMediaType === 'Folders') {
        const filteredDocs = documents.filter(doc => doc?.type === 'folder')
        setDocumentsSelected(filteredDocs)
        return
      }
      setDocumentsSelected(modifyDocuments)
    } else {
      setSelectAllInitiated('none')
      setDocumentsSelected([]);
    }
  };


  const postFilesHandler = async () => {
    let selectedObjects = []
    for (let index = 0; index < documentsSelected.length; index++) {
      let newObj;
      if (documentsSelected[index]?.type === "folder") {
        selectedObjects = []
        toast.error("Cannot post a folder.")
        break;
      }
      if (documentsSelected[index]?.type?.includes("image/")) {
        newObj = {
          external: true,
          file: null,
          name: documentsSelected[index]?.name,
          optimized: true,
          uploaded: true,
          url: documentsSelected[index]?.url,
          cite: "Cloud"
        };
        selectedObjects = [...selectedObjects, newObj]
      } else {
        newObj = {
          external: true,
          is_video: true,
          name: documentsSelected[index]?.name,
          optimized: true,
          uploaded: true,
          url: documentsSelected[index]?.url,
        };
        selectedObjects = [...selectedObjects, newObj]
      }
    }
    dispatch(setPublishablePostMedia([...mediaObjects, ...selectedObjects]));
    navigate(RoutePaths.POSTS)
  };

  useEffect(() => {
    setModifyDocuments(documents);
    setConstantDocuments(documents)
  }, [documents]);

  useEffect(() => {
    postlyMediaCloudFetch();
  }, [parent_folder])

  const handleTabRouting = (tab) => {
    if (tab?.name === "My Cloud") {
      navigate(RoutePaths.POSTLY_CLOUD);
      return;
    }
    navigate(`${RoutePaths.POSTLY_CLOUD}/${tab?.parentFolder}`);
  }

  const displayCurrentFolderDecendants = () => {
    if (!folderGenerations?.length) {
      return <p>My Cloud</p>
    }
    return folderGenerations?.map((folder, index) => {
      return <div
        className="d-flex gap-2 cursor-pointer"
        onClick={() => handleTabRouting(folder)}
      >{folder?.name} {
          index !== folderGenerations?.length - 1 && <span><MdChevronRight /></span>
        }</div>
    })
  }

  const handleGDriveTabRouting = () => {
    dispatch(setPostlyCloudGoogleDriveUploadIsActive(true))
    setAboutUploadingFromGoogleDrive(false)
    navigate(RoutePaths.GDRIVE_CONTENTS)
  }

  const getUsagePercentage = () => {
    const _usedBytesValue = parseInt(convertBytes(usedBytes)?.value);
    const _usedBytesUnit = convertBytes(usedBytes)?.unit;
    const _usedBytes = convertToBytes(_usedBytesValue, _usedBytesUnit);
    const _totalBytes = parseInt(convertToBytes(totalStorageLimit, 'kb'));
    const percentage = (_usedBytes / _totalBytes) * 100;
    let result = {
      value: percentage.toFixed(2),
      label: `${percentage.toFixed(2)}%`,
      valueWithoutDecimal: parseInt(percentage.toFixed(2))
    }
    setUsagePercentage(result)
  }

  useEffect(() => {
    getUsagePercentage()
  }, [usedBytes])

  const handleExportController = () => {
    setOpenExportModal(true)
  }

  const exportSelectedLinksInTXT = () => {
    const allLinks = documentsSelected.map(item => item.url);
    const txtContent = allLinks.join('\n');
    const blob = new Blob([txtContent], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, 'Postly_Cloud_link_exports.txt');
  }

  const exportSelectedLinksInCSV = () => {
    const allFiles = documentsSelected.filter(item => item?.type !== 'folder');
    const csvData = allFiles.map(item => {
      let data = {
        name: item.name,
        type: item.type,
        size: item.size,
        createdAt: item.createdAt,
      }
      if (item?.type.includes('image')) {
        data = {
          'image URL': item.url,
          'video URL': '',
          ...data
        }
      } else {
        data = {
          'image URL': '',
          'video URL': item.url,
          ...data
        }
      }
      return data;
    });
    let fileName = `${exportFileName}.csv` || 'Postly_Cloud_link_exports.csv';
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, fileName);
    setOpenExportModal(false);
    setExportFileName('');
    setDocumentsSelected([]);
  }

  return (
    <>
      <Card className="p-3 pb-0" style={{ height: "35vh" }}>
        <div
          style={{
            marginBottom: 8,
            paddingBottom: 10,
            height: "50%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "30px",
            borderBottom: "1px solid #bbb5ae",
          }}
        >
          <div
            style={{
              marginBottom: 8,
              paddingBottom: 10,
              height: "50%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "30px",
            }}
          >
            <div>
              <img
                src={PostlyLogo}
                alt="postly-logo"
                style={{ width: "40px", height: "40px" }}
              />
            </div>
            <div className="ms--2">
              <span style={{ fontSize: "20px" }}>Cloud</span>{" "}
              <br />
              <span>All your media in one place.</span>
            </div>
          </div>
          {
            usagePercentage?.label ?
              <div
                style={{
                  width: "40%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2
                }}
              >
                {
                  gettingStorage ?
                    <div>
                      {gettingStorage}
                    </div> :
                    <div>
                      {convertBytes(usedBytes)?.value} of {convertBytes(convertToBytes(totalStorageLimit, 'kb'))?.value} used ({usagePercentage?.value !== "NaN" ? usagePercentage?.label : `0.00%`})
                    </div>
                }
                <div
                  style={{ width: '100%' }}
                >
                  <ProgressBar now={usagePercentage?.valueWithoutDecimal} label={usagePercentage?.label > 5 && usagePercentage?.label} />
                </div>
                {/* upgrade button */}
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 10
                  }}
                >
                  <p
                    style={{
                      fontSize: 11,
                      fontWeight: 'bold'
                    }}
                  >
                    Images and videos from your draft posts also contribute to your Postly Cloud usage limit
                  </p>
                  <Button
                    style={{
                      marginTop: -15,
                    }}
                    variant={'primary'}
                    className="d-flex align-items-center justify-content-center gap-2" onClick={() => navigate(RoutePaths.POSTLY_CLOUD_BILLING)}>
                    Upgrade
                  </Button>
                </div>
              </div>
              :
              null
          }
        </div>


        <div
          style={{
            height: "50%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-end",
            paddingBottom: 10,
            gap: "20px",
          }}
        >
          <Form.Group>
            <Form.Label>Media type</Form.Label>
            <Form.Select
              value={selectedMediaType}
              onChange={(e) => {
                setSelectedMediaType(e.target.value);
              }}>
              {
                mediaTypes.map(x => {
                  return <option value={x}>{x}</option>
                })
              }
            </Form.Select>
          </Form.Group>
          {
            !moveToFolderModal && documentsSelected?.length < 1 &&
            <Button
              variant={'light'}
              className="d-flex align-items-center justify-content-center gap-2"
              onClick={() => setAboutUploadingFromGoogleDrive(true)}
            >
              <img
                src={GDriveLogo}
                alt="gdrive-logo"
                style={{ height: "16px", width: "16px" }}
              />
              Upload
            </Button>
          }
          {
            documentsSelected?.length < 1 &&
            <Button
              variant={'light'}
              className="d-flex align-items-center justify-content-center gap-2"
              onClick={() => setAddFolderModal(true)}
            >
              <AiFillFolderAdd color="#a1c9f7" size={16} /> Folder
            </Button>
          }

          {documentsSelected?.length > 0 && (
            <>
              {!moveToFolderModal && <Button
                variant={'light'}
                className="d-flex align-items-center justify-content-center gap-2"
                onClick={() => {
                  setItemsToMove(documentsSelected);
                  setMoveToFolderModal(!moveToFolderModal)
                  setCheckBoxMasterInstructor('Clear all selected items.')
                }
                }
                style={{ backgroundColor: moveToFolderModal && 'orange' }}
              >
                <MdMoveUp color="#a1c9f7" size={16} />
                {
                  moveToFolderModal ? 'Cancel' : 'Move'
                }
              </Button>}
              {
                moveToFolderModal &&
                <Button
                  variant={'light'}
                  className="d-flex align-items-center justify-content-center gap-2"
                  onClick={() => {
                    moveItemsToFolder();
                  }
                  }
                >
                  <MdMoveUp color="#a1c9f7" size={16} />
                  Move to folder
                </Button>
              }
              {
                moveToFolderModal &&
                <Button
                  variant={'light'}
                  className="d-flex align-items-center justify-content-center gap-2"
                  onClick={() => {
                    setMoveToFolderModal(!moveToFolderModal)
                    setCheckBoxMasterInstructor('Clear all selected items.')
                  }
                  }
                >
                  <MdMoveUp color="#a1c9f7" size={16} />
                  Abort
                </Button>
              }
              {
                !moveToFolderModal &&
                <Button
                  variant={'light'}
                  className="d-flex align-items-center justify-content-center gap-2"
                  disabled={deletingFiles}
                  onClick={() => setOpenConfirmDeleteAction(true)}
                >
                  <AiOutlineDelete size={16} />
                  {deletingFiles ? 'Deleting' : 'Delete'}
                </Button>
              }
              {
                !moveToFolderModal &&
                <Button
                  variant={'light'}
                  className="d-flex align-items-center justify-content-center gap-2"
                  onClick={postFilesHandler || moveToFolderModal}
                >
                  <IoIosSend size={16} />
                  Post file(s)
                </Button>
              }
              {
                !moveToFolderModal &&
                <Button
                  variant={'light'}
                  className="d-flex align-items-center justify-content-center gap-2"
                  onClick={() => {
                    handleExportController()
                  }}
                >
                  <CiExport size={16} />
                  Export Links
                </Button>

              }
            </>
          )}
        </div>
      </Card>
      <Card
        className="p-3 pb-0"
        style={{ marginTop: "10px", minHeight: "80vh" }}
      >
        <LocalUpload
          parent_folder={parent_folder}
          postlyMediaCloudFetch={postlyMediaCloudFetch}
          googleDriveUploadFiles={googleDriveUploadFiles}
          setGoogleDriveUploadFiles={setGoogleDriveUploadFiles}
          initializingGoogleDriveUpload={initializingGoogleDriveUpload}
          setInitializingGoogleDriveUpload={setInitializingGoogleDriveUpload}
          setFilesUploaded={setFilesUploaded}
          filesUploaded={filesUploaded}
          convertBytes={convertBytes}
          convertToBytes={convertToBytes}
          usedBytes={usedBytes}
          totalStorageLimit={totalStorageLimit}
        />
        <DivideLine />

        <div
          style={{
            borderBottom: "0.5px solid #bbb5ae",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
          }}
        >

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "10px",
              marginBottom: 10,
              width: "100%"
            }}
          >
            {displayCurrentFolderDecendants()}
            <Flex
              gap={2}
            >

              <Form.Group>
                <Flex gap={2} alignItems={'center'}>
                  <Form.Label
                    className="mt-2"
                  >Sort</Form.Label>
                  <Form.Select
                    value={selectedSortOption}
                    onChange={(e) => {
                      sorting(e.target.value);
                    }}>
                    <option value={undefined}>default</option>
                    {
                      sortOptions.map(x => {
                        return <option value={x}>{x}</option>
                      })
                    }
                  </Form.Select>
                </Flex>
              </Form.Group>
              <Form.Control
                type='text'

                required
                placeholder={'Search your files here'}
                value={fileSearchQuery}
                onChange={(e) => {
                  setFileSearchQuery(e.target.value)
                  if (e.target.value) {
                    const filteredDocs = documents.filter(doc => doc?.name?.toLowerCase().includes(e.target.value.toLowerCase()))
                    setModifyDocuments(filteredDocs)
                  } else {
                    setModifyDocuments(documents)
                  }
                }}
                className="w-50"
              />
            </Flex>
          </div>
        </div>
        <Table>
          <tbody>
            <tr style={{
              // borderBottom: "1px solid #dbdbdb", 
              width: "10%"
            }}>
              <td
                style={{
                  width: "80%",

                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Form.Check
                  type="checkbox"
                  style={{
                    width: '20px',
                    height: '20px',
                    marginTop: 10,
                  }}
                  label=""
                  onChange={(e) => {
                    selectAllBox(e)
                  }}
                />
                Name
              </td>
              <td style={{ width: "10%", fontSize: "14px" }}>
                Type
              </td>
              <td style={{ width: "10%", fontSize: "14px" }}>
                Size
              </td>
              <td
                style={{ width: "10%", fontSize: "14px" }}
              ></td>
            </tr>
            {
              fetchingFiles ? <div>
                <Flex className={'px-2'}>
                  <ReactLoading type={'bubbles'} width={50} height={50} color={'#0052cc'} />
                </Flex>

              </div> :
                modifyDocuments?.map((doc, i) => {
                  if (selectedMediaType === "All") {
                    return (
                      <DocumentItem
                        key={i}
                        doc={doc}
                        setCheckBoxMasterInstructor={setCheckBoxMasterInstructor}
                        checkBoxMasterInstructor={checkBoxMasterInstructor}
                        setDocumentsSelected={setDocumentsSelected}
                        documentsSelected={documentsSelected}
                        modifyDocuments={modifyDocuments}
                        setModifyDocuments={setModifyDocuments}
                        moveToFolderModal={moveToFolderModal}
                        convertBytes={convertBytes}
                        setFolderToMoveId={setFolderToMoveId}
                        selectAllInitiated={selectAllInitiated}
                        setSelectAllInitiated={setSelectAllInitiated}
                        selectedMediaType={selectedMediaType}
                      />
                    );
                  }
                  if (selectedMediaType === "Images" && (doc?.type?.includes("image/") || doc?.type === "folder")) {
                    return (
                      <DocumentItem
                        key={i}
                        doc={doc}
                        setCheckBoxMasterInstructor={setCheckBoxMasterInstructor}
                        checkBoxMasterInstructor={checkBoxMasterInstructor}
                        setDocumentsSelected={setDocumentsSelected}
                        documentsSelected={documentsSelected}
                        modifyDocuments={modifyDocuments}
                        setModifyDocuments={setModifyDocuments}
                        moveToFolderModal={moveToFolderModal}
                        convertBytes={convertBytes}
                        setFolderToMoveId={setFolderToMoveId}
                        selectAllInitiated={selectAllInitiated}
                        setSelectAllInitiated={setSelectAllInitiated}
                        selectedMediaType={selectedMediaType}
                      />
                    );
                  }
                  if (selectedMediaType === "Videos" && (doc?.type?.includes("video/") || doc?.type === "folder")) {
                    return (
                      <DocumentItem
                        key={i}
                        doc={doc}
                        setCheckBoxMasterInstructor={setCheckBoxMasterInstructor}
                        checkBoxMasterInstructor={checkBoxMasterInstructor}
                        setDocumentsSelected={setDocumentsSelected}
                        documentsSelected={documentsSelected}
                        modifyDocuments={modifyDocuments}
                        setModifyDocuments={setModifyDocuments}
                        moveToFolderModal={moveToFolderModal}
                        convertBytes={convertBytes}
                        setFolderToMoveId={setFolderToMoveId}
                        selectAllInitiated={selectAllInitiated}
                        setSelectAllInitiated={setSelectAllInitiated}
                        selectedMediaType={selectedMediaType}
                      />
                    );
                  }
                  if (selectedMediaType === "Folders" && doc?.type === "folder") {
                    return (
                      <DocumentItem
                        key={i}
                        doc={doc}
                        setCheckBoxMasterInstructor={setCheckBoxMasterInstructor}
                        checkBoxMasterInstructor={checkBoxMasterInstructor}
                        setDocumentsSelected={setDocumentsSelected}
                        documentsSelected={documentsSelected}
                        modifyDocuments={modifyDocuments}
                        setModifyDocuments={setModifyDocuments}
                        moveToFolderModal={moveToFolderModal}
                        convertBytes={convertBytes}
                        setFolderToMoveId={setFolderToMoveId}
                        selectAllInitiated={selectAllInitiated}
                        setSelectAllInitiated={setSelectAllInitiated}
                        selectedMediaType={selectedMediaType}
                      />
                    );
                  }
                  if (selectedMediaType === "Images only" && doc?.type?.includes("image/")) {
                    return (
                      <DocumentItem
                        key={i}
                        doc={doc}
                        setCheckBoxMasterInstructor={setCheckBoxMasterInstructor}
                        checkBoxMasterInstructor={checkBoxMasterInstructor}
                        setDocumentsSelected={setDocumentsSelected}
                        documentsSelected={documentsSelected}
                        modifyDocuments={modifyDocuments}
                        setModifyDocuments={setModifyDocuments}
                        moveToFolderModal={moveToFolderModal}
                        convertBytes={convertBytes}
                        setFolderToMoveId={setFolderToMoveId}
                        selectAllInitiated={selectAllInitiated}
                        setSelectAllInitiated={setSelectAllInitiated}
                        selectedMediaType={selectedMediaType}
                      />
                    );
                  }
                  if (selectedMediaType === "Videos only" && doc?.type?.includes("video/")) {
                    return (
                      <DocumentItem
                        key={i}
                        doc={doc}
                        setCheckBoxMasterInstructor={setCheckBoxMasterInstructor}
                        checkBoxMasterInstructor={checkBoxMasterInstructor}
                        setDocumentsSelected={setDocumentsSelected}
                        documentsSelected={documentsSelected}
                        modifyDocuments={modifyDocuments}
                        setModifyDocuments={setModifyDocuments}
                        moveToFolderModal={moveToFolderModal}
                        convertBytes={convertBytes}
                        setFolderToMoveId={setFolderToMoveId}
                        selectAllInitiated={selectAllInitiated}
                        setSelectAllInitiated={setSelectAllInitiated}
                        selectedMediaType={selectedMediaType}
                      />
                    );
                  }
                })
            }
          </tbody>
        </Table>
      </Card>
      <Modal
        size="md"
        centered
        show={addFolderModal}
        onHide={() => createFolderButton !== "Creating..." && setAddFolderModal(false)}
        onExit={() => createFolderButton !== "Creating..." && setAddFolderModal(false)}
      >
        <div style={{ padding: "0px" }}>
          <div style={{ border: "1px solid #bfc9d7" }}>
            <Modal.Header>
              <span
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <AiFillFolderAdd color="#a1c9f7" size={50} />
                  <Modal.Title>Create Folder</Modal.Title>
                </div>
                <AiOutlineClose
                  cursor={"pointer"}
                  onClick={() => setAddFolderModal(false)}
                />
              </span>
            </Modal.Header>
            <Modal.Body>
              <Flex direction={"column"}>
                <Form.Control
                  // onSubmit={createNewFolderHandler}
                  as="input"
                  rows={1}
                  placeholder={`Folder Name`}
                  className="rounded-2 resize-none border-y-10 px-card border-300 mb-2"
                  value={folderName}
                  onChange={(e) => setFolderName(e.target.value)}
                  style={{
                    verticalAlign: "middle",
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                />
              </Flex>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                  marginTop: "30px",
                }}
              >
                <Button
                  style={{
                    alignSelf: "flex-end",
                    backgroundColor: "#fafbfd",
                    minHeight: 35,
                    color: "#7f8894",
                  }}
                  onClick={() => setAddFolderModal(false)}
                  size="sm"
                  className={`px-3 px-sm-5`}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    alignSelf: "flex-end",
                    backgroundColor: "#1e63fe",
                    minHeight: 35,
                  }}
                  onClick={createNewFolderHandler}
                  size="sm"
                  className={`px-3 px-sm-5`}
                >
                  {createFolderButton}
                </Button>
              </div>
            </Modal.Body>
          </div>
        </div>
      </Modal>
      <Modal
        size="md"
        centered
        show={promptUserConcentOnFilesMovement}
        onHide={() => setPromptUserConcentOnFilesMovement(false)}
        onExit={() => setPromptUserConcentOnFilesMovement(false)}
      >
        <Modal.Header>
          <Modal.Title>Confirm your action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            You haven't chosen a destination folder for the selected items. If you continue, the items will be relocated to the root folder.
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "20px",
              marginTop: "30px",
            }}
          >
            <Button
              style={{
                alignSelf: "flex-end",
                backgroundColor: "#fafbfd",
                minHeight: 35,
                color: "#7f8894",
              }}
              onClick={() => setPromptUserConcentOnFilesMovement(false)}
              size="sm"
              className={`px-3 px-sm-5`}
            >
              Cancel
            </Button>
            <Button
              style={{
                alignSelf: "flex-end",
                backgroundColor: "#1e63fe",
                minHeight: 35,
              }}
              onClick={() => {
                moveItemsToFolder(true)
                setPromptUserConcentOnFilesMovement(false)
              }
              }
              size="sm"
              className={`px-3 px-sm-5`}
            >
              Move
            </Button>
          </div>
        </Modal.Body>
      </Modal >
      <Modal
        size="md"
        centered
        show={openExportModal}
        onHide={() => setOpenExportModal(false)}
        onExit={() => setOpenExportModal(false)}
      >
        <Modal.Header>
          <Modal.Title>Export Links</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="mb-3"
          >
            <FaCheck
              color="green"
            /> Please note folders will be ignored and only files will be exported.
          </div>
          <div
            className="mb-3"
          >
            <FaCheck
              color="green"
            /> If you have selected multiple files, they will be exported as a single CSV file.
          </div>
          <div
            className="mb-3"
          >
            <FaCheck
              color="green"
            /> Not specifying a file name will result in the default file name being used.
          </div>
          <Form.Control
            type="text"
            onChange={(e) => setExportFileName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                exportSelectedLinksInCSV()
              }
            }}
            value={exportFileName}
            placeholder='Enter the name of the file.'
          // style={{
          //   fontSize: 18,
          //   fontWeight: 600
          // }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "20px",
              marginTop: "30px",
            }}
          >
            <Button
              style={{
                alignSelf: "flex-end",
                backgroundColor: "#fafbfd",
                minHeight: 35,
                color: "#7f8894",
              }}
              onClick={() => setOpenExportModal(false)}
              size="sm"
              className={`px-3 px-sm-5`}
            >
              Cancel
            </Button>
            {/* <Button
              style={{
                alignSelf: "flex-end",
                backgroundColor: "#1e63fe",
                minHeight: 35,
              }}
              onClick={() => exportSelectedLinksInTXT()}
              size="sm"
              className={`px-3 px-sm-5`}
            >
              Export TXT
            </Button> */}
            <Button
              style={{
                alignSelf: "flex-end",
                backgroundColor: "#1e63fe",
                minHeight: 35,
              }}
              onClick={() => exportSelectedLinksInCSV()}
              size="sm"
              className={`px-3 px-sm-5`}
            >
              Export CSV
            </Button>
          </div>
        </Modal.Body>
      </Modal >
      <Modal
        size="md"
        centered
        show={aboutUploadingFromGoogleDrive}
        onHide={() => setAboutUploadingFromGoogleDrive(false)}
        onExit={() => setAboutUploadingFromGoogleDrive(false)}
      >
        <Modal.Header>
          <Modal.Title>Confirm your action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>You're about to upload files from your Google Drive. Clicking this will open the Google Drive page, and you'll be redirected here when you're finished.</div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "20px",
              marginTop: "30px",
            }}
          >
            <Button
              style={{
                alignSelf: "flex-end",
                backgroundColor: "#fafbfd",
                minHeight: 35,
                color: "#7f8894",
              }}
              onClick={() => setAboutUploadingFromGoogleDrive(false)}
              size="sm"
              className={`px-3 px-sm-5`}
            >
              Cancel
            </Button>
            <Button
              style={{
                alignSelf: "flex-end",
                backgroundColor: "#1e63fe",
                minHeight: 35,
              }}
              onClick={() => handleGDriveTabRouting()}
              size="sm"
              className={`px-3 px-sm-5`}
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal >
      <ConfirmModal
        open={openConfirmDeleteAction}
        onCancel={() => {
          setOpenConfirmDeleteAction(false);
        }}
        title="Delete selected files?"
        message="Are you sure you want to delete the selected files?"
        cancelText="CLOSE"
        confirmText="CONFIRM"
        onConfirm={() => {
          const folderSelected = documentsSelected?.find(doc => doc?.type === 'folder')
          if (folderSelected) {
            setOpenConfirmDeleteAction(false);
            setOpenExtraConfirmDeleteAction(true)
            return;
          }
          MediaCloudRemove();
        }}
      />
      <ConfirmModal
        open={openExtraConfirmDeleteAction}
        onCancel={() => {
          setOpenExtraConfirmDeleteAction(false);
        }}
        titleColor="red"
        title="Caution!"
        message="You're about to delete a folder. This will delete all files in the folder. Are you sure you want to continue?"
        cancelText="CLOSE"
        confirmText="CONFIRM"
        onConfirm={() => {
          MediaCloudRemove();
        }}
      />

    </>
  );
};

export default MediaCloud;
