import React, { useEffect, useState } from 'react'
import ReturningUsers from './returning_users'
import { Col, Row } from 'react-bootstrap'
import APIService from 'http/api_service';
import { useSelector } from 'react-redux';
import { getReturningUsers } from 'redux/slices/admin_slice';
import NewUsers from './new_users';
import Users from './users/users';
import StatisticsCard from './stats-cards/StatisticsCard';
import TotalUsers from './total_users';
import TotalWorkspaces from './total_workspaces';
import RealTimeUsers from './real-time-users/RealTimeUsers';

const Metrics = () => {
    const requestMetrics = async () => {
        let allUsersRequestBody = {
            skip: 0,
            sort: -1,
            backgroundProcessing: true
        }
        APIService.adminGetAllUsers(allUsersRequestBody, (response, error) => {
            if (error) {
                console.log("🚀 ~ APIService.adminGetAllUsers ~ error:", error)
                return;
            }
            let { message, data } = response;
        });
        APIService.getMetricsForAdmins(async (response, error) => {
            if (error) {
                return;
            }
        });
    }

    useEffect(() => {
        requestMetrics();
    }, [])

    const realTimeUsers = [
        {
            page: '/bootstrap-themes/',
            count: 3
        },
        {
            page: '/tags/html5/',
            count: 3
        },
        {
            page: '/',
            count: 3
        },
        {
            page: '/preview/falcon/dashboard/',
            count: 3
        },
        {
            page: '/100-best-themes...all-time/',
            count: 3
        },
        {
            page: '/product/falcon-admin-dashboard/',
            count: 3
        }
    ];

    return (
        <div>
            <Row className="g-3 mb-3">
                <Col md={6} xxl={3}>
                    {/* <Users /> */}
                    <TotalUsers />
                </Col>
                <Col md={6} xxl={3}>
                    {/* <MarketShare data={marketShare} radius={['100%', '87%']} /> */}
                    <TotalWorkspaces />
                </Col>
                <Col md={6} xxl={3}>
                    <ReturningUsers />
                </Col>
                <Col md={6} xxl={3}>
                    <NewUsers />
                </Col>
            </Row>

            <Row className="g-3 mb-3">
                {/* <Col xxl={8}>
                    <Audience chartData={audienceChart} className="mb-3" />
                    <ConnectCard />
                </Col> */}
                <Col md={6} xxl={4}>
                    <RealTimeUsers data={realTimeUsers} />
                </Col>
                {/* <Col md={6} xxl={4}>
                    <SessionByBrowser data={sessionByBrowser} />
                </Col>
                <Col md={6} xxl={4}>
                    <UsersByCountry chartData={sessionByCountry} mapData={countryData} />
                </Col>
                <Col md={6} xxl={4}>
                    <Intelligence data={intelligence} />
                </Col> */}
            </Row>

            {/* <ReturningUsers /> */}
        </div>
    )
}

export default Metrics