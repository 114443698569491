import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { findPlatformByIdentifier } from 'components/app/platforms';
import { MetricsProps, PlatformIdentifier, RoutePaths } from '../../../../constants';
import {
    canPublishIGVideoAsReel,
    setPublishIGVideoAsReel,
    setIGVideoCoverThumbOffset,
    getUploadableBulkContent,
    setUploadableBulkContent,
    setPublishIGVideoAsStories,
    canPublishIGVideoAsStories,
    setPublishIGImageAsStories,
    canPublishIGImageAsStories
} from '../../../../redux/slices/postslice';
import { useSelector, useDispatch } from 'react-redux';
import ReactPlayer from "react-player";
import Switch from "react-switch";
import { MATCH_URL_YOUTUBE } from 'utils/file_utils';
import { Alert, Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Divider from 'components/common/Divider';
import PrePublishErrorResolver from './PrePublishErrorResolver';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { getPublishablePostMedia } from 'redux/slices/post_media_slice';
import ConfirmModal from 'components/common/ConfirmModal';
import { useNavigate } from 'react-router-dom';
import { getActiveWorkSpace, getPlatformVideoUploadLimit } from 'redux/slices/workspaceslice';
import { isFreeWorkspace } from 'utils/workspace_utils';
import { setPopupUpgradeAlert } from 'redux/slices/authSlice';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { setCollectMetric } from 'redux/slices/analytics_slice';

export default function InstagramOptionsField(props) {

    const {
        bulkContentMode = false,
        rowIndex = 0,
        mediaType = 'video',
    } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const uploadableBulkContent = useSelector(getUploadableBulkContent);
    const bulkContentRow = uploadableBulkContent[rowIndex] ?? {};
    const instagramPlatform = findPlatformByIdentifier(PlatformIdentifier.INSTAGRAM);
    const [videoErrorMessage, setVideoErrorMessage] = useState(props.videoErrorMessage);
    const canVideoBePublishedAsReel = useSelector(canPublishIGVideoAsReel);
    const canVideoBePublishedAsStory = useSelector(canPublishIGVideoAsStories);
    const canImageBePublishedAsStory = useSelector(canPublishIGImageAsStories);
    const mediaObjects = useSelector(getPublishablePostMedia);
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
    const workspace = useSelector(getActiveWorkSpace);
    const isFreeWorkSpace = isFreeWorkspace(workspace);
    const bulkContentMediaObject = bulkContentRow?.video_url;
    const [videoCoverThumbOffsetUpdated, setVideoCoverThumbOffsetUpdated] = useState(false);
    const [bothReelsAndStoriesAreEnabled, setBothReelsAndStoriesAreEnabled] = useState(false);
    const bulkContentPublishVideoUnderReelsMappings = {
        "Yes": true,
        "YES": true,
        "No": false,
        "NO": false,
        "yes": true,
        "no": false,
        true: true,
        false: false
    }
    const bulkContentPublishVideoUnderStoriesMappings = {
        "Yes": true,
        "YES": true,
        "No": false,
        "NO": false,
        "yes": true,
        "no": false,
        true: true,
        false: false
    }
    const bulkContentPublishImageUnderStoriesMappings = {
        "Yes": true,
        "YES": true,
        "No": false,
        "NO": false,
        "yes": true,
        "no": false,
        true: true,
        false: false
    }
    const [bulkContentPublishVideoUnderReels, setBulkContentPublishVideoUnderReels] = useState();
    const [bulkContentPublishVideoUnderStories, setBulkContentPublishVideoUnderStories] = useState();
    const [bulkContentPublishImageUnderStories, setBulkContentPublishImageUnderStories] = useState();
    const platformVideoUploadLimit = useSelector(getPlatformVideoUploadLimit);
    const [show, setShow] = useState(true);

    const checkIfBothReelsAndStoriesAreEnabled = () => {
        if (bulkContentPublishVideoUnderReels && bulkContentPublishVideoUnderStories) {
            setBothReelsAndStoriesAreEnabled(true);
            return
        }
        if (canVideoBePublishedAsReel && canVideoBePublishedAsStory) {
            setBothReelsAndStoriesAreEnabled(true);
            return
        }
        setBothReelsAndStoriesAreEnabled(false);
    }

    useEffect(() => {
        checkIfBothReelsAndStoriesAreEnabled()
    }, [bulkContentPublishVideoUnderReels, bulkContentPublishVideoUnderStories, canVideoBePublishedAsReel, canVideoBePublishedAsStory])


    const initializeBulkPublishVideoUnderReels = () => {
        if (!bulkContentRow?.publish_instagram_video_under_reels) {
            setBulkContentPublishVideoUnderReels(false);
            return;
        }
        let value = bulkContentPublishVideoUnderReelsMappings[bulkContentRow?.publish_instagram_video_under_reels];
        setBulkContentPublishVideoUnderReels(value ?? false);
    }

    const initializeBulkPublishVideoUnderStories = () => {
        if (!bulkContentRow?.publish_instagram_video_under_stories) {
            setBulkContentPublishVideoUnderStories(false);
            return;
        }
        let value = bulkContentPublishVideoUnderStoriesMappings[bulkContentRow?.publish_instagram_video_under_stories];
        setBulkContentPublishVideoUnderStories(value ?? false);
    }

    const initializeBulkPublishImageUnderStories = () => {
        if (!bulkContentRow?.publish_instagram_image_under_stories) {
            setBulkContentPublishImageUnderStories(false);
            return;
        }
        let value = bulkContentPublishImageUnderStoriesMappings[bulkContentRow?.publish_instagram_image_under_stories];
        setBulkContentPublishImageUnderStories(value ?? false);
    }

    useEffect(() => {
        initializeBulkPublishVideoUnderReels();
        initializeBulkPublishVideoUnderStories();
        initializeBulkPublishImageUnderStories();
    }, [])


    const [bulkContentIGVideoCoverThumbOffset, setBulkContentIGVideoCoverThumbOffset] = useState(parseFloat(bulkContentRow?.instagram_video_cover_thumb_offset ?? 0));
    const videoPlayerRef = useRef();
    const [playerReady, setPlayerReady] = useState(false);

    useEffect(() => {
        setVideoErrorMessage(props.videoErrorMessage);
    }, [props.videoErrorMessage]);

    const updateBulkContentRow = (updatedRow) => {
        let uploadableBulkContentCopy = [...uploadableBulkContent];
        uploadableBulkContentCopy[rowIndex] = updatedRow;
        dispatch(setUploadableBulkContent(uploadableBulkContentCopy));
    }

    const firstVideo = () => {
        return mediaObjects.find(mediaObject => mediaObject.is_video);
    }

    useEffect(() => {
        if (bulkContentMode && playerReady) {
            if (!MATCH_URL_YOUTUBE.test(bulkContentMediaObject)) {
                videoPlayerRef?.current?.seekTo(bulkContentIGVideoCoverThumbOffset, 'seconds');
            }
        }
    }, [bulkContentMode, playerReady]);

    return (
        <Card className='mb-3'>
            <FalconCardHeader
                title={bulkContentMode ? "Instagram" : "For Instagram"}
                endEl={
                    <Flex
                        alignItems="center"
                        justifyContent="center"
                    >
                        <div
                            style={{
                                width: 20,
                                height: 20,
                                borderRadius: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: instagramPlatform.color
                            }}>
                            <FontAwesomeIcon
                                icon={instagramPlatform.icon}
                                style={{
                                    color: 'white',
                                    height: 12,
                                    width: 12
                                }}
                            />
                        </div>
                        <Flex
                            className="ms-2"
                            // alignItems="center"
                            // justifyContent="center"
                            style={{ cursor: 'pointer' }}
                        >
                            {
                                show ?
                                    <IoIosArrowUp
                                        onClick={() => setShow(false)}
                                        style={{
                                            // color: 'black',
                                            height: 20,
                                            width: 20
                                        }}
                                    /> :
                                    <IoIosArrowDown
                                        onClick={() => setShow(true)}
                                        style={{
                                            // color: 'black',
                                            height: 20,
                                            width: 20
                                        }}
                                    />
                            }
                        </Flex>
                    </Flex>
                } />
            {
                show &&
                <Card.Body className="bg-light">
                    <Flex direction={'column'}>
                        {
                            mediaType === 'video' &&
                            <div
                                style={{
                                    display: videoCoverThumbOffsetUpdated ? 'flex' : 'none',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    position: 'absolute',
                                    right: 0,
                                    top: 0,
                                    gap: 2,
                                    padding: 10,
                                    paddingLeft: 15,
                                    transition: 'all .2s'
                                }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: 15,
                                        height: 15,
                                        borderRadius: 100,
                                        backgroundColor: "#81C784"
                                    }}>
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        style={{
                                            width: 8,
                                            height: 8
                                        }}
                                    />
                                </div>
                                <span
                                    variant="contained"
                                    style={{
                                        fontSize: 12,
                                        color: '#81C784',
                                        paddingLeft: 2
                                    }}
                                >
                                    Instagram Video Cover Set
                                </span>
                            </div>
                        }
                        {
                            mediaType === 'video' &&
                            <fieldset
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginTop: 5,
                                    borderRadius: 3
                                }}>
                                <legend><h5 style={{ paddingLeft: 5, paddingRight: 5 }}>Video Cover</h5>
                                </legend>
                                <ReactPlayer
                                    ref={videoPlayerRef}
                                    controls
                                    muted
                                    onSeek={(seconds) => {
                                        //YouTube seems to have an issue with sending back seek value
                                        if (bulkContentMode) {
                                            setBulkContentIGVideoCoverThumbOffset(seconds);
                                            let updatedRow = { ...bulkContentRow }
                                            updatedRow.instagram_video_cover_thumb_offset = seconds;
                                            updateBulkContentRow(updatedRow);
                                        } else {
                                            dispatch(setIGVideoCoverThumbOffset(seconds));
                                        }
                                        setTimeout(() => {
                                            setVideoCoverThumbOffsetUpdated(true);
                                        }, 300);
                                        setTimeout(() => {
                                            setVideoCoverThumbOffsetUpdated(false);
                                        }, 2000);
                                    }}
                                    config={{
                                        file: {
                                            attributes: {
                                                autoPlay: false,
                                            }
                                        }
                                    }}
                                    url={bulkContentMode ? bulkContentMediaObject : firstVideo()?.url}
                                    width={'100%'}
                                    height={200}
                                    style={{
                                        objectFit: 'cover',
                                        width: '100%',
                                        borderRadius: 10,
                                        height: 200
                                    }}
                                    onReady={() => {
                                        setPlayerReady(true);
                                    }}
                                    onError={() => {
                                    }}
                                />
                                <div
                                    style={{
                                        paddingTop: 10
                                    }}>
                                    <span className='fs--1'
                                        style={{
                                            padding: 3, paddingTop: 10,
                                            marginTop: 10,
                                        }}>
                                        Seek to a particular time of the video to use as the Video Cover for Instagram
                                    </span>
                                </div>
                            </fieldset>
                        }
                        {
                            mediaType === 'video' &&
                            <div
                                style={{
                                    marginTop: 10,
                                    marginBottom: 20,
                                    paddingTop: 5,
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    fontSize: 18,
                                    gap: 10,
                                    fontWeight: 500
                                }}>
                                Publish Video as Instagram Reel
                                <Switch
                                    checked={bulkContentMode ? bulkContentPublishVideoUnderReels : canVideoBePublishedAsReel}
                                    onChange={(e) => {
                                        if (bulkContentMode) {
                                            setBulkContentPublishVideoUnderReels(e);
                                            let updatedRow = { ...bulkContentRow }
                                            if (!e) {
                                                e = "no"; //!This is a hack! Do not remove
                                            }
                                            updatedRow.publish_instagram_video_under_reels = e;
                                            updateBulkContentRow(updatedRow);
                                        } else {
                                            if (isFreeWorkSpace) {
                                                let metricInitializer = {
                                                    action: MetricsProps.INSTAGRAM_REELS,
                                                    detailedAction: `Upgrade notice: Publish Video as Instagram Reel`,
                                                    timestamp: new Date().toISOString(),
                                                    route: window.location.pathname.replace(workspace._id, '').replace('//', '/'),
                                                }
                                                dispatch(setCollectMetric(metricInitializer))
                                                dispatch(setPopupUpgradeAlert(true))
                                                return;
                                            }
                                            if (canVideoBePublishedAsStory) {
                                                dispatch(setPublishIGVideoAsStories(false));
                                            }
                                            dispatch(setPublishIGVideoAsReel(e));
                                        }
                                    }}
                                />
                            </div>
                        }
                        {
                            mediaType === 'video' &&
                            <div
                                style={{
                                    marginTop: 10,
                                    marginBottom: 20,
                                    paddingTop: 5,
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    fontSize: 18,
                                    gap: 10,
                                    fontWeight: 500
                                }}>
                                Publish Video as Instagram Stories
                                <Switch
                                    checked={bulkContentMode
                                        ?
                                        bulkContentPublishVideoUnderStories
                                        : canVideoBePublishedAsStory}
                                    onChange={(e) => {
                                        if (bulkContentMode) {
                                            setBulkContentPublishVideoUnderStories(e);
                                            let updatedRow = { ...bulkContentRow }
                                            if (!e) {
                                                e = "no"; //!This is a hack! Do not remove
                                            }
                                            updatedRow.publish_instagram_video_under_stories = e;
                                            updateBulkContentRow(updatedRow);
                                        } else {
                                            if (isFreeWorkSpace) {
                                                let metricInitializer = {
                                                    action: MetricsProps.INSTAGRAM_STORIES,
                                                    detailedAction: `Upgrade notice: Publish Video as Instagram Stories`,
                                                    timestamp: new Date().toISOString(),
                                                    route: window.location.pathname.replace(workspace._id, '').replace('//', '/'),
                                                }
                                                dispatch(setCollectMetric(metricInitializer))
                                                dispatch(setPopupUpgradeAlert(true))
                                                return;
                                            }
                                            if (canVideoBePublishedAsReel) {
                                                dispatch(setPublishIGVideoAsReel(false));
                                            }
                                            dispatch(setPublishIGVideoAsStories(e));
                                        }
                                    }}

                                />
                            </div>
                        }
                        {
                            videoErrorMessage && mediaType === 'video' &&
                            <PrePublishErrorResolver
                                error={videoErrorMessage}
                            />
                        }
                        {
                            !canVideoBePublishedAsReel && !canVideoBePublishedAsStory && !canImageBePublishedAsStory && (
                                mediaType === 'video' ? <Alert variant="warning" className="p-2 mb-0">
                                    <Flex>
                                        <FontAwesomeIcon icon="exclamation-triangle" className="fs-2" />
                                        <div className="ms-3 flex-1">
                                            <h4 className="alert-heading">Please note</h4>
                                            1. The recommended specifications for Instagram video post is MP4 or MOV, 1080 x 1080 pixels & 1080 x 1350 pixels; and not more than 90 seconds.
                                        </div>
                                    </Flex>
                                </Alert> : <Alert variant="warning" className="p-2 mb-0">
                                    <Flex>
                                        <FontAwesomeIcon icon="exclamation-triangle" className="fs-2" />
                                        <div className="ms-3 flex-1">
                                            <h4 className="alert-heading">Please note</h4>
                                            The recommended specifications for Instagram image posts are:
                                            <br />
                                            a. Instagram Stories: we recommend 1080 x 1920px.
                                            <br />
                                            b. Instagram feed: we recommend 1080 x 1350 pixels.
                                        </div>
                                    </Flex>
                                </Alert>
                            )
                        }
                        {
                            !bothReelsAndStoriesAreEnabled && canVideoBePublishedAsReel && mediaType === 'video' &&
                            <Flex direction={'column'}>
                                <Divider />
                                <Alert variant="warning" className="p-2 mb-0">
                                    <Flex>
                                        <FontAwesomeIcon icon="exclamation-triangle" className="fs-2" />
                                        <div className="ms-3 flex-1">
                                            <h4 className="alert-heading">Please note</h4>
                                            The recommended specifications for instagram reel is MP4 or MOV, 1080 x 1920 px and not more than 90 seconds
                                        </div>
                                    </Flex>
                                </Alert>
                            </Flex>
                        }
                        {
                            !bothReelsAndStoriesAreEnabled && canVideoBePublishedAsStory && mediaType === 'video' &&
                            <Flex direction={'column'}>
                                <Divider />
                                <Alert variant="warning" className="p-2 mb-0">
                                    <Flex>
                                        <FontAwesomeIcon icon="exclamation-triangle" className="fs-2" />
                                        <div className="ms-3 flex-1">
                                            <h4 className="alert-heading">Please note</h4>
                                            The recommended specifications for instagram stories is MP4 or MOV, 1080 x 1920 px and not more than 60 seconds
                                        </div>
                                    </Flex>
                                </Alert>
                            </Flex>
                        }
                        {
                            mediaType !== 'video' &&
                            <div
                                style={{
                                    marginTop: 10,
                                    marginBottom: 20,
                                    paddingTop: 5,
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    fontSize: 18,
                                    gap: 10,
                                    fontWeight: 500
                                }}>
                                Publish Image as Instagram Stories
                                <Switch
                                    checked={bulkContentMode ? bulkContentPublishImageUnderStories : canImageBePublishedAsStory}
                                    onChange={(e) => {
                                        if (bulkContentMode) {
                                            setBulkContentPublishImageUnderStories(e);
                                            let updatedRow = { ...bulkContentRow }
                                            if (!e) {
                                                e = "no"; //!This is a hack! Do not remove
                                            }
                                            updatedRow.publish_instagram_image_under_stories = e;
                                            updateBulkContentRow(updatedRow);
                                        } else {
                                            if (isFreeWorkSpace) {
                                                let metricInitializer = {
                                                    action: MetricsProps.INSTAGRAM_STORIES,
                                                    detailedAction: `Upgrade notice: Publish Image as Instagram Stories`,
                                                    timestamp: new Date().toISOString(),
                                                    route: window.location.pathname.replace(workspace._id, '').replace('//', '/'),
                                                }
                                                dispatch(setCollectMetric(metricInitializer))
                                                dispatch(setPopupUpgradeAlert(true))
                                                return;
                                            }
                                            dispatch(setPublishIGImageAsStories(e));
                                        }
                                    }}
                                />
                            </div>
                        }
                        {
                            canImageBePublishedAsStory && mediaType !== 'video' &&
                            <Flex direction={'column'}>
                                <Divider />
                                <Alert variant="warning" className="p-2 mb-0">
                                    <Flex>
                                        <FontAwesomeIcon icon="exclamation-triangle" className="fs-2" />
                                        <div className="ms-3 flex-1">
                                            <h4 className="alert-heading">Please note</h4>
                                            The recommended specifications for instagram stories is JPEG format, 1080 x 1920 px and not more than 8 MB in size
                                        </div>
                                    </Flex>
                                </Alert>
                            </Flex>
                        }
                        {
                            bothReelsAndStoriesAreEnabled &&
                            <Flex direction={'column'}>
                                <Divider />
                                <Alert variant="warning" className="p-2 mb-0">
                                    <Flex>
                                        <FontAwesomeIcon icon="exclamation-triangle" className="fs-2" />
                                        <div className="ms-3 flex-1">
                                            <h4 className="alert-heading">Please note</h4>
                                            Note that Instagram Reels and Stories cannot be published at the same time.
                                        </div>
                                    </Flex>
                                </Alert>
                            </Flex>
                        }
                    </Flex>
                </Card.Body>
            }
            <ConfirmModal
                open={openUpgradeDialog}
                title={"Upgrade Account"}
                message={"This feature requires an account upgrade. Kindly upgrade to a premium account to continue"}
                confirmText={"UPGRADE"}
                cancelText={"CANCEL"}
                onCancel={() => {
                    setOpenUpgradeDialog(false);
                }}
                onConfirm={() => {
                    setOpenUpgradeDialog(false);
                    navigate(`${RoutePaths.BILLING_STRIPE}`)
                }}
            />
        </Card>
    );
}
