import React, { useEffect, useState } from 'react'
import NavigationListener from './NavigationListener'
import { RoutePaths } from 'constants';
import { useSelector } from 'react-redux';
import { getActiveMetric, setActiveMetric } from 'redux/slices/analytics_slice';
import { v4 as uuid } from 'uuid';
import APIService from 'http/api_service';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { Button } from 'reactstrap';
import InstagramHero from 'assets/svgs/InstagramHero.svg';
import { getActionForBillingUpgradeModal, getLoggedInUser, getShowFreeUserBillingUpgradeModal, setActionForBillingUpgradeModal, setInitiateActivationOnPremiumPlan, setShowFreeUserBillingUpgradeModal } from 'redux/slices/user_slice';
import { useNavigate } from 'react-router-dom';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import StringUtils from './string';

const NavigationHandler = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);
    const activeMetric = useSelector(getActiveMetric)
    const showFreeUserBillingUpgradeModal = useSelector(getShowFreeUserBillingUpgradeModal);
    const [redirectToBilling, setRedirectToBilling] = useState(false)
    const workSpace = useSelector(getActiveWorkSpace);
    const loggedInUser = useSelector(getLoggedInUser);
    const actionForBillingUpgradeModal = useSelector(getActionForBillingUpgradeModal);

    const pushOutMetricToServer = (metric) => {
        if (!metric) return;
        APIService.newMetric(metric, (response, error) => {
            if (error) {
                console.log(error)
                return;
            }
        })
    }

    const collectMetrics = (userResponse = false, destroyActiveMetric = false) => {
        if (!userResponse) {
            const initMetric = {
                metricId: uuid(),
                data: {
                    action: 'Advance Upgrade Notice: Free plan user after creating a post.',
                    workspace_id: workSpace?._id,
                    userId: loggedInUser?._id,
                    userEmail: loggedInUser?.email,
                    timestamp: new Date().toISOString(),
                    route: window.location.pathname.replace(workSpace._id, '').replace(loggedInUser._id, '').replace('//', '/'),
                }
            }
            pushOutMetricToServer(initMetric);
            dispatch(setActiveMetric(initMetric))
            return
        }
        if (!activeMetric) return;
        const updatedMetric = {
            ...activeMetric,
            data: {
                ...activeMetric.data,
                responses: activeMetric?.data?.responses?.length ? [...activeMetric?.data?.responses, userResponse] : [userResponse]
            }
        }
        pushOutMetricToServer(updatedMetric);
        if (destroyActiveMetric) {
            dispatch(setActiveMetric(null))
        } else {
            dispatch(setActiveMetric(updatedMetric))
        }
    }

    useEffect(() => {
        if (!showFreeUserBillingUpgradeModal) return;
        localStorage.setItem('persistentBillingPopup', true)
        setRedirectToBilling(true)
        setShowPopup(true)
        collectMetrics()
    }, [showFreeUserBillingUpgradeModal])

    const handleNavigationEvent = (prevLocation, currentLocation) => {
        if (prevLocation.pathname === RoutePaths.BILLING_STRIPE && activeMetric) {
            let response = {
                responseId: uuid(),
                timestamp: new Date().toISOString(),
                response: `Route changed from ${prevLocation.pathname} to ${currentLocation.pathname}`,
                type: 'navigation'
            }
            const updatedMetric = {
                ...activeMetric,
                data: {
                    ...activeMetric.data,
                    responses: activeMetric?.data?.responses?.length ? [...activeMetric?.data?.responses, response] : [response]
                }
            }

            console.log(activeMetric, 'activeMetric')

            if (activeMetric?.data?.action) {
                let action = activeMetric?.data?.action;
                action = StringUtils.convertSnakeCaseToUpperCase(action);
                dispatch(setActionForBillingUpgradeModal(action))
                dispatch(setShowFreeUserBillingUpgradeModal(true))
            }

            pushOutMetricToServer(updatedMetric);
            dispatch(setActiveMetric(null))
        }
    };

    const displayMessages = () => {
        if (actionForBillingUpgradeModal) {
            return <div
                style={{
                    width: "100%",
                    fontSize: 15,
                    fontWeight: 700,
                }}
            >
                <p className="">
                    The {actionForBillingUpgradeModal} feature is essential for your needs.
                </p>
                <p>
                    Access this and other premium features to enhance your marketing campaigns when you upgrade your plan.
                </p>
            </div>
        }

        return <div
            style={{
                width: "100%",
                fontSize: 15,
                fontWeight: 700,
            }}
        >
            <p className="">
                Thousands of businesses and agencies use Postly to boost their marketing campaigns
            </p>
            <p>Why don't you start now?</p>
        </div>
    }

    const displayHeading = () => {
        if (actionForBillingUpgradeModal) {
            return `This seems important to you 😊`;
        }
        if (redirectToBilling) {
            return "Hi there 👋, you made a post!";
        }
        return "Hi there 👋, before you leave";
    }

    return (
        <>
            <NavigationListener onNavigationEvent={handleNavigationEvent} setShowPopup={setShowPopup} />
            <Modal
                size="md"
                centered
                show={showPopup}
                onHide={() => setShowPopup()}
            >
                <Modal.Header>
                    <span
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: "10px",
                            }}
                        >
                            <Modal.Title>
                                {displayHeading()}
                            </Modal.Title>
                        </div>
                    </span>
                </Modal.Header>
                <Modal.Body>
                    <Flex
                        alignItems={"center"}
                        direction={"column"}
                        style={{ gap: 10 }}
                    >
                        {/* <img src={InstagramHero} alt="copy" height="250" width="300px" /> */}
                        {displayMessages()}
                    </Flex>
                </Modal.Body>
                <Modal.Footer style={{ flexDirection: "column" }}>
                    <Flex justifyContent={"flex-start"} alignItems={"center"} direction={'column'} gap={2}>
                        <Flex
                            justifyContent={"between"}
                            alignItems={"start"}
                            style={{ gap: 20 }}
                        >
                            <Button
                                style={{
                                    minWidth: 150
                                }}
                                variant="danger"
                                onClick={() => {
                                    setShowPopup(!showPopup)
                                    dispatch(setActionForBillingUpgradeModal(false))
                                    dispatch(setShowFreeUserBillingUpgradeModal(false))
                                    if (redirectToBilling) {
                                        let response = {
                                            responseId: uuid(),
                                            timestamp: new Date().toISOString(),
                                            response: "Closed advance upgrade modal notice",
                                            buttonClicked: 'CLOSE',
                                        }
                                        setRedirectToBilling(false)
                                        collectMetrics(response, true)
                                        setTimeout(() => {
                                            window.location.reload()
                                        }, 2000);
                                    }
                                }}>
                                No, thanks
                            </Button>
                            <Button style={{
                                backgroundColor: '#2569c3',
                                borderColor: '#2569c3',
                                minWidth: 150
                            }} onClick={() => {
                                setShowPopup(!showPopup)
                                dispatch(setActionForBillingUpgradeModal(false))
                                dispatch(setShowFreeUserBillingUpgradeModal(false))
                                if (redirectToBilling) {
                                    let response = {
                                        responseId: uuid(),
                                        timestamp: new Date().toISOString(),
                                        response: "Clicked upgrade button on advance upgrade modal notice",
                                        buttonClicked: 'UPGRADE',
                                    }
                                    collectMetrics(response, false)
                                    dispatch(setInitiateActivationOnPremiumPlan(false))
                                    setRedirectToBilling(false)
                                    navigate(RoutePaths.BILLING_STRIPE)
                                } else {
                                    dispatch(setInitiateActivationOnPremiumPlan('SchedulerPremium'))
                                }
                            }}>
                                Upgrade
                            </Button>
                            {/* <Flex
                                direction={'column'}
                                alignItems={'center'}
                                justifyContent={'center'}
                            >
                                <Button style={{
                                    backgroundColor: '#2569c3',
                                    borderColor: '#2569c3',
                                    minWidth: 200
                                }} onClick={() => {
                                    setShowPopup(!showPopup)
                                    dispatch(setShowFreeUserBillingUpgradeModal(false))
                                    if (redirectToBilling) {
                                        let response = {
                                            responseId: uuid(),
                                            timestamp: new Date().toISOString(),
                                            response: "Clicked upgrade button on advance upgrade modal notice",
                                            buttonClicked: 'UPGRADE',
                                        }
                                        collectMetrics(response, false)
                                        dispatch(setInitiateActivationOnPremiumPlan(false))
                                        setRedirectToBilling(false)
                                        // navigate(RoutePaths.BILLING_STRIPE)
                                        window.open('https://buy.stripe.com/eVa5lMaCI8a195m28t?prefilled_promo_code=SAVE20', '_blank')
                                    } else {
                                        dispatch(setInitiateActivationOnPremiumPlan(false));
                                        window.open('https://buy.stripe.com/eVa5lMaCI8a195m28t?prefilled_promo_code=SAVE20', '_blank')
                                    }
                                }}>Skip trial</Button>
                                <p className="fs--2">Get 20% discount for 6 months</p>
                            </Flex> */}
                        </Flex>
                        {/* <Flex
                            justifyContent={"center"}
                            alignItems={"center"}
                            style={{ gap: 10 }}
                        >
                            <Button
                                style={{
                                    minWidth: 200
                                }}
                                variant="danger"
                                onClick={() => {
                                    setShowPopup(!showPopup)
                                    dispatch(setShowFreeUserBillingUpgradeModal(false))
                                    if (redirectToBilling) {
                                        let response = {
                                            responseId: uuid(),
                                            timestamp: new Date().toISOString(),
                                            response: "Closed advance upgrade modal notice",
                                            buttonClicked: 'CLOSE',
                                        }
                                        setRedirectToBilling(false)
                                        collectMetrics(response, true)
                                        setTimeout(() => {
                                            window.location.reload()
                                        }, 2000);
                                    }
                                }}>
                                Not now
                            </Button>
                        </Flex> */}
                    </Flex>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default NavigationHandler