import recursiveAspectFinder from 'utils/recursiveAspectFinder';

const rescaleImage = async (source, width, height, expectedWidth, expectedHeight, skipAspectFinder = false) => {
    if (skipAspectFinder) {
        try {
            const image = new Image();
            image.src = source;
            const canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            let scale = Math.max(
                canvas.width / image.naturalWidth,
                canvas.height / image.naturalHeight
            );
            let x = canvas.width / 2 - (image.naturalWidth / 2) * scale;
            let y = canvas.height / 2 - (image.naturalHeight / 2) * scale;
            canvas
                .getContext("2d")
                .drawImage(
                    image,
                    x,
                    y,
                    image.naturalWidth * scale,
                    image.naturalHeight * scale
                );
            const dataURL = await canvas.toDataURL('image/png');
            return dataURL;
        } catch (error) {
        }
    }
    const { new_width, new_height } = await recursiveAspectFinder(expectedWidth, expectedHeight, width, height, 1);
    const image = new Image();
    image.src = source;
    const canvas = document.createElement("canvas");
    canvas.width = new_width;
    canvas.height = new_height;
    let scale = Math.max(
        canvas.width / image.naturalWidth,
        canvas.height / image.naturalHeight
    );
    let x = canvas.width / 2 - (image.naturalWidth / 2) * scale;
    let y = canvas.height / 2 - (image.naturalHeight / 2) * scale;
    canvas
        .getContext("2d")
        .drawImage(
            image,
            x,
            y,
            image.naturalWidth * scale,
            image.naturalHeight * scale
        );
    const dataURL = await canvas.toDataURL();
    return dataURL;
}

export {
    rescaleImage
}