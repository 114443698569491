import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Background from 'components/common/Background';
import Flex from 'components/common/Flex';
import Section from 'components/common/Section';
import { TermsAndPolicies } from '../constants';
import bgShape from 'assets/img/illustrations/bg-shape.png';
import shape1 from 'assets/img/illustrations/shape-1.png';
import halfCircle from 'assets/img/illustrations/half-circle.png';
import FullLogo from 'components/common/FullLogo';
import { RoutePaths } from '../constants';
import { useQuery } from 'utils/url';
import Divider from 'components/common/Divider';
import Avatar from 'components/common/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import {
  canFlipToChildPages,
  getSwitchMode,
  setFlipToChildPages,
  setSwitchMode
} from 'redux/slices/authSlice';
import {
  getAltUsers,
  getLoggedInUser,
  setAltUser,
  setAltUsers
} from 'redux/slices/user_slice';
import Logo from 'components/common/Logo';
import './addOnsStyles.css';

const AuthCardLayout = ({
  leftSideContent,
  children,
  footer = true }) => {

  const navigate = useNavigate();
  const loggedInUser = useSelector(getLoggedInUser);
  const switchMode = useSelector(getSwitchMode);
  const query = useQuery();
  const [switchingTo, setSwitchingTo] = useState({});
  const dispatch = useDispatch();
  const flipToChildPages = useSelector(canFlipToChildPages);
  const currentURL = window.location.href;

  const SwitchView = () => {

    const loggedInAltUsers = useSelector(getAltUsers);

    useEffect(() => {
      if (loggedInUser) {
        let prevUsers = loggedInAltUsers ?? [];
        let currentUser = prevUsers.find(x => x._id === loggedInUser._id);
        if (!currentUser) {
          prevUsers.unshift(loggedInUser);
        }
        let newData = [...prevUsers];
        dispatch(setAltUsers(newData));
      }
    }, []);

    return (
      <>
        {
          flipToChildPages &&
          <div
            className="p-4 p-md-5 flex-grow-1">
            {children}
          </div>
        }
        <>
          {
            !flipToChildPages &&
            <>
              {
                !loggedInAltUsers ?
                  <div
                    className="p-4 p-md-5 flex-grow-1">
                    {children}
                  </div>
                  :
                  <Flex
                    alignItems={'center'}
                    justifyContent={'center'}
                    direction={'column'}
                    className={'gap-2'}>
                    <h5 className='p-0 m-0'>Switch Account</h5>
                    <Divider />
                    {
                      loggedInAltUsers.map(x => {
                        return (
                          <Button
                            disabled={switchingTo.hasOwnProperty(x._id)}
                            id={x._id}
                            style={{
                              width: '100%',
                              textAlign: 'start'
                            }}
                            variant='falcon-default'
                            onClick={() => {
                              setSwitchingTo((prev) => {
                                prev = prev ?? {};
                                prev[x._id] = true;
                                return { ...prev };
                              })
                              dispatch(setAltUser(x));
                              navigate(RoutePaths.DEFAULT);
                              window.location.reload();
                            }}>
                            <Flex
                              alignItems={'center'}
                              className={'gap-3'}>
                              <Avatar
                                className={`${x._id === loggedInUser._id ? 'status-online' : ''}`}
                                size='2xl'
                                src={x.avatar}
                                fallBackName={`${x.first_name ?? x.email}`}
                              />
                              {
                                switchingTo.hasOwnProperty(x._id) ? <span>Please wait...</span> :
                                  <Flex direction={'column'}>
                                    <h5 className='p-0 m-0'>{`${x.first_name} ${x.last_name}`}</h5>
                                    <span className='fs--1'>{x.email}</span>
                                  </Flex>
                              }
                            </Flex>
                          </Button>
                        )
                      })
                    }
                    <Button
                      onClick={() => {
                        dispatch(setFlipToChildPages(true));
                      }}
                      variant='falcon-primary'
                      style={{ width: '100%', marginTop: 10 }}>Use another Acount
                    </Button>
                  </Flex>
              }
            </>
          }
        </>
      </>
    )
  }

  const navigateToMainPage = (url, canSwitch) => {
    if (switchMode) {
      window.location = `${window.location.href}?switch=${switchMode}`;
      return;
    }
    if (!url.includes(RoutePaths.LOGOUT)
      && !url.includes(RoutePaths.INVITATIONS)
      && !url.includes(RoutePaths.TELEGRAM_LOGIN_PROXY)
      && !url.includes(RoutePaths.FORGOT_PASSWORD)
      && !url.includes(RoutePaths.RESET_PASSWORD)
      && !canSwitch) {
      navigate(RoutePaths.WORKSPACE);
    }
  }

  useEffect(() => {
    if (loggedInUser) {
      let url = window.location.href;
      let canSwitch = query.get("switch");
      if (canSwitch) {
        dispatch(setSwitchMode(canSwitch));
        if (canSwitch !== 'true') {
          navigateToMainPage(url);
        }
      } else {
        dispatch(setSwitchMode(false));
        navigateToMainPage(url, canSwitch);
      }
    } else {
      setTimeout(() => {
        let followUpDeletionUrl = localStorage.getItem('follow_up_deletion');
        if (followUpDeletionUrl) {
          localStorage.removeItem('follow_up_deletion');
          window.location.href = followUpDeletionUrl;
        }
      }, 200);
    }
  }, [window.location]);

  return (
    <Section fluid className="py-0">
      
      <Row className="g-0 min-vh-100 flex-center">
        <Col lg={6} xxl={5} className="py-3 position-relative">
          {(currentURL.includes("app.postly.ai") || currentURL.includes("localhost:3000")) &&
            <Link
              className="link-light mb-4 font-sans-serif fw-bolder fs-4 d-inline-block"
              to="/"
            >
              <Logo width={40} fontSize={30} />
            </Link>
          }
          <img
            className="bg-auth-circle-shape"
            src={bgShape}
            alt=""
            width="250"
          />
          <img
            className="bg-auth-circle-shape-2"
            src={shape1}
            alt=""
            width="150" 
          />
          <Card className="overflow-hidden z-index-1">
            <Card.Body className="p-0">
              <Row className="h-100 g-0">
                
                <Col
                  md={12}
                  as={Flex}
                  alignItems="center"
                  justifyContent="center"
                >
                  {
                    switchMode ?
                      <SwitchView />
                      :
                      <div className="p-4 p-md-5 flex-grow-1">{children}</div>
                  }
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};
AuthCardLayout.propTypes = {
  leftSideContent: PropTypes.node,
  children: PropTypes.node.isRequired,
  footer: PropTypes.bool
};
export default AuthCardLayout;
