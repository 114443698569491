import { faCompass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoBar from "components/common/InfoBar";
import NotificationDropdown from "components/navbar/top/NotificationDropdown";
import ProfileDropdown from "components/navbar/top/ProfileDropdown";
import AppContext from "context/Context";
import APIService from "http/api_service";
import React, { useContext, useState } from "react";
import { Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getLoggedInUser,
  isLTDUserAndRequiresUpgrade,
  setShowTourGuide
} from "redux/slices/user_slice";
import {
  getActiveWorkSpace,
  setActiveWorkspace,
} from "redux/slices/workspaceslice";
import "./addOns.css";

import "react-tooltip/dist/react-tooltip.css";

const TopNavRightSideNavItem = () => {
  const navigate = useNavigate();
  const ltdUserAndRequiresUpgrade = useSelector(isLTDUserAndRequiresUpgrade);

  const {
    config: { isDark, isRTL },
    setConfig,
  } = useContext(AppContext);

  const dispatch = useDispatch();
  const loggedInUser = useSelector(getLoggedInUser);
  const activeWorkSpace = useSelector(getActiveWorkSpace);
  const [currentPlan] = useState(loggedInUser?.active_plan);

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      {/* {ltdUserAndRequiresUpgrade ?
        <h3 className="text-primary cursor-pointer fs--1 mt-2" onClick={() => navigate(RoutePaths.LTD_UPGRADE_PAGE)}>
          Get 200 workspaces and unlimited social accounts
        </h3> : ""
      }
      {currentPlan.Name === "Free" ? (
        <h3 className="text-primary cursor-pointer fs--1 mt-2" onClick={() => navigate(RoutePaths.BILLING_STRIPE)}>
          Upgrade for unlimited workspaces and posts
        </h3>
      ) : null} */}

      <div
        style={{
          width: 10,
        }}
      ></div>
      <InfoBar
        info={`New update: Schedule posts on Threads!`}
        iconVariant={"check-circle"}
        otherElements={"📢 📢 📢"}
        iconContainerStyles={{
          width: 23,
          height: 23,
        }}
        innerIconStyles={{
          width: 17,
          height: 17,
        }}
        secondaryIconStyles={{ padding: 0 }}
      // customStyles={{
      //   fontWeight:400,
      // }}
      />

      <Nav.Item as={"li"}>
        <Nav.Link
          className="theme-control-toggle"
          onClick={() => {
            // dispatch(setDisplayTourGuide('false'));
            dispatch(setShowTourGuide(true));
            // window.location.reload(); 
          }}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? "bottom" : "left"}
            overlay={(props) => (
              <Tooltip id="tour-guide-tooltip" {...props}>
                Dashboard Tour Guide
              </Tooltip>
            )}
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon
                icon={faCompass}
                className="fs-0"
                style={{ width: 22, height: 22 }}
              />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>

      <Nav.Item as={"li"}>
        <Nav.Link
          className="theme-control-toggle"
          onClick={() => {
            window.open("https://www.postly.ai/help-center", "_blank");
          }}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? "bottom" : "left"}
            overlay={<Tooltip id="ThemeColor">Help center</Tooltip>}
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon
                icon={"question-circle"}
                className="fs-0"
                style={{ width: 22, height: 22 }}
              />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>

      <Nav.Item as={"li"}>
        <Nav.Link
          className="px-2 theme-control-toggle"
          onClick={() => {
            let darkNow = !isDark;
            setConfig("isDark", darkNow);
            let themeChoice = darkNow ? "dark" : "light";
            APIService.updateWorkspaceTheme(
              activeWorkSpace._id,
              themeChoice,
              (response, error) => {
                let themeChoices = activeWorkSpace.theme_choices ?? {};
                let userId = loggedInUser._id;
                themeChoices[userId] = themeChoice;
                dispatch(
                  setActiveWorkspace({
                    ...activeWorkSpace,
                    theme_choices: themeChoices,
                  })
                );
              }
            );
          }}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? "bottom" : "left"}
            overlay={
              <Tooltip id="ThemeColor">
                {isDark ? "Switch to light theme" : "Switch to dark theme"}
              </Tooltip>
            }
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon
                icon={isDark ? "sun" : "moon"}
                className="fs-0"
                style={{ width: 22, height: 22 }}
              />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>
      <NotificationDropdown />
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
