/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import {
  faEllipsis, faImage, faPencil, faRemove,
  faVideo
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmModal from "components/common/ConfirmModal";
import MultimediaSelectionDropdown from "components/common/MultimediaSelectionDropdown";
import CircularButton from "components/common/circularbutton";
import GDriveDashboard from "components/common/g-drive/dashboard";
import AppContext from "context/Context";
import parse from 'html-react-parser';
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { GoGift } from "react-icons/go";
import { RiDragDropLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getMediaArrangement, setMediaArrangement } from "redux/slices/publishable_media_arrangement_slice";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper } from "swiper/react";
import {
  extractLinksFromTweet, getPreviewFromUrl,
  url_replacer
} from "utils/url";
import { PlatformData, PlatformIdentifier } from "../../../../../constants";
import {
  canOpenPixelFilePickerDialog,
  getGoogleDriveManager,
  getManuallyEditedPlatforms, getMediaAddedViaSpecificPlaformUrlMethod,
  getNotifyPreviewsOfLiveMediaChanges,
  getSelectingPhotoFromHome,
  getSpecificMediaPlatformTied, getTimeToPostContent,
  setGoogleDriveManager,
  setManuallyEditedPlatforms,
  setOpenPixelFilePickerDialog,
  setSpecificMediaPlatformTied
} from "../../../../../redux/slices/postslice";
import { platforms } from "../../../platforms";
import DragToPositionImage from "./DragToPositionImage";
import ExtrernalImageSearchDialogue from "./ExtrernalImageSearchDialogue";
import GDriveConnectionRequestListener from "./GDriveConnectionRequestListener";
import "./addOnsStyles.css";
import Carousel from "./carousel";
import EditTextBox from "./editTextBox.js";
import PlayMediaInstagram from "./playMediaInstagram.js";
import { scanImages } from "./scanImages";
import {
  ImageDelete, ParentSection,
  PostImage, RedditEachBtn, RedditFirstLayer,
  RedditImageHolder,
  RedditInfo, RedditPreviewContentArea, RedditSecondLayer,
  RedditThirdLayer,
  Wrapper
} from "./styles/reddit";
import Flex from "components/common/Flex";

const Reddit = () => {

  const dispatch = useDispatch();
  const googleDriveManager = useSelector(getGoogleDriveManager)
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const googleDriveRef = useRef(null);
  const mediaObjectsTotalLength = 10;
  const [toggleEditTextInput, setToggleEditTextInput] = useState(false);
  const media_items_arrangement = useSelector(getMediaArrangement);
  const openPixelDialoguePicker = useSelector(canOpenPixelFilePickerDialog);
  const [max_min_imageSize, setMax_min_imageSize] = useState(null);
  const [useableMedia, setUseableMedia] = useState([]);
  const [openMediaSelction, setOpenMediaSelection] = useState(false);
  const [currentMediaType, setCurrentMediaType] = useState(null);
  const [repostionImages, setRepositionImages] = useState(false)
  const selectingPhotoFromHome = useSelector(getSelectingPhotoFromHome);
  const myRef = useRef(null);
  const focusImageRef = useRef(null);
  const textEditRef = useRef(null);
  const [timer, setTimer] = useState();
  const timeToPost = useSelector(getTimeToPostContent);
  const [limitedPostTextBody, setLimitedPostTextBody] = useState("");
  const mediaAddedViaSpecificPlaformUrlMethod = useSelector(
    getMediaAddedViaSpecificPlaformUrlMethod
  );
  const specificMediaPlatformTied = useSelector(getSpecificMediaPlatformTied);
  const [formattedPostBodyForLinks, setFormattedPostBodyForLinks] = useState("");
  const [linkExtracted, setLinkExtracted] = useState();
  const [previewerData, setPreviewerData] = useState(null);
  const [blockMediaItemsUpdate, setBlockMediaItemsUpdate] = useState(true)
  const manuallyEditedPlatforms = useSelector(getManuallyEditedPlatforms);
  const platformsWithLimits = [...platforms];
  const { config } = useContext(AppContext);
  const notifyPreviewsOfLiveMediaChanges = useSelector(getNotifyPreviewsOfLiveMediaChanges);
  let typingTimeout;

  const getPreviewLinkDetails = async (url) => {
    try {
      const result = await getPreviewFromUrl(url);
      setPreviewerData(result?.data)
    } catch (error) {
    }
  }

  const prepRedditImages = async () => {
    const imagesMedia = media_items_arrangement?.reddit?.media?.filter(x => x.is_video === false);
    await scanImages(imagesMedia)
    setUseableMedia(media_items_arrangement?.reddit?.media);
  }

  useEffect(() => {
    const result = url_replacer(limitedPostTextBody ?? '');
    setFormattedPostBodyForLinks(result)
  }, [limitedPostTextBody])

  useEffect(() => {
    const result = extractLinksFromTweet(limitedPostTextBody ?? '')
    setLinkExtracted(result[0]);
  }, [limitedPostTextBody])

  useEffect(() => {
    if (!linkExtracted) {
      return
    }
    getPreviewLinkDetails(linkExtracted);
  }, [linkExtracted])

  useEffect(() => {
    prepRedditImages();
  }, [notifyPreviewsOfLiveMediaChanges])


  useEffect(() => {
    const shouldOpenTextEdit = localStorage.getItem('open_hashtag_pop');
    if (shouldOpenTextEdit === 'true') {
      handleEditClick();
    }
  }, [])

  const dispatchEditedFlag = () => {
    if (!manuallyEditedPlatforms[PlatformIdentifier.REDDIT]) {
      const newData = { ...manuallyEditedPlatforms, reddit: true }
      dispatch(setManuallyEditedPlatforms(newData));
    }
  }

  const mediaRemoveHandler = (removableMediaObject) => {
    try {
      let cachedArrayMedia = useableMedia;
      const filterMedia = cachedArrayMedia?.filter(
        (item) => item.url !== removableMediaObject.url
      );
      setBlockMediaItemsUpdate(false)
      setUseableMedia((prevState) => (prevState = filterMedia));
    } catch (error) { }
  };

  const handleDeleteMedia = (data) => {
    dispatchEditedFlag()
    mediaRemoveHandler(data);
  };

  const updateMediaItems = async (
    files,
    canCreateObjectURL = true,
    moreProps = {},
  ) => {
    let expectedFilesLength = files.length + useableMedia.length;
    if (
      files.length > mediaObjectsTotalLength ||
      useableMedia.length > mediaObjectsTotalLength || expectedFilesLength > mediaObjectsTotalLength
    ) {
      toast.error(
        `Sorry, you can only upload a maximum of ${mediaObjectsTotalLength} files at a time`, { theme: 'colored' }
      );
      return;
    }
    const isImageExisiting = media_items_arrangement?.reddit?.media?.filter(
      (item) => item.is_video !== true
    );
    if (moreProps?.is_video && isImageExisiting?.length) {
      toast.error(
        `Videos are not allowed along side images on the same slide for reddit`, { theme: 'colored' }
      );
      return
    }
    const isVideoExisiting = useableMedia?.filter(
      (item) => item.is_video === true
    );
    if (isVideoExisiting.length > 0 && moreProps?.is_video === true) {
      toast.error(`Sorry, only one video is allowed.`, { theme: 'colored' });
      return;
    }
    let mo = [];
    for (let file of files) {
      if (canCreateObjectURL) {
        let fileUrl = URL.createObjectURL(file);
        let fileName = file.name;
        let existingFile = mo.find((x) => x.url === fileUrl);
        if (!existingFile) {
          let fileExtension = `.${fileName.split(".").pop()}`;
          let newThreadObject = {
            name: fileName,
            url: fileUrl,
            extension: fileExtension,
            file: file,
            ...moreProps,
          };

          if (file.size > PlatformData.MAXIMUM_ALLOWED_FILE_UPLOAD_SIZE) {
            toast.error(
              `Maximum allowed file size is ${PlatformData.MAXIMUM_ALLOWED_FILE_UPLOAD_SIZE / 1e9
              }GB`, { theme: 'colored' }
            );
          } else {
            mo.push(newThreadObject);
          }
        }
      } else {
        let fileWithName = mo.find((item) => item.name === file.name);
        if (!fileWithName) {
          mo.push(file);
        }
      }
    }
    const imagesMedia = mo?.filter(x => x.is_video === false);
    await scanImages(imagesMedia)
    mo?.map((item) => {
      setBlockMediaItemsUpdate(false)
      setUseableMedia((oldArray) => [...oldArray, item]);
    });
    dispatchEditedFlag();
    setOpenMediaSelection(false);
    return;
  }

  const mediaOpener = (
    <div
      ref={myRef}
      style={{
        position: "relative",
        zIndex: 5,
        width: "96%",
        marginLeft: "4%",
        marginBottom: 20,
        marginTop: 20,
      }}
    >
      {
        <MultimediaSelectionDropdown
          specificPlatformTied={PlatformIdentifier.REDDIT}
          fromPreview={true}
          initiatorId={
            currentMediaType === "image" ? `${PlatformIdentifier.REDDIT}-photo-chooser` : `${PlatformIdentifier.REDDIT}-video-chooser`
          }
          externalFilePickerTitle={
            currentMediaType === "image"
              ? "Enter Photo Location"
              : "Enter Video Location"
          }
          prompt={
            currentMediaType === "image" ? "Add Photo From" : "Add Video From"
          }
          fileType={currentMediaType === "image" ? "image" : "video"}
          externalPickerPromptLabel={
            currentMediaType === "image" ? "Photo" : "Video"
          }
          multiple={currentMediaType === "image" ? true : false}
          fileSelectionHandler={(response) => {
            if (response) {
              let { files, from_local, ...others } = response;
              updateMediaItems(files, from_local, others);
            }
          }}
        />
      }
    </div>
  );

  const displaySingleMedia = useableMedia?.map((item, index) => {
    return (
      <div style={{ height: "auto", width: "auto", position: "relative" }}>
        <ImageDelete
          editBgColor={!config.isDark ? "#e0e0e0" : "#0b1727"}
          onClick={(e) => handleDeleteMedia(item)}
        >
          <FontAwesomeIcon icon={faRemove} />
        </ImageDelete>

        {item?.is_video ? (
          <PlayMediaInstagram
            url={item?.url}
            maximunHeight={max_min_imageSize > 0 && max_min_imageSize}
            indexer={index}
          />
        ) : (
          <PostImage
            class="redditFirstImage"
            style={{
              maxHeight: `${500}px`,
              minHeight: "unset",
              objectFit: "fill",
            }}
            src={item?.url}
            alt="post preview"
          ></PostImage>
        )}
      </div>
    );
  });

  const dimensifyImage = async () => {
    try {
      let myImg = await document.querySelector(".redditFirstImage").height;
      if (
        !myImg &&
        useableMedia[0].is_video === true &&
        useableMedia.length > 1
      ) {
        setMax_min_imageSize((prevState) => (prevState = 400));
        return;
      }
      setMax_min_imageSize((prevState) => (prevState = myImg));
    } catch (e) {

    }
  }

  useEffect(async () => {
    dimensifyImage();
  }, [useableMedia]);

  const syncNewThread = async () => {
    let syncingThread = [];
    try {
      const mappedThread = useableMedia?.map((thread, index) => {
        const newThreadEntry = {
          name: thread?.name,
          url: thread?.url,
          is_video: thread?.is_video,
          external: thread?.external ?? false,
          uploaded: thread?.uploaded ?? false,
          file: thread?.file,
        };
        return newThreadEntry;
      });
      const oldData = { ...media_items_arrangement };
      const diapatchableData = { ...oldData, reddit: { ...oldData.reddit, media: mappedThread } }
      dispatch(setMediaArrangement(diapatchableData));
    } catch (error) { }
    return syncingThread;
  };

  const handleUpdateTextFromEditBox = (text) => {
    clearTimeout(typingTimeout);
    typingTimeout = setTimeout(() => {
      dispatchEditedFlag()
      const oldData = { ...media_items_arrangement };
      const getTextLimit = platformsWithLimits.find(x => x.identifier === PlatformIdentifier.REDDIT)
      const diapatchableData = { ...oldData, reddit: { ...oldData.reddit, text: text.substring(0, getTextLimit.textLimit) } }
      dispatch(setMediaArrangement(diapatchableData));
    }, 1000);
  }

  useEffect(() => {
    if (!blockMediaItemsUpdate) {
      setBlockMediaItemsUpdate(true)
      syncNewThread();
    }
  }, [useableMedia]);

  const limitChars = (text, limit) => {
    setLimitedPostTextBody(text?.substring(0, limit));
  };

  const waitTime = 1000;

  function runWhenUserStopTying() {
    window.clearTimeout(timer);
    setTimer(
      window.setTimeout(() => {
        if (!manuallyEditedPlatforms[PlatformIdentifier.REDDIT]) {
          limitChars(media_items_arrangement?.reddit?.text, 10000);
        }
      }, waitTime)
    );
  }

  useEffect(() => {
    runWhenUserStopTying();
  }, [media_items_arrangement?.reddit?.text]);

  useEffect(() => {
    limitChars(media_items_arrangement?.reddit?.text, 10000);
  }, []);

  const handleEditClick = () => {
    setToggleEditTextInput(true);
  };

  const addMediaFromUrl = (obj = mediaAddedViaSpecificPlaformUrlMethod[0]) => {
    const { extension, thumbnail, ...rest } = obj;
    setBlockMediaItemsUpdate(false);
    useableMedia.length
      ? setUseableMedia((oldArray) => [...oldArray, rest])
      : setUseableMedia([rest]);

    dispatchEditedFlag();
    dispatch(setSpecificMediaPlatformTied("global"));
    setOpenMediaSelection(false);
    if (googleDriveManager?.docs) {
      dispatch(setGoogleDriveManager({ ...googleDriveManager, docs: null }))
    }
  };

  useEffect(() => {
    if (!googleDriveManager?.docs || localStorage.getItem('gdrivePlatform') !== PlatformIdentifier.REDDIT) return;
    addMediaFromUrl(googleDriveManager?.docs);
    immediateActionWhenClieked(true)
  }, [googleDriveManager])


  const immediateActionWhenClieked = (remove) => {
    if (remove) {
      localStorage.removeItem("gdrivePlatform");
      return;
    }
    localStorage.setItem("gdrivePlatform", PlatformIdentifier.REDDIT);
  }

  useEffect(() => {
    specificMediaPlatformTied === "reddit" &&
      mediaAddedViaSpecificPlaformUrlMethod.length &&
      addMediaFromUrl();
  }, [mediaAddedViaSpecificPlaformUrlMethod]);

  const handleConnect = async () => {
    try {
      googleDriveRef.current.click();
    } catch (error) {

    }
  }

  useEffect(() => {
    if (googleDriveManager['fromPreviews'] && googleDriveManager['promptConnect']) {
      setOpenConfirmation(true)
    }
  }, [googleDriveManager])


  return (
    <>
      <Flex
        gap={3}
        className={`mt-2`}
      >
        <MultimediaSelectionDropdown
          dropDownAnchor={
            <CircularButton onClick={() => immediateActionWhenClieked(false)} title="Add Image">
              <FontAwesomeIcon icon={faImage} />
            </CircularButton>
          }
          specificPlatformTied={PlatformIdentifier.REDDIT}
          fromPreview={true}
          initiatorId={`${PlatformIdentifier.REDDIT}-photo-chooser`}
          externalFilePickerTitle={"Enter Photo Location"}
          prompt={"Add Photo From"}
          fileType={"image"}
          externalPickerPromptLabel={"Photo"}
          multiple={true}
          fileSelectionHandler={(response) => {
            if (response) {
              let { files, from_local, ...others } = response;
              updateMediaItems(files, from_local, others);
            }
          }}
        />

        <MultimediaSelectionDropdown
          dropDownAnchor={
            <CircularButton title="Add Video">
              <FontAwesomeIcon icon={faVideo} />
            </CircularButton>
          }
          specificPlatformTied={PlatformIdentifier.REDDIT}
          fromPreview={true}
          initiatorId={`${PlatformIdentifier.REDDIT}-video-chooser`}
          externalFilePickerTitle={"Enter Video Location"}
          prompt={"Add Video From"}
          fileType={"video"}
          externalPickerPromptLabel={"Video"}
          multiple={false}
          fileSelectionHandler={(response) => {
            if (response) {
              let { files, from_local, ...others } = response;
              updateMediaItems(files, from_local, others);
            }
          }}
        />
        <CircularButton
          editBgColor={!config.isDark ? "#e0e0e0" : "#333333"}
          onClick={() => setRepositionImages(!repostionImages)}
          title="re-position images"
        >
          <RiDragDropLine size={20} />
        </CircularButton>

        {!toggleEditTextInput && (
          <CircularButton
            editBgColor={!config.isDark ? "#e0e0e0" : "#333333"}
            onClick={() => handleEditClick()}
            title="Edit Text"
          >
            <FontAwesomeIcon icon={faPencil} />
          </CircularButton>
        )}
      </Flex>
      <ParentSection>
        <GDriveConnectionRequestListener
          googleDriveRef={googleDriveRef}
        />
        {
          googleDriveManager?.openPicker ? <GDriveDashboard platform={PlatformIdentifier.REDDIT} /> : null
        }
        <Wrapper>
          {openMediaSelction && !selectingPhotoFromHome && mediaOpener}
          {toggleEditTextInput && (
            <EditTextBox
              ref={textEditRef}
              isPlatformEdited={PlatformIdentifier.REDDIT}
              incomingRef={textEditRef}
              value={limitedPostTextBody}
              setValue={setLimitedPostTextBody}
              setToggleEditTextInput={setToggleEditTextInput}
              platform={"reddit"}
              setter={{ ...manuallyEditedPlatforms, reddit: true }}
              updateText={handleUpdateTextFromEditBox}
            />
          )}
          <RedditPreviewContentArea
            mainBgColor={!config.isDark ? "white" : "#0b1727"}
            theme={!config.isDark ? "1px 1px 3px #949494" : "none"}
            style={{
              borderColor: `${!config.isDark ? "#ededed" : "transparent"
                }`,
            }}
            ref={focusImageRef}
          >
            <div style={{ position: 'absolute', bottom: 0, right: 5, fontSize: 8, }}>
              Character Count: {formattedPostBodyForLinks?.length} / 10000
            </div>
            {manuallyEditedPlatforms[PlatformIdentifier.REDDIT] && (
              <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  fontStyle: "italic",
                  position: "absolute",
                  top: 0,
                  right: 5,
                  fontSize: 9,
                }}
              >
                <p style={{ color: !config.isDark ? "grey" : "grey" }}>
                  Edited
                </p>
              </div>
            )}
            <RedditFirstLayer>
              <RedditImageHolder>
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "100%",
                    backgroundColor: "white",
                  }}
                  src="https://app.postly.ai/static/media/postly_logo.2e3243cd201fdf7e1b5c.png"
                  alt="dp"
                />
              </RedditImageHolder>
              <RedditInfo>
                <p
                  type={"p"}
                  style={{
                    fontWeight: "700",
                    color: `${!config.isDark ? "black" : "whitesmoke"
                      }`,
                    fontSize: 10
                  }}
                >
                  {"Reddit Preview"}
                </p>
                <p
                  type={"p"}
                  style={{
                    color: "grey",
                    fontSize: 9
                  }}
                >
                  - Posted by use@postly {moment(timeToPost, "SS").fromNow()}
                </p>
              </RedditInfo>
            </RedditFirstLayer>

            {
              repostionImages ? <DragToPositionImage useableMedia={useableMedia} setter={setUseableMedia} platform={PlatformIdentifier.REDDIT} /> :
                <RedditSecondLayer>
                  {/* <TextareaAutoSize
                    id="compose-box"
                    minRows={2}
                    ref={textRef}
                    as="textarea"
                    placeholder={''}
                    className={`shadow-none resize-none border-0 ${config.isDark ? "border-x" : ""
                      } px-2 border-200`}
                    onChange={(e) => {
                      let value = e.target.value;
                      handleUpdateTextFromEditBox(value);
                    }}
                    style={{
                      width: "100%",
                      outline: "none",
                      whiteSpace: "pre-wrap",
                      display: "inherit",
                      background: config.isDark ? "#0b1727" : "#fff",
                      color: "inherit",
                      fontSize: 11,
                    }}
                    maxRows={20}
                  /> */}
                  <pre
                    style={{
                      margin: "1%",
                      marginTop: 10,
                      marginBottom: 10,
                      color: `${!config.isDark ? "black" : "#b3b3b3"}`,
                      fontFamily:
                        'Roboto, -apple-system, "apple color emoji", BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
                      lineHeight: "21px",
                      fontWeight: 400,
                      maxWidth: "100%",
                      width: "100%",
                      wordWrap: "break-word",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {parse(formattedPostBodyForLinks)}
                  </pre>
                  {useableMedia.length === 1 ? (
                    displaySingleMedia
                  ) : (
                    <Swiper
                      navigation={true}
                      pagination={true}
                      modules={[Navigation, Pagination]}
                      className="mySwiper"
                    >
                      <Carousel
                        mediaEdited={manuallyEditedPlatforms[PlatformIdentifier.REDDIT]}
                        ImageDelete={ImageDelete}
                        handleDeleteMedia={handleDeleteMedia}
                        FontAwesomeIcon={FontAwesomeIcon}
                        useableMedia={useableMedia}
                        faRemove={faRemove}
                        PostImage={PostImage}
                        max_min_imageSize={max_min_imageSize}
                        PlayMediaInstagram={PlayMediaInstagram}
                      />
                    </Swiper>
                  )}
                </RedditSecondLayer>
            }


            <RedditThirdLayer>
              <RedditEachBtn>
                <svg
                  style={{ marginTop: 4 }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${!config.isDark ? "#65676B" : "#B0B3B8"}`}
                  width="9"
                  height="9"
                  viewBox="0 0 24 24"
                >
                  <path d="M22 3v13h-11.643l-4.357 3.105v-3.105h-4v-13h20zm2-2h-24v16.981h4v5.019l7-5.019h13v-16.981z" />
                </svg>{" "}
                <span
                  type={"p"}
                  style={{
                    color: `${!config.isDark ? "#65676B" : "#818384"}`,
                    fontSize: 11
                  }}
                >
                  Comment
                </span>
              </RedditEachBtn>

              <RedditEachBtn>
                <GoGift size="9px" color={`${!config.isDark ? "#65676B" : "#818384"}`} />{" "}
                <span
                  type={"p"}
                  style={{
                    color: `${!config.isDark ? "#65676B" : "#818384"}`,
                    fontSize: 11
                  }}
                >
                  Award
                </span>
              </RedditEachBtn>

              <RedditEachBtn>
                <svg
                  width="9px"
                  height="9px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 5L21 10L16 15"
                    stroke={`${!config.isDark ? "#65676B" : "#818384"}`}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21 10H13C7.47715 10 3 14.4772 3 20V21"
                    stroke={`${!config.isDark ? "#65676B" : "#818384"}`}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>{" "}
                <span
                  type={"p"}
                  style={{
                    color: `${!config.isDark ? "#65676B" : "#818384"}`,
                    fontSize: 11
                  }}
                >
                  Share
                </span>
              </RedditEachBtn>

              <RedditEachBtn>
                <FontAwesomeIcon
                  style={{
                    marginTop: 2,
                    color: `${!config.isDark ? "#65676B" : "#818384"}`,
                    fontSize: 9
                  }}
                  icon={faBookmark}
                />{" "}
                <span
                  type={"p"}
                  style={{
                    color: `${!config.isDark ? "#65676B" : "#818384"}`,
                    fontSize: 11
                  }}
                >
                  Save
                </span>
              </RedditEachBtn>

              <RedditEachBtn>
                <FontAwesomeIcon
                  style={{
                    marginTop: 3,
                    color: `${!config.isDark ? "#65676B" : "#818384"}`,
                  }}
                  icon={faEllipsis}
                />{" "}
              </RedditEachBtn>
            </RedditThirdLayer>
          </RedditPreviewContentArea>
        </Wrapper>
        <Modal
          show={openPixelDialoguePicker}
          onHide={() => {
            dispatch(setOpenPixelFilePickerDialog(false));
          }}
          onExit={() => {
            dispatch(setOpenPixelFilePickerDialog(false));
          }}
        >
          <Modal.Header>
            <Modal.Title>Pexels.com</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ExtrernalImageSearchDialogue
              setUseableMedia={setUseableMedia}
              determiner={PlatformIdentifier.REDDIT}
              // options={externalFilePickerDialogOptions}
              modalClose={() => {
                // dispatch(setOpenExternalFilePickerDialog(false));
              }}
            />
          </Modal.Body>
        </Modal>
        <ConfirmModal
          open={openConfirmation}
          title={"Connect Your Google Drive Account"}
          message={'Your google drive account is not connected or has been disconnected. Do you wish to connect?'}
          confirmText={"PROCEED"}
          cancelText={"CANCEL"}
          showDialoguePrompt
          onCancel={() => {
            setOpenConfirmation(false);
            dispatch(setGoogleDriveManager({
              ...googleDriveManager,
              promptConnect: false,
            }))
          }}
          onConfirm={() => {
            handleConnect();
            dispatch(setGoogleDriveManager({ ...googleDriveManager, promptConnect: false, fromPreviews: false }))
          }}
        />
      </ParentSection>
    </>
  );
};

export default Reddit;
