import React, { useState } from "react";
import {
    getTimeToPostContent,
    setPostNow,
    setTimeToPostContent,
    setTimeZoneToPostContent,
    getTimeZoneToPostContent
} from "../../../../redux/slices/postslice";
import { useSelector, useDispatch } from "react-redux";
import { faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { createDateWithTimeZone } from "utils/time";
import { replaceLast } from "utils/url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import Flex from "components/common/Flex";
import DatePicker from 'react-datepicker';
import TimeZoneSelector from "components/common/TimeZoneSelector";

export const generateTimeClause = (date) => {
    if (!date) {
        return null;
    }
    let localTimeString = date.toLocaleDateString("en-US", {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour12: true,
        hour: '2-digit',
        minute: '2-digit'
    });
    return `${replaceLast(localTimeString, ",", " at ")}`;
}

export default function TimeToPostConfigurator(props) {

    const { modalClose } = props;
    const dispatch = useDispatch();
    const timeToPost = useSelector(getTimeToPostContent);
    const [timeChanged, setTimeChanged] = useState(false);
    const [finalDateToPost, setFinalDateToPost] = useState(timeToPost);
    const selectedTimezone = useSelector(getTimeZoneToPostContent);

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        return currentDate.getTime() < selectedDate.getTime();
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 20,
            }}>
            <h5
                style={{
                    fontSize: 22,
                    paddingBottom: 20
                }}>Schedule Post
            </h5>
            <div
                className="mb-5"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                    color: '#78909C'
                }}>
                <FontAwesomeIcon
                    style={{
                        color: '#78909C'
                    }}
                    icon={faStopwatch}
                />
                <span
                    style={{
                        color: '#78909C',
                        fontSize: 14
                    }}>Will Post on {generateTimeClause(createDateWithTimeZone(finalDateToPost, selectedTimezone, true))}
                </span>
            </div>
            <h6>Pick Date &amp; Time</h6>
            <DatePicker
                minDate={new Date()}
                filterTime={filterPassedTime}
                selected={finalDateToPost ?? timeToPost}
                onChange={(date) => {
                    setFinalDateToPost(date);
                    setTimeChanged(true);
                }}
                formatWeekDay={day => day.slice(0, 3)}
                className='form-control'
                placeholderText="Select Date & Time"
                timeIntervals={1}
                dateFormat="MM/dd/yyyy h:mm aa"
                showTimeSelect
                fixedHeight
            />
            <div
                className="mt-5"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 8
                }}>
                <h6>Pick Time Zone(Optional)</h6>
                <TimeZoneSelector
                    defaultTimeZone={selectedTimezone}
                    onChange={(result) => {
                        dispatch(setTimeZoneToPostContent(result))
                        setTimeChanged(true);
                    }}
                />
            </div>
            <Flex
                className={"mt-4"}
                alignItems={'flex-end'}
                justifyContent={'flex-end'}
                style={{
                    gap: 10,
                    alignSelf: 'flex-end'
                }}>
                <Button
                    variant={'default'}
                    hollow
                    onClick={modalClose}>
                    CANCEL
                </Button>
                <Button
                    variant={'primary'}
                    onClick={() => {
                        if (timeChanged) {
                            dispatch(setTimeToPostContent(finalDateToPost));
                        }
                        dispatch(setPostNow(false));
                        modalClose();
                    }}
                >
                    CONFIRM
                </Button>
            </Flex>
        </div>
    );
}