import React, { useState, useEffect, useContext } from 'react';
import APIService from '../../../http/api_service';
import { toast } from 'react-toastify';
import TeamMemberInviteView from './add_team_member_view';
import MemberRoleUpdateView from './member_role_update_view';
import DataTable, { createTheme } from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import {
    updateActivePageName,
    updateActivePageSubTitle,
    getTitleInWorkSpace,
    setCanFetchTeam,
    refreshTeam,
    setTeam,
    getTeam,
    setSearchHint,
    getSearchTerm,
    getActiveWorkSpace,
    setActiveWorkspace,
    setSocialAccountQuantity,
    getBilledYearly
} from '../../../redux/slices/workspaceslice';
import StringUtils from '../../../utils/string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPen } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import {
    PERMISSION_INVITE_TEAM_MEMBER, PERMISSION_REMOVE_MEMBER_FROM_WORKSPACE,
    PERMISSION_SEE_PAGE_SUBTITLE,
    PERMISSION_VIEW_PENDING_INVITES,
    RoutePaths,
    WorkSpaceRole
} from '../../../constants';
import { Button, Card, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import AppContext from 'context/Context';
import CircularButton from 'components/common/circularbutton';
import CopyLinkButton from 'components/common/copylinkbutton';
import Flex from 'components/common/Flex';
import FreePageHeader from 'components/common/FreePageHeader';
import ConfirmModal from 'components/common/ConfirmModal';
import { useNavigate } from 'react-router-dom';
import { userCanPerformActionInWorkspace } from 'utils/workspace_utils';
import { getLoggedInUser, setLoggedInUser } from 'redux/slices/user_slice';
import { isLTD } from 'utils/ltd_utils';
import {
    pricingDataNew,
    extraSocialAccounts,
    postlyExtraUsers,
} from '../account/pricing/pricingDataNew';
import MonthlyYearlySwitch from '../account/pricing/MonthlyYearlySwitch';
import IconButton from 'components/common/IconButton';
import { isFreeUser } from 'utils/user_utils';

export default function TeamPage(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const workSpace = useSelector(getActiveWorkSpace);
    const numberOfValidTeamMembers = workSpace?.active_plan?.Users || null
    const loggedInUser = useSelector(getLoggedInUser);
    const team = useSelector(getTeam);
    const [pendingInvitations, setPendingInvitations] = useState();
    const [tempTeam, setTempTeam] = useState();
    const [membersInRemoval, setMembersInRemoval] = useState([]);
    const canFetchTeam = useSelector(refreshTeam);
    const [canFetchPendingInvites, setCanFetchPendingInvites] = useState(true);
    const jobTitle = useSelector(getTitleInWorkSpace);
    const [editableMemberData, setEditableMemberData] = useState();
    const [openMemberEditDialog, setOpenMemberEditDialog] = useState();
    const [openTeamMemberInviteDialog, setOpenTeamMemberInviteDialog] = useState(false);
    const [openInvitationCancelDialog, setOpenInvitationCancelDialog] = useState(false);
    const [invitationToCancel, setInvitationToCancel] = useState();
    const { config } = useContext(AppContext);
    const searchTerm = useSelector(getSearchTerm);
    const [memberToRemove, setMemberToRemove] = useState();
    const [openMemberRemovalDialog, setOpenMemberRemovalDialog] = useState(false);
    const [openUserQuantityPicker, setOpenUserQuantityPicker] = useState(false);
    const [currentPlan] = useState(loggedInUser.active_plan);
    const [planType, setPlanType] = useState(0);
    const selectedPlan = pricingDataNew[planType];
    const [formData, setFormData] = useState({ numberOfUsers: "", });
    const billedYearly = useSelector(getBilledYearly);
    const [processingPayment, setProcessingPayment] = useState(false);
    const [checkoutCompletedMessage, setCheckoutCompletedMessage] = useState();
    const [openCheckoutCompletedPopup, setOpenCheckoutCompletedPopup] =
        useState(false);
    const totalAmountToBeCharged =
        formData.numberOfUsers *
        (billedYearly ? selectedPlan.extraPrice.Year : selectedPlan.extraPrice.Month);
    const [openSocialAccountQuantityPicker, setOpenSocialAccountQuantityPicker] = useState(false);


    useEffect(() => {
        dispatch(setSearchHint("Search team members"));
    }, []);

    useEffect(() => {
        handleSearchChangeListener(searchTerm);
    }, [searchTerm]);

    useEffect(() => {
        if (jobTitle) {
            dispatch(setCanFetchTeam(true));
        }
    }, [jobTitle]);

    const userHasOwnerOrAdminPrivileges = () => {
        return workSpace.role.toLowerCase() === WorkSpaceRole.OWNER.toLowerCase() || workSpace.role.toLowerCase() === WorkSpaceRole.ADMIN.toLowerCase();
    }

    const roleUpdateDoneHandler = () => {
        dispatch(setCanFetchTeam(true));
    }

    const RoleUpdateView = React.forwardRef((props, ref) => {

        const { excludedRole, memberId, memberEmail } = props;

        return (
            <CircularButton
                onClick={() => {
                    setEditableMemberData({ memberId, excludedRole, memberEmail });
                    setOpenMemberEditDialog(true);
                }}>
                <FontAwesomeIcon icon={faPen}
                    id={'member_role_updater'}
                />
            </CircularButton>
        );
    });

    useEffect(() => {
        if (config.isDark) {
            createTheme('solarized', {
                text: {
                    primary: '#9da9bb',
                },
                background: {
                    default: '',
                },
            }, 'light');
        }
    }, [config]);

    const pendingInvitationsColumn = [
        {
            name: 'Email',
            selector: row => row.owner_email,
            sortable: true
        },
        {
            name: 'Role',
            selector: row => row.data.toString(),
            sortable: true
        },
        {
            name: 'Invited Since',
            selector: row => new Date(row.created_at).toDateString(),
            sortable: true
        },
        {
            name: 'Invitation Link',
            selector: row => <CopyLinkButton
                size='sm'
                linkToCopy={`https://app.postly.ai/invitations?token=${encodeURIComponent(row.token)}`}
                variant={'outline-info'}
            />
        },
        {
            cell: (row) =>
                <div style={{ display: 'flex' }}>
                    <div>
                        <Button
                            size='sm'
                            variant={'outline-danger'}
                            onClick={() => {
                                setInvitationToCancel(row);
                                setOpenInvitationCancelDialog(true);
                            }}>CANCEL INVITE
                        </Button>
                        <span>{' '}</span>
                    </div>
                    <span>{' '}</span>
                </div>
        }
    ];

    const userHasRightToChangeOwner = (excludedRole) => {
        if (excludedRole.toLowerCase() === WorkSpaceRole.OWNER.toLowerCase() && workSpace.role.toLowerCase() === WorkSpaceRole.ADMIN.toLowerCase()) {
            return false;
        }
        return true;
    }

    const isNotMyself = (email) => {
        return email !== loggedInUser.email;
    }

    const teamMemberColumns = [
        {
            name: 'Member',
            selector: row => row.member,
            sortable: true,
        },
        {
            name: "Title",
            selector: row => <div style={{ display: 'flex', alignItems: 'center', gap: 10, color: config.isDark ? '#9da9bb' : 'black' }}><span>{row.title}</span></div>,
            sortable: true,
        },
        {
            name: "Email",
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Role',
            selector: row => <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}><span>{StringUtils.capitalizeString(row.role)}</span>{userHasOwnerOrAdminPrivileges() && userHasRightToChangeOwner(row.role) && isNotMyself(row.email) && row.user_id._id !== loggedInUser._id && <RoleUpdateView memberId={row.user_id._id} excludedRole={row.role} memberEmail={row.email} />}</div>,
            sortable: true,
        },
        {
            cell: (row) => {
                if (row.user_id._id !== loggedInUser._id) {
                    if (row.user_id._id !== workSpace.owner_id) {
                        if (userCanPerformActionInWorkspace(workSpace, PERMISSION_REMOVE_MEMBER_FROM_WORKSPACE)) {
                            return (
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <Button
                                            size='sm'
                                            isWorking={membersInRemoval.includes(row.user_id._id)}
                                            variant={'outline-danger'}
                                            onClick={() => {
                                                setMemberToRemove(row);
                                                setOpenMemberRemovalDialog(true);
                                            }}>Remove
                                        </Button>
                                        <span>{' '}</span>
                                    </div>
                                    <span>{' '}</span>
                                </div>
                            );
                        }
                    }
                } else {
                    if (row.user_id._id !== workSpace.owner_id) {
                        return (
                            <Button
                                size='sm'
                                isWorking={membersInRemoval.includes(row.user_id._id)}
                                variant={'danger'}
                                onClick={() => {
                                    setMemberToRemove(row);
                                    setOpenMemberRemovalDialog(true);
                                }}>Leave
                            </Button>
                        );
                    }
                }
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];

    const themedGreen = () => {
        if (!config.isDark) {
            return 'green';
        }
        return '#C8E6C9';
    }

    const themedRed = () => {
        if (!config.isDark) {
            return 'red';
        }
        return '#EF9A9A';
    }

    const TeamPrivilegesTable = React.forwardRef((props, ref) => {
        let teamPrivilegesColumn = [
            {
                name: 'Privilege',
                grow: 2,
                selector: row => row.name
            },
            {
                name: 'Owner',
                selector: row => <FontAwesomeIcon icon={row.owner ? faCheck : null}
                    style={{
                        color: row.owner ? themedGreen() : themedRed()
                    }}
                />
            },
            {
                name: 'Admin',
                selector: row => <FontAwesomeIcon icon={row.admin ? faCheck : null}
                    style={{
                        color: row.admin ? themedGreen() : themedRed()
                    }}
                />
            },
            {
                name: 'Approver',
                selector: row => <FontAwesomeIcon icon={row.approver ? faCheck : null}
                    style={{
                        color: row.approver ? themedGreen() : themedRed()
                    }}
                />
            },
            {
                name: 'Reviewer',
                selector: row => <FontAwesomeIcon icon={row.reviewer ? faCheck : null}
                    style={{
                        color: row.reviewer ? themedGreen() : themedRed()
                    }}
                />
            },
            {
                name: 'Editor',
                selector: row => <FontAwesomeIcon icon={row.editor ? faCheck : null}
                    style={{
                        color: row.editor ? themedGreen() : themedRed()
                    }}
                />
            }
        ];
        let teamPrivilegesRows = [
            {
                name: 'Draft Posts',
                owner: true,
                admin: true,
                approver: true,
                reviewer: true,
                editor: true
            },
            {
                name: 'Review Posts',
                owner: true,
                admin: true,
                approver: true,
                reviewer: true,
                editor: false
            },
            {
                name: 'Approve Posts',
                owner: true,
                admin: true,
                approver: true,
                reviewer: false,
                editor: false
            },
            {
                name: 'Create more Workspaces',
                owner: true,
                admin: false,
                approver: false,
                reviewer: false,
                editor: false
            },
            {
                name: 'Invite Team members or Clients to Workspace',
                owner: true,
                admin: true,
                approver: false,
                reviewer: false,
                editor: false
            },
            {
                name: 'View and manage Platforms',
                owner: true,
                admin: true,
                approver: false,
                reviewer: false,
                editor: false
            },
            {
                name: 'Modify Approval Workflow process',
                owner: true,
                admin: true,
                approver: false,
                reviewer: false,
                editor: false
            },
            {
                name: 'Remove Team Members',
                owner: true,
                admin: true,
                approver: false,
                reviewer: false,
                editor: false
            }
        ];
        return (
            <DataTable
                columns={teamPrivilegesColumn}
                data={teamPrivilegesRows}
                theme={config.isDark ? "solarized" : null}
            />
        );
    });

    const TeamMembersTable = React.forwardRef((props, ref) => {
        return (
            <DataTable
                columns={teamMemberColumns}
                data={team}
                theme={config.isDark ? "solarized" : null}
            />
        )
    })

    const PendingInvitesTable = React.forwardRef((props, ref) => {
        return (
            <DataTable
                columns={pendingInvitationsColumn}
                data={pendingInvitations}
                theme={config.isDark ? "solarized" : null}
            />
        )
    })

    useEffect(() => {
        dispatch(updateActivePageName("Team"));
        if (userCanPerformActionInWorkspace(workSpace, PERMISSION_SEE_PAGE_SUBTITLE)) {
            dispatch(updateActivePageSubTitle("Manage your team here. Invite members to your workspace."));
        } else {
            dispatch(updateActivePageSubTitle(""));
        }
        return () => {
            dispatch(updateActivePageName(" "));
            dispatch(updateActivePageSubTitle(null));
        }
    }, []);

    useEffect(() => {
        if (canFetchTeam) {
            APIService.fetchTeam(workSpace['_id'], (response, error) => {
                dispatch(setCanFetchTeam(false));
                if (error) {
                    toast.error(error, { theme: 'colored' });
                    return;
                }
                let data = response['data'];
                data = data.filter(x => x.user_id);
                data = data.map((x) => {
                    if (!x.user_id.first_name || !x.user_id.last_name) {
                        x.user_id.first_name = 'Postly';
                        x.user_id.last_name = 'User'
                    }
                    let name = `${x.user_id.first_name} ${x.user_id.last_name}`;
                    x.member = name;
                    if (x.user_id._id === loggedInUser['_id']) {
                        let member = x.member;
                        member = `${member}(You)`;
                        x.member = member;
                    }
                    x.email = x.user_id.email;
                    return x;
                });
                dispatch(setTeam(data));
                setTempTeam(data);
            });
        }
    }, [canFetchTeam]);

    useEffect(() => {
        if (canFetchPendingInvites) {
            if (userCanPerformActionInWorkspace(workSpace, PERMISSION_VIEW_PENDING_INVITES)) {
                APIService.fetchPendingTeamInvites(workSpace._id, (response, error) => {
                    setCanFetchPendingInvites(false);
                    if (error) {
                        toast.error(error, { theme: 'colored' });
                        return;
                    }
                    let { data } = response;
                    setPendingInvitations(data);
                });
            }
        }
    }, [canFetchPendingInvites]);

    const handleSearchChangeListener = (searchString) => {
        let membersInSearch = tempTeam?.filter((member) => member.user_id.first_name.toLowerCase().includes(searchString.toLowerCase()) || member.user_id.last_name.toLowerCase().includes(searchString.toLowerCase()) || member.user_id.email.toLowerCase().includes(searchString.toLowerCase()));
        if (searchString) {
            dispatch(setTeam([...membersInSearch]));
        } else {
            if (tempTeam) {
                dispatch(setTeam([...tempTeam]));
            }
        }
    }

    const handleInvitationCancel = (invitation) => {
        toast.info("Cancelling invitation", { theme: 'colored' });
        APIService.cancelPendingTeamInvite(workSpace._id, invitation.token, (response, error) => {
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            toast.success("Invitation Cancelled successfully", { theme: 'colored' });
            setCanFetchPendingInvites(true);
        });
    }

    const handleMemberRemoval = (member) => {
        toast.info(member?.user_id?._id === loggedInUser?._id ? "Leaving workspace..." : "Removing member", { theme: 'colored' });
        setMembersInRemoval((prevMembersInRemoval) => {
            if (!prevMembersInRemoval.includes(member.user_id.id)) {
                prevMembersInRemoval.push(member.user_id.id);
            }
            return [...prevMembersInRemoval];
        });
        APIService.removeTeamMember(workSpace['_id'], member.user_id._id, (response, error) => {
            setMembersInRemoval((prevMembersInRemoval) => {
                if (prevMembersInRemoval.includes(member.user_id.id)) {
                    let indexOfMemberInRemoval = prevMembersInRemoval.indexOf(member.user_id.id);
                    if (indexOfMemberInRemoval !== -1) {
                        prevMembersInRemoval.splice(indexOfMemberInRemoval, 1);
                    }
                }
                return [...prevMembersInRemoval];
            });
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let message = response['message'];
            if (member.user_id._id === loggedInUser._id) {
                dispatch(setActiveWorkspace(null));
                navigate(RoutePaths.DEFAULT);
                window.location.reload();
                return;
            } else {
                toast.success(message, { theme: 'colored' });
                dispatch(setCanFetchTeam(true));
            }
        });
    }

    const handleNumberOfSocualAccountChange = (event) => {
        const value = parseInt(event.target.value);
        dispatch(setSocialAccountQuantity(event.target.value));
        if (value >= 0) {
            setFormData({
                ...formData,
                numberOfUsers: value,
            });
        } else {
            setFormData({
                ...formData,
                numberOfUsers: "",
            });
        }
    };

    const pollSubscriptionStatus = (heartbeat, pricingData, type, done) => {
        const intervalId = setInterval(() => {
            APIService.fetchLatestMe((latestMeResponse, error) => {
                if (error) {
                    toast.error(error, { theme: "colored" });
                    if (
                        error
                            .toLowerCase()
                            .includes("Invalid credentials provided".toLowerCase())
                    ) {
                        window.localStorage.clear();
                        setTimeout(() => {
                            navigate(RoutePaths.LOGIN);
                        }, 100);
                    }
                    return;
                }
                const localUserPlan = loggedInUser.active_plan;
                let latestUserData = latestMeResponse.data;
                const newPlan = latestUserData.active_plan;
                const referencePlan = pricingData.plan;
                if (type === "new_subscription" && newPlan.Key === referencePlan) {
                    clearInterval(intervalId);
                    done(latestUserData);
                } else if (type === "new_subscription" && pricingData.for_ai_writer) {
                    const activeAIWriterSubscription =
                        latestUserData.active_ai_writer_subscription;
                    if (activeAIWriterSubscription != null) {
                        clearInterval(intervalId);
                        done(latestUserData);
                    }
                } else if (
                    type === "new_subscription" &&
                    pricingData.for_telegram_bot
                ) {
                    const currentWorkspace = workSpace;
                    const existingBotSlots = currentWorkspace.creatable_bot_slots ?? 0;
                    APIService.fetchWorkSpace(currentWorkspace._id, (res, err) => {
                        if (err) {
                            clearInterval(intervalId);
                            done(latestUserData);
                            return;
                        }
                        const latestWorkspaceData = res.data;
                        const newBotSlots = latestWorkspaceData.creatable_bot_slots ?? 0;
                        if (newBotSlots > existingBotSlots) {
                            dispatch(setActiveWorkspace({ ...latestWorkspaceData }));
                            clearInterval(intervalId);
                            done();
                        }
                    });
                } else if (
                    type === "additional_social_accounts" &&
                    newPlan.Key === referencePlan
                ) {
                    const existingCount = parseInt(localUserPlan.SocialAccounts);
                    const newCount = parseInt(newPlan.SocialAccounts);
                    if (!isNaN(existingCount) && !isNaN(newCount)) {
                        if (newCount > existingCount) {
                            clearInterval(intervalId);
                            done(latestUserData, newCount - existingCount);
                        }
                    }
                }
            });
        }, heartbeat);
    };


    const checkAndLazyUpdateActiveWorkspace = (latestUserData) => {
        if (!latestUserData) {
            return;
        }
        if (latestUserData._id === workSpace.owner_id) {
            setTimeout(() => {
                dispatch(
                    setActiveWorkspace({
                        ...workSpace,
                        active_plan: latestUserData.active_plan,
                    })
                );
            }, 1000);
        }
    };

    const handleSubscription = (pricingData, user, _price) => {
        console.log(pricingData)
        const Paddle = window.Paddle;
        const oneOff = pricingData.one_off ?? false;
        Paddle.Checkout.open({
            quantity: pricingData.SocialAccounts ?? 1,
            product: parseInt(
                billedYearly ? pricingData.AnnualSub : pricingData.MonthlySub
            ),
            email: user?.email,
            plan: pricingData.plan,
            allowQuantity: true,
            passthrough: JSON.stringify({
                accountId: user._id,
                subscriptionMode: billedYearly ? "Yearly" : "Monthly",
                workspaceId: user.workSpaceId,
                plan: pricingData.plan,
                quantity: pricingData.SocialAccounts ?? 1,
                SocialAccounts: pricingData.SocialAccounts ?? 1,
                prorate: true,
                prorated_keys: ["SocialAccounts"],
                coupon: pricingData.coupon,
                for_ai_writer: pricingData.for_ai_writer,
                for_telegram_bot: pricingData.for_telegram_bot,
            }),
            successCallback: () => {
                setProcessingPayment(true);
                pollSubscriptionStatus(
                    2000,
                    pricingData,
                    "new_subscription",
                    (data) => {
                        if (data) {
                            dispatch(setLoggedInUser({ ...data }));
                            checkAndLazyUpdateActiveWorkspace(data);
                            setProcessingPayment(false);
                            setCheckoutCompletedMessage(
                                oneOff
                                    ? "Payment successful"
                                    : `You have successfully subscribed to the ${pricingData.title} plan`
                            );
                            if (!openCheckoutCompletedPopup) {
                                setOpenCheckoutCompletedPopup(true);
                            }
                        }
                    }
                );
            },
        });
    };

    const handleClickOpenUserQuantityPicker = () => {
        setOpenUserQuantityPicker(true);
    };

    const buyExtraUsers = (pricingData, user) => {
        handleSubscription({ ...pricingData, for_postly_extra_user: true }, user);
    };

    return (
        <div
            style={{
                display: 'flex',
                height: '100%',
                marginTop: 10,
                margin: 20,
                flexDirection: 'column'
            }}>
            {
                !team &&
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '70vh',
                    width: '100%'
                }}>
                    <Spinner animation={'border'} size={30} />
                </div>
            }
            {
                team &&
                <div
                    style={{
                        width: '100%',
                        borderTopLeftRadius: 5,
                        borderTopRightRadius: 5
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'column',
                            borderTopRightRadius: 5,
                            borderTopLeftRadius: 5
                        }}>
                        <FreePageHeader
                            titleTag="h5"
                            className="mb-3">
                            <Flex
                                className={'gap-2'}
                                alignItems={'center'}>
                                <h5>Team</h5>
                                <div style={{ flex: 1 }}></div>
                                {
                                    userCanPerformActionInWorkspace(workSpace, PERMISSION_INVITE_TEAM_MEMBER) &&
                                    <Button
                                        onClick={() => setOpenTeamMemberInviteDialog(true)}
                                        id={'add_member'}
                                        variant="outline-info"
                                    >Invite Team Member
                                    </Button>
                                }
                            </Flex>
                            <p className="fs--1 mt-1">Postly is fun with teams, invite team members to your workspace</p>
                            {/* {
                                !isLTD(workSpace?.active_plan) && <IconButton
                                    icon="crown"
                                    variant="success"
                                    iconClassName="me-2"
                                    // className="ml-5"
                                    // style={{
                                    //     marginLeft: 20
                                    // }}
                                    onClick={() => {
                                        navigate(RoutePaths.ALL_BILLING)
                                    }}
                                >
                                    Add more team member slots
                                </IconButton>
                            } */}
                        </FreePageHeader>
                        <Card>
                            <TeamMembersTable />
                        </Card>
                    </div>
                </div>
            }
            {
                (pendingInvitations ?? []).length > 0 && userCanPerformActionInWorkspace(workSpace, PERMISSION_VIEW_PENDING_INVITES) &&
                <div
                    style={{
                        marginTop: 40,
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                    <span style={{
                        fontSize: 20
                    }}>Pending Invitations
                    </span>
                    <Card>
                        <PendingInvitesTable />
                    </Card>
                </div>
            }

            {
                workSpace?.owner_id === loggedInUser?._id &&
                <Flex
                    justifyContent={'end'}
                    alignItems={'center'}
                    style={{
                        marginTop: 10,
                    }}>
                    {/* <Button
                        variant="falcon-primary"
                        style={{
                            width: 400,
                            fontSize: 12
                        }}
                        onClick={() => {
                            handleClickOpenUserQuantityPicker()
                        }}
                    >
                        {
                            numberOfValidTeamMembers ? `You currently have ${team?.length} of ${numberOfValidTeamMembers} ${(numberOfValidTeamMembers > 1 || numberOfValidTeamMembers === 'Unlimited') ? 'users.' : 'user.'} ${numberOfValidTeamMembers !== 'Unlimited' ? 'Click to add more user slots' : ''}.` : 'Click to add more user slots'
                        }
                    </Button> */}
                </Flex>
            }
            {
                team &&
                <div style={{
                    marginTop: 40,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <span style={{
                        fontSize: 20
                    }}>Role Privileges</span>
                    <Card>
                        <TeamPrivilegesTable />
                    </Card>
                </div>
            }
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={openMemberEditDialog}
                size={'lg'}
                onExit={() => setOpenMemberEditDialog(false)}
                onHide={() => setOpenMemberEditDialog(false)}
            >
                <Modal.Body>
                    <MemberRoleUpdateView
                        excludedRole={editableMemberData?.excludedRole}
                        memberId={editableMemberData?.memberId}
                        memberEmail={editableMemberData?.memberEmail}
                        updateDoneCallback={roleUpdateDoneHandler}
                        cancelHandler={() => {
                            setOpenMemberEditDialog(false);
                        }}
                    />
                </Modal.Body>
            </Modal>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={openTeamMemberInviteDialog}
                size={'lg'}
                onExit={() => {
                    setOpenTeamMemberInviteDialog(false);
                }}
                onHide={() => setOpenTeamMemberInviteDialog(false)}
            >
                <Modal.Body>
                    <TeamMemberInviteView
                        cancelHandler={(success) => {
                            if (success) {
                                setCanFetchPendingInvites(true);
                            }
                            setOpenTeamMemberInviteDialog(false);
                        }}
                    />
                </Modal.Body>
            </Modal>
            <Modal
                show={openUserQuantityPicker}
                onHide={() => setOpenUserQuantityPicker(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Buy extra user seats
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        // isLTD(currentPlan) ? (
                        //     <p>You are not eligible for this request</p>
                        // ) :
                        (
                            <>
                                <MonthlyYearlySwitch />
                                <hr />
                                {planType !== 2 && (
                                    <span className="mt-0 fs-0 fs-sm-0 fs-lg-0 px-2">
                                        Your Active Plan: <b>{currentPlan["Name"]}</b>
                                    </span>
                                )}
                                <br />
                                {/* <span className="mt-0 fs-0 fs-sm-0 fs-lg-0 px-2">
                  Your number of users: <b>{currentPlan["SocialAccounts"]}</b>
                </span> */}
                                <br />
                                {currentPlan["Name"] === "Free" && (
                                    <span className="mt--4 fs-0 fs-sm-0 fs-lg-0 px-2">
                                        Request:{" "}
                                        <b>
                                            Upgrade to {selectedPlan.title}
                                        </b>
                                    </span>
                                )}
                                <br /> <br />
                                <Flex direction={"column"} className={"gap-2"}>
                                    {
                                        planType !== 2 && (
                                            <>
                                                <Form.Group className="mt-3">
                                                    <Form.Label>
                                                        {" "}
                                                        How many users do you want?
                                                    </Form.Label>
                                                    <Row className="align-items-center gx-0">
                                                        <Col
                                                            xs
                                                            md="auto"
                                                            className="d-flex justify-content-start"
                                                        >
                                                            <Form.Group controlId="numberOfUsers">
                                                                <Form.Control
                                                                    type="number"
                                                                    value={formData.numberOfUsers}
                                                                    name="numberOfUsers"
                                                                    onChange={handleNumberOfSocualAccountChange}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col
                                                            xs="auto"
                                                            className="d-flex justify-content-start ml-10"
                                                        >
                                                            <p className="mb-1 fs-0 fs-sm-0 fs-lg-0 px-2">
                                                                x $
                                                                {billedYearly
                                                                    ? selectedPlan.extraPrice.Year
                                                                    : selectedPlan.extraPrice.Month}
                                                                /{billedYearly ? "yr" : "mo"} = $
                                                                {totalAmountToBeCharged}/
                                                                {billedYearly ? "yr" : "mo"}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <p className="mt-4 fs-0 fs-sm-0 fs-lg-0 px-2">
                                                    You can buy more seats at any time. You will
                                                    be charged a prorated fee for the billing cycle.
                                                </p>
                                            </>
                                        )}
                                    <div className="text-end">
                                        <Button
                                            disabled={formData.numberOfUsers <= 0}
                                            onClick={() => {
                                                buyExtraUsers(
                                                    {
                                                        ...postlyExtraUsers,
                                                        for_postly_extra_user: true,
                                                    },
                                                    loggedInUser
                                                );
                                            }}
                                            style={{ width: "100%" }}
                                        >
                                            Pay now
                                        </Button>
                                    </div>
                                    <div className="text-center">
                                        <Button
                                            onClick={() => {
                                                setOpenSocialAccountQuantityPicker(false);
                                            }}
                                            variant={"outlined"}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </Flex>
                            </>
                        )}
                </Modal.Body>
            </Modal>
            <ConfirmModal
                open={openInvitationCancelDialog}
                title="Cancel Invitation"
                message={`Are you sure about cancelling the invitation sent to <b>${invitationToCancel?.owner_email}</b>?`}
                cancelText="NO"
                confirmText="YES, CANCEL"
                onCancel={() => {
                    setOpenInvitationCancelDialog(false);
                }}
                onConfirm={() => {
                    setOpenInvitationCancelDialog(false);
                    handleInvitationCancel(invitationToCancel);
                }}
            />
            <ConfirmModal
                open={openMemberRemovalDialog}
                title={memberToRemove?.user_id?._id === loggedInUser?._id ? "Leave Workspace" : "Remove Member"}
                message={memberToRemove?.user_id?._id === loggedInUser?._id ? "Are you sure about leaving this workspace?" : `Are you sure about removing <b>${memberToRemove?.email}</b> from this Workspace?`}
                cancelText="NO"
                confirmText={memberToRemove?.user_id?._id === loggedInUser?._id ? "YES, LEAVE" : "YES, REMOVE"}
                onCancel={() => {
                    setOpenMemberRemovalDialog(false);
                }}
                onConfirm={() => {
                    setOpenMemberRemovalDialog(false);
                    handleMemberRemoval(memberToRemove);
                }}
            />
        </div>
    );
}