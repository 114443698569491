import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullCalendar from "@fullcalendar/react";
import DropdownFilter from "components/common/DropdownFilter";
import Flex from "components/common/Flex";
import TooltipBadge from "components/common/TooltipBadge";
import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import ReactLoading from "react-loading";
import dayGridPlugin from '@fullcalendar/daygrid'

const events = [
  { title: 'Meeting', start: new Date() }
]

// a custom render function
function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  )
}

const EventCalendar = () => {
  return (
    <>
      <Card>
      <Card.Header>

      </Card.Header>
      <Card.Body className="p-0">
      <h1>Demo App</h1>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView='dayGridMonth'
        weekends={false}
        events={events}
        eventContent={renderEventContent}
      />
      </Card.Body>
      </Card>
    </>
  );
};

export default EventCalendar;
