import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConfirmModal from "components/common/ConfirmModal";
import Flex from 'components/common/Flex';
import APIService from "http/api_service";
import usePubSub from "pubsub";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { Checkmark } from "react-checkmark";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getLoggedInUser, setLoggedInUser } from "redux/slices/user_slice";
import {
    getActiveWorkSpace,
    getBilledYearly,
    getSocialAccountQuantity,
    setActiveWorkspace,
    setSocialAccountQuantity
} from "redux/slices/workspaceslice";
import { isFreePlan } from "utils/pricing_utils";
import { useQuery } from "utils/url";
import { isAccountUnderAppSumo, isFreePostlyCloud, isLoggedInUserOwnerOfWorkspace, isWorkSpaceUnderAppSumo } from "utils/user_utils";
import { isFreeWorkspace } from 'utils/workspace_utils';
import { RoutePaths } from "../../../../constants";
import './AddOnsStyles.css';
import LtdSpecialUpgradePlanCard from './LtdSpecialUpgradePlanCard';
import LtdSpecialUpgradePlanUnEligible from './LtdSpecialUpgradePlanUnEligible';
import MonthlyYearlySwitch from './MonthlyYearlySwitch';
import PlanCard from './PlanCard';
import PlanCardAdvance from './PlanCardAdvance';
import { ltdUpgradePricingData } from './ltdPricingData';
import {
    AIWriterPricing,
    AddOnsPlans,
    WhiteLabelPlan,
    extraSocialAccounts,
    postlyCloudPricing,
    postlyExtraUsers,
    pricingDataNew
} from "./pricingDataNew";
import InfoBar from 'components/common/InfoBar';
import ActivePlans from './ActivePlans';
import { setPopupUpgradeAlert } from 'redux/slices/authSlice';

const AllBilling = () => {

    const dispatch = useDispatch();
    const workspace = useSelector(getActiveWorkSpace);
    const billedYearly = useSelector(getBilledYearly);
    const socialAccountQuantity = useSelector(getSocialAccountQuantity);
    const loggedInUser = useSelector(getLoggedInUser);
    const [activePlan, setActivePlan] = useState(loggedInUser.active_plan);
    const [openSubscriptionCancelPrompt, setOpenSubscriptionCancelPrompt] =
        useState(false);
    const [
        openAIWriterSubscriptionCancelPrompt,
        setOpenAIWriterSubscriptionCancelPrompt,
    ] = useState(false);
    const [cancellingSubscription, setCancellingSubscription] = useState(false);
    const [cancellingAIWriterSubscription, setCancellingAIWriterSubscription] =
        useState(false);
    const [openCheckoutCompletedPopup, setOpenCheckoutCompletedPopup] =
        useState(false);
    const [checkoutCompletedMessage, setCheckoutCompletedMessage] = useState();
    const [processingPayment, setProcessingPayment] = useState(false);
    const [
        openJoinedAIWriterCancellationPrompt,
        setOpenJoinedAIWriterCancellationPrompt,
    ] = useState(false);
    const navigate = useNavigate();
    const query = useQuery();
    const { addPubSubEventListener, removePubSubEventListener } = usePubSub();
    const [openPrecancellationDialog, setOpenPreCancellationDialog] =
        useState(false);
    const [planType, setPlanType] = useState(0);
    const selectedPlan = pricingDataNew[planType];
    const [formData, setFormData] = useState({ numberOfSocialAccounts: "", numberOfUsers: 0 });
    const isUserOwnerOfWorkspace = isLoggedInUserOwnerOfWorkspace(loggedInUser, workspace);
    const [numberOfValidTeamMembers, setNumberOfValidTeamMembers] = useState(null)
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(null);
    const [openUserQuantityPicker, setOpenUserQuantityPicker] = useState(false);
    const [currentPlan] = useState(loggedInUser.active_plan);
    const totalAmountToBeCharged =
        formData.numberOfUsers *
        (billedYearly ? selectedPlan.extraPrice.Year : selectedPlan.extraPrice.Month);
    const totalAmountForExtraUser =
        formData.numberOfUsers *
        (billedYearly ? selectedPlan.extraUserPrice.Year : selectedPlan.extraUserPrice.Month);
    const [openSocialAccountQuantityPicker, setOpenSocialAccountQuantityPicker] = useState(false);
    const [currentPricingData, setCurrentPricingData] = useState(null);

    useEffect(() => {
        const scrollToHash = () => {
            const hash = window?.location?.hash?.substr(1);
            if (hash) {
                const element = document.getElementById(hash);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth", block: 'start' });
                }
            }
        };
        setTimeout(scrollToHash, 0);

        return () => {
            // No need to remove anything
        };
    }, []);


    const refreshLoggedInUserData = () => {
        APIService.fetchLatestMe((latestMeResponse, error) => {
            if (error) {
                toast.error(error, { theme: "colored" });
                if (
                    error
                        .toLowerCase()
                        .includes("Invalid credentials provided".toLowerCase())
                ) {
                    window.localStorage.clear();
                    setTimeout(() => {
                        navigate(RoutePaths.AUTHENTICATION);
                    }, 100);
                }
                return;
            }
            let latestUserData = latestMeResponse.data;
            setActivePlan(latestUserData.active_plan);
            dispatch(setLoggedInUser({ ...latestUserData }));
            // checkAndLazyUpdateActiveWorkspace(latestUserData);
        });
    };

    const decipherAllActivePlans = () => {
        let plans = [];
        const activePlan = loggedInUser.active_plan;
        // console.log("🚀 ~ decipherAllActivePlans ~ loggedInUser:", loggedInUser)
        if (activePlan?.Key?.toLowerCase() !== 'free') {
            plans.push({
                plan: activePlan,
                type: "active"
            });
        }
        const AIActivePlan = loggedInUser?.active_ai_writer_subscription;
        if (AIActivePlan) {
            let newPlanObj = { ...AIActivePlan, Key: 'Unlimited AI', Name: 'Postly AI' }
            plans.push({
                plan: newPlanObj,
                type: "active_unlimited_ai"
            });
        }
        const extraPlans = loggedInUser?.plans?.active;
        for (const p in extraPlans) {
            const actualPlan = extraPlans[p];
            if (actualPlan?.Key?.toLowerCase() !== 'free') {
                plans.push({
                    plan: extraPlans[p],
                    type: "extra"
                });
            }
        }
        return plans
    }

    const pollSubscriptionStatus = (heartbeat, pricingData, type, done) => {
        const intervalId = setInterval(() => {
            APIService.fetchLatestMe((latestMeResponse, error) => {
                if (error) {
                    toast.error(error, { theme: "colored" });
                    if (
                        error
                            .toLowerCase()
                            .includes("Invalid credentials provided".toLowerCase())
                    ) {
                        window.localStorage.clear();
                        setTimeout(() => {
                            navigate(RoutePaths.LOGIN);
                        }, 100);
                    }
                    return;
                }
                const localUserPlan = loggedInUser.active_plan;
                let latestUserData = latestMeResponse.data;
                const newPlan = latestUserData.active_plan;
                const referencePlan = pricingData.plan;
                if (type === "new_subscription" && newPlan.Key === referencePlan) {
                    clearInterval(intervalId);
                    done(latestUserData);
                } else if (type === "new_subscription" && pricingData.for_ai_writer) {
                    const activeAIWriterSubscription =
                        latestUserData.active_ai_writer_subscription;
                    if (activeAIWriterSubscription != null) {
                        clearInterval(intervalId);
                        done(latestUserData);
                    }
                } else if (
                    type === "new_subscription" &&
                    pricingData.for_telegram_bot
                ) {
                    const currentWorkspace = workspace;
                    const existingBotSlots = currentWorkspace.creatable_bot_slots ?? 0;
                    APIService.fetchWorkSpace(currentWorkspace._id, (res, err) => {
                        if (err) {
                            clearInterval(intervalId);
                            done(latestUserData);
                            return;
                        }
                        const latestWorkspaceData = res.data;
                        const newBotSlots = latestWorkspaceData.creatable_bot_slots ?? 0;
                        if (newBotSlots > existingBotSlots) {
                            dispatch(setActiveWorkspace({ ...latestWorkspaceData }));
                            clearInterval(intervalId);
                            done();
                        }
                    });
                } else if (
                    type === "additional_social_accounts" &&
                    newPlan.Key === referencePlan
                ) {
                    const existingCount = parseInt(localUserPlan.SocialAccounts);
                    const newCount = parseInt(newPlan.SocialAccounts);
                    if (!isNaN(existingCount) && !isNaN(newCount)) {
                        if (newCount > existingCount) {
                            clearInterval(intervalId);
                            done(latestUserData, newCount - existingCount);
                        }
                    }
                }
            });
        }, heartbeat);
    };

    const handleSubscription = (pricingData, user, _price) => {
        const Paddle = window.Paddle;
        const oneOff = pricingData.one_off ?? false;
        Paddle.Checkout.open({
            quantity: pricingData.SocialAccounts ?? 1,
            product: parseInt(
                billedYearly ? pricingData.AnnualSub : pricingData.MonthlySub
            ),
            email: user?.email,
            plan: pricingData.plan,
            allowQuantity: false,
            passthrough: JSON.stringify({
                accountId: user._id,
                subscriptionMode: billedYearly ? "Yearly" : "Monthly",
                workspaceId: user.workSpaceId,
                plan: pricingData.plan,
                quantity: pricingData.SocialAccounts ?? 1,
                SocialAccounts: pricingData.SocialAccounts ?? 1,
                prorate: true,
                prorated_keys: ["SocialAccounts"],
                coupon: pricingData.coupon,
                for_ai_writer: pricingData.for_ai_writer,
                for_telegram_bot: pricingData.for_telegram_bot,
            }),
            successCallback: () => {
                setProcessingPayment(true);
                pollSubscriptionStatus(
                    2000,
                    pricingData,
                    "new_subscription",
                    (data) => {
                        if (data) {
                            dispatch(setLoggedInUser({ ...data }));
                            // checkAndLazyUpdateActiveWorkspace(data);
                            setProcessingPayment(false);
                            setCheckoutCompletedMessage(
                                oneOff
                                    ? "Payment successful"
                                    : `You have successfully subscribed to the ${pricingData.title} plan`
                            );
                            if (!openCheckoutCompletedPopup) {
                                setOpenCheckoutCompletedPopup(true);
                            }
                        }
                    }
                );
            },
        });
    };

    const handleBuyExtraUserSubscription = (pricingData, user, quantity) => {
        const Paddle = window.Paddle;
        const oneOff = pricingData.one_off ?? false;
        Paddle.Checkout.open({
            quantity: quantity ?? 1,
            product: parseInt(
                billedYearly ? pricingData.AnnualSub : pricingData.MonthlySub
            ),
            email: user?.email,
            plan: pricingData.plan,
            allowQuantity: false,
            passthrough: JSON.stringify({
                accountId: user._id,
                subscriptionMode: billedYearly ? "Yearly" : "Monthly",
                workspaceId: user.workSpaceId,
                plan: pricingData.plan,
                quantity: quantity ?? 1,
                prorate: true,
                coupon: pricingData.coupon,
                for_postly_extra_user: pricingData.for_postly_extra_user,
            }),
            successCallback: () => {
                setProcessingPayment(true);
                pollSubscriptionStatus(
                    2000,
                    pricingData,
                    "new_subscription",
                    (data) => {
                        if (data) {
                            dispatch(setLoggedInUser({ ...data }));
                            // checkAndLazyUpdateActiveWorkspace(data);
                            setProcessingPayment(false);
                            setCheckoutCompletedMessage(
                                oneOff
                                    ? "Payment successful"
                                    : `You have successfully subscribed to the ${pricingData.title} plan`
                            );
                            if (!openCheckoutCompletedPopup) {
                                setOpenCheckoutCompletedPopup(true);
                            }
                        }
                    }
                );
            },
        });

    }

    const buyMoreSocialAccounts = (pricingData, user, _price) => {
        const Paddle = window.Paddle;
        const extraSocialAccountPlan = extraSocialAccounts.find(
            (x) => x.LinkedPlan === pricingData.plan
        );
        Paddle.Checkout.open({
            product: parseInt(
                billedYearly
                    ? extraSocialAccountPlan.AnnualSub
                    : extraSocialAccountPlan.MonthlySub
            ),
            email: user?.email,
            quantity: parseInt(socialAccountQuantity),
            plan: extraSocialAccountPlan.plan,
            allowQuantity: false,
            passthrough: JSON.stringify({
                accountId: user._id,
                subscriptionMode: billedYearly ? "Yearly" : "Monthly",
                workspaceId: user.workSpaceId,
                plan: extraSocialAccountPlan.plan,
                linkedInPlan: pricingData.plan,
                linkedToExistingPlan: true,
                quantity: parseInt(socialAccountQuantity),
                SocialAccounts: parseInt(socialAccountQuantity),
                prorate: true,
                planName: extraSocialAccountPlan.title,
                prorated_keys: ["SocialAccounts"],
                offering: `${parseInt(socialAccountQuantity)} social accounts`,
                coupon: pricingData.coupon,
                downgradable: false,
                assignable: false,
            }),
            successCallback: () => {
                setProcessingPayment(true);
                pollSubscriptionStatus(
                    2000,
                    pricingData,
                    "additional_social_accounts",
                    (data, diff) => {
                        if (data) {
                            dispatch(setLoggedInUser({ ...data }));
                            // checkAndLazyUpdateActiveWorkspace(data);
                            setProcessingPayment(false);
                            setCheckoutCompletedMessage(
                                `You have successfully bought ${diff} more social accounts`
                            );
                            if (!openCheckoutCompletedPopup) {
                                setOpenCheckoutCompletedPopup(true);
                            }
                        }
                    }
                );
            },
        });
    };

    useEffect(() => {
        let paymentFor = query.get("payment_for");
        if (paymentFor) {
            try {
                let data = JSON.parse(decodeURIComponent(paymentFor));
                let { pricingData, coupon, user } = data;
                if (coupon) {
                    pricingData.coupon = coupon;
                }
                if (pricingData) {
                    handleSubscription(pricingData, user);
                }
            } catch (e) { }
        }
    }, [query]);

    useEffect(() => {
        refreshLoggedInUserData();
    }, []);

    useEffect(() => {
        let checkoutSessionListener = (data) => {
            let { error, one_off, cancellation, message, plan = "Premium" } = data;
            if (error) {
                toast.error(error, { theme: "colored" });
                return;
            }
            if (!cancellation) {
                if (openCheckoutCompletedPopup) {
                    return;
                }
                APIService.fetchLatestMe((latestMeResponse, error) => {
                    if (error) {
                        toast.error(error, { theme: "colored" });
                        if (
                            error
                                .toLowerCase()
                                .includes("Invalid credentials provided".toLowerCase())
                        ) {
                            window.localStorage.clear();
                            setTimeout(() => {
                                navigate(RoutePaths.LOGIN);
                            }, 100);
                        }
                        return;
                    }
                    let latestUserData = latestMeResponse.data;
                    dispatch(setLoggedInUser({ ...latestUserData }));
                    // checkAndLazyUpdateActiveWorkspace(latestUserData);
                    setProcessingPayment(false);
                    setCheckoutCompletedMessage(
                        one_off
                            ? message
                            : `You have successfully subscribed to the ${plan} plan`
                    );
                    setOpenCheckoutCompletedPopup(true);
                });
            } else {
                navigate(RoutePaths.DEFAULT);
                window.location.reload();
            }
        };
        addPubSubEventListener(
            `${loggedInUser?._id}-billing-status`,
            checkoutSessionListener
        );
        return () => {
            removePubSubEventListener(
                `${loggedInUser?._id}-billing-status`,
                checkoutSessionListener
            );
        };
    }, []);

    const cancelSubscription = (e) => {
        let activePlan = workspace.active_plan;
        if (activePlan.cancel_url) {
            window.location = activePlan.cancel_url;
        } else {
            setCancellingAIWriterSubscription(true);
            APIService.cancelSubscription((response, error) => {
                if (error) {
                    setCancellingSubscription(false);
                    toast.error(error, { theme: "colored" });
                    return;
                }
                let { message, data } = response;
                dispatch(setLoggedInUser({ ...data }));
                // checkAndLazyUpdateActiveWorkspace(data);
                toast.success(message, { theme: "colored" });
                setCancellingSubscription(false);
                setTimeout(() => {
                    window.location = window.location.href.split("?")[0];
                    window.location.reload();
                }, 500);
            });
        }
    };

    const findCurrentPlanFromPricingTable = (planKey) => {
        const currentPlan = pricingDataNew.find(plan => plan.plan === planKey);
        return currentPlan || null;
    };

    useEffect(() => {
        const planKeyToFind = activePlan.Key;
        const plan = findCurrentPlanFromPricingTable(planKeyToFind);
        setCurrentPricingData(plan);
    }, [activePlan.Key]);

    const cancelSubscriptionForScheduler = (e) => {
        let activePlan = workspace.active_plan;
        if (activePlan.cancel_url) {
            window.location = activePlan.cancel_url;
        } else {
            setCancellingAIWriterSubscription(true);
            APIService.cancelSubscription((response, error) => {
                if (error) {
                    setCancellingSubscription(false);
                    toast.error(error, { theme: "colored" });
                    return;
                }
                let { message, data } = response;
                dispatch(setLoggedInUser({ ...data }));
                // checkAndLazyUpdateActiveWorkspace(data);
                toast.success(message, { theme: "colored" });
                setCancellingSubscription(false);
                setTimeout(() => {
                    window.location = window.location.href.split("?")[0];
                    window.location.reload();
                }, 500);
            });
        }
    };

    const cancelAISubscription = (e) => {
        let activeAISubscription = loggedInUser.active_ai_writer_subscription;
        if (activeAISubscription.tied_to_plan) {
            setOpenJoinedAIWriterCancellationPrompt(true);
            return;
        }
        if (activeAISubscription.cancel_url) {
            window.location = activeAISubscription.cancel_url;
        } else {
            setCancellingAIWriterSubscription(true);
            APIService.cancelAISubscription((response, error) => {
                if (error) {
                    setCancellingAIWriterSubscription(false);
                    toast.error(error, { theme: "colored" });
                    return;
                }
                let { message, data } = response;
                dispatch(setLoggedInUser({ ...data }));
                toast.success(message, { theme: "colored" });
                setCancellingAIWriterSubscription(false);
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            });
        }
    };

    const hashUpdatableUrl = () => {
        let cancelUrl = activePlan.cancel_url;
        let updateUrl = activePlan.update_url;
        if (updateUrl) {
            return updateUrl;
        }
        if (cancelUrl) {
            return cancelUrl.replace("cancel", "update");
        }
        return null;
    };

    const updatableUrl = hashUpdatableUrl();

    const getTeamMates = () => {
        APIService.fetchTeam(workspace['_id'], (response, error) => {
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let data = response['data'];
            let _numberOfValidTeamMembers = 0;
            for (let teamMember of data) {
                if (teamMember.user_id) {
                    _numberOfValidTeamMembers++;
                }
            }
            setNumberOfValidTeamMembers(_numberOfValidTeamMembers)
        });
    }

    useEffect(() => {
        getTeamMates()
    }, [])

    const subscribeToAIWriterUnlimited = (pricingData, user) => {
        handleSubscription({ ...pricingData, for_ai_writer: true }, user);
    };

    const handleClickOpenUserQuantityPicker = () => {
        setOpenUserQuantityPicker(true);
    };

    const hashUpdatableUrlForScheduler = () => {
        let cancelUrl = loggedInUser?.active_plan?.cancel_url;
        let updateUrl = loggedInUser?.active_plan?.update_url;
        if (updateUrl) {
            return updateUrl;
        }
        if (cancelUrl) {
            return cancelUrl.replace("cancel", "update");
        }
        return null;
    };

    const updatableUrlForScheduler = hashUpdatableUrlForScheduler();

    const displaySchedulerBillingTabs = pricingDataNew?.map((x, i) => {
        const thisIsActivePlan = ((x?.Key === loggedInUser?.active_plan?.Key && x?.Name === loggedInUser?.active_plan?.Name) || (x?.plan === loggedInUser?.active_plan?.Key))
        return (
            <PlanCard
                key={i}
                data={x}
                pricing={x}
                billedYearly={billedYearly}
                activePlanFromDB={activePlan}
                updatableUrl={thisIsActivePlan ? updatableUrlForScheduler : null}
                handleSubscriptionCancellation={() => {
                    setOpenSubscriptionCancelPrompt(true);
                }}
                hasActiveSubscription={thisIsActivePlan ? loggedInUser?.active_plan : null}
                handleBilling={(data) => {
                    if (!isFreePlan(loggedInUser.active_plan)) {
                        setOpenPreCancellationDialog(true);
                        return;
                    }
                    handleSubscription(data, {
                        ...loggedInUser,
                        workSpaceId: workspace._id,
                    });
                }}
                billingTag={'scheduler'}
            />
        )
    })

    const hashUpdatableUrlForAI = () => {
        let activeSubscription = loggedInUser?.active_ai_writer_subscription ?? {};
        let cancelUrl = activeSubscription.cancel_url;
        let updateUrl = activeSubscription.update_url;
        if (updateUrl) {
            return updateUrl;
        }
        if (cancelUrl) {
            return cancelUrl.replace("cancel", "update");
        }
        return null;
    };

    const updatableUrlAIPaymentDetails = hashUpdatableUrlForAI();

    const displayAIBillingTabs = AIWriterPricing?.map((x, i) => {
        return (
            <PlanCard
                key={i}
                billingTag={'postly_AI'}
                data={x}
                pricing={x}
                activePlanFromDB={activePlan}
                updatableUrl={updatableUrlAIPaymentDetails}
                handleSubscriptionCancellation={() => {
                    setOpenAIWriterSubscriptionCancelPrompt(true);
                }}
                hasActiveSubscription={loggedInUser?.active_ai_writer_subscription}
                handleBilling={(data) => {
                    subscribeToAIWriterUnlimited(
                        {
                            ...x,
                            ai_writer_subscription: true,
                        },
                        loggedInUser
                    );
                }}
            />
        )
    })

    const hashUpdatableUrlForPostlyCloud = () => {
        let plan = loggedInUser?.plans?.active?.postly_cloud;
        if (plan) {
            let updateUrl = plan?.cancel_url || null
            let cancelUrl = plan?.cancel_url || null
            return {
                updateUrl: updateUrl,
                cancelUrl: cancelUrl,
            }
        }
    };

    const updatableUrlForPostlyCloud = hashUpdatableUrlForPostlyCloud();

    const displayCloudBillingTabs = postlyCloudPricing?.map((x, i) => {
        const thisIsActivePlan = ((x?.Key === loggedInUser?.plans?.active?.postly_cloud?.Key && x?.Name === loggedInUser?.plans?.active?.postly_cloud?.Name) || (x?.plan === loggedInUser?.plans?.active?.postly_cloud?.Key))

        return (
            <PlanCard
                key={i}
                data={x}
                billingTag={'postly_cloud'}
                pricing={x}
                activePlanFromDB={activePlan}
                updatableUrl={thisIsActivePlan && updatableUrlForPostlyCloud?.updateUrl}
                handleSubscriptionCancellation={() => {
                    const url = updatableUrlForPostlyCloud?.cancelUrl
                    if (url) {
                        window.open(url, '_blank');
                        return;
                    }
                    toast.error(`Sorry, technical issues. Please contact support.`)
                }}
                hasActiveSubscription={thisIsActivePlan && (loggedInUser?.plans?.active?.postly_cloud?.Name && loggedInUser?.plans?.active?.postly_cloud?.Name?.toLowerCase() !== 'free')}
                handleBilling={(data) => {
                    if (!isFreePostlyCloud(loggedInUser)) {
                        setOpenPreCancellationDialog(true);
                        return;
                    }
                    handleSubscription(data, {
                        ...loggedInUser,
                        workSpaceId: workspace._id,
                    });
                }}

            />
        )
    })

    const hashUpdatableUrlForWhiteLabel = () => {
        let activeSubscription = loggedInUser?.plans?.active?.postly_white_label?.Key?.toLowerCase() !== 'free' ? loggedInUser?.plans?.active?.postly_white_label : false ?? false;
        if (activeSubscription) {
            let updateUrl = activeSubscription?.cancel_url || null
            let cancelUrl = activeSubscription?.cancel_url || null
            return {
                updateUrl: updateUrl,
                cancelUrl: cancelUrl,
            }
        } else {
            return null
        }
    };

    const updatableUrlWhiteLabelPaymentDetails = hashUpdatableUrlForWhiteLabel();

    const displayAddOnsBillingTabs = AddOnsPlans?.map((x, i) => {
        const thisIsActiveWhiteLabelPlan = ((x?.Key === loggedInUser?.plans?.active?.postly_cloud?.Key && x?.Name === loggedInUser?.plans?.active?.postly_white_label?.Name) || (x?.plan === loggedInUser?.plans?.active?.postly_white_label?.Key))

        return (
            <PlanCardAdvance
                key={i}
                data={x}
                addOns={true}
                updatableUrl={thisIsActiveWhiteLabelPlan && updatableUrlWhiteLabelPaymentDetails?.updateUrl}
                handleSubscriptionCancellation={() => {
                    const url = updatableUrlWhiteLabelPaymentDetails?.cancelUrl
                    if (url) {
                        window.open(url, '_blank');
                        return;
                    }
                    toast.error(`Sorry, technical issues. Please contact support.`)
                }}
                hasActiveSubscription={thisIsActiveWhiteLabelPlan && (loggedInUser?.plans?.active?.postly_white_label?.Name && loggedInUser?.plans?.active?.postly_white_label?.Name?.toLowerCase() !== 'free')}
                handleSubscribe={() => {
                    if (isFreeWorkspace(workspace)) {
                        dispatch(setPopupUpgradeAlert(true))
                        return;
                    }
                    if (x.plan === 'AddOrRemoveSocialAccount') {
                        handleClickOpenSocialAccountQuantityPicker(currentPricingData.id)
                    }
                    else if (x?.plan === 'PostlyWhiteLabelPremium') {
                        navigate(RoutePaths.WHITELABEL_SETTINGS);
                    }
                    else {
                        handleClickOpenUserQuantityPicker()
                    }
                }}
            />
        )
    })

    const displayWhiteLabelBillingTabs = WhiteLabelPlan?.map((x, i) => {
        const thisIsActiveWhiteLabelPlan = ((x?.Key === loggedInUser?.plans?.active?.postly_cloud?.Key && x?.Name === loggedInUser?.plans?.active?.postly_white_label?.Name) || (x?.plan === loggedInUser?.plans?.active?.postly_white_label?.Key))

        return (
            <PlanCardAdvance
                key={i}
                data={x}
                addOns={false}
                updatableUrl={thisIsActiveWhiteLabelPlan && updatableUrlWhiteLabelPaymentDetails?.updateUrl}
                handleSubscriptionCancellation={() => {
                    const url = updatableUrlWhiteLabelPaymentDetails?.cancelUrl
                    if (url) {
                        window.open(url, '_blank');
                        return;
                    }
                    toast.error(`Sorry, technical issues. Please contact support.`)
                }}
                hasActiveSubscription={thisIsActiveWhiteLabelPlan && (loggedInUser?.plans?.active?.postly_white_label?.Name && loggedInUser?.plans?.active?.postly_white_label?.Name?.toLowerCase() !== 'free')}
                handleSubscribe={() => {
                    if (isFreeWorkspace(workspace)) {
                        dispatch(setPopupUpgradeAlert(true))
                        return;
                    }
                    if (x.plan === 'AddOrRemoveSocialAccount') {
                        handleClickOpenSocialAccountQuantityPicker(currentPricingData.id)
                    }
                    else if (x?.plan === 'PostlyWhiteLabelPremium') {
                        navigate(RoutePaths.WHITELABEL_SETTINGS);
                    }
                    else {
                        handleClickOpenUserQuantityPicker()
                    }
                }}
            />
        )
    })

    const getSchedulerPlanAnalytics = () => {
        let activePlan = loggedInUser?.active_plan;
        let activePlanName = activePlan?.Name;
        if (activePlanName?.toLowerCase() === 'free' || !activePlan) {
            return {
                name: activePlanName || 'Free',
                message: `Upgrade to enjoy unlimited workspaces and scheduled posts.
                `,
            }
        }
        return {
            name: activePlanName,
            Key: activePlan?.Key,
            cancel_url: activePlan?.cancel_url,
            update_url: activePlan?.update_url,
            nextBillingDate: activePlan?.next_bill_date
        }
    }
    const getAIPlanAnalytics = () => {
        let activePlan = loggedInUser?.active_ai_writer_subscription;
        let activePlanName = activePlan?.Name;
        if (activePlanName?.toLowerCase() === 'free' || !activePlan) {
            return {
                name: activePlanName || 'Free',
                message: `Upgrade to enjoy unlimited AI access.`,
            }
        }
        return {
            name: activePlanName,
            Key: activePlan?.Key,
            cancel_url: activePlan?.cancel_url,
            update_url: activePlan?.update_url,
            nextBillingDate: activePlan?.next_bill_date
        }
    }
    const getPostlyCloudPlanAnalytics = () => {
        let activePlan = loggedInUser.plans.active
        let activePlanName = activePlan?.postly_cloud?.["Name"];
        if (isFreePostlyCloud(loggedInUser) || !activePlan) {
            return {
                name: activePlanName || 'Free',
                message: `Upgrade to enjoy more storage and bulk post processing.`,
            }
        }
        return {
            name: activePlanName,
            Key: activePlan?.Key,
            cancel_url: activePlan?.cancel_url,
            update_url: activePlan?.update_url,
            nextBillingDate: activePlan?.next_bill_date
        }
    }

    const prepareLTDCard = () => {
        const isOwner = isLoggedInUserOwnerOfWorkspace(loggedInUser, workspace);
        // if (!isOwner) {
        //     return <InfoBar
        //         info={`You are not authorized to view this information as it is exclusively for the workspace owner`}
        //     />
        // }
        if (loggedInUser?.active_plan?.Workspace < 189) {
            return <Flex
                gap={2}
                wrap={'wrap'}
                justifyContent={'left'}
                className={'mb-3'}
            >
                <LtdSpecialUpgradePlanUnEligible
                    // highlighted={true}
                    title={getSchedulerPlanAnalytics()?.name}
                    subTitle={""}
                    buttonText="Cancel your subscription"
                    currencySign={"$"}
                    price={{
                        Month: 497,
                        Year: 497
                    }}
                    handleSubscriptionCancellation={() => {
                        setOpenSubscriptionCancelPrompt(true);
                    }}
                    features={[
                        { id: 1, title: `${loggedInUser?.active_plan?.Workspace} Workspaces` },
                        { id: 2, title: `${loggedInUser?.active_plan?.SocialAccounts} Social Accounts` },
                        { id: 3, title: `${loggedInUser?.active_plan?.ScheduledPosts} Posts` },
                        { id: 4, title: `${loggedInUser?.active_plan?.AI_Writer_Chars} AI characters/month` },
                        { id: 5, title: `500 bulk posts/month` },
                        // { id: 6, title: `No automation` },
                        { id: 6, title: `Single approvals` },
                        // { id: 8, title: `10 active recurring posts` },
                    ]}
                />
                <LtdSpecialUpgradePlanCard
                    highlighted={true}
                    title="LTD Upgrade (one time fee)"
                    subTitle={"Get 200 workspaces and unlimited social accounts"}
                    buttonText="Upgrade"
                    cancelText="Cancel your subscription"
                    currencySign={"$"}
                    price={{
                        Month: 999,
                        Year: 999
                    }}
                    handleUpgrade={() => {
                        const pricingData = ltdUpgradePricingData.find(
                            (x) => x.plan === "LTD UPGRADE"
                        );
                        handleSubscription(pricingData, { ...loggedInUser });
                    }}
                    features={[
                        { id: 1, title: '200 Workspaces' },
                        { id: 2, title: 'Unlimited Social Accounts' },
                        { id: 3, title: 'Unlimited Posts' },
                        { id: 4, title: '100k AI characters/month' },
                        { id: 5, title: '30 days money back guarantee' },
                        { id: 5, title: `Bulk posts` },
                        // { id: 6, title: `Access to automation` },
                        { id: 6, title: `Multiple approvals` },
                        // { id: 8, title: `Unlimited active recurring posts` },
                    ]}
                />
            </Flex>
        } else {
            return <Flex
                gap={2}
                wrap={'wrap'}
                justifyContent={'left'}
                className={'mb-3'}
            >
                <LtdSpecialUpgradePlanCard
                    highlighted={true}
                    title="LTD Upgrade (one time fee)"
                    subTitle={"Get 200 workspaces and unlimited social accounts"}
                    buttonText="Upgrade"
                    currencySign={"$"}
                    price={{
                        Month: 497,
                        Year: 497
                    }}
                    handleUpgrade={() => {
                        const pricingData = ltdUpgradePricingData.find(
                            (x) => x.plan === "LTD UPGRADE"
                        );
                        handleSubscription(pricingData, { ...loggedInUser });
                    }}
                    features={[
                        { id: 1, title: '200 Workspaces' },
                        { id: 2, title: 'Unlimited Social Accounts' },
                        { id: 3, title: 'Unlimited Posts' },
                        { id: 4, title: '100k AI characters/month' },
                        { id: 5, title: '30 days money back guarantee' },
                        { id: 5, title: `Bulk posts` },
                        { id: 6, title: `Access to automation` },
                        { id: 7, title: `Multiple approvals` },
                    ]}
                />
            </Flex>
        }
    }

    const handleNumberOfSocualAccountChange = (event) => {
        const value = parseInt(event.target.value);
        dispatch(setSocialAccountQuantity(event.target.value));
        if (value >= 0) {
            setFormData({
                ...formData,
                numberOfUsers: value,
            });
        } else {
            setFormData({
                ...formData,
                numberOfUsers: "",
            });
        }
    };

    const buyExtraUsers = (pricingData, user) => {
        handleBuyExtraUserSubscription({ ...pricingData, for_postly_extra_user: true }, user, formData.numberOfUsers);
    };

    const handleClickOpenSocialAccountQuantityPicker = (planType) => {
        if (typeof planType === "number" && (planType >= 0 && planType <= 5)) {
            setOpenSocialAccountQuantityPicker(true);
            setPlanType(parseInt(planType));
        }
    };

    const handleAddSubscriptionQuantity = (data) => {
        buyMoreSocialAccounts(data, {
            ...loggedInUser,
            workSpaceId: workspace._id,
        });
    }



    return (
        <>
            {/* <Card className="mb-3">
                <Card.Body
                    className="bg-light"
                    style={{
                        width: '100%'
                    }}
                >
                    <Flex
                        gap={2}
                        style={{
                            width: '100%'
                        }}
                    >

                        BILLING ANALYTICS GOES HERE........
                    </Flex>
                </Card.Body>
            </Card> */}

            <ActivePlans
                activePlans={decipherAllActivePlans()}
                setLoggedInUser={setLoggedInUser}
            />

            <Card className="mb-3">
                <Card.Body
                    className="bg-light"
                    style={{
                        width: '100%'
                    }}
                >

                    {
                        isAccountUnderAppSumo(loggedInUser) ? <Flex
                            justifyContent={'start'}
                            gap={5}
                            style={{
                                width: '100%',
                            }}
                        >
                            <Flex
                                gap={2}
                                style={{
                                    // width: '100%',
                                    fontWeight: 700,
                                }}
                                id="schedulerSection001"
                            >

                                LIFE-TIME DEAL  <FontAwesomeIcon
                                    icon="crown"
                                    className="text-success"
                                    style={{
                                        marginTop: 3
                                    }}
                                />
                            </Flex>
                        </Flex> : <Flex
                            justifyContent={'start'}
                            gap={5}
                            style={{
                                width: '100%',
                            }}
                        >
                            <Flex
                                gap={2}
                                alignItems={'center'}
                                style={{
                                    // width: '100%',
                                    fontWeight: 700
                                }}
                                id="schedulerSection001"
                            >

                                SCHEDULER PLANS  <FontAwesomeIcon
                                    icon="crown"
                                    className="text-success"
                                // style={{
                                //     marginTop: 3
                                // }}
                                />

                                <div
                                    style={{
                                        marginTop: 10
                                    }}
                                >
                                    <MonthlyYearlySwitch />
                                </div>

                            </Flex>
                        </Flex>
                    }

                </Card.Body>
            </Card>
            {
                isAccountUnderAppSumo(loggedInUser) ? prepareLTDCard()
                    :
                    <Flex
                        gap={2}
                        wrap={'wrap'}
                        justifyContent={'left'}
                        className={'mb-3'}
                    >
                        {displaySchedulerBillingTabs}
                        {displayAddOnsBillingTabs}
                    </Flex>
            }
            <Card className="mb-3 mt-5">
                <Card.Body
                    className="bg-light"
                    style={{
                        width: '100%'
                    }}
                >
                    <Flex
                        justifyContent={'start'}
                        gap={5}
                        style={{
                            width: '100%',
                        }}

                    >
                        <Flex
                            gap={2}
                            alignItems={'center '}
                            style={{
                                // width: '100%',
                                fontWeight: 700
                            }}
                            id="AISection001"
                        >

                            POSTLY AI PLANS  <FontAwesomeIcon
                                icon="crown"
                                className="text-success"
                            // style={{
                            //     marginTop: 3
                            // }}
                            />

                            <div
                                style={{
                                    marginTop: 10
                                }}
                            >
                                <MonthlyYearlySwitch />
                            </div>
                        </Flex>
                    </Flex>
                </Card.Body>
            </Card>
            <Flex
                gap={2}
                wrap={'wrap'}
                justifyContent={'left'}
                className={'mb-3'}
            >
                {displayAIBillingTabs}
            </Flex>
            <Card className="mb-3 mt-5">
                <Card.Body
                    className="bg-light"
                    style={{
                        width: '100%'
                    }}
                >
                    <Flex
                        justifyContent={'start'}
                        gap={5}
                        style={{
                            width: '100%',
                        }}
                    >
                        <Flex
                            gap={2}
                            alignItems={'center'}
                            style={{
                                // width: '100%',
                                fontWeight: 700
                            }}
                        >

                            POSTLY CLOUD PLANS  <FontAwesomeIcon
                                icon="crown"
                                className="text-success"
                            // style={{
                            //     marginTop: 3
                            // }}
                            />
                            <div
                                style={{
                                    marginTop: 10
                                }}
                            >
                                <MonthlyYearlySwitch />
                            </div>
                        </Flex>
                    </Flex>
                </Card.Body>
            </Card>
            <Flex
                gap={2}
                wrap={'wrap'}
                justifyContent={'left'}
                className={'mb-3'}
            >
                {displayCloudBillingTabs}
            </Flex>
            <Card className="mb-3 mt-5">
                <Card.Body
                    className="bg-light"
                    style={{
                        width: '100%'
                    }}
                >
                    <Flex
                        gap={2}
                        alignItems={'center'}
                        style={{
                            width: '100%',
                            fontWeight: 700
                        }}
                    >

                        WHITE LABEL  <FontAwesomeIcon
                            icon="crown"
                            className="text-success"
                        // style={{
                        //     marginTop: 3
                        // }}
                        />
                        <div
                            style={{
                                marginTop: 10
                            }}
                        >
                            <MonthlyYearlySwitch />
                        </div>
                    </Flex>
                </Card.Body>
            </Card>
            <Flex
                gap={2}
                wrap={'wrap'}
                justifyContent={'left'}
                className={'mb-3'}
            >
                {displayWhiteLabelBillingTabs}
            </Flex>
            <ConfirmModal
                open={openJoinedAIWriterCancellationPrompt}
                onCancel={() => {
                    setOpenJoinedAIWriterCancellationPrompt(false);
                }}
                title="Cancel your subscription?"
                message="Your AI Writer Unlimited Subscription is currently tied to your active plan. Kindly use the Cancel Subscription option on your active plan instead"
                cancelText="CLOSE"
                confirmText="OKAY"
                onConfirm={() => {
                    setOpenJoinedAIWriterCancellationPrompt(false);
                }}
            />
            <ConfirmModal
                open={openSubscriptionCancelPrompt}
                onCancel={() => {
                    setOpenSubscriptionCancelPrompt(false);
                }}
                title="Cancel your subscription?"
                message="This will cancel your current subscription and downgrade your account to the free plan."
                cancelText="NO"
                confirmText="CONTINUE"
                onConfirm={() => {
                    setOpenSubscriptionCancelPrompt(false);
                    // cancelSubscription();
                    cancelSubscriptionForScheduler()
                }}
            />
            <ConfirmModal
                open={openAIWriterSubscriptionCancelPrompt}
                onCancel={() => {
                    setOpenAIWriterSubscriptionCancelPrompt(false);
                }}
                title="Cancel your Unlimited AI subscription"
                message="This will cancel your current Unlimited AI subscription and downgrade your Unlimited AI quota to the free plan."
                cancelText="NO"
                confirmText="CONTINUE"
                onConfirm={() => {
                    setOpenAIWriterSubscriptionCancelPrompt(false);
                    cancelAISubscription();
                }}
            />
            <ConfirmModal
                open={openPrecancellationDialog}
                onCancel={() => {
                    setOpenPreCancellationDialog(false);
                }}
                title="Cancel existing subscription"
                message="You have an existing subscription, please cancel it before changing your plan. Please note that all your scheduled posts will continue to be published, however, you need to reconnect any disconnected platforms."
                cancelText="CLOSE"
                confirmText="OK"
                onConfirm={() => {
                    setOpenPreCancellationDialog(false);
                }}
            />
            <ConfirmModal
                open={openUpgradeDialog}
                title={"Action requires Upgrade"}
                message={"Sorry, this requires a premium account. Upgrade your plan to use this feature. Thank you!"}
                confirmText={"UPGRADE"}
                cancelText={"CANCEL"}
                onCancel={() => {
                    setOpenUpgradeDialog(false);
                }}
                onConfirm={() => {
                    setOpenUpgradeDialog(false);
                    navigate(`${RoutePaths.BILLING_STRIPE}#scheduler_section`);
                }}
            />
            <Modal
                show={openCheckoutCompletedPopup}
                onHide={() => {
                    setOpenCheckoutCompletedPopup(false);
                    navigate(RoutePaths.DEFAULT);
                    window.location.reload();
                }}
                centered
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>Subscription Successful!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Flex
                        alignItems={"center"}
                        className={"gap-2"}
                        justifyContent={"center"}
                        direction={"column"}
                    >
                        <Checkmark size="96px" />
                        <span>{checkoutCompletedMessage}</span>
                    </Flex>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            setOpenCheckoutCompletedPopup(false);
                            navigate(RoutePaths.DEFAULT);
                            window.location.reload();
                        }}
                    >
                        Continue
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={processingPayment}
                onHide={() => {
                    setProcessingPayment(false);
                    toast.info("Your new plan will reflect after verification", {
                        theme: "colored",
                    });
                }}
                onExit={() => {
                    setProcessingPayment(false);
                }}
                centered
            >
                <Modal.Body>
                    <Flex alignItems={"center"} justifyContent={"center"}>
                        <Flex
                            className={"gap-2"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            direction={"column"}
                        >
                            <Spinner animation="border" />
                            <span>Verifying. Please wait...</span>
                        </Flex>
                    </Flex>
                </Modal.Body>
            </Modal>

            <Modal
                show={openUserQuantityPicker}
                onHide={() => setOpenUserQuantityPicker(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Buy extra user seats
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        // isLTD(currentPlan) ? (
                        //   <p>You are not eligible for this request</p>
                        // ) : 
                        (
                            <>
                                <MonthlyYearlySwitch />
                                <hr />
                                {planType !== 2 && (
                                    <span className="mt-0 fs-0 fs-sm-0 fs-lg-0 px-2">
                                        Your Active Plan: <b>{currentPlan["Name"]}</b>
                                    </span>
                                )}
                                <br />
                                {/* <span className="mt-0 fs-0 fs-sm-0 fs-lg-0 px-2">
                  Your number of users: <b>{currentPlan["SocialAccounts"]}</b>
                </span> */}
                                <br />
                                {currentPlan["Name"] === "Free" && (
                                    <span className="mt--4 fs-0 fs-sm-0 fs-lg-0 px-2">
                                        Request:{" "}
                                        <b>
                                            Upgrade to {selectedPlan.title}
                                        </b>
                                    </span>
                                )}
                                <br /> <br />
                                <Flex direction={"column"} className={"gap-2"}>
                                    {
                                        planType !== 2 && (
                                            <>
                                                <Form.Group className="mt-3">
                                                    <Form.Label>
                                                        {" "}
                                                        How many users do you want?
                                                    </Form.Label>
                                                    <Row className="align-items-center gx-0">
                                                        <Col
                                                            xs
                                                            md="auto"
                                                            className="d-flex justify-content-start"
                                                        >
                                                            <Form.Group controlId="numberOfUsers">
                                                                <Form.Control
                                                                    type="number"
                                                                    value={formData.numberOfUsers}
                                                                    name="numberOfUsers"
                                                                    onChange={handleNumberOfSocualAccountChange}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col
                                                            xs="auto"
                                                            className="d-flex justify-content-start ml-10"
                                                        >
                                                            <p className="mb-1 fs-0 fs-sm-0 fs-lg-0 px-2">
                                                                x $
                                                                {billedYearly
                                                                    ? selectedPlan.extraUserPrice.Year
                                                                    : selectedPlan.extraUserPrice.Month}
                                                                /{billedYearly ? "yr" : "mo"} = $
                                                                {totalAmountForExtraUser}/
                                                                {billedYearly ? "yr" : "mo"}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <p className="mt-4 fs-0 fs-sm-0 fs-lg-0 px-2">
                                                    You can buy more seats at any time. You will
                                                    be charged a prorated fee for the billing cycle.
                                                </p>
                                            </>
                                        )}
                                    <div className="text-end">
                                        <Button
                                            disabled={formData.numberOfUsers <= 0}
                                            onClick={() => {
                                                buyExtraUsers(
                                                    {
                                                        ...postlyExtraUsers[0],
                                                        for_postly_extra_user: true,
                                                    },
                                                    loggedInUser
                                                );
                                            }}
                                            style={{ width: "100%" }}
                                        >
                                            Pay now
                                        </Button>
                                    </div>
                                    <div className="text-center">
                                        <Button
                                            onClick={() => {
                                                setOpenSocialAccountQuantityPicker(false);
                                            }}
                                            variant={"outlined"}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </Flex>
                            </>
                        )}
                </Modal.Body>
            </Modal>
            <Modal
                show={openSocialAccountQuantityPicker}
                onHide={() => setOpenSocialAccountQuantityPicker(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Upgrade
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* {isLTD(currentPlan) ? (
            <p>You are not eligible for this request</p>
          ) : ( */}
                    <>
                        <MonthlyYearlySwitch />
                        <hr />
                        {planType !== 2 && (
                            <span className="mt-0 fs-0 fs-sm-0 fs-lg-0 px-2">
                                Your Active Plan: <b>{currentPlan["Name"]}</b>
                            </span>
                        )}
                        <br />
                        <span className="mt-0 fs-0 fs-sm-0 fs-lg-0 px-2">
                            Your Number of Social Accounts: <b>{currentPlan["SocialAccounts"]}</b>
                        </span>
                        <br />
                        {currentPlan["Name"] === "Free" && (
                            <span className="mt--4 fs-0 fs-sm-0 fs-lg-0 px-2">
                                Request:{" "}
                                <b>
                                    Upgrade to {selectedPlan.title}
                                </b>
                            </span>
                        )}
                        <br /> <br />
                        <Flex direction={"column"} className={"gap-2"}>
                            {
                                planType !== 2 && (
                                    <>
                                        <Form.Group className="mt-3">
                                            <Form.Label>
                                                {" "}
                                                How many social accounts do you want?
                                            </Form.Label>
                                            <Row className="align-items-center gx-0">
                                                <Col
                                                    xs
                                                    md="auto"
                                                    className="d-flex justify-content-start"
                                                >
                                                    <Form.Group controlId="numberOfUsers">
                                                        <Form.Control
                                                            type="number"
                                                            value={formData.numberOfUsers}
                                                            name="numberOfUsers"
                                                            onChange={handleNumberOfSocualAccountChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col
                                                    xs="auto"
                                                    className="d-flex justify-content-start ml-10"
                                                >
                                                    <p className="mb-1 fs-0 fs-sm-0 fs-lg-0 px-2">
                                                        x $
                                                        {billedYearly
                                                            ? selectedPlan.extraPrice.Year
                                                            : selectedPlan.extraPrice.Month}
                                                        /{billedYearly ? "yr" : "mo"} = $
                                                        {totalAmountToBeCharged}/
                                                        {billedYearly ? "yr" : "mo"}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <p className="mt-4 fs-0 fs-sm-0 fs-lg-0 px-2">
                                            You can buy more social accounts at any time. You will
                                            be charged a prorated fee for the billing cycle.
                                        </p>
                                    </>
                                )}
                            <div className="text-end">
                                <Button
                                    disabled={formData.numberOfUsers <= 0}
                                    onClick={() => {
                                        handleAddSubscriptionQuantity(currentPricingData);
                                    }}
                                    style={{ width: "100%" }}
                                >
                                    Pay now
                                </Button>
                            </div>
                            <div className="text-center">
                                <Button
                                    onClick={() => {
                                        setOpenSocialAccountQuantityPicker(false);
                                    }}
                                    variant={"outlined"}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Flex>
                    </>
                    {/* )} */}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AllBilling