/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import Section from 'components/common/Section';
import { Outlet, useNavigate } from 'react-router-dom';
import FullLogo from 'components/common/FullLogo';
import Logo from 'components/common/Logo';

import PropTypes from "prop-types";
import Background from "components/common/Background";
import { Container } from "react-bootstrap";
import platformgrid from "assets/img/illustrations/platformgrid.png";
import postlyLoginPageChart3D from "assets/images/postly_login_page.png";
import { Button, Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { RoutePaths } from '../constants';
import { useQuery } from 'utils/url';
import Divider from 'components/common/Divider';
import Avatar from 'components/common/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import {
  canFlipToChildPages,
  getSwitchMode,
  setFlipToChildPages,
  setSwitchMode
} from 'redux/slices/authSlice';
import {
  getAltUser,
  getAltUsers,
  getLoggedInUser,
  setAltUser,
  setAltUsers
} from "redux/slices/user_slice";
import './addOnsStyles.css'
import APIService from "http/api_service";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import AppContext from 'context/Context';
import { Link } from 'react-router-dom';

const AuthSimpleLayout = ({ children }) => {

  const navigate = useNavigate();
  const loggedInUser = useSelector(getLoggedInUser);
  const switchMode = useSelector(getSwitchMode);
  const query = useQuery();
  const [switchingTo, setSwitchingTo] = useState({});
  const dispatch = useDispatch();
  const flipToChildPages = useSelector(canFlipToChildPages);
  const currentURL = window.location.href;
  const [customLogo, setCustomLogo] = useState(null)
  const [customName, setCustomName] = useState(null)
  const [brandPrivacyPolicy, setBrandPrivacyPolicy] = useState(null);
  const [brandTermOfService, setBrandTermOfService] = useState(null);
  const [fetchingCustomLogo, setFetchingCustomLogo] = useState(true);
  const { config } = useContext(AppContext);

  const SwitchView = () => {

    const loggedInAltUsers = useSelector(getAltUsers);
    const altUser = useSelector(getAltUser);

    useEffect(() => {
      if (loggedInUser) {
        let prevUsers = [...(loggedInAltUsers ?? [])];
        let currentUser = prevUsers.find(x => x._id === loggedInUser._id);
        if (!currentUser) {
          prevUsers.unshift(loggedInUser);
          let newData = [...prevUsers];
          dispatch(setAltUsers(newData));
        }
        setTimeout(() => {
          if (!altUser) {
            dispatch(setAltUser({ ...loggedInUser }));
          }
        }, 100);
      }
    }, []);

    return (
      <>
        {
          flipToChildPages &&
          <div
            className="flex-grow-1">
            {children}
          </div>
        }
        <>
          {
            !flipToChildPages &&
            <>
              {
                !loggedInAltUsers ?
                  <div
                    className=" flex-grow-1">
                    {children}
                  </div>
                  :
                  <Flex
                    alignItems={'center'}
                    justifyContent={'center'}
                    direction={'column'}
                    className={'gap-2'}>
                    <h5 className='p-0 m-0'>Switch Account</h5>
                    <Divider />
                    {
                      loggedInAltUsers.map(x => {
                        return (
                          <Button
                            key={x._id}
                            disabled={switchingTo.hasOwnProperty(x._id)}
                            id={x._id}
                            style={{
                              width: '100%',
                              textAlign: 'start'
                            }}
                            variant='falcon-default'
                            onClick={() => {
                              setSwitchingTo((prev) => {
                                prev = prev ?? {};
                                prev[x._id] = true;
                                return { ...prev };
                              })
                              dispatch(setAltUser({ ...x }));
                              navigate(RoutePaths.DEFAULT);
                              window.location.reload();
                            }}>
                            <Flex
                              alignItems={'center'}
                              className={'gap-3'}>
                              <Avatar
                                className={`${x._id === loggedInUser._id ? 'status-online' : ''}`}
                                size='2xl'
                                src={x.avatar}
                                fallBackName={`${x.first_name ?? x.email}`}
                              />
                              {
                                switchingTo.hasOwnProperty(x._id) ? <span>Please wait...</span> :
                                  <Flex direction={'column'}>
                                    <h5 className='p-0 m-0'>{`${x.first_name} ${x.last_name}`}</h5>
                                    <span className='fs--1'>{x.email}</span>
                                  </Flex>
                              }
                            </Flex>
                          </Button>
                        )
                      })
                    }
                    <Button
                      onClick={() => {
                        dispatch(setAltUser({ ...loggedInUser }));
                        dispatch(setFlipToChildPages(true));
                      }}
                      variant='falcon-primary'
                      style={{ width: '100%', marginTop: 10 }}>Use another Acount
                    </Button>
                  </Flex>
              }
            </>
          }
        </>
      </>
    )
  }


  const navigateToMainPage = (url, canSwitch) => {
    if (switchMode) {
      window.location = `${window.location.href}?switch=${switchMode}`;
      return;
    }
    if (!url.includes(RoutePaths.LOGOUT)
      && !url.includes(RoutePaths.INVITATIONS)
      && !url.includes(RoutePaths.TELEGRAM_LOGIN_PROXY)
      && !url.includes(RoutePaths.FORGOT_PASSWORD)
      && !url.includes(RoutePaths.RESET_PASSWORD)
      && !canSwitch) {
      navigate(RoutePaths.WORKSPACE);
    }
  }

  useEffect(() => {
    if (loggedInUser) {
      let url = window.location.href;
      let canSwitch = query.get("switch");
      if (canSwitch) {
        dispatch(setSwitchMode(canSwitch));
        if (canSwitch !== 'true') {
          navigateToMainPage(url);
        }
      } else {
        dispatch(setSwitchMode(false));
        navigateToMainPage(url, canSwitch);
      }
    } else {
      setTimeout(() => {
        let followUpDeletionUrl = localStorage.getItem('follow_up_deletion');
        if (followUpDeletionUrl) {
          localStorage.removeItem('follow_up_deletion');
          window.location.href = followUpDeletionUrl;
        }
      }, 200);
    }
  }, [window.location]);

  const getDomainImage = () => {
    const domain = window.location.hostname
    APIService.getCustomDomainLogo(domain, (response, error) => {
      if (error) {
        return;
      }
      let { data } = response;
      setCustomLogo(data?.logo)
      setCustomName(data?.name)
      setBrandTermOfService(data?.brand_term_of_service)
      setBrandPrivacyPolicy(data?.brand_privacy_policy)
      setFetchingCustomLogo(false)
    });
  }

  useEffect(() => {
    if (!currentURL.includes("app.postly.ai") && !currentURL.includes("localhost:3000")) {
      getDomainImage()
    }
  }, [])

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { brandPrivacyPolicy, brandTermOfService });
    }
    return child;
  });



  return <Section className="d-flex align-items-center justify-content-center py-0" style={{ height: '100vh' }}>
    <Row className="flex-center py-4">
      <Col sm={12} md={8} lg={6} xl={5} className="col-xxl-4">
        {
          (currentURL.includes("app.postly.ai") || currentURL.includes("localhost:3000")) ?
            <div className="d-flex justify-content-center" style={{ marginBottom: "10px" }}>
              <Logo width={40} fontSize={30} />
            </div> :
            <Flex
              style={{
                width: '100%',
              }}
              justifyContent={'center'}
              alignItems={'center'}
              className={'mb-2'}
              gap={2}
            >
              {
                customLogo ?
                  (fetchingCustomLogo ? <Skeleton
                    style={{
                      height: 80,
                      width: 80
                    }}
                  /> : <img
                    src={customLogo}
                    alt=""
                    className=""
                    style={{
                      height: 60,
                      width: 60
                    }}
                  />) : null
              }

              {customName && <span
                style={{
                  // color: config.isDark ? '' : '',
                  fontSize: 22,
                  // textAlign: 'center'
                }}
                className='font-sans-serif'>{customName}</span>}
            </Flex>
        }
        <Card>
          <Card.Body className="p-4">

            {
              switchMode ?
                <SwitchView />
                : childrenWithProps
            }
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Section>
}
export default AuthSimpleLayout;

{/* <Outlet /> */ }