/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useRef, useState } from "react";

import { faComment, faEye } from "@fortawesome/free-regular-svg-icons";
import {
  faImage,
  faPencil,
  faRemove,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmModal from "components/common/ConfirmModal";
import MultimediaSelectionDropdown from "components/common/MultimediaSelectionDropdown";
import CircularButton from "components/common/circularbutton";
import GDriveDashboard from "components/common/g-drive/dashboard";
import AppContext from "context/Context";
import parse from "html-react-parser";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";
import { RiDragDropLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setSelectedPlatformForPreview } from "redux/slices/platformSlice";
import { getMediaArrangement, setMediaArrangement } from "redux/slices/publishable_media_arrangement_slice";
import { darkTheme, lightTheme } from "utils/theme";
import {
  extractLinksFromTweet,
  getPreviewFromUrl,
  url_replacer,
} from "utils/url";
import { PlatformData, PlatformIdentifier } from "../../../../../constants";
import {
  canOpenPixelFilePickerDialog,
  getGoogleDriveManager,
  getManuallyEditedPlatforms,
  getMediaAddedViaSpecificPlaformUrlMethod,
  getNotifyPreviewsOfLiveMediaChanges,
  getSelectingPhotoFromHome,
  getSpecificMediaPlatformTied,
  getTimeToPostContent,
  setGoogleDriveManager,
  setManuallyEditedPlatforms,
  setOpenPixelFilePickerDialog,
  setSpecificMediaPlatformTied,
} from "../../../../../redux/slices/postslice";
import { platforms } from "../../../platforms";
import DragToPositionImage from "./DragToPositionImage";
import ExtrernalImageSearchDialogue from "./ExtrernalImageSearchDialogue";
import GDriveConnectionRequestListener from "./GDriveConnectionRequestListener";
import EditTextBox from "./editTextBox.js";
import LinkPreviewer from "./link_previewer";
import PlayMediaFb from "./playMediaFb.js";
import { EditPopUp, EditPopUpBtnDiv, Info } from "./styles/facebook";
import {
  ChildGallary,
  EditContainer,
  ImageDelete,
  LeftAction,
  ParentGallary,
  ParentSection,
  PostImage,
  PreviewContentArea,
  RightAction,
  SecondLayer,
  ThirdLayer,
  Wrapper,
} from "./styles/telegram";
import { rescaleImage } from "utils/media";
import Flex from "components/common/Flex";
import { displayPlatformIcon } from "utils/platform_utils";

const Telegram = () => {
  const dispatch = useDispatch();
  const googleDriveManager = useSelector(getGoogleDriveManager)
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const googleDriveRef = useRef(null);
  const [toggleEditTextInput, setToggleEditTextInput] = useState(false);
  const media_items_arrangement = useSelector(getMediaArrangement);
  const [currentMediaType, setCurrentMediaType] = useState(null);
  const [openMediaSelction, setOpenMediaSelection] = useState(false);
  const mediaObjectsTotalLength = 10;
  const selectingPhotoFromHome = useSelector(getSelectingPhotoFromHome);
  const [useableMedia, setUseableMedia] = useState([]);
  const myRef = useRef(null);
  const focusImageRef = useRef(null);
  const openPixelDialoguePicker = useSelector(canOpenPixelFilePickerDialog);
  const textEditRef = useRef(null);
  const [timer, setTimer] = useState();
  const [limitedPostTextBody, setLimitedPostTextBody] = useState("");
  const [repostionImages, setRepositionImages] = useState(false)
  const mediaAddedViaSpecificPlaformUrlMethod = useSelector(
    getMediaAddedViaSpecificPlaformUrlMethod
  );
  const specificMediaPlatformTied = useSelector(getSpecificMediaPlatformTied);
  const timeToPost = useSelector(getTimeToPostContent);
  const [openEditArea, setOpenEditArea] = useState(false);
  const [imageToEditUrl, setImageToEditUrl] = useState("");
  const [comparebleUrl, setComparebleUrl] = useState("");
  const [fileNameBackup, setFileNameBackup] = useState("");
  const [formattedPostBodyForLinks, setFormattedPostBodyForLinks] =
    useState("");
  const [linkExtracted, setLinkExtracted] = useState();
  const [previewerData, setPreviewerData] = useState(null);
  const [blockMediaItemsUpdate, setBlockMediaItemsUpdate] = useState(true);
  const manuallyEditedPlatforms = useSelector(getManuallyEditedPlatforms);
  const platformsWithLimits = [...platforms];
  const { config } = useContext(AppContext);
  const notifyPreviewsOfLiveMediaChanges = useSelector(getNotifyPreviewsOfLiveMediaChanges);

  let editInfoText = `webp images are not supported.`;
  let videoInfoText = `Telegram videos should be at least 480 x 320.`;
  let editVideoFormat = `Telegram videos supports .mp4 and .mov format`;

  useEffect(() => {
    const result = url_replacer(limitedPostTextBody ?? "");
    setFormattedPostBodyForLinks(result);
  }, [limitedPostTextBody]);

  useEffect(() => {
    const result = extractLinksFromTweet(limitedPostTextBody ?? "");
    setLinkExtracted(result[0]);
  }, [limitedPostTextBody]);

  const getPreviewLinkDetails = async (url) => {
    try {
      const result = await getPreviewFromUrl(url ?? "");
      setPreviewerData(result?.data);
    } catch (error) {
      // console.log(error, "as error here")
    }
  };

  useEffect(() => {
    const shouldOpenTextEdit = localStorage.getItem('open_hashtag_pop');
    if (shouldOpenTextEdit === 'true') {
      handleEditClick();
    }
  }, [])

  useEffect(() => {
    if (!linkExtracted) {
      return;
    }
    getPreviewLinkDetails(linkExtracted);
  }, [linkExtracted]);

  const closeImgEditor = () => {
    setOpenEditArea(false);
  };

  useEffect(() => {
    setUseableMedia(media_items_arrangement?.telegram?.media);
  }, [notifyPreviewsOfLiveMediaChanges]);

  const dispatchEditedFlag = () => {
    if (!manuallyEditedPlatforms[PlatformIdentifier.TELEGRAM]) {
      const newData = { ...manuallyEditedPlatforms, telegram: true };
      dispatch(setManuallyEditedPlatforms(newData));
    }
  };

  const syncNewThread = async () => {
    let syncingThread = [];
    try {
      const mappedThread = useableMedia?.map((thread, index) => {
        const newThreadEntry = {
          name: thread?.name,
          url: thread?.url,
          is_video: thread?.is_video,
          optimized: thread?.optimized ?? false,
          external: thread?.external ?? false,
          uploaded: thread?.uploaded ?? false,
          file: thread?.file,
        };
        return newThreadEntry;
      });
      const oldData = { ...media_items_arrangement };
      const diapatchableData = {
        ...oldData,
        telegram: { ...oldData.telegram, media: mappedThread },
      };
      dispatch(setMediaArrangement(diapatchableData));
    } catch (error) { }
    return syncingThread;
  };

  const handleUpdateTextFromEditBox = (text) => {
    dispatchEditedFlag();
    const oldData = { ...media_items_arrangement };
    const getTextLimit = platformsWithLimits.find(
      (x) => x.identifier === PlatformIdentifier.TELEGRAM
    );
    const diapatchableData = {
      ...oldData,
      telegram: {
        ...oldData.telegram,
        text: text.substring(0, getTextLimit.textLimit),
      },
    };
    dispatch(setMediaArrangement(diapatchableData));
  };

  function getImageDimension(url) {
    return new Promise(resolve => {
      const image = document.createElement('img');
      image.addEventListener("load", function () {
        const height = this.height;
        const width = this.width;
        resolve({ height, width });
      }, false);
      image.src = url;
    });
  }

  const handleFixTelegramImages = async (item) => {
    let faultyImageFound = false;
    let newUseableMedias = []
    for (let item of useableMedia) {
      if (item?.file?.type === "image/webp") {
        const { width, height } = await getImageDimension(item?.url);
        const dataURL = await rescaleImage(item?.url, width, height, '', '', true)
        let _item = { ...item, url: dataURL, optimized: true }
        if (_item?.file && _item?.file?.type) {
          const blob = await fetch(dataURL).then(res => res.blob());
          const file = new File([blob], _item?.file?.name, { type: "image/png", lastModified: new Date() });
          _item = { ..._item, file: file }
        }
        faultyImageFound = true;
        newUseableMedias.push(_item)
      } else {
        newUseableMedias.push(item)
      }
    }

    if (faultyImageFound) {
      setUseableMedia(newUseableMedias)
      toast.info(`Your recently added Image(s) have been converted to PNG as ${PlatformIdentifier.TELEGRAM} does not support WEBP format.`, {
        theme: 'colored'
      })
      // dispatchEditedFlag();
      // dispatch(setSelectedPlatformForPreview("refresh"));
      // setTimeout(() => {
      //   dispatch(setSelectedPlatformForPreview(PlatformIdentifier.TELEGRAM));
      // }, 1000);
    }


  }


  useEffect(() => {
    // fix all images that needs fixing;
    handleFixTelegramImages()
    if (!blockMediaItemsUpdate) {
      setBlockMediaItemsUpdate(true);
      syncNewThread();
    }
  }, [useableMedia]);

  const updateMediaItems = async (
    files,
    canCreateObjectURL = true,
    moreProps = {}
  ) => {
    let expectedFilesLength = files.length + useableMedia.length;
    if (
      files.length > mediaObjectsTotalLength ||
      useableMedia.length > mediaObjectsTotalLength || expectedFilesLength > mediaObjectsTotalLength
    ) {
      toast.error(
        `Sorry, you can only upload a maximum of ${mediaObjectsTotalLength} files at a time`
      );
      return;
    }

    let mo = [];
    for (let file of files) {
      if (canCreateObjectURL) {
        let fileUrl = URL.createObjectURL(file);
        let fileName = file.name;
        let existingFile = mo.find((x) => x.url === fileUrl);
        if (!existingFile) {
          let fileExtension = `.${fileName.split(".").pop()}`;
          let newThreadObject = {
            name: fileName,
            url: fileUrl,
            extension: fileExtension,
            file: file,
            ...moreProps,
          };

          if (file.size > PlatformData.MAXIMUM_ALLOWED_FILE_UPLOAD_SIZE) {
            toast.error(
              `Maximum allowed file size is ${PlatformData.MAXIMUM_ALLOWED_FILE_UPLOAD_SIZE / 1e9
              }GB`
            );
          } else {
            mo.push(newThreadObject);
          }
        }
      } else {
        let fileWithName = mo.find((item) => item.name === file.name);
        if (!fileWithName) {
          mo.push(file);
        }
      }
    }
    mo?.map((item) => {
      setBlockMediaItemsUpdate(false);
      setUseableMedia((oldArray) => [...oldArray, item]);
    });
    dispatchEditedFlag();
    setOpenMediaSelection(false);
    return;
  }

  useEffect(() => {
    if (googleDriveManager['fromPreviews'] && googleDriveManager['promptConnect']) {
      setOpenConfirmation(true)
    }
  }, [googleDriveManager])

  const mediaRemoveHandler = (removableMediaObject) => {
    try {
      let cachedArrayMedia = useableMedia;
      const filterMedia = cachedArrayMedia?.filter(
        (item) => item.url !== removableMediaObject.url
      );
      setBlockMediaItemsUpdate(false);
      setUseableMedia((prevState) => (prevState = filterMedia));
    } catch (error) { }
  };

  const handleDeleteMedia = (data) => {
    dispatchEditedFlag();
    mediaRemoveHandler(data);
  };

  const mediaOpener = (
    <div
      ref={myRef}
      style={{
        position: "relative",
        zIndex: 5,
        marginBottom: 20,
        marginTop: 20,
        width: "100%",
      }}
    >
      {
        <MultimediaSelectionDropdown
          specificPlatformTied={PlatformIdentifier.TELEGRAM}
          fromPreview={true}
          initiatorId={
            currentMediaType === "image"
              ? `${PlatformIdentifier.TELEGRAM}-photo-chooser`
              : `${PlatformIdentifier.TELEGRAM}-video-chooser`
          }
          externalFilePickerTitle={
            currentMediaType === "image"
              ? "Enter Photo Location"
              : "Enter Video Location"
          }
          prompt={
            currentMediaType === "image" ? "Add Photo From" : "Add Video From"
          }
          fileType={currentMediaType === "image" ? "image" : "video"}
          externalPickerPromptLabel={
            currentMediaType === "image" ? "Photo" : "Video"
          }
          multiple={currentMediaType === "image" ? true : false}
          fileSelectionHandler={(response) => {
            if (response) {
              let { files, from_local, ...others } = response;
              updateMediaItems(files, from_local, others);
            }
          }}
        />
      }
    </div>
  );

  const handleOpeningEditArea = async (source, myFile) => {
    setFileNameBackup(myFile.name);
    try {
      const image = new Image();
      image.src = source;
      const canvas = document.createElement("canvas");
      canvas.width = 1080;
      canvas.height = 1080;
      let scale = Math.max(
        canvas.width / image.naturalWidth,
        canvas.height / image.naturalHeight
      );
      let x = canvas.width / 2 - (image.naturalWidth / 2) * scale;
      let y = canvas.height / 2 - (image.naturalHeight / 2) * scale;
      canvas
        .getContext("2d")
        .drawImage(
          image,
          x,
          y,
          image.naturalWidth * scale,
          image.naturalHeight * scale
        );
      const dataURL = canvas.toDataURL();
      setImageToEditUrl(dataURL);
      setComparebleUrl(source);
      setOpenEditArea(!openEditArea);
    } catch (error) {
      const image = new Image();
      image.src = source;
      const canvas = document.createElement("canvas");
      setTimeout(() => {
        canvas.width = 1080;
        canvas.height = 1080;
        let scale = Math.max(
          canvas.width / image.naturalWidth,
          canvas.height / image.naturalHeight
        );
        let x = canvas.width / 2 - (image.naturalWidth / 2) * scale;
        let y = canvas.height / 2 - (image.naturalHeight / 2) * scale;
        canvas
          .getContext("2d")
          .drawImage(
            image,
            x,
            y,
            image.naturalWidth * scale,
            image.naturalHeight * scale
          );
        const dataURL = canvas.toDataURL();
        setImageToEditUrl(dataURL);
        setComparebleUrl(source);
        setOpenEditArea(!openEditArea);
      }, 1000);
    }
  };

  const displayMedias = useableMedia.map((item, index) => {
    let notValid = false;
    return (
      <ChildGallary>
        {item?.is_video === false && notValid && !item?.uploaded && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
              backgroundColor: "#949ca4AA",
              zIndex: 4,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "65%",
                  backgroundColor: `${config.isDark
                    ? darkTheme.workSpaceSettingsHeaderBar
                    : lightTheme.emptyButtonActive
                    }`,
                  padding: 10,
                  borderRadius: 10,
                }}
              >
                <EditPopUp
                  style={{
                    color: `${!config.isDark && "black"}`,
                    fontSize: 10,
                  }}
                >
                  {editInfoText}
                  <EditPopUpBtnDiv>
                    <Button
                      tintBackground={config.isDark}
                      variant="primary"
                      onClick={() =>
                        handleOpeningEditArea(item?.url, item?.file)
                      }
                      style={{ width: "auto", height: "30px" }}
                    >
                      Fix
                    </Button>
                  </EditPopUpBtnDiv>
                </EditPopUp>
              </div>
            }
          </div>
        )}
        <ImageDelete
          editBgColor={!config.isDark ? "#e0e0e0" : "#0b1727"}
          onClick={(e) => handleDeleteMedia(item)}
        >
          <FontAwesomeIcon icon={faRemove} />
        </ImageDelete>

        {item.is_video ? (
          <PlayMediaFb
            videoEditText={videoInfoText}
            fullFile={item}
            platform={PlatformIdentifier.TELEGRAM}
            editVideoFormat={editVideoFormat}
            videoInfoText={videoInfoText}
            checkingVideo={true}
            videoHeightLimit={319}
            videoWidthLimit={479}
            url={item.url}
            file={item?.file}
          />
        ) : (
          <PostImage src={item?.url} alt="post preview"></PostImage>
        )}
      </ChildGallary>
    );
  });

  const limitChars = (text, limit) => {
    setLimitedPostTextBody(text?.substring(0, limit));
  };

  const waitTime = 1000;

  function runWhenUserStopTying() {
    window.clearTimeout(timer);
    setTimer(
      window.setTimeout(() => {
        if (!manuallyEditedPlatforms[PlatformIdentifier.TELEGRAM]) {
          limitChars(media_items_arrangement?.telegram?.text, 4096);
        }
      }, waitTime)
    );
  }

  useEffect(() => {
    runWhenUserStopTying();
  }, [media_items_arrangement?.telegram?.text]);

  useEffect(() => {
    limitChars(media_items_arrangement?.telegram?.text, 4096);
  }, []);

  const handleEditClick = () => {
    setToggleEditTextInput(true);
  };

  const addMediaFromUrl = (obj = mediaAddedViaSpecificPlaformUrlMethod[0]) => {
    const { extension, thumbnail, ...rest } =
      obj;
    setBlockMediaItemsUpdate(false);
    useableMedia.length
      ? setUseableMedia((oldArray) => [...oldArray, rest])
      : setUseableMedia([rest]);

    dispatchEditedFlag();
    dispatch(setSpecificMediaPlatformTied("global"));
    setOpenMediaSelection(false);
    if (googleDriveManager?.docs) {
      dispatch(setGoogleDriveManager({ ...googleDriveManager, docs: null }))
    }
  };


  const immediateActionWhenClieked = (remove) => {
    if (remove) {
      localStorage.removeItem("gdrivePlatform");
      return;
    }
    localStorage.setItem("gdrivePlatform", PlatformIdentifier.TELEGRAM);
  }

  useEffect(() => {
    if (!googleDriveManager?.docs || localStorage.getItem('gdrivePlatform') !== PlatformIdentifier.TELEGRAM) return;
    addMediaFromUrl(googleDriveManager?.docs);
    immediateActionWhenClieked(true)
  }, [googleDriveManager])

  useEffect(() => {
    specificMediaPlatformTied === "telegram" &&
      mediaAddedViaSpecificPlaformUrlMethod.length &&
      addMediaFromUrl();
  }, [mediaAddedViaSpecificPlaformUrlMethod]);

  const saveDesignStateImage = async (newUrl) => {
    closeImgEditor();
    const blob = await (await fetch(newUrl)).blob();
    const new_file = new File([blob], fileNameBackup, {
      type: "image/jpeg",
      lastModified: new Date(),
    });
    setUseableMedia((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.url === comparebleUrl) {
          setBlockMediaItemsUpdate(false);
          return { ...obj, url: newUrl, file: new_file, optimized: true };
        }
        return obj;
      });
      dispatchEditedFlag();
      dispatch(setSelectedPlatformForPreview("refresh"));
      setTimeout(() => {
        dispatch(setSelectedPlatformForPreview(PlatformIdentifier.TELEGRAM));
      }, 1000);
      return newState;
    });
  };

  const handleConnect = async () => {
    try {
      googleDriveRef.current.click();
    } catch (error) {

    }
  }

  useEffect(() => {
    if (googleDriveManager['fromPreviews'] && googleDriveManager['promptConnect']) {
      setOpenConfirmation(true)
    }
  }, [googleDriveManager])

  return (
    <>
      {openEditArea && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100vh",
            width: "100%",
            backgroundColor: "green",
            zIndex: 10000,
          }}
        >
          <FilerobotImageEditor
            source={imageToEditUrl}
            theme={{
              palette: {
                "bg-secondary": !config.isDark
                  ? lightTheme?.rootBackground
                  : darkTheme?.rootBackground,
              },
              typography: {
                fontFamily: "Roboto, Arial",
              },
            }}
            onBeforeSave={() => false}
            onSave={async (editedImageObject, designState) => {
              let canvas = editedImageObject.imageCanvas;
              saveDesignStateImage(canvas.toDataURL());
            }}
            onClose={closeImgEditor}
            annotationsCommon={{
              fill: "#ff0000",
            }}
            Text={{ text: "Postly..." }}
            tabsIds={[
              TABS.ADJUST,
              TABS.ANNOTATE,
              TABS.WATERMARK,
              TABS.FILTERS,
              TABS.FINETUNE,
              TABS.RESIZE,
            ]}
            defaultTabId={TABS.ANNOTATE} // or 'Annotate'
            defaultToolId={TOOLS.TEXT} // or 'Text'
          />
        </div>
      )}

      <Flex
        gap={3}
        className={`mt-2`}
        wrap={'wrap'}
      >
        <MultimediaSelectionDropdown
          dropDownAnchor={
            <CircularButton onClick={() => immediateActionWhenClieked(false)} title="Add Image">
              <FontAwesomeIcon icon={faImage} />
            </CircularButton>
          }
          specificPlatformTied={PlatformIdentifier.TELEGRAM}
          fromPreview={true}
          initiatorId={`${PlatformIdentifier.TELEGRAM}-photo-chooser`}
          externalFilePickerTitle={"Enter Photo Location"}
          prompt={"Add Photo From"}
          fileType={"image"}
          externalPickerPromptLabel={"Photo"}
          multiple={true}
          fileSelectionHandler={(response) => {
            if (response) {
              let { files, from_local, ...others } = response;
              updateMediaItems(files, from_local, others);
            }
          }}
        />

        <MultimediaSelectionDropdown
          dropDownAnchor={
            <CircularButton title="Add Video">
              <FontAwesomeIcon icon={faVideo} />
            </CircularButton>
          }
          specificPlatformTied={PlatformIdentifier.TELEGRAM}
          fromPreview={true}
          initiatorId={`${PlatformIdentifier.TELEGRAM}-video-chooser`}
          externalFilePickerTitle={"Enter Video Location"}
          prompt={"Add Video From"}
          fileType={"video"}
          externalPickerPromptLabel={"Video"}
          multiple={false}
          fileSelectionHandler={(response) => {
            if (response) {
              let { files, from_local, ...others } = response;
              updateMediaItems(files, from_local, others);
            }
          }}
        />
        {!toggleEditTextInput && (
          <CircularButton
            editBgColor={!config.isDark ? "#e0e0e0" : "#0b1727"}
            onClick={() => handleEditClick()}
            title="Edit Text"
          >
            <FontAwesomeIcon icon={faPencil} />
          </CircularButton>
        )}
        <CircularButton
          editBgColor={!config.isDark ? "#e0e0e0" : "#333333"}
          onClick={() => setRepositionImages(!repostionImages)}
          title="re-position images"
        >
          <RiDragDropLine size={20} />
        </CircularButton>
      </Flex>

      <ParentSection>
        {
          googleDriveManager?.openPicker ? <GDriveDashboard platform={PlatformIdentifier.TELEGRAM} /> : null
        }
        <GDriveConnectionRequestListener
          googleDriveRef={googleDriveRef}
        />
        <Wrapper>
          {openMediaSelction && !selectingPhotoFromHome && mediaOpener}
          {toggleEditTextInput && (
            <EditTextBox
              ref={textEditRef}
              isPlatformEdited={PlatformIdentifier.TELEGRAM}
              incomingRef={textEditRef}
              value={limitedPostTextBody}
              setValue={setLimitedPostTextBody}
              setToggleEditTextInput={setToggleEditTextInput}
              platform={"telegram"}
              setter={{ ...manuallyEditedPlatforms, telegram: true }}
              updateText={handleUpdateTextFromEditBox}
            />
          )}
          <PreviewContentArea
            ref={focusImageRef}
            style={{
              borderColor: `${!config.isDark ? "#ededed" : "transparent"}`,
            }}
            mainBgColor={!config.isDark ? "white" : "#0b1727"}
            theme={!config.isDark && "1px 1px 3px #949494"}
          >
            <div style={{ position: 'absolute', bottom: 0, right: 5, fontSize: 11, }}>
              Character Count: {formattedPostBodyForLinks?.length} / 4096
            </div>
            {manuallyEditedPlatforms[PlatformIdentifier.TELEGRAM] && (
              <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  fontStyle: "italic",
                  position: "absolute",
                  top: 0,
                  right: 5,
                  fontSize: 11,
                }}
              >
                <p style={{ color: !config.isDark ? "grey" : "grey" }}>
                  Edited
                </p>
              </div>
            )}
            <Flex
              gap={2}
              alignContent={"start"}
              alignItems={"start"}
              style={{
                width:'100%',
                padding: '2%'
              }}
            >
              {displayPlatformIcon(PlatformIdentifier.TELEGRAM)}
              <Info>
                <span
                  type={"p"}
                  style={{
                    fontSize: 11,
                    fontWeight: "600",
                    color: `${!config.isDark ? "#050505" : "#E4E6EB"}`,
                  }}
                >
                  Telegram Preview
                </span>
              </Info>
            </Flex>
            {
              repostionImages ? <DragToPositionImage useableMedia={useableMedia} setter={setUseableMedia} platform={PlatformIdentifier.TELEGRAM} /> :
                <SecondLayer>
                  <ParentGallary>{displayMedias}</ParentGallary>
                  <pre
                    style={{
                      margin: "1%",
                      marginTop: 10,
                      marginBottom: 10,
                      color: `${!config.isDark ? "black" : "#b3b3b3"}`,
                      fontFamily:
                        'Roboto, -apple-system, "apple color emoji", BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
                      lineHeight: "21px",
                      fontWeight: 400,
                      maxWidth: "100%",
                      width: "100%",
                      wordWrap: "break-word",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {parse(formattedPostBodyForLinks)}
                  </pre>

                  {previewerData && !useableMedia.length && (
                    <ParentGallary>
                      {
                        <LinkPreviewer
                          domain={previewerData?.siteName}
                          img={previewerData?.images?.length ? previewerData?.images[0] : null}
                          requestUrl={previewerData?.url}
                          title={previewerData?.title}
                          favicon={previewerData?.favicons?.length ? previewerData?.favicons[0] : null}
                        />
                      }
                    </ParentGallary>
                  )}

                  {/* <p
                    type={"p"}
                    style={{
                      fontSize: 11,
                      margin: "1%",
                      color: "grey",
                      position: "absolute",
                      bottom: "5px",
                      right: "5px",
                    }}
                  >
                    <FontAwesomeIcon
                      style={{
                        color: "grey",
                      }}
                      icon={faEye}
                    />{" "}
                    320 WinadeeTV, {moment(timeToPost).format("h:mm a")}
                  </p> */}
                </SecondLayer>
            }
            <ThirdLayer>
              <LeftAction>
                <FontAwesomeIcon
                  style={{
                    color: "#0e6cc4",
                    width: 15,
                    height: 15,
                    marginTop: -3,
                  }}
                  icon={faComment}
                />

                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    padding: "2%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <span
                    type={"p"}
                    style={{
                      color: "#0e6cc4",
                      fontSize: 11
                    }}
                  >
                    Leave a comment
                  </span>
                </div>
              </LeftAction>
              {/* <RightAction>
                <span
                  type={"p"}
                  style={{
                    fontWeight: "600",
                    color: "#0e6cc4",
                    fontSize: 11
                  }}
                >
                  {">"}
                </span>
              </RightAction> */}
            </ThirdLayer>
          </PreviewContentArea>
        </Wrapper>

        {/* <EditContainer
          style={{ justifyContent: "space-around" }}
          editBgColor={
            !config.isDark ? lightTheme.surfaceColor : darkTheme.rootBackground
          }
        >
       
        </EditContainer> */}
        <Modal
          show={openPixelDialoguePicker}
          onHide={() => {
            dispatch(setOpenPixelFilePickerDialog(false));
          }}
          onExit={() => {
            dispatch(setOpenPixelFilePickerDialog(false));
          }}
        >
          <Modal.Header>
            <Modal.Title>Pexels.com</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ExtrernalImageSearchDialogue
              setUseableMedia={setUseableMedia}
              determiner={PlatformIdentifier.TELEGRAM}
              // options={externalFilePickerDialogOptions}
              modalClose={() => {
                // dispatch(setOpenExternalFilePickerDialog(false));
              }}
            />
          </Modal.Body>
        </Modal>
        <ConfirmModal
          open={openConfirmation}
          title={"Connect Your Google Drive Account"}
          message={'Your google drive account is not connected or has been disconnected. Do you wish to connect?'}
          confirmText={"PROCEED"}
          cancelText={"CANCEL"}
          showDialoguePrompt
          onCancel={() => {
            setOpenConfirmation(false);
            dispatch(setGoogleDriveManager({
              ...googleDriveManager,
              promptConnect: false,
            }))
          }}
          onConfirm={() => {
            handleConnect();
            dispatch(setGoogleDriveManager({ ...googleDriveManager, promptConnect: false, fromPreviews: false }))
          }}
        />
      </ParentSection>
    </>
  );
};

export default Telegram;
