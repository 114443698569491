import React, { useEffect, useState } from "react";
import StringUtils from '../../../utils/string';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { MetricsProps, RoutePaths } from "../../../constants";
import { toast } from 'react-toastify';
import APIService from '../../../http/api_service';
import jwt_decode from "jwt-decode";
import { useQuery } from '../../../utils/url';
import ResultsPreview from "./results_preview";
import { grabHashTags } from "../../../utils/hash_tags";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import ConfirmModal from "components/common/ConfirmModal";
import Flex from "components/common/Flex";
import FreePageHeader from "components/common/FreePageHeader";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux';
import { getLoggedInUser } from "redux/slices/user_slice";
import { getActiveWorkSpace, setActiveWorkspace } from "redux/slices/workspaceslice";
import useProtectedRoute from "hooks/useProtectedRoute";
import { setPopupUpgradeAlert } from "redux/slices/authSlice";
import { setCollectMetric } from "redux/slices/analytics_slice";

export const maxUsableCharacters = (workSpaceOwner = {}) => {
    let maxChars = workSpaceOwner?.active_plan?.AI_Writer_Words ?? workSpaceOwner?.active_plan?.AI_Writer_Chars ?? 0;
    if (workSpaceOwner.active_ai_writer_subscription) {
        return "Unlimited";
    }
    return maxChars;
}

export const usedAIWriterChars = (workSpaceOwner = {}) => {
    let max = maxUsableCharacters(workSpaceOwner);
    let used = workSpaceOwner?.used_ai_writer_characters ?? 0;
    if (used > 0) {
        return used;
    }
    if (workSpaceOwner.ai_writer_privileges) {
        used = ~~(workSpaceOwner.ai_writer_privileges?.used_ai_writer_tokens ?? 0);
    }
    if (max === "Unlimited") {
        return used;
    }
    if (used > max) {
        used = max;
    }
    return used;
}

export default function AIWriterTool(props) {
    console.log("🚀 ~ AIWriterTool ~ props:", props)

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const maximumAllowedDescriptionLength = 1000;
    const [tools] = useState(props.tools);
    const tagName = StringUtils.substringAfterLast(window.location.href, "=");
    const tag = tagName === window.location.href ? null : tagName;
    const toolIdentifier = StringUtils.substringBefore(StringUtils.substringAfterLast(window.location.href, "/"), "?");
    const [tool, setTool] = useState('postCaptions');
    const [referenceTool, setReferenceTool] = useState({ name: 'Undefined' });
    // const { status } = useProtectedRoute(referenceTool?.tool === "ProductDescription" ? ['free'] : [], `${RoutePaths.BILLING_STRIPE}/#AISection001`)
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [generatingText, setGeneratingText] = useState(false);
    const workSpace = useSelector(getActiveWorkSpace);
    const loggedInUser = useSelector(getLoggedInUser);
    const [buttonText, setButtonText] = useState("Create Copies");
    const [tones] = useState([
        "🏚️ Adventurous",
        "🛄 Professional",
        "👨 Friendly",
        "💡 Witty",
        "💪 Bold",
        "🧠 Persuasive",
        "🤗 Empathetic",
        "💎 Luxury",
        "😂 Funny",
        "🙏 Appreciative",
        "🥷 Assertive",
        "👌 Awestruck",
        "🍭 Candid",
        "😊 Relaxed",
        "👕 Casual",
        "⚠️ Cautionary",
        "😢 Compassionate",
        "😳 Critical",
        "🤵‍♂️ Formal",
        "🙇 Humble",
        "ℹ️ Informative",
        "🦋 Inspirational",
        "🤣 Joyful",
        "❤️‍🔥 Passionate",
        "💭 Thoughtful"
    ]);

    useEffect(() => {
        setTool(toolIdentifier === window.location.href ? null : toolIdentifier)
    }, [toolIdentifier]);
    useEffect(() => {
        if (tool) {
            setReferenceTool(tools.find(x => x.tool.toLowerCase() === tool.toLowerCase()) ?? { name: 'Undefined' });
        }
    }, [tool]);

    const [tone, setTone] = useState("😊 Relaxed");
    const [copiesCount] = useState(
        [...[...Array(20).keys()].map(x => x + 1)]
    );
    const [copyCount, setCopyCount] = useState(2);
    const [referenceTag] = useState(() => {
        if (referenceTool) {
            if (tag && referenceTool.sub_items) {
                let retrievedTag = referenceTool.sub_items[parseInt(tag)] ?? { name: 'Undefined' };
                return retrievedTag;
            }
            return null;
        }
        return null
    });
    const [results, setResults] = useState([]);
    const [openBuyMoreCharactersDialog, setOpenBuyMoreCharactersDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [generatedCopiesTitle, setGeneratedCopiesTitle] = useState("Generated Copies");
    const query = useQuery();
    const [savedCopies, setSavedCopies] = useState([]);
    const purchasableAIWriterCharacters = 'Unlimited';
    const costOfPurchasableAIWriterCharacters = '$29/month';
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
    const [workSpaceOwner, setWorkspaceOwner] = useState({});

    const attachOccupationToWorkspace = (firstWorkspace) => {
        if (loggedInUser) {
            if (loggedInUser.occupation) {
                if (!firstWorkspace.title) {
                    firstWorkspace.title = loggedInUser.occupation;
                }
            }
        }
    }

    const getMostRecentCopies = () => {
        let ref = referenceTag ? referenceTag : referenceTool;
        let cachedString = localStorage.getItem(`ai_writer_recent_copies_for_${ref.name}`);
        if (cachedString) {
            return JSON.parse(cachedString);
        }
        return null;
    }

    const cacheResults = (results) => {
        let ref = referenceTag ? referenceTag : referenceTool;
        localStorage.setItem(`ai_writer_recent_copies_for_${ref.name}`, JSON.stringify(results));
    }

    const fetchSavedCopies = () => {
        let ref = referenceTag ? referenceTag : referenceTool;
        APIService.fetchSavedCopies(workSpace._id, ref.name, (response, error) => {
            if (response) {
                let justSaved = response.data;
                setSavedCopies(justSaved);
            }
        });
    }

    const fetchWorkspaceOwner = () => {
        APIService.fetchWorkSpaceOwner(workSpace._id, (response, error) => {
            if (response) {
                let { data } = response;
                setWorkspaceOwner(data);
            }
        });
    }

    useEffect(() => {
        if (savedCopies.length > 0) {
            if (results.length > 0) {
                for (let result of results) {
                    let savedCopy = savedCopies.find(x => x.text === result.text);
                    if (savedCopy) {
                        result.saved = true;
                        result._id = savedCopy._id;
                        result.ref = savedCopy.ref
                    } else {
                        result.saved = false;
                    }
                }
                setResults([...results]);
            }
        } else {
            if (results.length > 0) {
                setResults(results.map(x => {
                    return {
                        ...x,
                        saved: false,
                    }
                }));
            }
        }
    }, [savedCopies]);

    const refreshWorkspace = () => {
        APIService.fetchWorkSpace(workSpace['_id'], (response, error) => {
            if (error) {
                return;
            }
            if (response) {
                let responseData = response['data'];
                let receivedWorkspace = responseData['workspace'];
                receivedWorkspace['role'] = responseData['role'];
                receivedWorkspace['value'] = receivedWorkspace['name'];
                receivedWorkspace['title'] = responseData['title'];
                receivedWorkspace['label'] = receivedWorkspace['name'];
                attachOccupationToWorkspace(receivedWorkspace);
                dispatch(setActiveWorkspace(receivedWorkspace));
            }
        });
    }

    useEffect(() => {
        if (workSpace) {
            refreshWorkspace();
            fetchWorkspaceOwner();
        }
        let mostRecentCopies = getMostRecentCopies();
        if (mostRecentCopies) {
            setGeneratedCopiesTitle("Recent Copies");
            setResults(mostRecentCopies);
        }
        fetchSavedCopies();
    }, []);

    const generateText = () => {
        let ref = referenceTag ? referenceTag : referenceTool;
        let prompt = '';
        // let realTone = tone.split(" ")[1];
        let prefix = ref.prefix;
        if (description) {
            prompt = `${prefix}\n\n${!description.toLowerCase().includes(title.toLowerCase()) ? `${title},` : ''}${description}`;
        } else {
            prompt = `${prefix}\n\n${title}`;
        }
        setGeneratingText(true);
        setButtonText("Generating Copies...");
        APIService.generateText(workSpace._id, { prompt, count: copyCount }, (response, error) => {
            setGeneratingText(false);
            refreshWorkspace();
            if (error) {
                setButtonText("Create Copies");
                if (error.toLowerCase().includes("quota")) {
                    setErrorMessage(`${error}. Would you like to subscribe to ${purchasableAIWriterCharacters.toLocaleString()} Characters at ${costOfPurchasableAIWriterCharacters}?`);
                    setOpenBuyMoreCharactersDialog(true);
                } else if (error.toLowerCase().includes('upgrade')) {
                    let metricInitializer = {
                        action: MetricsProps.AI_WRITER_TOOL,
                        detailedAction: `Upgrade notice: Attempted to use AI Writer`,
                        timestamp: new Date().toISOString(),
                        route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
                    }
                    dispatch(setCollectMetric(metricInitializer))
                    dispatch(setPopupUpgradeAlert(true))
                } else {
                    toast.error(error, { theme: 'colored' });
                }
                return;
            }
            document.getElementById("ai-writer-tool-header")?.scrollIntoView();
            let { data } = response;
            if (data.length > 0) {
                setButtonText("Generate Again");
                setGeneratedCopiesTitle("Generated Copies");
                data = data.flat().map(x => {
                    return { text: prompt.includes("hashtags") ? grabHashTags(x.text, true).join(" ") : x.text }
                });
                data = data.filter(x => x.text.length > 1);
                cacheResults(data);
                setResults(data);
                fetchWorkspaceOwner();
            } else {
                setButtonText("Create Copies");
            }
        });
    }

    useEffect(() => {
        let meta = query.get("meta");
        if (meta) {
            try {
                let metaData = jwt_decode(meta);
                if (metaData) {
                    if (metaData.success_message) {
                        toast.success(metaData.success_message, { theme: 'colored' });
                    }
                }
            } catch (e) {
                //Malformed jwt
            }
        }
    }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

    const buyMoreAIWriterCharacters = () => {
        APIService.buyMoreAIWriterQuotas(false, workSpace._id, (response, error) => {
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let { data } = response;
            window.location = data;
        });
    }

    return (
        <>
            <FreePageHeader
                titleTag="h5"
                className="mb-3">
                <div
                    id="ai-writer-tool-header"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 10
                    }}>
                    <FontAwesomeIcon
                        color={referenceTag ? referenceTag.color : referenceTool.color}
                        icon={referenceTag ? referenceTag.icon : referenceTool.icon}
                        style={{
                            width: 30,
                            height: 30
                        }}
                    />
                    <h5>
                        {referenceTag ? referenceTag.name : referenceTool.name}
                    </h5>
                    <div style={{ flex: 1 }}></div>
                    {
                        savedCopies.length > 0 &&
                        <Button
                            variant="outline-info"
                            onClick={() => {
                                setGeneratedCopiesTitle("Saved Copies");
                                setResults(savedCopies.map(x => {
                                    return {
                                        ...x,
                                        saved: true
                                    }
                                }));
                            }}
                        >{`View ${savedCopies.length.toLocaleString()} Saved ${savedCopies.length > 1 ? 'copies' : 'Copy'}`}
                        </Button>
                    }
                </div>
            </FreePageHeader>
            <Row>
                <Col lg={6}>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 25,
                            paddingTop: 2,
                            gap: 15
                        }}>
                        <Card
                            style={{
                                width: '100%',
                                padding: 25,
                                borderRadius: 5
                            }}>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 10
                                }}>
                                <Form.Group>
                                    <Form.Label>{referenceTag ? referenceTag.navigationTitle : referenceTool.navigationTitle}{' '}{referenceTag ? referenceTag.optional : referenceTool.optional ? '(optional)' : ''}</Form.Label>
                                    <Form.Control as={'textarea'}
                                        type="text"
                                        className="shadow-none rounded-0 resize-none px-card border-200"
                                        value={title}
                                        onChange={(e) => {
                                            let changedText = e.target.value;
                                            if (changedText.length <= maximumAllowedDescriptionLength) {
                                                setTitle(changedText);
                                            }
                                            if (buttonText !== "Create copies") {
                                                setButtonText("Create Copies");
                                            }
                                        }}
                                        rows={(referenceTag ? referenceTag.navigationDescription : referenceTool.navigationDescription) ? 1 : 8}
                                        placeholder={referenceTag ? referenceTag.navigationTitleHint : referenceTool.navigationTitleHint}
                                    />
                                </Form.Group>
                                {
                                    !(referenceTag ? referenceTag.navigationDescription : referenceTool.navigationDescription) &&
                                    <span
                                        style={{
                                            textAlign: 'end',
                                            color: '#009688',
                                            paddingTop: 5, fontSize: 13
                                        }}>{`${title.length}/${maximumAllowedDescriptionLength}`}
                                    </span>
                                }
                            </div>
                        </Card>
                        {
                            referenceTag ? referenceTag.navigationDescription : referenceTool.navigationDescription &&
                                <Card
                                    style={{
                                        width: '100%',
                                        padding: 25,
                                        borderRadius: 5,
                                    }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}>
                                        <Form.Group>
                                            <Form.Label>{referenceTag ? referenceTag.navigationDescription : referenceTool.navigationDescription}</Form.Label>
                                            <Form.Control as={'textarea'}
                                                value={description}
                                                className="shadow-none rounded-0 resize-none px-card border-200"
                                                onChange={(e) => {
                                                    let changedText = e.target.value;
                                                    if (changedText.length <= maximumAllowedDescriptionLength) {
                                                        setDescription(changedText);
                                                    }
                                                    if (buttonText !== "Create copies") {
                                                        setButtonText("Create Copies");
                                                    }
                                                }}
                                                rows={8}
                                                placeholder={referenceTag ? referenceTag.navigationDescriptionHint : referenceTool.navigationDescriptionHint}
                                            />
                                        </Form.Group>
                                        <span
                                            style={{
                                                textAlign: 'end',
                                                color: '#009688',
                                                paddingTop: 5, fontSize: 11
                                            }}>{`${description.length}/${maximumAllowedDescriptionLength}`}
                                        </span>
                                    </div>
                                </Card>
                        }
                        <Card
                            style={{
                                width: '100%',
                                padding: 25,
                                borderRadius: 5,
                            }}>
                            <Form.Group>
                                <Form.Label>Choose a Tone</Form.Label>
                                <Form.Select
                                    value={tone}
                                    onChange={(e) => {
                                        setTone(e.target.value);
                                    }}>
                                    {
                                        tones.map(x => {
                                            return <option value={x}>{x}</option>
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Card>
                        <Card
                            style={{
                                width: '100%',
                                borderRadius: 5,
                                padding: 25,
                            }}>
                            <Form.Group>
                                <Form.Label>Number of copies to generate</Form.Label>
                                <Form.Select
                                    value={copyCount}
                                    onChange={(e) => setCopyCount(e.target.value)}>
                                    {
                                        copiesCount.map(x => <option value={x}>{x}</option>)
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Card>
                        <Button
                            variant={'primary'}
                            isWorking={generatingText}
                            disabled={generatingText || (!title && !description)}
                            style={{ width: '100%' }}
                            onClick={() => {
                                generateText();
                            }}>{buttonText}
                        </Button>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            gap: 5,
                        }}>
                            <span><b>({usedAIWriterChars(workSpaceOwner).toLocaleString()} / {maxUsableCharacters(workSpaceOwner).toLocaleString()})</b> characters used</span>
                            <Button
                                size={'sm'}
                                variant={`${maxUsableCharacters() === "Unlimited" ? "" : "outline-"}info`}
                                className='fs--2 rounded-pill'
                                onClick={() => {
                                    navigate(`${RoutePaths.ACCOUNT}?t=billing`)
                                }}
                            >
                                {maxUsableCharacters(workSpaceOwner) === "Unlimited" ? "Subscribed" : "Subscribe to Unlimited Quota"}
                                {
                                    maxUsableCharacters(workSpaceOwner) === "Unlimited" &&
                                    <FontAwesomeIcon
                                        icon={faCheckCircle}
                                        style={{ marginLeft: 5 }}
                                    />
                                }
                            </Button>
                        </div>
                    </div>
                </Col>
                <Col lg={6}>
                    {
                        generatingText &&
                        <Flex alignContent={'center'} alignItems={'center'} justifyContent={'center'}>
                            <Flex alignContent={'center'} alignItems={'center'} justifyContent={'center'}>
                                <Spinner animation="border" />
                            </Flex>
                        </Flex>
                    }
                    {
                        !generatingText && results.length > 0 &&
                        <ResultsPreview
                            generatedCopiesTitle={generatedCopiesTitle}
                            fetchSavedCopies={fetchSavedCopies}
                            referenceTag={referenceTag}
                            referenceTool={referenceTool}
                            workSpace={workSpace}
                            results={results}
                        />
                    }
                </Col>
            </Row>
            <ConfirmModal
                open={openBuyMoreCharactersDialog}
                title={"Increase your AI Writer Quota"}
                message={errorMessage}
                confirmText={"BUY"}
                cancelText={"CANCEL"}
                onCancel={() => {
                    setOpenBuyMoreCharactersDialog(false);
                }}
                onConfirm={() => {
                    setOpenBuyMoreCharactersDialog(false);
                    buyMoreAIWriterCharacters();
                }}
            />
            <ConfirmModal
                open={openUpgradeDialog}
                title={"Upgrade Account"}
                message={"This feature requires an account upgrade. Kindly upgrade to a premium account to continue"}
                confirmText={"UPGRADE"}
                cancelText={"CANCEL"}
                onCancel={() => {
                    setOpenUpgradeDialog(false);
                }}
                onConfirm={() => {
                    setOpenUpgradeDialog(false);
                    navigate(`${RoutePaths.ACCOUNT}?t=billing`)
                }}
            />
        </>
    );
}