import { Strings } from "../constants";

export const isLoggedInUserOwnerOfWorkspace = (loggedInUser, activeWorkSpace) => {
    return loggedInUser._id === activeWorkSpace.owner_id;
}

export const isFreeUser = (user) => {
    let activePlan = user.active_plan;
    return activePlan?.Name === "Free" || activePlan?.Key === "Free";
}

export const isFreePostlyWhiteLabelUser = (user) => {
    let activePostlyWhiteLabelUser = user.plans.active.postly_white_label;
    return activePostlyWhiteLabelUser?.Name === "Free" || activePostlyWhiteLabelUser?.Key === "Free";
}

export const isFreePostlyCloud = (user) => {
    let activePostlyCloudPlan = user.plans.active.postly_cloud;
    return activePostlyCloudPlan?.Name === "Free" || activePostlyCloudPlan?.Key === "Free";
}

export const isFreePostlyWhiteLabel = (user) => {
    let activePostlyWhiteLabelPlan = user.plans.active.postly_white_label;
    return activePostlyWhiteLabelPlan?.Name === "Free" || activePostlyWhiteLabelPlan?.Key === "Free";
}

export const getLoggedInUserFromLocalStorage = () => {
    let localStorageUserString = localStorage.getItem(Strings.LOGGED_IN_USER);
    if (localStorageUserString) {
        return JSON.parse(localStorageUserString);
    }
    return null;
}

export const canLoggedInUserBeDemo = (user) => {
    if (!user) {
        return false;
    }
    let demoEmailAddresses = [
        "test@usepostly.com",
        "collinsrollins07@gmail.com",
        "ponyebuchi@gmail.com",
        // "collins.rollins@postly.ai",
        "paul.onu@mindraytech.com",
        "juoemeka@gmail.com",
        "husseinmubarak500@gmail.com",
        "test@postly.ai",
    ];
    return demoEmailAddresses.includes(user.email);
}

export const excludeUserFromMetrics = (email) => {
    if (!email) {
        return false;
    }
    let demoEmailAddresses = [
        "test@usepostly.com",
        "collinsrollins07@gmail.com",
        "ponyebuchi@gmail.com",
        "collins.rollins@postly.ai",
        "paul.onu@mindraytech.com",
        "juoemeka@gmail.com",
        "husseinmubarak500@gmail.com",
        "test@postly.ai",
    ];
    return demoEmailAddresses.includes(email);
}


export const isWorkSpaceUnderAppSumo = (activeWorkSpace) => {
    const name = activeWorkSpace?.active_plan?.Name

    if (!name) return false
    let isLTD = name.toLowerCase().includes('appsumo') || name.toLowerCase().includes('lifetime') || name.toLowerCase().includes('ltd') || name.toLowerCase().includes('deal')
    return isLTD
}
export const isAccountUnderAppSumo = (account) => {
    const name = account?.active_plan?.Name
    if (!name) return false
    let isLTD = name.toLowerCase().includes('appsumo') || name.toLowerCase().includes('lifetime') || name.toLowerCase().includes('ltd') || name.toLowerCase().includes('deal')
    return isLTD
}

export const userIsOfAnyOfThePlans = (plans = [], account) => {
    const userPlanName = account?.active_plan?.Name;
    const planFound = plans.find(plan => plan === userPlanName);
    return planFound
}
