import FalconCardHeader from 'components/common/FalconCardHeader';
import { RoutePaths } from '../../../../constants';
import React, { useState, useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getLoggedInUser } from 'redux/slices/user_slice';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveWorkSpace, setActiveWorkspace } from 'redux/slices/workspaceslice';

const DelegatedAdditionalWorkspaceSettings = (props) => {

    const { stateHandler } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loggedInUser = useSelector(getLoggedInUser);
    const workSpace = useSelector(getActiveWorkSpace);
    const [workSpaceOwnerDetails, setWorkspaceOwnerDetails] = useState(workSpace.workspace_owner ?? {});
    const [fetchingOwnerDetails, setFetchingOwnerDetails] = useState(false);

    const createNewWorkspaceAsDelegate = (attributes) => {
        navigate(`${RoutePaths.NEW_WORKSPACE}?attributes=${encodeURIComponent(JSON.stringify(attributes))}`);
    }

    const fetchWorkspaceOwnerDetails = (justSetup = false) => {
        setFetchingOwnerDetails(true);
        APIService.fetchWorkSpaceOwner(workSpace._id, (response, err) => {
            setFetchingOwnerDetails(false);
            if (err) {
                toast.error(err, { theme: 'colored' });
                if (err.toLowerCase().includes("owner not found")) {
                    dispatch(setActiveWorkspace(null));
                    navigate(RoutePaths.DEFAULT);
                }
                return;
            }
            if (stateHandler) {
                stateHandler(response.data);
            }
            let { _id, name = "" } = response.data;
            let [first_name, last_name] = name.split(" ");
            let attributes = {
                owner_id: _id,
                delegate_id: loggedInUser._id,
                name,
                first_name,
                last_name
            }
            if (justSetup) {
                setWorkspaceOwnerDetails({
                    id: _id,
                    name
                })
            } else {
                createNewWorkspaceAsDelegate(attributes);
            }
        })
    }

    useEffect(() => {
        fetchWorkspaceOwnerDetails(true);
    }, []);

    return (
        <Card className="mb-3">
            <FalconCardHeader
                title={
                    <h5>
                        Additional Workspaces
                    </h5>
                }
            />
            <Card.Body className="bg-light">
                <Flex alignItems={'center'}>
                    <span style={{ flex: 1 }}>Create a New workspace on behalf of <b>{workSpaceOwnerDetails.name ?? "owner"}</b></span>
                    <Button
                        disabled={fetchingOwnerDetails}
                        variant='outline-success'
                        onClick={() => {
                            if (!workSpaceOwnerDetails?.id) {
                                fetchWorkspaceOwnerDetails();
                            } else {
                                let [first_name, last_name] = workSpaceOwnerDetails?.name?.split(" ");
                                let attributes = {
                                    owner_id: workSpaceOwnerDetails?.id,
                                    delegate_id: loggedInUser._id,
                                    name: workSpaceOwnerDetails.name,
                                    first_name,
                                    last_name
                                }
                                createNewWorkspaceAsDelegate(attributes);
                            }
                        }}>Create</Button>
                </Flex>
            </Card.Body>
        </Card>
    );
};

export default DelegatedAdditionalWorkspaceSettings;