/* eslint-disable eqeqeq */
import Avatar from "components/common/Avatar";
import Flex from "components/common/Flex";
import WriteAndPreviewTabs from 'components/common/WriteAndPreviewTabs';
import { PanelNames } from "constants";
import usePubSub from 'pubsub';
import { useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { getLoggedInUser, getLoggedInUserFullName } from "redux/slices/user_slice";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import CreatePostForm from './CreatePostForm';
import DraftProgressIndicator from './DraftProgressIndicator';
import MediaSyncer from "./media_syncer";
import { getShouldStartSyncingMediasOnPlatformSelection, setShouldStartSyncingMediasOnPlatformSelection } from "redux/slices/postslice";
import Divider from "components/common/Divider";
import AppContext from "context/Context";

const WritePostPanel = (props) => {
    const dispatch = useDispatch();
    const { config } = useContext(AppContext);
    const { addPubSubEventListener, removePubSubEventListener } = usePubSub();
    const publishFeedBack = props.publishFeedBack;
    const loggedInUser = useSelector(getLoggedInUser);
    const loggedInUserFullName = useSelector(getLoggedInUserFullName);
    const workSpace = useSelector(getActiveWorkSpace);
    const shouldStartSyncingMediasOnPlatformSelection = useSelector(getShouldStartSyncingMediasOnPlatformSelection);
    const [workSpaceSocketIOEvent, setWorkSpaceSocketIOEvent] = useState();
    const [mediaUploadProgressSocketIOEvent, setMediaUploadProgressSocketIOEvent] = useState();

    const selectTargetPlatforms = () => {
        console.log('platform selected...')
        if (!shouldStartSyncingMediasOnPlatformSelection) {
            dispatch(setShouldStartSyncingMediasOnPlatformSelection(true));
        }
        if (props.panelChangeHandler) {
            props.panelChangeHandler(PanelNames.PostAudience);
        }
    }

    useEffect(() => {
        let workSpaceEventListener = (response) => {
            setWorkSpaceSocketIOEvent(response);
        }
        let contentUploadProgressEventListener = (response) => {
            setMediaUploadProgressSocketIOEvent(response);
        }
        addPubSubEventListener(`${workSpace?._id}_post_status`, workSpaceEventListener, [{ event: `content_upload_progress_to_${loggedInUser?._id}`, callback: contentUploadProgressEventListener }]);
        return () => {
            removePubSubEventListener(`${workSpace?._id}_post_status`, workSpaceEventListener, [{ event: `content_upload_progress_to_${loggedInUser?._id}`, callback: contentUploadProgressEventListener }]);
        }
    }, []);


    return (
        <Card className='mb-3'>
            <Card.Body className="p-0" id="create-content">
                <div
                    style={{
                        // backgroundColor: config?.isDark ? '#121e2d' : '#E8F1F2',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingRight: '1rem',
                    }}
                >
                    <div
                        className="d-flex justify-content-start align-items-center p-3 gap-2"
                    >
                        {
                            loggedInUser?.avatar ?
                                <Avatar size="l"
                                    src={loggedInUser.avatar} />
                                : <Avatar size='l'
                                    name={loggedInUserFullName}
                                />
                        }
                        <p
                            className="m-0 ml-3"
                            style={{
                                fontWeight: '600'
                            }}
                        >
                            Create a post
                        </p>
                    </div>
                    <DraftProgressIndicator />
                </div>
                <div className='flex-1'></div>

                <CreatePostForm {...props}
                    selectTargetPlatforms={selectTargetPlatforms}
                    publishFeedBack={publishFeedBack}
                    workSpaceSocketIOEvent={workSpaceSocketIOEvent}
                    mediaUploadProgressSocketIOEvent={mediaUploadProgressSocketIOEvent}
                />
            </Card.Body>
            <MediaSyncer

            />
        </Card>
    );

}
export default WritePostPanel;