import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { platforms } from "components/app/platforms";
import { PlatformIdentifier } from "../../../../constants";
import { useEffect, useState } from "react";
import { Nav, Row, Spinner, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import loadable from '@loadable/component';
import APIService from "http/api_service";
import { toast } from "react-toastify";
import { hasPopupBlocker, recommendUrlToUnblockPopup } from "utils/url";
import usePubSub from "pubsub";
import { connectFacebook, connectInstagram, connectLinkedIn, connectTwitter } from "utils/platform_utils";
import ConfirmModal from "components/common/ConfirmModal";
import Divider from "components/common/Divider";
import { useSelector } from 'react-redux';
import { getActiveWorkSpace } from "redux/slices/workspaceslice";

const LoadAccount = (identifier, data, connectAccount, processing) => {
    let Page = loadable(() => import(`./${identifier}`), {
        fallback: <Spinner animation='border' size={20} />
    });
    return <Page data={data} connectAccount={connectAccount} processing={processing} />
}

function findPlatformByIdentifier(refPlatorms, identifier) {
    let platformArrays = Object.entries(refPlatorms).map((entry) => entry);
    let platform = platformArrays.find((x) => x[1]['identifier'] === identifier);
    if (platform) {
        platform = { ...platform[1] };
    }
    return platform;
}

export const whatsApp = {
    name: "WhatsApp",
    color: "#25D366",
    icon: faWhatsapp,
    identifier: PlatformIdentifier.WHATSAPP,
    selected: false,
    metaFamily: true
}

const MessagingAccountsConnector = () => {

    const workSpace = useSelector(getActiveWorkSpace);
    const messageables = ['facebook', 'instagram', 'twitter', 'linked_in'];
    const socialsForMessaging = [
        ...platforms.filter(x => messageables.includes(x.identifier)),
        whatsApp
    ].reverse().sort(x => x.metaFamily ? -1 : 0);
    const [activeTab, setActiveTab] = useState("facebook");
    const [fetchingConnectedAccounts, setFetchingConnectedAccounts] = useState();
    const [retrievedAccounts, setRetrievedAccounts] = useState();
    const popUpUnblockHint = recommendUrlToUnblockPopup();
    const [popupBlocked, setPopupBlocked] = useState(false);
    const [processing, setProcessing] = useState(false);
    const { addPubSubEventListener, removePubSubEventListener } = usePubSub();

    const observeWindow = (win) => {
        let isPopupBlocked = hasPopupBlocker(win);
        if (isPopupBlocked) {
            setPopupBlocked(true);
            return;
        }
        let eventHandler = (data) => {
            setProcessing(false);
            if (!win.closed) {
                win.close();
            }
            if (data['oauth_status'] === "cancelled") {
                toast.error("Integration Cancelled", { theme: 'colored' });
            } else {
                toast.success(data['message'], { theme: 'colored' });
                fetchConnectedAccounts();
            }
            removePubSubEventListener(`${workSpace['_id']}auth`, eventHandler);
        };
        addPubSubEventListener(`${workSpace['_id']}auth`, eventHandler);
        const timer = setInterval(() => {
            if (win.closed) {
                setProcessing(false);
                clearInterval(timer);
                removePubSubEventListener(`${workSpace['_id']}auth`, eventHandler);
            }
        }, 500);
    }

    const fetchConnectedAccounts = () => {
        setFetchingConnectedAccounts(true);
        APIService.fetchWorkSpacePlatforms(workSpace._id, (response, error) => {
            setFetchingConnectedAccounts(false);
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let { data } = response;
            setRetrievedAccounts(data);
        });
    }

    useEffect(() => {
        fetchConnectedAccounts();
    }, []);

    const connectAccount = () => {
        setProcessing(true);
        let platform = findPlatformByIdentifier(socialsForMessaging, activeTab);
        let platformName = platform.name;
        let platformIdentifier = platform.identifier;
        switch (activeTab) {
            case PlatformIdentifier.FACEBOOK:
                connectFacebook(workSpace, platformName, platformIdentifier, (response, error) => {
                    setProcessing(false);
                    if (error) {
                        toast.error(error, { theme: 'colored' });
                        return;
                    }
                    toast.success(response, { theme: 'colored' });
                    localStorage.setItem('pages_messaging', true);
                    fetchConnectedAccounts();
                });
                break;
            case PlatformIdentifier.INSTAGRAM:
                connectInstagram(workSpace, platformName, platformIdentifier, (response, error) => {
                    setProcessing(false);
                    if (error) {
                        toast.error(error, { theme: 'colored' });
                        return;
                    }
                    toast.success(response, { theme: 'colored' });
                    localStorage.setItem('pages_messaging', true);
                    fetchConnectedAccounts();
                });
                break;
            case PlatformIdentifier.TWITTER:
                connectTwitter(workSpace, PlatformIdentifier.TWITTER, (response, error) => {
                    setProcessing(false);
                    if (error) {
                        toast.error(error, { theme: 'colored' });
                        return;
                    }
                    let win = window.open(response.data, "_blank");
                    observeWindow(win);
                });
                break;
            case PlatformIdentifier.LINKED_IN:
                connectLinkedIn(workSpace, PlatformIdentifier.LINKED_IN, (response, error) => {
                    if (error) {
                        setProcessing(false);
                        toast.error(error, { theme: 'colored' });
                        return;
                    }
                    let win = window.open(response.data, "_blank");
                    observeWindow(win);
                });
                break;
            case PlatformIdentifier.WHATSAPP:
                break;
        }
    }

    return (
        <Flex direction={'column'} className={'pt-3'}>
            {
                fetchingConnectedAccounts &&
                <Flex
                    className={'gap-2 p-10'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    direction={'column'}>
                    <Spinner animation="border" />
                    <span>Please wait...</span>
                </Flex>
            }
            {
                !fetchingConnectedAccounts &&
                <Tab.Container
                    id="social-accounts-setup-tabs"
                    activeKey={activeTab}
                    onSelect={(e) => {
                        setActiveTab(e);
                    }}
                >
                    <Row sm={1} className={'px-3 py-0 mb-0 pb-0'}>
                        <Nav variant="tabs" className="border-bottom-0 pb-0 mb-0 flex-row">
                            {
                                socialsForMessaging?.filter(({ identifier }) => identifier === "facebook" || identifier === "twitter" || identifier === "instagram")?.map(x => {
                                    return (
                                        <Nav.Item className="mb-0 pb-0">
                                            <Flex
                                                className={'px-4 gap-2'}
                                                alignItems={'center'}
                                                justifyContent={'center'}>
                                                <FontAwesomeIcon className="p-0 m-0" icon={x.icon} color={x.color} />
                                                <Nav.Link className="p-0 m-0" eventKey={x.identifier}>{x.name}</Nav.Link>
                                            </Flex>
                                        </Nav.Item>
                                    )
                                })
                            }
                        </Nav>
                    </Row>
                    <Divider className={'p-0 m-0'} />
                    <Tab.Content
                        style={{
                            overflowY: 'scroll',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',

                        }}>
                        <Tab.Pane eventKey={activeTab}>
                            {LoadAccount(activeTab, retrievedAccounts?.filter(x => x.identifier === activeTab), connectAccount, processing)}
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            }
            <ConfirmModal
                open={popupBlocked}
                title={"Popup Blocker Detected"}
                message={`Your browser is blocking our pop-ups. As a result you will not be able to connect your <b>${findPlatformByIdentifier(socialsForMessaging, activeTab)?.name}</b> account.\n\n${popUpUnblockHint ? popUpUnblockHint : 'Kindly disable pop-up blocking'} and try again.\n\nYou can re-enable pop-up blocking after connecting ${findPlatformByIdentifier(socialsForMessaging, activeTab)?.name} and other relevant platforms.\n\nThank you`}
                confirmText={"OK"}
                cancelText={"CLOSE"}
                onConfirm={() => {
                    setPopupBlocked(false);
                    setProcessing(false);
                }}
                onCancel={() => {
                    setPopupBlocked(false);
                    setProcessing(false);
                }}
            />
        </Flex>
    );

}
export default MessagingAccountsConnector;