import React, { useRef } from 'react'
import { ImageContainer, MainDragContainer } from './styles/drag_to_position_image'
import { useSelector, useDispatch } from 'react-redux'
import { getManuallyEditedPlatforms, setManuallyEditedPlatforms } from 'redux/slices/postslice'
import { PlatformIdentifier } from 'constants'
import DragDropRenderer from './DragDropRenderer'
import { getMediaArrangement, setMediaArrangement } from 'redux/slices/publishable_media_arrangement_slice'

const DragToPositionImage = (props) => {
    const { platform } = props
    const dispatch = useDispatch();
    const startRef = useRef(null)
    const enterRef = useRef(null)
    // const endRef = useRef(null);
    const manuallyEditedPlatforms = useSelector(getManuallyEditedPlatforms);
    const media_items_arrangement = useSelector(getMediaArrangement);

    const handleMediaUpdate = (media) => {
        const oldData = { ...media_items_arrangement };
        if (platform === PlatformIdentifier.TWITTER) {
            const threadIndex = localStorage.getItem('twitterIndex');
            const newClone = oldData?.twitter?.map((item, index) => {
                if (index === parseInt(threadIndex)) {
                    let newObj = {
                        media, text: item?.text, textEdited: item?.textEdited, mediaEdited: true
                    }
                    return newObj;
                }
                return item;
            });
            dispatch(setMediaArrangement({ ...oldData, twitter: newClone }))
            return
        }
        if (platform === PlatformIdentifier.THREADS) {
            const threadIndex = localStorage.getItem('threadsIndex');
            const newClone = oldData?.threads?.map((item, index) => {
                if (index === parseInt(threadIndex)) {
                    let newObj = {
                        media, text: item?.text, textEdited: item?.textEdited, mediaEdited: true
                    }
                    return newObj;
                }
                return item;
            });
            dispatch(setMediaArrangement({ ...oldData, threads: newClone }))
            return
        }
        if (!manuallyEditedPlatforms[props?.platform]) {
            const newData = { ...manuallyEditedPlatforms, [platform]: true };
            dispatch(setManuallyEditedPlatforms(newData));
        }


        const diapatchableData = {
            ...oldData,
            [platform]: {
                ...oldData?.[platform],
                media,
            },
        };
        dispatch(setMediaArrangement(diapatchableData));
    };


    const handleSorting = () => {
        // duplicate items
        let _images = [...props?.useableMedia];

        // remove and save the drag item content
        const draggedItemContent = _images?.splice(startRef.current, 1)[0];

        // switch the postion
        _images.splice(enterRef.current, 0, draggedItemContent);

        // reset the position ref
        startRef.current = null;
        enterRef.current = null;

        // update the actual array
        props?.setter(_images);
        handleMediaUpdate(_images);
    }

    const dragStart = (e, index) => {
        startRef.current = index;
    }
    const dragEnter = (e, index) => {
        enterRef.current = index;
    }
    const dragEnd = (e, index) => {
        handleSorting();
    }



    const displayImages = props?.useableMedia.map((item, index) => {

        return <DragDropRenderer
            item={item}
            index={index}
            dragStart={dragStart}
            dragEnter={dragEnter}
            dragEnd={dragEnd}
        />
    })
    return (
        <>
            <MainDragContainer>
                {displayImages}
            </MainDragContainer>
        </>
    )
}

export default DragToPositionImage