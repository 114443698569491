import React, { useContext, useEffect, Fragment, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Nav, Navbar, Row, Col } from "react-bootstrap";
import { navbarBreakPoint, topNavbarBreakpoint } from "config";
import AppContext from "context/Context";
import Flex from "components/common/Flex";
import ActiveWorkspace from "components/common/ActiveWorkspace";
import NavbarVerticalMenu from "./NavbarVerticalMenu";
import ToggleButton from "./ToggleButton";
import routes from "routes/routes";
import { capitalize } from "helpers/utils";
import NavbarTopDropDownMenus from "components/navbar/top/NavbarTopDropDownMenus";
// import Footer from '../footer';
import bgNavbar from "assets/img/generic/bg-navbar.png";
import { useSelector } from "react-redux";
import { getLoggedInUser } from "redux/slices/user_slice";
import { canLoggedInUserBeDemo } from "utils/user_utils";
import PurchaseCard from './PurchaseCard';
const currentURL = window.location.href;
console.log("🚀 ~ currentURL:", currentURL)

const NavbarVertical = ({
  lightColor = '#fff',
}) => {
  const { config } = useContext(AppContext);

  const {
    config: {
      navbarPosition,
      navbarStyle,
      isNavbarVerticalCollapsed,
      showBurgerMenu,
    },
  } = useContext(AppContext);

  const loggedInUser = useSelector(getLoggedInUser);
  const [currentPlan] = useState(loggedInUser.active_plan);

  const HTMLClassList = document.getElementsByTagName("html")[0].classList;

  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add("navbar-vertical-collapsed");
    } else {
      HTMLClassList.remove("navbar-vertical-collapsed");
    }
    return () => {
      HTMLClassList.remove("navbar-vertical-collapsed-hover");
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add("navbar-vertical-collapsed-hover");
      }, 100);
    }
  };
  const handleMouseLeave = () => {
    clearTimeout(time);
    HTMLClassList.remove("navbar-vertical-collapsed-hover");
  };

  const NavbarLabel = ({ label }) => (
    <Nav.Item as="li">
      <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
        <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
          {label}
        </Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider"></hr>
        </Col>
      </Row>
    </Nav.Item>
  );

  return (
    <Navbar
      style={{ marginLeft: -15, marginBottom: 30 }}
      expand={navbarBreakPoint}
      className={classNames("navbar-vertical", {
        [`navbar-${navbarStyle}`]: navbarStyle !== "transparent",
      })}
      // className="navbar-vertical"
      variant="card"
    >
      <Flex alignItems="center" className="mb-3">
        <ToggleButton />
        <ActiveWorkspace at="navbar-vertical" width={35} height={27} />
      </Flex>
      <Navbar.Collapse
        in={showBurgerMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          backgroundImage:
            navbarStyle === "vibrant"
              ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})`
              : "none",
        }}
      >
        <div
          className="navbar-vertical-content scrollbar"
          style={{ position: "relative" }}
        >
          <Nav className="flex-column" as="ul">
            {routes
              .filter((x) => {
                let keep = true;
                if (
                  x.visibleToOnlyDemoAccount &&
                  !canLoggedInUserBeDemo(loggedInUser)
                ) {
                  keep = false;
                }
                return keep;
              })
              .map((route) => (
                // <></>
                <Fragment key={route.label}>
                  {!route.labelDisable && (
                    <NavbarLabel label={capitalize(route.label)} />
                  )}
                  <NavbarVerticalMenu
                    canViewMore={route.canViewMore}
                    routes={route.children.filter((x) => {
                      let keep = true;
                      if (
                        x.visibleToOnlyDemoAccount &&
                        !canLoggedInUserBeDemo(loggedInUser)
                      ) {
                        keep = false;
                      }
                      return keep;
                    })}
                  />
                </Fragment>
              ))}
          </Nav>
          <>
            {navbarPosition === "combo" && (
              <div className={`d-${topNavbarBreakpoint}-none`}>
                <div className="navbar-vertical-divider">
                  <hr className="navbar-vertical-hr my-2" />
                </div>
                <Nav navbar>
                  <NavbarTopDropDownMenus />
                </Nav>
              </div>
            )}
            {currentURL.includes("app.postly.ai") &&
              <PurchaseCard />
            }
            {/* <div style={{ height: 18 }}></div> */}
            {/* <div
              style={{
                position: "sticky",
                marginTop: "8px",
                marginBottom: "10px",
                bottom: "0",
                left: "0",
                width: "100%",
                zIndex: 1,
                // borderTop: "1px grey solid",
                boxShadow: "0px -2px 0px rgba(0, 0, 0, 0.1)",
                textAlign: "left",
                backgroundColor: config.isDark ? '#121e2d' : getColor('white')

              }}
            >
              <Flex
                className="mb-3"
                style={{ marginTop: "15px", marginBottom: "10px" }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Logo width={23} fontSize={20} />
              </Flex>

              <Flex className="fs--1 mt-2" justifyContent="between">
                <p className="mb-0">{currentPlan["Name"]} Plan</p>
                {currentPlan.Name === "Free" ? (
                  <Button
                    as={Link}
                    to={RoutePaths.BILLING}
                    variant="primary"
                    className="d-block"
                    size="sm"
                    transform="shrink-5"
                    style={{ padding: "2px 4px", fontSize: "12px" }}
                  >
                    Upgrade
                  </Button>
                ) : null}
              </Flex>

              <Link to="/help" >
                <Flex
                  className="mt-3"
                  style={{ marginTop: "8px" }}
                >
                  <FontAwesomeIcon
                    icon={"question-circle"}
                    className="text-info me-1 mb-3"
                    transform="down-5"
                  />
                  <div className="flex-1">
                    <p>Help Center</p>
                  </div>
                </Flex>
              </Link>
            </div> */}
          </>
          {/* {
            !isNavbarVerticalCollapsed &&
            <Footer alignment={'center'} />
          } */}
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

NavbarVertical.propTypes = {
  label: PropTypes.string,
};

export default NavbarVertical;
