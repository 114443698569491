import ConfirmModal from 'components/common/ConfirmModal';
import FalconCardHeader from 'components/common/FalconCardHeader';
import PageHeader from 'components/common/PageHeader';
import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RoutePaths } from '../../../../../constants';
import FileUploader from '../../../file-uploader';
import BulkPostsPreview from './bulk_content_post_preview';

import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { PiImageLight, PiVideoCamera } from "react-icons/pi";
import { setPostlyCloudPickerMediaType } from 'redux/slices/postslice';
import { getLoggedInUser } from 'redux/slices/user_slice';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { isWorkSpaceUnderAppSumo, userIsOfAnyOfThePlans } from 'utils/user_utils';
import { workspaceIsOfAnyOfThePlans } from 'utils/workspace_utils';
import { setPopupAppreciationMessage, setPopupPrimaryMessage, setPopupSecondaryMessage, setPopupUpgradeAlert, setPopupUpgradeAlertForLTD, setPopupUpgradeAlertGoBackOnCancel } from 'redux/slices/authSlice';
import APIService from 'http/api_service';

const instructionItems = [
    {
        id: 1,
        label: 'You must adhere to the {{CSV template format}}((https://postly.ai/bulk-post)).',
        icon: 'check'
    },
    {
        id: 2,
        label: 'Do not delete or rename any of the header information.',
        icon: 'check'
    },
    { id: 3, label: 'Delete any columns that you do not require for the post.', icon: 'check' },
    {
        id: 4,
        label: 'This CSV template file hosted online cannot be edited directly, please make a copy or download it as a CSV file before updating it with your own content.',
        icon: 'check'
    },
    {
        id: 5,
        label: 'You should connect only the platform channels you want to publish to before uploading the bulk upload CVS file, otherwise, the content will be published to all the connected channels.',
        icon: 'check'
    },
    {
        id: 6,
        label: 'To avoid disconnecting and reconnecting platforms numerous times, please use different Workspaces for your different platforms.',
        icon: 'check'
    },
    {
        id: 7,
        label: 'You can publish a maximum of 500 rows and a minimum of 4 rows at once.',
        icon: 'check'
    },
    {
        id: 8,
        label: 'Please delete all the instructions on the CSV file before uploading. Only the post content is required for the publishing process.',
        icon: 'check'
    },
    {
        id: 9,
        label: ' Postly does not copy over the image and video files you shared via links,  therefore, it is better to not delete the images and videos on the server after publishing.',
        icon: 'check'
    },
    {
        id: 10,
        label: 'Follow the steps below to publish your bulk content.',
        icon: 'check'
    }
];
const downloadCsvItems = [
    {
        id: 1,
        label: 'Download and update the CSV template file.',
        icon: 'check'
    },
    {
        id: 2,
        label: 'You must adhere to the file format and follow the instructions listed above to avoid publishing issues.',
        icon: 'check'
    },
    {
        id: 3,
        label: '{{Download CSV Template}}((https://postly.ai/bulk-post))',
        icon: 'check'
    },
];

const uploadCsvItems = [
    {
        id: 1,
        label: 'Ready with your CSV file for bulk content posting? Upload it here.',
        icon: 'check'
    },
]

const BulkPostsCreator = () => {
    const navigate = useNavigate();
    const [openPreviewer, setOpenPreviewer] = useState(false);
    const [data, setData] = useState([]);
    const workSpace = useSelector(getActiveWorkSpace);
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
    const dispatch = useDispatch();
    const loggedInUser = useSelector(getLoggedInUser);
    const [postLimitations, setPostLimitations] = useState(null)
    // const { status } = useProtectedRoute(['free'], `${RoutePaths.BILLING_STRIPE}/#schedulerSection001`)

    // useEffect(() => {
    //     const userIsOnSuggestedPlan = workspaceIsOfAnyOfThePlans(['Scheduler Premium', 'Scheduler Growth', 'Agency', 'Agency Plus', 'Scheduler Enterprise', 'Scheduler Expansion', 'Scheduler Scale', 'LTD UPGRADE', 'Premium', 'Growth', 'Enterprise', 'Expansion', 'Scale', 'AgencyTwo', 'Agency Plus'], workspace);
    //     if (!userIsOnSuggestedPlan) {
    //         if (isWorkSpaceUnderAppSumo(workspace)) {
    //             dispatch(setPopupUpgradeAlert(true))
    //             dispatch(setPopupUpgradeAlertForLTD(true))
    //             dispatch(setPopupUpgradeAlertGoBackOnCancel(true))
    //         }
    //     }
    // }, [])


    function convertTextToLinks(text) {
        const regex = /{{(.*?)\}\}\(\((.*?)\)\)/g;
        const replacedText = text.replace(regex, '<a href="$2" target="_blank">$1</a>');
        return replacedText;
    }

    useEffect(() => {
        APIService.fetchPostLimitations(workSpace._id, (response, err) => {
            if (err) {
                return;
            }
            let { data } = response;
            setPostLimitations(data)
        });
    }, [])



    return (
        <Flex
            direction={'column'}>

            <PageHeader
                title={'Bulk Content Upload'}
                icon={faFileCsv}
                titleTag="h4"
            />
            <Card className='mt-4'>
                <Card.Header>
                    <h2
                        className='fs-2 mb-0'
                    >
                        Instructions
                    </h2>
                </Card.Header>
                <Card.Body>
                    <ul className="fa-ul ms-2 ps-card mb-2">
                        {instructionItems.map(item => (
                            <li key={item.id} className="py-1">
                                <h6 className="text-700">
                                    <FontAwesomeIcon
                                        icon={item.icon}
                                        className="fa-li text-success"
                                    />
                                    <div dangerouslySetInnerHTML={{ __html: convertTextToLinks(item.label) }}></div>
                                </h6>
                            </li>
                        ))}
                    </ul>
                </Card.Body>
            </Card>
            <Card className='mt-4'>
                <Card.Header>
                    <h2
                        className='fs-2 mb-0'
                    >
                        STEP 1: Download our CSV template
                    </h2>
                </Card.Header>
                <Card.Body>
                    <ul className="fa-ul ms-2 ps-card mb-2">
                        {downloadCsvItems.map(item => (
                            <li key={item.id} className="py-1">
                                <h6 className="text-700">
                                    <FontAwesomeIcon
                                        icon={item.icon}
                                        className="fa-li text-success"
                                    />
                                    <div dangerouslySetInnerHTML={{ __html: convertTextToLinks(item.label) }}></div>
                                </h6>
                            </li>
                        ))}
                    </ul>
                </Card.Body>
            </Card>

            <Card className='mt-4'>
                {/* <FalconCardHeader title="Step 2: Import media links from Postly Media Cloud (optional)" /> */}
                <div className="ms--2 p-3"
                    style={{
                        marginLeft: 3
                    }}
                >
                    <span>STEP 2:</span>{" "}
                    {/* <br /> */}
                    <span>Export your image and video links from  Postly Cloud</span>
                </div>
                <Card.Body>
                    <Flex direction={'column'}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: '40px',

                            }}
                            className="mb-3">
                            <Flex
                                direction={'column'}
                                alignItems={'center'}
                                onClick={() => {
                                    dispatch(setPostlyCloudPickerMediaType('image'))
                                    navigate(RoutePaths.POSTLY_CLOUD);
                                }}
                            >
                                <PiImageLight
                                    size={40}
                                    cursor={'pointer'}
                                />
                                <p
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                >
                                    Image links
                                </p>
                            </Flex>
                            <Flex
                                direction={'column'}
                                alignItems={'center'}
                                onClick={() => {
                                    dispatch(setPostlyCloudPickerMediaType('video'))
                                    navigate(RoutePaths.POSTLY_CLOUD);
                                }}
                            >
                                <PiVideoCamera
                                    size={40}
                                    cursor={'pointer'}
                                />
                                <p
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                >
                                    Video links
                                </p>
                            </Flex>
                        </div>
                    </Flex>
                </Card.Body>
            </Card>
            {
                !openPreviewer &&
                <Card className='mt-4'>
                    <FalconCardHeader
                        title="STEP 3: Upload your final CSV file"
                        endEl={<Flex
                            direction={'column'}
                            alignItems={'flex-end'}
                            justifyContent={'flex-end'}
                        >
                            <div className="fs--1">
                                Monthly limit: {postLimitations?.bulkPosts || 'N/A'} rows
                            </div>
                            <div className="fs--1">
                                Used: {postLimitations?.usedBulkPostsForTheMonth === 0 ? '0' : postLimitations?.usedBulkPostsForTheMonth || 'N/A'} rows
                            </div>
                            <div className="fs--1">
                                Reset on: {(postLimitations?.resetDateForBulkPost && new Date(postLimitations?.resetDateForBulkPost).toLocaleDateString()) || 'N/A'}
                            </div>
                        </Flex>}
                    />
                    <Card.Body className="bg-light">
                        <ul className="fa-ul ms-2 ps-card mb-2">
                            {uploadCsvItems.map(item => (
                                <li key={item.id} className="py-1">
                                    <h6 className="text-700">
                                        <FontAwesomeIcon
                                            icon={item.icon}
                                            className="fa-li text-success"
                                        />
                                        <div dangerouslySetInnerHTML={{ __html: convertTextToLinks(item.label) }}></div>
                                    </h6>
                                </li>
                            ))}
                        </ul>
                        <FileUploader
                            hint={"Select or Drag a CSV file here"}
                            onFileLoaded={(data) => {
                                setData(data);
                                setOpenPreviewer(true);
                            }}
                        />
                    </Card.Body>
                </Card>
            }
            {
                openPreviewer &&
                <BulkPostsPreview
                    data={data}
                    pickNewFile={() => {
                        setOpenPreviewer(false);
                    }}
                    modalClose={() => {
                        setOpenPreviewer(false);
                    }}
                />
            }
            <ConfirmModal
                open={openUpgradeDialog}
                title={"Action requires Upgrade"}
                message={"This feature requires that you are on a premium account"}
                confirmText={"UPGRADE"}
                cancelText={"CANCEL"}
                onCancel={() => {
                    setOpenUpgradeDialog(false);
                    navigate(RoutePaths.POSTS)
                }}
                onConfirm={() => {
                    setOpenUpgradeDialog(false);
                    navigate(RoutePaths.BILLING_STRIPE);
                }}
            />
        </Flex>
    )
}

export default BulkPostsCreator;