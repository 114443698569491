import { faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PlatformData, PlatformIdentifier } from 'constants';
import AppContext from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { darkTheme, lightTheme } from 'utils/theme';
import ImageEditButton from './image_edit_button';
import PlayMediaFb from './playMediaFb';
import { EditPopUp, EditPopUpBtnDiv } from './styles/facebook';
import { ImageDelete, PostImage, PostImageOne } from './styles/linkedin';
import { toast } from 'react-toastify';
import { rescaleImage } from 'utils/media';
import { platformSpecs } from 'utils/platform_utils';

const LinkedinMediaDisplay = ({
    item,
    useableMedia,
    index,
    handleOpeningEditArea,
    handleDeleteMedia,
    openVideoEditArea,
    setOpenVideoEditArea,
    canOPenPopOver,
    expandMedias,
    handleExpansion,
    setUseableMedia
}) => {
    // let editInfoText = `LinkedIn images should be at least 1080 x 1080`;
    let videoInfoText = `LinkedIn video should be at least 1080 x 1080`;
    let editVideoFormat = `Linkedin videos support MP4  & MOV format`;
    let videoSizeError = `Maximum allowed file size is ${PlatformData.LINKEDIN_MAXIMUM_ALLOWED_FILE_UPLOAD_SIZE / 1e6
        }MB`

    const [notValid, setNotValid] = React.useState(false);
    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);
    const { config } = useContext(AppContext);
    const [fixable, setFixable] = useState(true);
    const [editInfoText, setEditInfoText] = useState(`LinkedIn images should be at least 1080 x 1080`);

    function getImageDimension(url) {
        return new Promise(resolve => {
            const image = document.createElement('img');
            image.addEventListener("load", function () {
                const height = this.height;
                const width = this.width;
                resolve({ height, width });
            }, false);
            image.src = url;
        });
    }

    const replaceFaultyMediaWithFixed = async (media, oldMedia, toastMessage) => {
        const _useableMedias = [...useableMedia];
        const newMedias = _useableMedias.map((_media) => {
            if (_media?.url === oldMedia?.url) return media;
            return _media
        })
        setUseableMedia(newMedias)
        toast.info(toastMessage ?? `Your recently added Image have been auto resized to the minimum required size for ${PlatformIdentifier.LINKED_IN}`, {
            theme: 'colored'
        })
    }


    const checkImageValidation = async () => {
        const { width, height } = await getImageDimension(item?.url);
        if (item?.external && (width < platformSpecs[PlatformIdentifier.LINKED_IN]?.image?.minWidth || height < platformSpecs[PlatformIdentifier.LINKED_IN]?.image?.minHeight)) {
            setNotValid(true);
            setFixable(false)
            setEditInfoText("LinkedIn images should be at least 1080 x 1080. Please upload a new image as we cannot fix external images.")

        } else if (!item?.external && (width < platformSpecs[PlatformIdentifier.LINKED_IN]?.image?.minWidth || height < platformSpecs[PlatformIdentifier.LINKED_IN]?.image?.minHeight)) {
            const dataURL = await rescaleImage(item?.url, width, height, platformSpecs[PlatformIdentifier.LINKED_IN]?.image?.minWidth, platformSpecs[PlatformIdentifier.LINKED_IN]?.image?.minHeight, false)
            let _item = { ...item, url: dataURL, optimized: true }
            if (_item?.file && _item?.file?.type) {
                const blob = await fetch(dataURL).then(res => res.blob());
                const file = new File([blob], _item?.file?.name, { type: _item?.file?.type });
                _item = { ..._item, file: file }
            }
            let toastMessage = `Your recently added Image have been auto resized to the minimum required size for ${PlatformIdentifier.LINKED_IN}`
            await replaceFaultyMediaWithFixed(_item, item, toastMessage)

        } else if (item?.file?.type == "image/webp") {
            const dataURL = await rescaleImage(item?.url, width, height, '', '', true)
            let _item = { ...item, url: dataURL, optimized: true }
            if (_item?.file && _item?.file?.type) {
                const blob = await fetch(dataURL).then(res => res.blob());
                const file = new File([blob], _item?.file?.name, { type: "image/png", lastModified: new Date() });
                _item = { ..._item, file: file }
            }
            let toastMessage = `Your recently added Image have been converted to PNG as ${PlatformIdentifier.LINKED_IN} does not support WEBP format`
            await replaceFaultyMediaWithFixed(_item, item, toastMessage)
        }
        setWidth(width);
        setHeight(height);
    }

    useEffect(() => {
        checkImageValidation()
    }, [useableMedia])

    if (useableMedia.length === 1) {
        return (
            index < 4 && (
                <PostImageOne>
                    {item?.is_video === false && notValid && !item?.uploaded && (
                        <div
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                height: "100%",
                                width: "100%",
                                backgroundColor: "#949ca4AA",
                                zIndex: 4,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "65%",
                                        backgroundColor: `${config.isDark
                                            ? darkTheme.workSpaceSettingsHeaderBar
                                            : lightTheme.emptyButtonActive
                                            }`,
                                        padding: 10,
                                        borderRadius: 10,
                                    }}
                                >
                                    <EditPopUp
                                        style={{
                                            color: `${!config.isDark && "black"}`,
                                            fontSize: 15,
                                        }}
                                    >
                                        {editInfoText}
                                        {
                                            fixable &&
                                            <EditPopUpBtnDiv>
                                                <Button
                                                    tintBackground={config.isDark}
                                                    variant="primary"
                                                    onClick={() =>
                                                        handleOpeningEditArea(item?.url, width, height, item?.file)
                                                    }
                                                    style={{ width: "70px" }}
                                                >
                                                    Fix
                                                </Button>
                                            </EditPopUpBtnDiv>
                                        }
                                    </EditPopUp>
                                </div>
                            }
                        </div>
                    )}
                    {!notValid && !item?.external && (
                        <ImageEditButton
                            tintBackground={config.isDark}
                            variant="primary"
                            onClick={() => handleOpeningEditArea(item?.url, width, height)}
                            style={{
                                position: "absolute",
                                margin: 10,
                            }}
                        />
                    )}

                    <ImageDelete
                        editBgColor={!config.isDark ? "#e0e0e0" : "#0b1727"}
                        onClick={(e) => handleDeleteMedia(item)}
                    >
                        <FontAwesomeIcon icon={faRemove} />
                    </ImageDelete>

                    {item.is_video ? (
                        <PlayMediaFb
                            videoEditText={videoInfoText}
                            fullFile={item}
                            fileSizeError={videoSizeError}
                            platform={PlatformIdentifier.LINKED_IN}
                            maxVideoDuration={900}
                            editVideoFormat={editVideoFormat}
                            videoInfoText={videoInfoText}
                            openVideoEditArea={openVideoEditArea}
                            setOpenVideoEditArea={setOpenVideoEditArea}
                            checkingVideo={true}
                            videoHeightLimit={719}
                            videoWidthLimit={1199}
                            canOPenPopOver={canOPenPopOver}
                            url={item.url}
                            file={item?.file}
                        />
                    ) : (
                        <img
                            src={item?.url}
                            alt="post preview"
                            height="100%"
                            width="100%"
                        />
                    )}
                </PostImageOne>
            )
        );
    }
    if (useableMedia.length > 1) {
        const limited = expandMedias ? 10 : 5;
        const isThree = useableMedia.length === 3 ? true : false;
        return (
            index < limited && (
                <PostImage
                    style={{
                        position: "relative",
                        height: useableMedia.length > 4 ? "200px" : "100%",
                        gridArea: !isThree
                            ? !expandMedias && useableMedia.length > 4
                                ? index === 0
                                    ? "header"
                                    : index === 1
                                        ? "menu"
                                        : index === 2
                                            ? "main"
                                            : index === 3
                                                ? "right"
                                                : index === 4
                                                    ? "footer"
                                                    : null
                                : null
                            : "unset",
                        gridRow: index === 0 && isThree && "span 2",
                    }}
                >
                    {item?.is_video === false && notValid && !item?.uploaded && (
                        <div
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                height: "100%",
                                width: "100%",
                                backgroundColor: "#949ca4AA",
                                zIndex: 4,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "80%",
                                        backgroundColor: `${config.isDark
                                            ? darkTheme.workSpaceSettingsHeaderBar
                                            : lightTheme.emptyButtonActive
                                            }`,
                                        padding: 10,
                                        borderRadius: 10,
                                    }}
                                >
                                    <EditPopUp
                                        style={{
                                            color: `${!config.isDark && "black"}`,
                                            fontSize: 13,
                                        }}
                                    >
                                        {editInfoText}
                                        {
                                            fixable &&
                                            <EditPopUpBtnDiv>
                                                <Button
                                                    tintBackground={config.isDark}
                                                    variant="primary"
                                                    onClick={() =>
                                                        handleOpeningEditArea(item?.url, width, height)
                                                    }
                                                    style={{ width: "70px" }}
                                                >
                                                    Fix
                                                </Button>
                                            </EditPopUpBtnDiv>
                                        }
                                    </EditPopUp>
                                </div>
                            }
                        </div>
                    )}
                    {!notValid && !item?.external && (
                        <ImageEditButton
                            tintBackground={config.isDark}
                            variant="primary"
                            onClick={() => handleOpeningEditArea(item?.url, width, height)}
                            style={{
                                position: "absolute",
                                margin: 10,
                            }}
                        />
                    )}
                    <ImageDelete
                        editBgColor={!config.isDark ? "#e0e0e0" : "#0b1727"}
                        onClick={(e) => handleDeleteMedia(item)}
                    >
                        <FontAwesomeIcon icon={faRemove} />
                    </ImageDelete>

                    {item.is_video ? (
                        <PlayMediaFb
                            videoEditText={videoInfoText}
                            fullFile={item}
                            platform={PlatformIdentifier.LINKED_IN}
                            maxVideoDuration={1800}
                            editVideoFormat={editVideoFormat}
                            videoInfoText={videoInfoText}
                            openVideoEditArea={openVideoEditArea}
                            setOpenVideoEditArea={setOpenVideoEditArea}
                            checkingVideo={true}
                            videoHeightLimit={719}
                            videoWidthLimit={1199}
                            canOPenPopOver={canOPenPopOver}
                            url={item.url}
                            file={item?.file}
                        />
                    ) : (
                        <img
                            src={item?.url}
                            alt="post preview"
                            height="100%"
                            width="100%"
                            style={{ objectFit: "fill" }}
                        />
                    )}

                    {!expandMedias && useableMedia.length > 5 && index === 4 && (
                        <div
                            onClick={handleExpansion}
                            style={{
                                position: "absolute",
                                zIndex: 1,
                                backgroundColor: "#00000050",
                                height: "100%",
                                width: "100%",
                                fontSize: 30,
                                color: "white",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                top: 0,
                            }}
                        >
                            +{useableMedia.length - 5}
                        </div>
                    )}
                </PostImage>
            )
        );
    }

}

export default LinkedinMediaDisplay