import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import ReactGA from 'react-ga';
import ErrorBoundary from 'ErrorBoundary';
import { createContext } from 'react';
import NavigationHandler from 'utils/navigationHandler';

ReactGA.initialize('G-QHJNCJNYNC');

export const PubSubContext = createContext();

const App = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <NavigationHandler />
      <ErrorBoundary>
        <Layout />
      </ErrorBoundary>
    </Router>
  );

}

export default App;
