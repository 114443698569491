/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import {
  faEllipsisVertical, faImage,
  faPencil, faRemove,
  faVideo
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmModal from "components/common/ConfirmModal";
import MultimediaSelectionDropdown from "components/common/MultimediaSelectionDropdown";
import CircularButton from "components/common/circularbutton";
import GDriveDashboard from "components/common/g-drive/dashboard";
import AppContext from "context/Context";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { RiDragDropLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getMediaArrangement, setMediaArrangement } from "redux/slices/publishable_media_arrangement_slice";
import { darkTheme, lightTheme } from "utils/theme";
import { PlatformData, PlatformIdentifier } from "../../../../../constants";
import {
  canOpenPixelFilePickerDialog,
  getGMBCTAActionType, getGMBCTAActionURL,
  getGoogleDriveManager,
  getManuallyEditedPlatforms,
  getMediaAddedViaSpecificPlaformUrlMethod,
  getNotifyPreviewsOfLiveMediaChanges,
  getSelectingPhotoFromHome,
  getSpecificMediaPlatformTied, getTimeToPostContent,
  setGoogleDriveManager,
  setManuallyEditedPlatforms,
  setOpenPixelFilePickerDialog,
  setSelectingPhotoFromHome,
  setSpecificMediaPlatformTied
} from "../../../../../redux/slices/postslice";
import { platforms } from "../../../platforms";
import DragToPositionImage from "./DragToPositionImage";
import ExtrernalImageSearchDialogue from "./ExtrernalImageSearchDialogue";
import GDriveConnectionRequestListener from "./GDriveConnectionRequestListener";
import EditTextBox from "./editTextBox.js";
import PlayMediaInstagram from "./playMediaInstagram";
import {
  EditContainer,
  ImageDelete,
  ImageHolder,
  LearnMoreBtn,
  LeftSide,
  Main,
  NameAndTime,
  ParentSection,
  PreviewContentArea,
  RightSide,
  TextsHolder,
  TextsHolderBottom,
  TextsHolderCenter, TopHeader,
  TopHeaderLeft,
  TopHeaderRight,
  Wrapper
} from "./styles/google";
import Flex from "components/common/Flex";
import TextareaAutoSize from "react-textarea-autosize";

const Google = () => {
  const dispatch = useDispatch();
  const googleDriveManager = useSelector(getGoogleDriveManager)
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const googleDriveRef = useRef(null);
  const media_items_arrangement = useSelector(getMediaArrangement);
  const mediaAddedViaSpecificPlaformUrlMethod = useSelector(
    getMediaAddedViaSpecificPlaformUrlMethod
  );
  const [repostionImages, setRepositionImages] = useState(false)
  const openPixelDialoguePicker = useSelector(canOpenPixelFilePickerDialog);
  const mediaObjectsTotalLength = 10;
  const [toggleEditTextInput, setToggleEditTextInput] = useState(false);
  const specificMediaPlatformTied = useSelector(getSpecificMediaPlatformTied);
  const [max_min_imageSize, setMax_min_imageSize] = useState(270);
  const [useableMedia, setUseableMedia] = useState([]);
  const [openMediaSelction, setOpenMediaSelection] = useState(false);
  const [currentMediaType, setCurrentMediaType] = useState(null);
  const selectingPhotoFromHome = useSelector(getSelectingPhotoFromHome);
  const gmbCTAActionUrl = useSelector(getGMBCTAActionURL);
  const gmbCTAActionType = useSelector(getGMBCTAActionType);
  const [limitedPostTextBody, setLimitedPostTextBody] = useState("");
  const timeToPost = useSelector(getTimeToPostContent);
  const [timer, setTimer] = useState();
  const [blockMediaItemsUpdate, setBlockMediaItemsUpdate] = useState(true)
  const manuallyEditedPlatforms = useSelector(getManuallyEditedPlatforms);
  const platformsWithLimits = [...platforms];
  const { config } = useContext(AppContext);
  const notifyPreviewsOfLiveMediaChanges = useSelector(getNotifyPreviewsOfLiveMediaChanges);
  const [textToDisplay, setTextToDisplay] = useState('');
  let typingTimeout;

  useEffect(() => {
    setUseableMedia(media_items_arrangement?.google_my_business?.media);
  }, [notifyPreviewsOfLiveMediaChanges])

  const dispatchEditedFlag = () => {
    if (!manuallyEditedPlatforms[PlatformIdentifier.GOOGLE_MY_BUSINESS]) {
      const newData = { ...manuallyEditedPlatforms, google_my_business: true }
      dispatch(setManuallyEditedPlatforms(newData));
    }
  }

  const limitChars = (text, limit) => {
    setLimitedPostTextBody(text?.substring(0, limit));
  };


  useEffect(() => {
    const shouldOpenTextEdit = localStorage.getItem('open_hashtag_pop');
    if (shouldOpenTextEdit == 'true') {
      handleEditClick();
    }
  }, [])

  const waitTime = 1000;

  function runWhenUserStopTying() {
    window.clearTimeout(timer);
    setTimer(
      window.setTimeout(() => {
        if (!manuallyEditedPlatforms[PlatformIdentifier.GOOGLE_MY_BUSINESS]) {
          limitChars(media_items_arrangement?.google_my_business?.text, 750);
        }
      }, waitTime)
    );
  }

  useEffect(() => {
    runWhenUserStopTying();
  }, [media_items_arrangement?.google_my_business?.text]);

  useEffect(() => {
    limitChars(media_items_arrangement?.google_my_business?.text, 10000);
  }, []);

  useEffect(async () => {
    try {
      let myImg = await document.querySelector("#firstGoogleImage").height;
      if (
        !myImg &&
        useableMedia[0].is_video === true &&
        useableMedia.length > 1
      ) {
        setMax_min_imageSize((prevState) => (prevState = 400));
        return;
      }
      setMax_min_imageSize((prevState) => (prevState = myImg));
    } catch (error) { }
  }, [useableMedia]);

  const updateMediaItems = async (
    files,
    canCreateObjectURL = true,
    moreProps = {},
  ) => {
    let expectedFilesLength = files.length + useableMedia.length;
    if (
      files.length > mediaObjectsTotalLength ||
      useableMedia.length > mediaObjectsTotalLength || expectedFilesLength > mediaObjectsTotalLength
    ) {
      toast.error(
        `Sorry, you can only upload a maximum of ${mediaObjectsTotalLength} files at a time`, { theme: 'colored' }
      );
      return;
    }
    const isVideoExisiting = useableMedia?.filter(
      (item) => item.is_video === true
    );
    if (isVideoExisiting.length > 0 && moreProps?.is_video === true) {
      toast.error(`Sorry, only one video is allowed.`, { theme: 'colored' });
      return;
    }

    let mo = [];
    for (let file of files) {
      if (canCreateObjectURL) {
        let fileUrl = URL.createObjectURL(file);
        let fileName = file.name;
        let existingFile = mo.find((x) => x.url === fileUrl);
        if (!existingFile) {
          let fileExtension = `.${fileName.split(".").pop()}`;
          let newThreadObject = {
            name: fileName,
            url: fileUrl,
            extension: fileExtension,
            file: file,
            ...moreProps,
          };

          if (file.size > PlatformData.MAXIMUM_ALLOWED_FILE_UPLOAD_SIZE) {
            toast.error(
              `Maximum allowed file size is ${PlatformData.MAXIMUM_ALLOWED_FILE_UPLOAD_SIZE / 1e9
              }GB`, { theme: 'colored' }
            );
          } else {
            mo.push(newThreadObject);
          }
        }
      } else {
        let fileWithName = mo.find((item) => item.name === file.name);
        if (!fileWithName) {
          mo.push(file);
        }
      }
    }
    mo?.map((item) => {
      setBlockMediaItemsUpdate(false)
      setUseableMedia((oldArray) => [...oldArray, item]);
    });
    dispatchEditedFlag();
    setOpenMediaSelection(false);
    return;
  };

  const handleEditClick = () => {
    setToggleEditTextInput(true);
  };

  const mediaOpener = (
    <div
      style={{
        position: "relative",
        zIndex: 5,
        marginBottom: 20,
        width: "95%",
        marginLeft: "5%",
        marginTop: 20,
      }}
    >
      {
        <MultimediaSelectionDropdown
          specificPlatformTied={"google_my_business"}
          fromPreview={true}
          initiatorId={
            currentMediaType === "image" ? `${PlatformIdentifier.GOOGLE_MY_BUSINESS}-photo-chooser` : `${PlatformIdentifier.GOOGLE_MY_BUSINESS}-video-chooser`
          }
          externalFilePickerTitle={
            currentMediaType === "image"
              ? "Enter Photo Location"
              : "Enter Video Location"
          }
          prompt={
            currentMediaType === "image" ? "Add Photo From" : "Add Video From"
          }
          fileType={currentMediaType === "image" ? "image" : "video"}
          externalPickerPromptLabel={
            currentMediaType === "image" ? "Photo" : "Video"
          }
          multiple={currentMediaType === "image" ? true : false}
          fileSelectionHandler={(response) => {
            if (response) {
              let { files, from_local, ...others } = response;
              updateMediaItems(files, from_local, others);
            }
          }}
        />
      }
    </div>
  );

  const syncNewThread = async () => {
    let syncingThread = [];
    try {
      const mappedThread = useableMedia?.map((thread, index) => {
        const newThreadEntry = {
          name: thread?.name,
          url: thread?.url,
          is_video: thread?.is_video,
          file: thread?.file
        };
        return newThreadEntry;
      });
      const oldData = { ...media_items_arrangement };
      const diapatchableData = { ...oldData, google_my_business: { ...oldData.google_my_business, media: mappedThread } }
      dispatch(setMediaArrangement(diapatchableData));
    } catch (error) { }
    return syncingThread;
  };


  const handleUpdateTextFromEditBox = (text) => {
    clearTimeout(typingTimeout);
    typingTimeout = setTimeout(() => {
      dispatchEditedFlag()
      const oldData = { ...media_items_arrangement };
      const getTextLimit = platformsWithLimits.find(x => x.identifier === PlatformIdentifier.GOOGLE_MY_BUSINESS)
      const diapatchableData = { ...oldData, google_my_business: { ...oldData.google_my_business, text: text.substring(0, getTextLimit.textLimit) } }
      dispatch(setMediaArrangement(diapatchableData));
    }, 1000);
  }

  useEffect(() => {
    if (!blockMediaItemsUpdate) {
      setBlockMediaItemsUpdate(true)
      syncNewThread();
    }
  }, [useableMedia]);


  const addMediaFromUrl = (obj = mediaAddedViaSpecificPlaformUrlMethod[0]) => {
    const { extension, thumbnail, ...rest } =
      obj;
    setBlockMediaItemsUpdate(false);
    useableMedia.length
      ? setUseableMedia((oldArray) => [...oldArray, rest])
      : setUseableMedia([rest]);

    dispatchEditedFlag();
    dispatch(setSpecificMediaPlatformTied("global"));
    setOpenMediaSelection(false);
    if (googleDriveManager?.docs) {
      dispatch(setGoogleDriveManager({ ...googleDriveManager, docs: null }))
    }
  };

  useEffect(() => {
    if (!googleDriveManager?.docs || localStorage.getItem('gdrivePlatform') !== PlatformIdentifier.GOOGLE_MY_BUSINESS) return;
    addMediaFromUrl(googleDriveManager?.docs);
    immediateActionWhenClieked(true)
  }, [googleDriveManager])

  useEffect(() => {
    specificMediaPlatformTied === "google_my_business" &&
      mediaAddedViaSpecificPlaformUrlMethod.length &&
      addMediaFromUrl();
  }, [mediaAddedViaSpecificPlaformUrlMethod]);

  const toggleMediaSelection = (mediaType) => {
    if (mediaType !== currentMediaType && openMediaSelction) {
      setCurrentMediaType(mediaType);
      dispatch(setSelectingPhotoFromHome(false));
      return;
    }
    setCurrentMediaType(mediaType);
    dispatch(setSelectingPhotoFromHome(false));
    openMediaSelction
      ? setOpenMediaSelection(false)
      : setOpenMediaSelection(true);

    return;
  };

  const mediaRemoveHandler = (removableMediaObject) => {
    try {
      let cachedArrayMedia = useableMedia;
      const filterMedia = cachedArrayMedia?.filter(
        (item) => item.url !== removableMediaObject.url
      );
      setBlockMediaItemsUpdate(false)
      setUseableMedia((prevState) => (prevState = filterMedia));
    } catch (error) { }
  };

  const handleDeleteMedia = (data) => {
    dispatchEditedFlag()
    mediaRemoveHandler(data);
  };

  const handleConnect = async () => {
    try {
      googleDriveRef.current.click();
    } catch (error) {

    }
  }

  useEffect(() => {
    if (googleDriveManager['fromPreviews'] && googleDriveManager['promptConnect']) {
      setOpenConfirmation(true)
    }
  }, [googleDriveManager])

  const immediateActionWhenClieked = (remove) => {
    if (remove) {
      localStorage.removeItem("gdrivePlatform");
      return;
    }
    localStorage.setItem("gdrivePlatform", PlatformIdentifier.GOOGLE_MY_BUSINESS);
  }

  const displayMedias = useableMedia.map((item, index) => {
    return (
      <div
        style={{
          height: max_min_imageSize,
          minWidth: "100%",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 5,
            left: 10,
            backgroundColor: "black",
            fontSize: 9,
            color: "white",
            height: "fit-content",
            width: "fit-content",
            borderRadius: "100%",
            padding: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {index + 1}/{useableMedia.length}
        </div>
        <ImageDelete
          editBgColor={!config.isDark ? "#e0e0e0" : "#0b1727"}
          onClick={(e) => handleDeleteMedia(item)}
        >ha
          <FontAwesomeIcon icon={faRemove} />
        </ImageDelete>
        {item?.is_video ? (
          <PlayMediaInstagram
            url={item?.url}
            maximunHeight={max_min_imageSize > 0 && max_min_imageSize}
            indexer={index}
            external={item?.external}
          />
        ) : (
          <img
            id={index === 0 && "firstGoogleImage"}
            src={item?.url}
            alt="postly previews"
            style={{
              height: "100%",
              width: "100%",
              objectFit: "fill",
            }}
          />
        )}
      </div>
    );
  });

  return (
    <>
      <Flex
        gap={3}
        className={`mt-2`}
        wrap={'wrap'}
      >
        <MultimediaSelectionDropdown
          dropDownAnchor={
            <CircularButton onClick={() => immediateActionWhenClieked(false)} title="Add Image">
              <FontAwesomeIcon icon={faImage} />
            </CircularButton>
          }
          specificPlatformTied={PlatformIdentifier.GOOGLE_MY_BUSINESS}
          fromPreview={true}
          initiatorId={`${PlatformIdentifier.GOOGLE_MY_BUSINESS}-photo-chooser`}
          externalFilePickerTitle={"Enter Photo Location"}
          prompt={"Add Photo From"}
          fileType={"image"}
          externalPickerPromptLabel={"Photo"}
          multiple={true}
          fileSelectionHandler={(response) => {
            if (response) {
              let { files, from_local, ...others } = response;
              updateMediaItems(files, from_local, others);
            }
          }}
        />

        <MultimediaSelectionDropdown
          dropDownAnchor={
            <CircularButton title="Add Video">
              <FontAwesomeIcon icon={faVideo} />
            </CircularButton>
          }
          specificPlatformTied={PlatformIdentifier.GOOGLE_MY_BUSINESS}
          fromPreview={true}
          initiatorId={`${PlatformIdentifier.GOOGLE_MY_BUSINESS}-video-chooser`}
          externalFilePickerTitle={"Enter Video Location"}
          prompt={"Add Video From"}
          fileType={"video"}
          externalPickerPromptLabel={"Video"}
          multiple={false}
          fileSelectionHandler={(response) => {
            if (response) {
              let { files, from_local, ...others } = response;
              updateMediaItems(files, from_local, others);
            }
          }}
        />
        {!toggleEditTextInput && (
          <CircularButton
            editBgColor={!config.isDark ? "#e0e0e0" : "#333333"}
            onClick={() => handleEditClick()}
            title="Edit Text"
          >
            <FontAwesomeIcon icon={faPencil} />
          </CircularButton>
        )}
        <CircularButton
          editBgColor={!config.isDark ? "#e0e0e0" : "#333333"}
          onClick={() => setRepositionImages(!repostionImages)}
          title="re-position images"
        >
          <RiDragDropLine size={20} />
        </CircularButton>
      </Flex>
      <ParentSection>
        <GDriveConnectionRequestListener
          googleDriveRef={googleDriveRef}
        />
        {
          googleDriveManager?.openPicker ? <GDriveDashboard platform={PlatformIdentifier.GOOGLE_MY_BUSINESS} /> : null
        }
        <Wrapper>
          {openMediaSelction && !selectingPhotoFromHome && mediaOpener}
          {toggleEditTextInput && (
            <EditTextBox
              isPlatformEdited={PlatformIdentifier.GOOGLE_MY_BUSINESS}
              value={limitedPostTextBody}
              setValue={setLimitedPostTextBody}
              setToggleEditTextInput={setToggleEditTextInput}
              platform={"google_my_business"}
              setter={{ ...manuallyEditedPlatforms, google_my_business: true }}
              updateText={handleUpdateTextFromEditBox}
            />
          )}
          <PreviewContentArea
            mainBgColor={!config.isDark ? "white" : "#0b1727"}
            style={{
              borderColor: `${!config.isDark ? "#ededed" : "transparent"
                }`,
            }}
          >
            <div style={{ position: 'absolute', bottom: 0, right: 5, fontSize: 9, }}>
              Character Count: {limitedPostTextBody?.length} / 750
            </div>
            {manuallyEditedPlatforms[PlatformIdentifier.GOOGLE_MY_BUSINESS] && (
              <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  fontStyle: "italic",
                  position: "absolute",
                  top: 0,
                  right: 5,
                  fontSize: 11,
                }}
              >
                <p style={{ color: !config.isDark ? "grey" : "grey" }}>
                  Edited
                </p>
              </div>
            )}
            <TopHeader>
              <TopHeaderLeft>
                <img
                  src="https://app.postly.ai/static/media/postly_logo.2e3243cd201fdf7e1b5c.png"
                  alt="profile postly"
                  height="30px"
                  width="30px"
                  style={{ borderRadius: "100%", backgroundColor: "white" }}
                />
                <NameAndTime>
                  <p
                    style={{
                      fontSize: 11,
                      fontWeight: "600",
                      color: `${!config.isDark ? "black" : "whitesmoke"
                        }`,
                    }}
                  >
                    Google My Business Preview
                  </p>
                  <p
                    style={{
                      fontSize: 8,
                      fontWeight: "500",
                      color: `${!config.isDark ? "black" : "whitesmoke"
                        }`,
                    }}
                  >
                    {moment(timeToPost, "SS").fromNow()}
                  </p>
                </NameAndTime>
              </TopHeaderLeft>
              <TopHeaderRight>
                <FontAwesomeIcon
                  style={{
                    color: `${!config.isDark ? "#b0b3b8" : "#b0b3b8"}`,
                  }}
                  icon={faEllipsisVertical}
                />
              </TopHeaderRight>
            </TopHeader>
            <Main>
              <LeftSide>
                {
                  repostionImages ? <DragToPositionImage useableMedia={useableMedia} setter={setUseableMedia} platform={PlatformIdentifier.GOOGLE_MY_BUSINESS} /> :
                    <ImageHolder style={{ objectFit: "fill" }}>
                      {displayMedias}
                    </ImageHolder>
                }
              </LeftSide>
              <RightSide>
                <TextsHolder>
                  <TextsHolderCenter>
                    {/* <TextareaAutoSize
                      id="compose-box"
                      minRows={2}
                      ref={textRef}
                      as="textarea"
                      placeholder={''}
                      className={`shadow-none resize-none border-0 ${config.isDark ? "border-x" : ""
                        } px-2 border-200`}
                      onChange={(e) => {
                        let value = e.target.value;
                        handleUpdateTextFromEditBox(value);
                      }}
                      style={{
                        width: "100%",
                        outline: "none",
                        whiteSpace: "pre-wrap",
                        display: "inherit",
                        background: config.isDark ? "#0b1727" : "#fff",
                        color: "inherit",
                        fontSize: 11,
                      }}
                      maxRows={20}
                    /> */}
                    <pre
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                        fontWeight: "500",
                        color: `${!config.isDark ? "#222222" : "#b3b3b3"
                          }`,
                        maxWidth: "100%",
                        width: "100%",
                        wordWrap: "break-word",
                        whiteSpace: "pre-wrap",
                        fontSize: 13,
                      }}
                    >
                      {limitedPostTextBody}
                    </pre>
                  </TextsHolderCenter>
                  <TextsHolderBottom>
                    <LearnMoreBtn>
                      <a href={`${gmbCTAActionUrl && gmbCTAActionUrl}`} target="_blank" rel="noopener noreferrer"
                        style={{
                          fontSize: 14,
                          fontWeight: "700",
                          color: `${!config.isDark ? "#212121" : "#445fc2"
                            }`,
                        }}
                      >
                        {gmbCTAActionType && gmbCTAActionType.replace(/_/g, ' ')}
                      </a>
                    </LearnMoreBtn>
                  </TextsHolderBottom>
                </TextsHolder>
              </RightSide>
            </Main>
          </PreviewContentArea>
        </Wrapper>
        {/* <EditContainer
          style={{ justifyContent: "space-around" }}
          editBgColor={
            !config.isDark
              ? lightTheme.surfaceColor
              : darkTheme.rootBackground
          }
        >
         
        </EditContainer> */}
        <Modal
          show={openPixelDialoguePicker}
          onHide={() => {
            dispatch(setOpenPixelFilePickerDialog(false));
          }}
          onExit={() => {
            dispatch(setOpenPixelFilePickerDialog(false));
          }}
        >
          <Modal.Header>
            <Modal.Title>Pexels.com</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ExtrernalImageSearchDialogue
              setUseableMedia={setUseableMedia}
              determiner={PlatformIdentifier.GOOGLE_MY_BUSINESS}
              modalClose={() => {
              }}
            />
          </Modal.Body>
        </Modal>
        <ConfirmModal
          open={openConfirmation}
          title={"Connect Your Google Drive Account"}
          message={'Your google drive account is not connected or has been disconnected. Do you wish to connect?'}
          confirmText={"PROCEED"}
          cancelText={"CANCEL"}
          showDialoguePrompt
          onCancel={() => {
            setOpenConfirmation(false);
            dispatch(setGoogleDriveManager({
              ...googleDriveManager,
              promptConnect: false,
            }))
          }}
          onConfirm={() => {
            handleConnect();
            dispatch(setGoogleDriveManager({ ...googleDriveManager, promptConnect: false, fromPreviews: false }))
          }}
        />
      </ParentSection>
    </>
  );
};
export default Google;
