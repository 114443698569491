import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import { PlatformIdentifier } from "constants";
import APIService from "http/api_service";
import React, { useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getPublishablePostMedia, setPublishablePostMedia } from "redux/slices/post_media_slice";
import { getPublishablePostTextBody, setPublishablePostTextBody } from "redux/slices/post_text_body_slice";
import {
  getManuallyEditedPlatforms,
  setManuallyEditedPlatforms,
  setOpenPixelFilePickerDialog,
  setOpenPixelFilePickerDialogForWriteTab,
} from "redux/slices/postslice";
import { getMediaArrangement, setMediaArrangement } from "redux/slices/publishable_media_arrangement_slice";
import EachExternalImageDisplay from "./EachExternalImageDisplay";
import EachExternalVideosDisplay from "./EachExternalVideosDisplay";
import {
  FlexDivInput,
  VideoWrapper,
  Wrapper
} from "./ExtrernalImageSearchDialogueStyle";




export default function ExtrernalImageSearchDialogue(props) {
  console.log("🚀 ~ file: ExtrernalImageSearchDialogue.js:31 ~ ExtrernalImageSearchDialogue ~ props:", props)
  const dispatch = useDispatch();
  const manuallyEditedPlatforms = useSelector(getManuallyEditedPlatforms);
  const [text, setText] = useState();
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const media_items_arrangement = useSelector(getMediaArrangement);
  const mediaObjects = useSelector(getPublishablePostMedia);
  const postTextBody = useSelector(getPublishablePostTextBody);
  const [fileType, setFileType] = useState(localStorage.getItem("fileType"));

  const getPixelImages = async () => {
    if (!text) {
      toast.error(`Invalid Request or empty query.`, { theme: "colored" });
    }
    try {
      setLoading(true);
      APIService.fetchPixelImages(text, (response, error) => {
        if (error) {
          console.log("🚀 ~ file: ExtrernalImageSearchDialogue.js:55 ~ APIService.fetchPixelImages ~ error:", error)
          setImages([]);
          setLoading(false);
          return;
        }
        let { data } = response;
        console.log("🚀 ~ file: ExtrernalImageSearchDialogue.js:61 ~ APIService.fetchPixelImages ~ data:", data)
        setImages(data?.photos);
        setLoading(false);
      });
      // console.log(data);
    } catch (error) {
      setLoading(false);
    }
  };
  const getPixelVideos = async () => {
    if (!text) {
      toast.error(`Invalid Request or empty query.`, { theme: "colored" });
    }
    try {
      setLoading(true);
      APIService.fetchPixelVideos(text, (response, error) => {
        if (error) {
          setVideos([]);
          setLoading(false);
          return;
        }
        let { data } = response;
        // console.log("🚀 ~ file: ExtrernalImageSearchDialogue.js:83 ~ APIService.fetchPixelVideos ~ data:", data)
        setVideos(data?.videos);
        setLoading(false);
      });
      // console.log(data);
    } catch (error) {
      setLoading(false);
    }
  };


  const handleClick = async (src, photographer, file) => {
    let newObj;
    if (!file) {
      newObj = {
        external: true,
        file: null,
        name: src,
        optimized: true,
        uploaded: true,
        url: src,
      };
    } else {
      newObj = {
        external: false,
        file: file,
        is_video: true,
        name: src,
        optimized: false,
        uploaded: false,
        url: src,
      };
    }
    if (props?.fromWriteTab) {
      let newPost = `${postTextBody}
      

      media by: ${photographer}
    `;
      dispatch(setPublishablePostTextBody(newPost));
      dispatch(setPublishablePostMedia([...mediaObjects, newObj]));
      dispatch(setOpenPixelFilePickerDialogForWriteTab(false));
      return;
    }
    const oldData = { ...media_items_arrangement };
    if (props?.determiner === PlatformIdentifier.PINTEREST) {
      const newMedia = [newObj];
      props?.setUseableMedia(newMedia);
      const diapatchableData = {
        ...oldData,
        [props?.determiner]: {
          ...oldData?.[props?.determiner],
          media: newMedia,
        },
      };
      if (!manuallyEditedPlatforms[props?.determiner]) {
        const newData = { ...manuallyEditedPlatforms, [props?.determiner]: true };
        dispatch(setManuallyEditedPlatforms(newData));
      }
      dispatch(setMediaArrangement(diapatchableData));
      dispatch(setOpenPixelFilePickerDialog(false));
      return;
    }
    if (props?.determiner === PlatformIdentifier.THREADS) {
      const threadsMediaClone = { ...media_items_arrangement };
      const threadIndex = parseInt(localStorage.getItem("threadsIndex"));
      localStorage.removeItem("threadsIndex");
      let newText;
      const newClone = threadsMediaClone?.threads?.map((item, index) => {
        newText = `${item.text}
        
        photo by: ${photographer}
        `
        if (index === threadIndex) {
          let medias;
          if (item?.media?.length) {
            let newMedia = [...item?.media];
            newMedia.push(newObj);
            medias = newMedia;
          } else {
            medias = [newObj];
          }
          let Obj = {
            media: medias,
            text: `${item.text} ...media by: ${photographer}`,
            mediaEdited: true,
            textEdited: true,
            // textEdited: item?.textEdited,
          };
          return Obj;
        }
        return item;
      });
      dispatch(
        setMediaArrangement({
          ...media_items_arrangement,
          threads: newClone,
        })
      );
      props?.setChunkedTweet(newClone);  //TODO: 101 - check this out. It is originally for twitter.
      dispatch(setOpenPixelFilePickerDialog(false));
      return;
    }
    if (props?.determiner === PlatformIdentifier.TWITTER) {
      const twitterMediaClone = { ...media_items_arrangement };
      const threadIndex = parseInt(localStorage.getItem("twitterIndex"));
      localStorage.removeItem("twitterIndex");
      let newText;
      const newClone = twitterMediaClone?.twitter?.map((item, index) => {
        newText = `${item.text}
        
        photo by: ${photographer}
        `
        if (index === threadIndex) {
          let medias;
          if (item?.media?.length) {
            let newMedia = [...item?.media];
            newMedia.push(newObj);
            medias = newMedia;
          } else {
            medias = [newObj];
          }
          let Obj = {
            media: medias,
            text: `${item.text} ...media by: ${photographer}`,
            mediaEdited: true,
            textEdited: true,
            // textEdited: item?.textEdited,
          };
          return Obj;
        }
        return item;
      });
      dispatch(
        setMediaArrangement({
          ...media_items_arrangement,
          twitter: newClone,
        })
      );
      props?.setChunkedTweet(newClone);
      dispatch(setOpenPixelFilePickerDialog(false));
      return;
    }
    let newText = `${oldData?.[props?.determiner]?.text}
    
    media by: ${photographer}
  `;
    const newMedia = [...oldData?.[props?.determiner]?.media, newObj];
    props?.setUseableMedia(newMedia);
    const diapatchableData = {
      ...oldData,
      [props?.determiner]: {
        ...oldData?.[props?.determiner],
        media: newMedia,
        text: newText,
      },
    };

    if (!manuallyEditedPlatforms[props?.determiner]) {
      const newData = { ...manuallyEditedPlatforms, [props?.determiner]: true };
      dispatch(setManuallyEditedPlatforms(newData));
    }

    dispatch(setMediaArrangement(diapatchableData));
    dispatch(setOpenPixelFilePickerDialog(false));
  };



  const imagesDisplay = images?.map((x, i) => {
    // console.log(x, 'as x')
    return <EachExternalImageDisplay handleClick={handleClick} x={x} key={i} />
  });
  const videosDisplay = videos?.map((x, i) => {
    return <EachExternalVideosDisplay handleClick={handleClick} x={x} key={i} />
  });

  const displayImagesArea = (
    <>
      {
        fileType === 'image' ? <Wrapper>{imagesDisplay}</Wrapper> : <VideoWrapper>{videosDisplay}</VideoWrapper>
      }
    </>
  );

  const mediaSwitchHandler = (media) => {
    setFileType(media)
  }

  return (
    <>
      <Flex direction={"column"}>
        <Flex direction={"column"}>
          <Dropdown className="btn-reveal-trigger">
            <Dropdown.Toggle
              variant="link"
              size="sm"
              className="p-0 m-0 text-decoration-none text-600"
              style={{ marginBottom: "10px" }}
            >
              <span id='global-video-span'>
                Select Media Types
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ width: 'fit-content' }} className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg p-2" align={"end"}>
              <Dropdown.Header>Choose Media Type</Dropdown.Header>
              <Dropdown.Item onClick={() => mediaSwitchHandler('image')}>
                Images
              </Dropdown.Item>
              <Dropdown.Item onClick={() => mediaSwitchHandler('video')}>
                Videos
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <FlexDivInput>
            <Form.Control
              placeholder={fileType === 'image' ? "Search for images here" : "Search for videos here"}
              className="rounded-2 resize-none border-y-10 px-card border-300 mb-2"
              value={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.keyCode == 13) {
                  fileType !== 'video' ? getPixelImages() : getPixelVideos()
                }
              }}
            />
            <Button
              className="btn-primary"
              style={{ fontSize: 15, whiteSpace: "nowrap", marginTop: -6 }}
              onClick={() => {
                fileType !== 'video' ? getPixelImages() : getPixelVideos()
              }}
            >
              {loading ? (fileType === "image" ? 'Getting images' : 'Getting videos') : (fileType === "image" ? 'Get Images' : 'Get Videos')}
            </Button>
          </FlexDivInput>
          {displayImagesArea}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 5,
            }}
          >
            <FontAwesomeIcon
              icon={faInfoCircle}
              style={{
                width: 12,
                height: 12,
              }}
            />
            <p style={{ fontSize: 10, marginTop: 12 }}>Note that any {fileType === 'image' ? 'images' : 'videos'} used here will have to be credited to the photographer and to pexels.com</p>
          </div>
        </Flex>
      </Flex>
      {/* <ConfirmModal
        open={openConfirmation}
        title={"Confirm Citation"}
        message={'Using this image will attach a citation to the photographer and Pixel to your final post. Do you still wish to proceed?'}
        confirmText={"PROCEED"}
        cancelText={"CANCEL"}
        showDialoguePrompt
        onCancel={() => {
          setOpenConfirmation(false);
        }}
        onConfirm={() => {
          setOpenConfirmation(false);
          handleClick(src);
        }}
      /> */}
    </>
  );
}
