import FalconCardHeader from 'components/common/FalconCardHeader';
import { WorkSpaceContentApprovalWorkFlow } from '../../../../constants';
import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Switch from "react-switch";
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import { cachePosts } from 'redux/slices/postslice';
import { useDispatch, useSelector } from 'react-redux';
import {
    getActiveWorkSpace,
    setActiveWorkspace
} from 'redux/slices/workspaceslice';

const PostsSettings = () => {

    const workSpace = useSelector(getActiveWorkSpace);
    let workSpaceSettings = workSpace.settings ?? {};
    const [turnOffAllPostsAndCampaigns, setTurnOffAllPostsAndCampaigns] = useState(workSpaceSettings[WorkSpaceContentApprovalWorkFlow.TURN_OFF_ALL_POSTS_AND_CAMPAIGNS] ?? false);
    const dispatch = useDispatch();

    const commitTurnOff = (newValue) => {
        APIService.turnOffAllWorkSpacePostsAndCampaigns(workSpace._id, newValue ? "yes" : "no", (response, error) => {
            if (error) {
                if (error.includes("turn off yet")) {
                    toast.info(error, { theme: 'colored' });
                } else {
                    toast.error(error, { theme: 'colored' });
                }
                return;
            }
            let { message } = response;
            toast.success(message, { theme: 'colored' });
            dispatch(cachePosts([]));
            let workSpaceSetings = workSpace.settings ?? {};
            workSpaceSetings[WorkSpaceContentApprovalWorkFlow.TURN_OFF_ALL_POSTS_AND_CAMPAIGNS] = newValue;
            dispatch(setActiveWorkspace({
                ...workSpace,
                settings: workSpaceSetings
            }));
        });
    }

    return (
        <Card className="mb-3">
            <FalconCardHeader
                title={
                    <h5>
                        Posts Settings
                    </h5>
                }
            />
            <Card.Body className="bg-light">
                <Flex direction={'column'}>
                    <Flex alignItems={'center'}>
                        <span style={{ flex: 1 }}>Turn Off All Scheduled posts/Campaigns</span>
                        <Switch
                            checked={turnOffAllPostsAndCampaigns}
                            onChange={() => {
                                let newValue = !turnOffAllPostsAndCampaigns;
                                setTurnOffAllPostsAndCampaigns(newValue);
                                commitTurnOff(newValue);
                            }}
                        />
                    </Flex>
                </Flex>
            </Card.Body>
        </Card>
    );
};

export default PostsSettings;