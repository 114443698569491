import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    changedPlatform: null,
    targetPlatforms: [],
    updatedAccount: {},
    selectedPlatformForPreview: null,
    disconnectedPlatforms: null,
    audienceGroupsController: {
        data: [],
        canModify: false,

    },
    platformChangeCounter: 0,
    autoFixedPlatformMediasBank: {},
    selectedRSSPlatforms: [],
    reconnectAccount: null,
};

export const platformSlice = createSlice({
    name: 'platform',
    initialState,
    reducers: {
        setChangedPlatform: (state, action) => {
            state.changedPlatform = action.payload
        },
        setChangedTargetPlatforms: (state, action) => {
            state.targetPlatforms = action.payload;
        },
        setUpdatedAccount: (state, action) => {
            state.updatedAccount = action.payload;
        },
        setSelectedPlatformForPreview: (state, action) => {
            state.selectedPlatformForPreview = action.payload;
        },
        setDisconnectedPlatforms: (state, action) => {
            state.disconnectedPlatforms = action.payload;
        },
        setAudienceGroupsController: (state, action) => {
            state.audienceGroupsController = action.payload;
        },
        setPlatformChangeCounter: (state, action) => {
            state.platformChangeCounter = action.payload;
        },
        setAutoFixedPlatformMediasBank: (state, action) => {
            state.autoFixedPlatformMediasBank = action.payload;
        },
        setSelectedRSSPlatforms: (state, action) => {
            state.selectedRSSPlatforms = action.payload;
        },
        setReconnectAccount: (state, action) => {
            state.reconnectAccount = action.payload;
        },
    }
});

export const getChangedPlatform = (state) => state.platform.changedPlatform;
export const getTargetPlatforms = (state) => state.platform.targetPlatforms;
export const getUpdatedAccount = (state) => state.platform.updatedAccount;
export const getSelectedPlatformForPreview = (state) => state.platform.selectedPlatformForPreview;
export const getDisconnectedPlatforms = (state) => state.platform.disconnectedPlatforms;
export const getAudienceGroupsController = (state) => state.platform.audienceGroupsController;
export const getPlatformChangeCounter = (state) => state.platform.platformChangeCounter;
export const getSelectedRSSPlatforms = (state) => state.platform.selectedRSSPlatforms;
export const getAutoFixedPlatformMediasBank = (state) => state.platform.autoFixedPlatformMediasBank;
export const getReconnectAccount = (state) => state.platform.reconnectAccount;

export const {
    setChangedPlatform,
    setChangedTargetPlatforms,
    setUpdatedAccount,
    setSelectedPlatformForPreview,
    setDisconnectedPlatforms,
    setAudienceGroupsController,
    setPlatformChangeCounter,
    setAutoFixedPlatformMediasBank,
    setSelectedRSSPlatforms,
    setReconnectAccount
} = platformSlice.actions;
export default platformSlice.reducer;