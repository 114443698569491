import React, { useState } from "react";
import { Button, Card, Spinner, Table } from "react-bootstrap";
import Flex from "components/common/Flex";
import Divider from "components/common/Divider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faClose } from "@fortawesome/free-solid-svg-icons";
import IconAlert from "components/common/IconAlert";
import APIService from "http/api_service";
import ConfirmModal from "components/common/ConfirmModal";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { getDomains, setDomains } from "redux/slices/links_shortener_slice";
import { useDispatch, useSelector } from "react-redux";
import CopyLinkButton from "components/common/copylinkbutton";
import { copyToClipboard } from "utils/browser";
import CopyIcon from "../../../assets/svgs/CopyIcon.svg";
import useProtectedRoute from "hooks/useProtectedRoute";
import { RoutePaths } from "constants";

const CustomDomainSettingsPage = () => {
  const dispatch = useDispatch();

  //selectors
  const domains = useSelector(getDomains);

  const [customDomains, setCustomDomains] = useState(domains);
  const [newDomainName, setNewDomainName] = useState();
  const [addingNewDomain, setAddingNewDomain] = useState(false);
  const [openRemoveDomainPrompt, setOpenRemoveDomainPrompt] = useState(false);
  const [removableDomain, setRemovableDomain] = useState();
  const [openMakePrimary, setOpenMakePrimary] = useState();
  const [removingDomain, setRemovingDomain] = useState(false);
  const [verifiableDomain, setVerifiableDomain] = useState();
  const [onLoading, setOnloading] = useState(false);
  const [verificationNeeded, setVerificationNeeded] = useState(false); // [true, false, 'pending'
  const [responseFromDomainPreparation, setResponseFromDomainPreparation] =
    useState(); // [true, false, 'pending'
  const [domainVerificationError, setDomainVerificationError] = useState();
  const [error, setError] = useState();
  const { status } = useProtectedRoute(['free'], `${RoutePaths.ALL_BILLING}/#schedulerSection001`)


  const prepareCustomDomainForWorkspace = (domain) => {
    APIService.prepareCustomDomainForWorkspace(domain, (response, error) => {
      if (error) {
        console.log(error);
        setAddingNewDomain(false);
        return;
      }
      setAddingNewDomain(false);
      setRemovingDomain(false);
      const { data, message } = response;
      setResponseFromDomainPreparation(data.data);
      if (data.verificationNeeded) {
        setVerificationNeeded(true);
      } else {
        setVerificationNeeded(false);
      }
    });
  };

  const getAllDomain = async () => {
    setOnloading(true);
    APIService.fetchDomains((response, error) => {
      if (error) {
        console.log(error);
        setOnloading(false);
        toast.error(error, { theme: "colored" });
        return;
      }
      setOnloading(false);
      const { data, message } = response;
      if (!data) {
        if(message !=="No domain available"){
          toast.error(message, { theme: "colored" });
        }
        return;
      }
      dispatch(setDomains(data));
      setCustomDomains(data);
      console.log(
        "🚀 ~ file: CustomDomain.jsx:69 ~ APIService.fetchDomains ~ data:",
        data
      );
    });
  };

  const updateWorkspace = (customDomainsCopy) => {
    APIService.updateWorkSpaceCustomDomains(
      customDomainsCopy,
      (response, error) => {
        if (error) {
          console.log(error);
          setAddingNewDomain(false);
          return;
        }
        prepareCustomDomainForWorkspace(newDomainName);
      }
    );
  };

  const handleNewDomainAddition = (e) => {
    e.preventDefault();
    // toast.info(newDomainName);
    // return;
    if (!newDomainName) {
      return;
    }
    if (!newDomainName.includes(".")) {
      setError("Invalid domain entered");
      return;
    }
    if (newDomainName.includes("postly.link")) {
      setError("Oops! Not acceptable");
      return;
    }

    if (newDomainName.includes("postly.click")) {
      setError("Oops! Not acceptable");
      return;
    }
    setAddingNewDomain(true);
    // let customDomainsCopy = [...customDomains];
    // if (!customDomainsCopy.includes(newDomainName.toLowerCase())) {
    //     customDomainsCopy.push(newDomainName.toLowerCase());
    //     updateWorkspace(customDomainsCopy);
    // }
    let requestBody = {
      custom_domain: newDomainName,
    };

    APIService.addDomain(requestBody, (response, error) => {
      if (error) {
        console.log(error);
        toast.error(error, { theme: "colored" });
        setAddingNewDomain(false);
        return;
      }
      const { message } = response;
      toast.success(message);
      setAddingNewDomain(false);
      getAllDomain();
      setNewDomainName("");
    });
  };

  const removeDomain = async () => {
    setRemovingDomain(true);
    // remove domain with id: removableDomain
    APIService.removeDomainFromWorkSpace(
      { custom_domain: removableDomain?.id },
      (response, error) => {
        if (error) {
          console.log(error);
          setRemovingDomain(false);
          return;
        }
        setRemovingDomain(false);
        const { message } = response;
        toast.success(message);
        getAllDomain();
      }
    );
  };

  const getDomainVerificationStatements = () => {
    const verifications = responseFromDomainPreparation?.verification;
    return verifications?.map((x) => {
      const type = x.type;
      if (type === "TXT") {
        return [
          `Go to your domain provider (where you have hosted or bought your domain from)`,
          `Go to your DNS settings`,
          `Add a ${type} record with the following details;`,
          `Domain: <b>${x.domain}</b>`,
          `value: <b>${x.value}</b>`,
          `reason: <b>${x.reason}</b>`,
          `Save the record`,
          `We will verify and activate your custom domain if all is properly set up.`,
        ].map((x) => <li dangerouslySetInnerHTML={{ __html: x }}></li>);
      }
      if (type === "CNAME") {
        return [
          `Go to your domain provider (where you have hosted or bought your domain from)`,
          `Go to your DNS settings`,
          `Add a ${type} record with the following details;`,
          `Host: <b>${x.host}</b>`,
          `Points to: <b>${x.pointsTo}</b>`,
          `Save the record`,
          `We will verify and activate your custom domain if all is properly set up.`,
        ].map((x) => <li dangerouslySetInnerHTML={{ __html: x }}></li>);
      }
      return [`We have no verification steps as of now.`].map((x) => (
        <li dangerouslySetInnerHTML={{ __html: x }}></li>
      ));
    });
  };

  useEffect(() => {
    domains === null && getAllDomain();
  }, []);

  // onLoading
  return (
    <div>
      {onLoading ? (
        <div
          style={{
            width: "100%",
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" />
        </div>
      ) : (
        <Flex direction={"column"} style={{ width: "100%" }}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-end">
                <Button type="button" onClick={getAllDomain}>Refresh</Button>
              </div>
              <Divider />
              <Flex direction={"column"} className={"gap-2"}>
                {customDomains?.length < 1 && (
                  <>
                    <span>You have not set up any custom domains</span>
                    <Divider />
                  </>
                )}
                {customDomains?.length > 0 && (
                  <Table striped bordered hover variant="default">
                    <thead>
                      <tr>
                        <th>Domain Name</th>
                        <th>DNS Record Type</th>
                        <th>DNS Target</th>
                        <th></th>
                      </tr>
                      {customDomains?.map((x) => {
                        return (
                          <tr>
                            <td>
                              {x?.hostname}{" "}
                              <span>
                                {x?.acm_status === "cert issued" ? (
                                  <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    style={{
                                      color: "green",
                                    }}
                                  />
                                ) : (
                                  <div
                                    style={{ color: "red", fontSize: "10px" }}
                                  >
                                    Pending verification
                                  </div>
                                )}
                              </span>
                            </td>
                            <td>CNAME</td>
                            <td style={{ fontSize: "12px" }}>
                              {x?.cname}{" "}
                              <CopyLinkButton
                                defaultText={<img src={CopyIcon} alt="copy" />}
                                variant={"outline-info"}
                                handleCopy={() => {
                                  copyToClipboard(x?.cname);
                                }}
                                style={{
                                  fontSize: "12px",
                                  padding: "5px",
                                  backgroundColor: "inherit",
                                  border: "none",
                                }}
                              />
                            </td>
                            <td>
                              {" "}
                              {removingDomain &&
                              removableDomain?.id === x?.id &&
                              verifiableDomain !== x ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  style={{ cursor: "pointer" }}
                                  icon={faClose}
                                  onClick={() => {
                                    setRemovableDomain(x);
                                    setOpenRemoveDomainPrompt(true);
                                  }}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </thead>
                    <tbody></tbody>
                  </Table>
                )}

                <form
                  onSubmit={handleNewDomainAddition}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Flex
                    direction={"column"}
                    style={{
                      flex: 1,
                    }}
                  >
                    <Card.Title style={{ fontSize: "12px" }}>
                      Add you domain below
                    </Card.Title>
                    <input
                      disabled={addingNewDomain}
                      value={newDomainName}
                      onChange={(e) => {
                        let enteredValue = e.target.value;
                        setNewDomainName(enteredValue);
                        if (!enteredValue) {
                          setError(null);
                        }
                      }}
                      placeholder="www.example.com"
                      style={{
                        display: "block",
                        border: "none",
                        outline: "none",
                        backgroundColor: "inherit",
                        color: "inherit",
                      }}
                    />
                    <span className="fs--1 py-3" style={{ color: "red" }}>
                      {error}
                    </span>
                  </Flex>
                  {newDomainName && (
                    <Button type="submit" disabled={addingNewDomain}>
                      {addingNewDomain ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Add Domain"
                      )}
                    </Button>
                  )}
                </form>
              </Flex>
              {verificationNeeded && (
                <Flex direction={"column"} className={"gap-2"}>
                  <p>
                    <b>
                      To proceed into using{" "}
                      {responseFromDomainPreparation?.name}, follow the steps
                      below;
                    </b>
                  </p>
                  <ol>{getDomainVerificationStatements()}</ol>
                </Flex>
              )}
              <br />
              <IconAlert
                variant={"info"}
                dismissible={false}
                style={{ backgroundColor: "inherit" }}
              >
                {/* <p className="mb-0">HOW TO ADD POSTLY TO YOUR DNS RECORD</p> */}
                {/* <p className="mb-0">
                Make sure to point to <b>postly.link</b> in your DNS settings
              </p> */}
                <p>Setting Up Custom Domain: Quick Steps</p>
                <ol>
                  <li>
                    <strong>Provide Your Domain:</strong> Input your custom
                    domain name that you want to add the CNAME record to.
                  </li>
                  <li>
                    <strong>Generate CNAME Details:</strong> Our system
                    generates specific CNAME details, including type and target.
                  </li>
                  <li>
                    <strong>Access DNS Registrar Settings:</strong> Navigate to
                    the DNS settings for the domain you want to modify.
                  </li>
                  <li>
                    <strong>Add CNAME Record:</strong> Click on the "Add Record"
                    button to create a new DNS record.
                    <ul>
                      <li>
                        <strong>Type:</strong> Select "CNAME" from the dropdown
                        menu.
                      </li>
                      <li>
                        <strong>Name:</strong> If "@" and "www" records are
                        already in use, please use a subdomain instead e.g
                        (docs.postly.ai).
                      </li>
                      <li>
                        <strong>Target:</strong> Copy the target generated in
                        the previous step and paste it in the "Target" field.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Save Changes:</strong> Click on the "Save" or "Add
                    Record" button to save the new CNAME record.
                  </li>
                  <li>
                    <strong>Wait for Validation:</strong> Propagation may some
                    time or take up to 48 hours.
                  </li>
                </ol>
              </IconAlert>
              {/* <Button className="p-2 px-4">Test Domains</Button> */}
            </Card.Body>
          </Card>
        </Flex>
      )}
      <ConfirmModal
        open={openRemoveDomainPrompt}
        message={"Are you sure about removing this domain?"}
        title={`Remove ${removableDomain?.hostname}?`}
        onConfirm={() => {
          setOpenRemoveDomainPrompt(false);
          removeDomain();
        }}
        onCancel={() => {
          setOpenRemoveDomainPrompt(false);
        }}
        cancelText={"CANCEL"}
        confirmText={"REMOVE"}
      />
    </div>
  );
};

export default CustomDomainSettingsPage;
