import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setFacebookFirstComment } from "redux/slices/postslice";

const FBCommentView = () => {
  const dispatch = useDispatch();

  const handleTextChange = (e) => {
    const value = e.target.value;
    console.log(value, "__facebook_first_comment__");
    dispatch(setFacebookFirstComment(value));
  };

  return (
    <>
      <Form.Control
        as="textarea"
        rows={3}
        style={{
          padding: "10px",
        }}
        placeholder="Type first comment"
        onChange={(e) => {
          handleTextChange(e);
        }}
      />
    </>
  );
};

export default FBCommentView;
