/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { faUser } from "@fortawesome/free-solid-svg-icons";
import Avatar from "components/common/Avatar";
import Flex from "components/common/Flex";
import CircularButton from "components/common/circularbutton";
import AppContext from "context/Context";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  getWordPressTitle,
  getWordpressCategories, getWordpressFeaturedImageThumbnail, getWordpressPostTags
} from "redux/slices/postslice";
import { getMediaArrangement, setMediaArrangement } from "redux/slices/publishable_media_arrangement_slice";
import { getLoggedInUser } from "redux/slices/user_slice";
import "./addOnsStyles.css";
import RichEditor from "./rich_editor";
import {
  BodyArea, ContentHeader, ContentHeaderItem,
  ContentHeaderTitle, EditContainer, ImageSection, ParentSection,
  PreviewContentArea, Wrapper
} from "./styles/wordpress";
import { Info } from "./styles/facebook";
import { displayPlatformIcon } from "utils/platform_utils";
import { PlatformIdentifier } from "constants";

const Wordpress = () => {
  const { config } = useContext(AppContext);
  const loggedInUser = useSelector(getLoggedInUser);
  const [openRichTextEditor, setOpenRichTextEditor] = useState(false);
  const bodyRef = useRef(null);
  const [bodyText, setBodyText] = useState(null);
  const media_items_arrangement = useSelector(getMediaArrangement);
  const wordpressTitle = useSelector(getWordPressTitle);
  const wordpressFeaturedImage = useSelector(getWordpressFeaturedImageThumbnail);
  const wordpressTags = useSelector(getWordpressPostTags);
  const wordpressCategory = useSelector(getWordpressCategories);
  const dispatch = useDispatch();

  const date = new Date();

  const isHtml = (str) => {
    const regex = /(<([^>]+)>)/gi;
    return regex.test(str);
  };

  const initialAssignments = () => {
    const wordpressBodyText = media_items_arrangement?.wordpress?.text;
    const isHtmlCode = isHtml(wordpressBodyText);
    if (isHtmlCode) {
      setBodyText(wordpressBodyText)
    } else {
      setBodyText(`<div>${wordpressBodyText}</div>`)
    }
  };

  useEffect(() => {
    initialAssignments();
  }, []);

  useEffect(() => {
  }, [wordpressTitle, wordpressFeaturedImage])


  const handleCancel = () => {
    setOpenRichTextEditor(!openRichTextEditor);
  };

  const handleSave = (value) => {
    setBodyText(value);
    const oldData = { ...media_items_arrangement };
    const newData = {
      ...oldData,
      wordpress: { ...oldData.wordpress, text: value },
    };
    dispatch(setMediaArrangement(newData));
    setOpenRichTextEditor(!openRichTextEditor);
  };

  return (
    <>
      <Flex
        gap={3}
        className={`mt-2`}
      >

      </Flex>
      <ParentSection>
        <Wrapper>

          <PreviewContentArea
            style={{
              borderColor: `${!config.isDark ? "#ededed" : "transparent"}`,
            }}
            mainBgColor={!config.isDark ? "white" : "#0b1727"}
            theme={!config.isDark ? "1px 1px 3px #949494" : "none"}
          >
            <Flex
              gap={2}
              alignContent={"start"}
              alignItems={"start"}
              style={{
                width: '100%',
                padding: '2%'
              }}
            >
              {displayPlatformIcon(PlatformIdentifier.WORDPRESS)}
              <Info>
                <span
                  type={"p"}
                  style={{
                    fontSize: 11,
                    fontWeight: "600",
                    color: `${!config.isDark ? "#050505" : "#E4E6EB"}`,
                  }}
                >
                  WordPress Preview
                </span>
              </Info>
            </Flex>


            {
              wordpressTitle ?
                <h2 className="text-capitalize text-center px-2" style={{ fontSize: "18px" }}>
                  {wordpressTitle} </h2> :
                <p
                  style={{
                    fontSize: 9,
                    fontStyle: 'italic',
                  }}
                >
                  No Title. Add title in wordpress options below.
                </p>
            }
            {
              wordpressFeaturedImage &&
              <ImageSection>
                <img src={wordpressFeaturedImage["url"]}
                  alt={"Wordpress Featured Thumbnail"}
                  height={300}
                  width={'100%'}
                  style={{
                    borderRadius: 10,
                    objectFit: 'cover'
                  }}
                />
              </ImageSection>
            }
            <ContentHeader>
              <div>
                <ContentHeaderTitle>In</ContentHeaderTitle>
                {
                  wordpressCategory.length > 0 ?
                    wordpressCategory?.map((category) => (
                      <ContentHeaderItem>
                        {category}
                      </ContentHeaderItem>
                    ))
                    : <ContentHeaderItem>Posts</ContentHeaderItem>
                }
              </div>

              <div>
                <ContentHeaderTitle>Tags</ContentHeaderTitle>
                {
                  wordpressTags.length > 0 ?
                    wordpressTags?.map((tag) => (
                      <ContentHeaderItem>
                        {tag}
                      </ContentHeaderItem>
                    ))
                    :
                    <ContentHeaderItem>People</ContentHeaderItem>
                }
              </div>

              <div>
                <ContentHeaderTitle>
                  {moment(date).format("MMM DD, YYYY")}
                </ContentHeaderTitle>
              </div>


              <Flex
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Avatar icon={faUser} iconSize={12} />
                <ContentHeaderItem style={{ margin: "0 3px" }}>{
                  `${loggedInUser?.first_name} ${loggedInUser?.last_name}`
                }</ContentHeaderItem>
              </Flex>

            </ContentHeader>

            <BodyArea
              dangerouslySetInnerHTML={{ __html: bodyText }}
            ></BodyArea>
          </PreviewContentArea>
        </Wrapper >
        <EditContainer style={{ justifyContent: "flex-start" }}>
          <CircularButton
            editBgColor={!config.isDark ? "#e0e0e0" : "#333333"}
            onClick={() => setOpenRichTextEditor(!openRichTextEditor)}
            title="Edit Post Text"
          >
            <FaEdit size={20} />
          </CircularButton>
        </EditContainer>
      </ParentSection>
      {openRichTextEditor ? (
        <RichEditor
          ref={bodyRef}
          setBodyText={setBodyText}
          bodyText={bodyText}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      ) : null}
    </>
  );
};

export default Wordpress;
