import React, { useEffect, useRef, useState, useContext } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { darkTheme, lightTheme } from "utils/theme";
import { EditPopUp } from "./styles/facebook";
import AppContext from "context/Context";
import { ProgressBar, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { PlatformIdentifier } from "../../../../../constants";
import { setSelectedPlatformForPreview, getSelectedPlatformForPreview } from "redux/slices/platformSlice";
import {
  getManuallyEditedPlatforms,
  setManuallyEditedPlatforms,
  setTriggerComponentRemount
} from "redux/slices/postslice";

import APIService from "http/api_service";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import { getMediaArrangement, setMediaArrangement } from "redux/slices/publishable_media_arrangement_slice";

const BaseVideoPlayer = ({
  styles,
  videoUri,
  controls,
  height,
  width,
  videoInfoText,
  checkingVideo,
  platform,
  videoHeightLimit,
  videoWidthLimit,
  file,
  fullFile,
  editVideoFormat,
  fileSizeError,
  maxVideoDuration,
  threadIndex
}) => {
  const dispatch = useDispatch();
  const [videoValid, setVideoValid] = useState(false);
  const videoRef = useRef();
  const [videoMedia, setVideoMedia] = useState([]);
  const [videoSize, setVideoSize] = useState(null);
  const [transcodeText, setTranscodeText] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [comparableUrl, setComparableUrl] = useState("");
  const [validVideoType, setValidVideoType] = useState(true);
  const [videoExists, setVideoExists] = useState("");
  const [videoDuration, setVideoDuration] = useState(0);
  const [fileTempUrl, setFileTempUrl] = useState("");
  const [progress, setProgress] = useState(0)
  const [loadTranscoder, setLoadTranscoder] = useState(false);
  const { config } = useContext(AppContext);
  const video = document.getElementById("video");
  const manuallyEditedPlatforms = useSelector(getManuallyEditedPlatforms);
  const media_items_arrangement = useSelector(getMediaArrangement);
  const socialPlatformVideoType = ["mp4"];
  const activePlatformTab = useSelector(getSelectedPlatformForPreview)
  // const twitterVideoDuration = 140;
  let activeWorkspace = useSelector(getActiveWorkSpace);

  useEffect(() => {
    if (platform === PlatformIdentifier.TWITTER) {
      console.log(media_items_arrangement, "media_items_arrangement")
      console.log(media_items_arrangement?.twitter, "media_items_arrangement??.twitter")
      media_items_arrangement?.twitter?.map((item) => setVideoMedia(item?.media));
    }
    if (platform === PlatformIdentifier.PINTEREST) {
      setVideoMedia(media_items_arrangement?.pinterest?.media);
    }
  }, [platform, media_items_arrangement]);

  const handleLoadedMetaData = () => {
    const loaded_video = videoRef.current;
    if (!loaded_video) return;
  };

  function refreshTab() {
    const activeTab = activePlatformTab;
    dispatch(setTriggerComponentRemount('refresh'));
    setTimeout(() => {
      dispatch(setTriggerComponentRemount(activeTab ?? PlatformIdentifier.FACEBOOK))
    }, 2000);
  }

  useEffect(() => {
    setTimeout(() => {
      const getVideoDuration = Math.floor(videoRef.current?.getDuration());
      setVideoDuration(getVideoDuration);
    }, 1000);

    setComparableUrl(videoUri);
    setFileTempUrl(videoUri)

    setVideoSize(file?.size / 1e6);
    setTimeout(() => {
      if (!checkingVideo) return;
      if (
        videoRef?.current?.videoHeight < videoHeightLimit &&
        videoRef?.current?.videoWidth < videoWidthLimit &&
        file?.type === "video/mp4"
      ) {
        setVideoValid(true);
      }
      const extension = file?.name?.split(".").pop().toLowerCase()
      const foundExtensionType = socialPlatformVideoType.find(element => element === extension);
      if (!foundExtensionType && file) {
        setValidVideoType(false);
      }
      if (!videoUri?.startsWith('https://firebasestorage.googleapis.com/v0/b/postly') && !file) {
        setValidVideoType(false);
      }
    }, 1000);

    setTranscodeText('Getting Started')
  }, []);

  const handleFileSize = () => {
    console.log('Handle file size...')
  }

  const handleFaultyVideo = () => {
    try {
      setLoadTranscoder(true);
      const videoRequestBody = new FormData();
      setProgress(0);
      const mediaFile = fullFile?.media?.map((item) => {
        return item;
      });

      let metaData = {
        PlatformIdentifier: platform,
      };

      for (const item of mediaFile) {
        if (!item?.external && item?.is_video) {
          console.log('aboyt apendng file now')
          videoRequestBody.append("file", file);
          console.log('appended file now')
        }
        console.log(videoRequestBody, "videoRequestBody 1")
        setTranscodeText("Getting Started");
        if (item?.external && item?.is_video) {
          let externalFileName = item?.url;
          metaData.video_url = externalFileName;
          videoRequestBody.append("data", JSON.stringify(metaData));
        }
      }

      APIService.transcodeVideo(
        activeWorkspace._id,
        videoRequestBody,
        platform,
        !fullFile?.external && videoSize,
        {
          uploadProgressListener: (uploadProgress) => {
            const roundProgress = Math.ceil((uploadProgress) / 1e6);
            setTranscodeText("Uploading");
            setProgress(roundProgress);
          },
          transcodeProgressListener: (transcodeProgress) => {
            const roundProgress = Math.ceil(transcodeProgress);
            setTranscodeText("Transcoding");
            setProgress(roundProgress);
          },
          completionListener: async (result) => {
            console.log(result, "result")
            setValidVideoType(true);
            setTranscodeText("Complete");
            setVideoUrl(result?.data);
            setFileTempUrl(result?.data)
            setTimeout(() => {
              setLoadTranscoder(false)
            }, 2000);
            const oldData = { ...media_items_arrangement }
            const newtwitter = oldData?.twitter?.map((item, index) => {
              if (index == threadIndex) {
                let newObj = {
                  ...item,
                  media: item?.media?.map((x) => {
                    if (x?.url === videoUri) {
                      return {
                        ...x,
                        url: result?.data,
                        external: true,
                        is_video: true,
                        optimized: true,
                        file: undefined
                      }
                    }
                    return x;
                  })
                }
                return newObj;
              }
              return item;
            })
            console.log(newtwitter, "newMediaItems")
            setValidVideoType(true);
            dispatch(setMediaArrangement({ ...oldData, twitter: newtwitter }))
            if (!manuallyEditedPlatforms[platform]) {
              if (platform === PlatformIdentifier.TWITTER) {
                const newData = { ...manuallyEditedPlatforms, twitter: true };
                dispatch(setManuallyEditedPlatforms(newData));
                setTimeout(() => {
                  dispatch(setSelectedPlatformForPreview(PlatformIdentifier.TWITTER));
                }, 1000);
              }
            }
            setVideoValid(false);
            await refreshTab();
          },
          errorListener: (error) => {
            toast.error(error, { theme: "colored" });
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const loadTranscodeProgress = (
    <div style={{
      backgroundColor: '#ffffff',
      position: 'absolute',
      zIndex: 10,
      top: '50%',
      width: "80%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: 'column',
      padding: "0.5em 1em",
      transform: "translate(35px, 10px)"
    }}>
      <div style={{ fontSize: '13px', marginBottom: '10px' }}>
        <span style={{ marginRight: '10px' }}>{`${transcodeText}`}</span>
        {(progress === 0 || isNaN(progress) || progress == null) ?
          <Spinner animation="border" size="sm" variant="success" /> :
          <span>{`${progress}%`}</span>}
      </div>
      <ProgressBar
        style={{
          width: '100%'
        }}
        striped
        variant="success"
        now={`${progress || 0}`}
      />
    </div>
  );

  const fixVideoFormatDialog = !validVideoType && (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        backgroundColor: "#949ca4AA",
        zIndex: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "80%",
            backgroundColor: `${config.isDark
              ? darkTheme.workSpaceSettingsHeaderBar
              : lightTheme.emptyButtonActive
              }`,
            padding: 10,
            borderRadius: 10,
          }}
        >
          <EditPopUp
            style={{
              color: `${!config.isDark && "black"}`,
              fontSize: 13,
            }}
          >
            {editVideoFormat}
            {/* <EditPopUpBtnDiv>
              <Button
                tintBackground={config.isDark}
                variant="primary"
                onClick={handleFaultyVideo}
                style={{ width: "70px" }}
              >
                Fix
              </Button>
            </EditPopUpBtnDiv> */}
          </EditPopUp>
        </div>
      }
    </div>
  );

  return (
    <>
      <div>{loadTranscoder && loadTranscodeProgress}</div>
      <div>{platform !== PlatformIdentifier.PINTEREST && fixVideoFormatDialog}</div>
      {videoValid && platform !== PlatformIdentifier.PINTEREST && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            backgroundColor: "#949ca4AA",
            zIndex: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "80%",
                backgroundColor: `${config.isDark
                  ? darkTheme.workSpaceSettingsHeaderBar
                  : lightTheme.emptyButtonActive
                  }`,
                padding: 10,
                borderRadius: 10,
              }}
            >
              <EditPopUp
                style={{
                  color: `${!config.isDark && "black"}`,
                  fontSize: 13,
                }}
              >
                {fileSizeError}
                {/* <EditPopUpBtnDiv>
                  <Button
                    tintBackground={config.isDark}
                    variant="primary"
                    onClick={handleFileSize}
                    style={{ width: "70px" }}
                  >
                    Fix
                  </Button>
                </EditPopUpBtnDiv> */}
              </EditPopUp>
            </div>
          }
        </div>
      )}
      {!videoExists ? (
        <ReactPlayer
          ref={videoRef}
          controls
          wrapper={"div"}
          config={{
            file: {
              attributes: {
                autoPlay: false,
              },
            },
          }}
          url={videoUri}
          width={width}
          height={height}
          style={{
            width: "100%",
            height: "100%",
            ...styles,
          }}
        />
      ) : (
        <video
          ref={videoRef}
          onLoadedMetadata={handleLoadedMetaData}
          onContextMenu={false}
          controls={video?.paused ? false : true}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: 10,
            maxHeight: "350px",
            position: "relative",
            zIndex: `${video?.paused ? 0 : 2}`,
          }}
          id="video"
        >
          <source src={videoUri} type="video/mp4" />
        </video>
      )}
    </>
  );
};

export default BaseVideoPlayer;
